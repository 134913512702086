<template>
    <div>
        <v-dialog v-model="dialog" :overlay="false" max-width="800px" transition="dialog-transition">
            <v-card>
                <v-toolbar dense color="primary" dark>
                    <v-toolbar-title>Confirmación de transferencia</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="closeDialog">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-card-title primary-title>
                                Lista de artículos
                            </v-card-title>
                        </v-col>
                        <v-col cols="12" md="6" class="pl-4 ">
                            <v-switch v-model="editedItem.incomplete_items" @click="activateCheck"
                                label="No he recibido todos los elementos" color="red darken-3"></v-switch>
                        </v-col>
                        <v-col cols="12" md="12" v-if="editedItem.observations !== null" class="pl-6 pt-0 pb-0">
                            <div class="subtitle-2">
                                Observaciones del envío: <p class="font-weight-regular">{{
                                    editedItem.observations }}</p>
                            </div>
                        </v-col>
                        <v-col cols="12" md="12" class="pl-4 pt-0 pb-0" v-if="editedItem.incomplete_items">
                            <v-textarea label="Observaciones de la confirmación" rows="1"
                                v-model="editedItem.confirmation_remarks"></v-textarea>
                        </v-col>
                        <v-col cols="12" md="12" class="pl-4 pt-0 pb-0" v-if="editedItem.incomplete_items">
                            <p class="caption">
                                <b>
                                    <v-icon color="amber darken-3">fa-exclamation-circle</v-icon> Seleccione unicamente los
                                    elementos que no ha recibido
                                </b>
                            </p>
                        </v-col>
                        <v-col cols="12">
                            <v-data-table :headers="headers" :items="editedItem.details" :single-select="showCheckbox"
                                :show-select="!showCheckbox" :show-checkbox="!showCheckbox" v-model="selected">
                            </v-data-table>
                        </v-col>

                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="secondary" @click.stop="closeDialog">Cancelar</v-btn>
                    <v-btn color="primary" @click.stop="saveForm">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: "FormSaveTransfer",
    components: {},
    props: {
        allInfoArticles: {
            type: Array,
            default: null
        },
    },
    data: () => {
        return {
            dialog: false,
            headers: [
                { text: 'Nombre del Artículo', value: 'name' },
                { text: 'Unidades completas', value: 'full_amount_sent' },
                { text: 'Unidades Incompletas', value: 'incomplete_amount_sent' },

            ],
            editedItem: {},
            showCheckbox: true,
            selected: []
        }
    },
    mounted() { },
    methods: {
        ...mapActions(['chgLoad', 'confirmTransfer']),
        activateCheck() {
            this.showCheckbox = !this.showCheckbox;
        },
        openDialog(item) {
            this.editedItem = {
                'id': item.id,
                'status': item.status,
                'observations': item.observations !== null && item.observations.length !== 0 ? item.observations : null,
                'incomplete_items': false,
                'warehouse_origin_id': item.warehouse_origin_id,
                'warehouse_destination_id': item.warehouse_destination_id,
                'details': []
            };

            if (item && item.transfer_details.length > 0) {
                item.transfer_details.forEach((element) => {
                    const transferDetail = {
                        'id': element.id,
                        'full_amount_sent': element.full_amount_sent,
                        'incomplete_amount_sent': element.incomplete_amount_sent,
                        'article_id': element.article_id,
                    };
                    this.editedItem.details.push(transferDetail);
                })
            }

            this.editedItem.details.forEach(elm => {
                const selectedItem = this.allInfoArticles.find(item => item.id === elm.article_id)
                elm['name'] = selectedItem.name
            })

            this.dialog = true;
        },

        closeDialog() {
            this.editedItem = {}
            this.selected = []
            this.dialog = false
            this.incomplete_items = false
            this.showCheckbox = true
        },

        saveForm() {
            try {
                if (this.editedItem.incomplete_items && (this.selected.length <= 0)) {
                    throw new Error('Al seleccionar que no ha recibido elementos, debe indicar cuales son')
                }

                if (this.editedItem.incomplete_items && this.selected.length > 0) {
                    this.editedItem.selected = this.selected
                }

                if (!this.editedItem.incomplete_items && this.selected.length > 0) {
                    if ('selected' in this.editedItem) {
                        delete this.editedItem.selected;
                    }

                }

                this.$swal({
                    title: "Confirmación",
                    text: "¿Está seguro de guardar la información registrada?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, deseo guardar',
                    cancelButtonText: 'Cancelar'
                })
                    .then(async (res) => {
                        if (res.isConfirmed) {
                            this.chgLoad(true)
                            try {
                                var msg = "";
                                msg = await this.confirmTransfer(this.editedItem);
                                this.$root.$alert({ text: msg.message, color: "success", timeout: 2000 });
                                await this.$emit('initialize2');
                                this.closeDialog();
                            }
                            catch (error) {
                                this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 5000 });
                                this.chgLoad(false);
                            }
                            this.chgLoad(false);
                        }
                    })
                    .catch((error) => {
                        this.$root.$alert({ text: error, color: "error", timeout: 5000 });
                        this.chgLoad(false);

                    })
                this.chgLoad(false);

            }
            catch (error) {
                this.chgLoad(false);
                this.$root.$alert({ text: error, color: "error", timeout: 5000 });
            }

        }
    },
    computed: {}
}
</script>

<style lang="scss" scoped></style>