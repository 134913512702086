  <template>
    <div>
      <v-data-table
        :headers="headers"
        :items="allEquipmentTypes"
        :search="search"
        :loading="loading"
        sort-by="title"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fa-angle-double-left',
          lastIcon: 'fa-angle-double-right',
          prevIcon: 'fa-angle-left',
          nextIcon: 'fa-angle-right'
        }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Tipos de Equipo</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-tooltip bottom v-if="can('equipment_type-create')">
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="$refs.formEquipmentType2.newItem()">
                  <v-icon dark>fa-plus</v-icon>
                </v-btn>
              </template>
              <span>Nuevo</span>
            </v-tooltip>
          </v-toolbar>
        </template>

        <template v-slot:item.inventory="props">
          {{stateItem(props.item.inventory)}}
        </template>

        <template v-slot:item.active="props">
          {{stateItem(props.item.active)}}
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="$refs.formEquipmentType2.editItem(item)" v-on="on" class="mr-1 my-1" color="info">fa-pen</v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom v-if="can('equipment_type-delete')">
            <template v-slot:activator="{ on }">
              <v-icon @click="deleteItem(item)" v-on="on" class="mr-1" color="error">fa-trash</v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn text icon color="green" dark @click="initialize()" v-on="on"><v-icon dark v-on="on">fa-sync-alt</v-icon></v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <FormEquipmentType ref="formEquipmentType2"/>
    </div>
</template>
<script>
import { can } from '../../util/functions';
import { mapActions, mapGetters } from 'vuex'
import FormEquipmentType from './FormEquipmentType'
  export default{
    name: 'EquipmentType',
    components: {
      FormEquipmentType
    },
    data() {
      return {
        search: '',
        headers: [
            { text: 'Id', value: 'id' },
            { text: 'Nombre', value: 'name' },
            { text: 'Inventario', value: 'inventory' },
            { text: 'Activo', value: 'active' },
            { text: 'Acciones', value: 'action', sortable: false },
        ],
      }
    },

    computed: {
      ...mapGetters(['allEquipmentTypes','loading']),
      currentUser(){
        return this.$store.getters.currentUser
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      ...mapActions(['deleteEquipmentType', 'getEquipmentTypes', 'chgLoad']),
      can:can,
      async initialize () {
        this.chgLoad(true)
        try{
          await this.getEquipmentTypes()
        }catch(error) {
          console.error(error)
        }
        this.chgLoad(false)
      },

      stateItem (stateIt) {
        return stateIt ? 'Activo' : 'Inactivo'
      },

      async deleteItem (item) {
        this.$root.$confirm('Eliminar Tipo de Equipo', 'Esta Seguro?', { color: 'red' })
        .then(async (res) => {
          if(res){
            this.chgLoad(true)
            try{
              var msg = await this.deleteEquipmentType(item)
              this.$root.$alert({text:msg.message,color:"success",timeout:3000})
            }catch(error){
              console.error(error)
              this.$root.$alert({text:"Imposible Eliminar Registro",color:"error",timeout:3000})
            }
            this.chgLoad(false)
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

    }
  }
</script>
