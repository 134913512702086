<template>
    <div>
        <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="1500px" transition="dialog-transition">
            <v-card>
                <v-toolbar dense color="primary" dark>
                    <v-toolbar-title v-if="!searchForm.found">Datos del solicitante</v-toolbar-title>
                    <v-toolbar-title v-else>Entrega de consumibles</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="closeDialog">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-card-text>
                    <span class="subtitle-1 font-weight-bold mx-2 my-2">Datos de la entrega</span>
                    <v-container fluid>
                        <template v-if="!searchForm.found">
                            <v-form ref="formDelivery1_" @submit.prevent="findUser">
                                <v-row>
                                    <v-col cols="12" md="6" class="my-0 py-0">
                                        <v-autocomplete autofocus v-model="searchForm.type" :items="types"
                                            id="searchFormType" label="* Tipo de Solicitante"
                                            placeholder="Seleccione el Tipo de Solicitante" overflow
                                            required></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-0 py-0">
                                        <v-text-field v-model="searchForm.id" label="Identificación / Código"
                                            id="searchFormId" @keyup.enter="findUser" append-outer-icon="fa-greater-than"
                                            required @click:append-outer="findUser"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </template>
                        <template v-else>
                            <v-row>
                                <v-col cols="12" md="6" class="my-0 py-0">
                                    <span class="font-weight-bold">Identificación: </span>
                                    <span class="info--text">{{ editedItem.third.identification }}</span>
                                </v-col>
                                <v-col cols="12" md="6" class="my-0 py-0">
                                    <span class="font-weight-bold">Nombre: </span>
                                    <span class="info--text">{{ editedItem.third.name }}</span>
                                </v-col>
                                <v-col cols="12" md="6" class="my-0 py-0">
                                    <span class="font-weight-bold">Email: </span>
                                    <span class="info--text">{{ editedItem.third.email }}</span>
                                </v-col>
                                <v-col cols="12" md="6" class="my-0 py-0">
                                    <span class="font-weight-bold">Teléfono(s): </span>
                                    <span class="info--text">{{ (editedItem.third.phone ? editedItem.third.phone + ' - ' :
                                        '')
                                        +
                                        (editedItem.third.mobile ? editedItem.third.mobile : '') }}</span>
                                </v-col>
                                <v-col cols="12" md="6" class="my-0 py-0">
                                    <span class="font-weight-bold">Tipo de Solicitante: </span>
                                    <span class="info--text">{{ editedItem.type_borrower }}</span>
                                </v-col>
                                <v-col cols="12" md="6" class="my-0 py-0">
                                    <span class="font-weight-bold">Datos del Solicitante: </span>
                                    <span class="info--text">{{ editedItem.data_borrower }}</span>
                                </v-col>
                                <v-col cols="12" md="6" v-if="editedItem.date" class="my-0 py-0">
                                    <span class="font-weight-bold">Fecha Préstamo: </span>
                                    <span class="info--text">{{ editedItem.date }}</span>
                                </v-col>
                                <v-col cols="12" md="6" v-if="editedItem.user_loan" class="my-0 py-0">
                                    <span class="font-weight-bold">Colaborador Préstamo: </span>
                                    <span class="info--text">{{ editedItem.user_loan.name }}</span>
                                </v-col>
                                <v-col cols="12" class="my-0 py-0">
                                    <v-autocomplete v-model="editedItem.warehouse_origin_id" :items="allMyWarehouses"
                                        item-value="id" item-text="name" id="place_id" label="* Bodega"
                                        placeholder="Seleccione la bodega donde se realizará el préstamo" required
                                        @change="changeOrigin(editedItem.warehouse_origin_id)" clearable></v-autocomplete>
                                </v-col>

                            </v-row>
                            <!-- <div v-if="editedItem.warehouse_origin_id"> -->
                            <div v-if="editedItem.warehouse_origin_id">
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line
                                    hide-details></v-text-field>
                                <v-data-table :headers="headers" :items="infoArticles" :loading="loading" :search="search"
                                    v-model="selected" :single-select="singleSelect" show-select item-key="id"
                                    @update:selected="updateSelected">

                                    <template v-slot:item.units_complete_send="{ item }">
                                        <v-edit-dialog :return-value.sync="item.units_complete_send" large persistent
                                            @save="save(item)" @cancel="cancel" @open="open" @close="close"
                                            @keyup.enter="save(item)" v-model="item.units_complete_send" save-text="Guardar"
                                            cancel-text="Cancelar">
                                            <div>
                                                {{ item.units_complete_send }}
                                            </div>
                                            <template v-slot:input>
                                                <div class="mt-4 text-h6">
                                                    Número de unidades completas
                                                </div>
                                                <v-text-field v-model="item.units_complete_send" label="Ingrese el valor"
                                                    single-line autofocus></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </template>

                                    <template v-slot:item.units_incomplete_send="{ item }">
                                        <v-edit-dialog :return-value.sync="item.units_incomplete_send" large persistent
                                            @save="save(item)" @cancel="cancel" @open="open" @keyup.enter="save(item)"
                                            v-model="item.units_incomplete_send" save-text="Guardar" cancel-text="Cancelar">
                                            <div>
                                                {{ item.units_incomplete_send }}
                                            </div>
                                            <template v-slot:input>
                                                <div class="mt-4 text-h6">
                                                    Número de unidades incompletas
                                                </div>
                                                <v-text-field v-model="item.units_incomplete_send" label="Ingrese el valor"
                                                    single-line autofocus></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </template>

                                    <template v-slot:header.units_complete_send="{}">
                                        <span>Digite unidades completas </span>
                                        <v-icon small color="orange">mdi-pencil</v-icon>
                                    </template>


                                    <template v-slot:header.units_incomplete_send="{}">
                                        <span>Digite Unidades incompletas</span>
                                        <v-icon small color="orange">mdi-pencil</v-icon>
                                    </template>

                                </v-data-table>
                                <v-col cols="12">
                                    <v-textarea outlined name="observation" id="observation" label="Observación Préstamo"
                                        v-model="editedItem.observation"></v-textarea>
                                </v-col>
                            </div>
                            <v-snackbar v-model="snack" :timeout="6000" :color="snackColor">
                                {{ snackText }}

                                <template v-slot:action="{ attrs }">
                                    <v-btn v-bind="attrs" text @click="snack = false">
                                        Cerrar
                                    </v-btn>
                                </template>
                            </v-snackbar>

                        </template>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="secondary" @click.stop="closeDialog">Cancelar</v-btn>
                    <v-btn color="primary" @click.stop="saveForm" v-if="editedItem.warehouse_origin_id">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { can } from '../../../util/functions';
import { URL_APP } from '../../../util/constants';

export default {
    name: 'FormDelivery',
    props: {
        allMyWarehouses: {
            type: Array,
            default: null
        },
        allInfoArticles: {
            type: Array,
            default: null
        },
        allArticlesLocation: {
            type: Array,
            default: null
        }
    },
    data: () => {
        return {
            dialog: false,
            snack: false,
            snackColor: '',
            snackText: '',
            editedItem: {},
            notifyEmail: '',
            infoArticles: [],
            selected: [],
            singleSelect: false,
            search: '',
            searchForm: {
                found: false,
                type: '',
                id: '',
            },
            types: [
                'Estudiante', 'Docente', 'Administrativo', 'Externo'
            ],
            headers: [{
                text: 'ID',
                align: 'start',
                value: 'id'
            },
            { text: 'Nombre', value: 'name' },
            { text: 'Unidades completas disponibles', value: 'units_full_available', sorteable: false },
            { text: 'Digite unidades completas', value: 'units_complete_send', sorteable: false },
            { text: 'Unidades incompletas disponibles', value: 'units_incomplete_available', sorteable: false },
            { text: 'Digite las unidades incompletas', value: 'units_incomplete_send', sorteable: false },
            ],
        }
    },

    watch: {
        // Vigilar los cambios en el array 'selected' y actualizar el orden en 'infoArticles'
        selected: {
            handler: 'updateSelected',
            deep: true,
        },
    },

    methods: {
        ...mapActions(['chgLoad', 'saveDelivery']),
        can: can,

        save(item) {
            if (item.units_full_available < item.units_complete_send) {
                this.snack = true
                this.snackColor = 'error'
                this.snackText = 'El número de unidades completas a envíar superan el stock'
            }
            else if (item.units_incomplete_available < item.units_incomplete_send) {
                this.snack = true
                this.snackColor = 'error'
                this.snackText = 'El número de unidades incompletas a envíar superan el stock'
            }
            else {

                this.snack = true;
                this.snackColor = 'success';
                this.snackText = 'Información guardada';
            }

        },

        cancel() {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Cancelado'
        },

        open() {
            this.snack = true
            this.snackColor = 'info'
            this.snackText = 'Edición Abierta'
        },

        close() {
        },

        updateSelected(selected) {
            const selectedItems = this.infoArticles.filter(item => selected.includes(item));
            const nonSelectedItems = this.infoArticles.filter(item => !selected.includes(item));
            this.infoArticles = [...selectedItems, ...nonSelectedItems];
        },

        openDialog() {
            this.dialog = true
        },

        closeDialog() {
            this.editedItem = {}
            this.editedItem.third = {}
            this.editedItem.articles = []
            this.infoArticles = []
            this.selected = []
            this.dialog = false
            this.notifyEmail = ''
            this.searchForm = {
                found: false,
                type: '',
                id: '',
            }
        },

        // Vamos a buscar al usuario a quien realizar el préstamo 
        async findUser() {
            if (!this.loading) {
                if (this.searchForm.type == '') {
                    this.$root.$alert({ text: "Tipo de Solicitante Obligatorio", color: "error", timeout: 3000 })
                    document.getElementById('searchFormType').focus()
                }

                else if (this.searchForm.id == '') {
                    this.$root.$alert({ text: "Identificación Obligatoria", color: "error", timeout: 3000 })
                    document.getElementById('searchFormId').focus()
                }

                else {
                    this.chgLoad(true)
                    let params = {
                        'type': this.searchForm.type,
                        'id': this.searchForm.id
                    }
                    await this.$http.post(URL_APP + "/api/loan_consumables/infoTomasino", params)
                        .then((response) => {
                            this.editedItem.third = response.data
                            this.notifyEmail = this.editedItem.third.email;
                            if (this.notifyEmail) {
                                this.searchForm.found = true
                                this.editedItem.type_borrower = this.searchForm.type
                                if (this.editedItem.third.department) {
                                    this.editedItem.data_borrower = this.editedItem.third.department
                                }
                                if (Array.isArray(this.editedItem.third.programs)) {
                                    if (this.editedItem.third.programs.length > 0) {
                                        this.editedItem.data_borrower = this.editedItem.third.programs[0].NOM_UNIDAD
                                    }
                                }
                                if (this.searchForm.type == "Externo") {
                                    this.editedItem.data_borrower = "Personal Externo - Contratistas"
                                }
                                this.editedItem.third_id = this.editedItem.third.id
                            }
                            // else {
                            //     this.$refs.formThird.show(this.editedItem.third)
                            // }
                        })
                        .catch((error) => {
                            this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 3000 })
                            // if (this.searchForm.type == "Externo") {
                            //     this.thirdItem.identification = this.searchForm.id
                            //     this.$refs.formThird.show(this.thirdItem)
                            // }
                        })
                    this.chgLoad(false)

                }
            }
        },

        changeOrigin(item) {
            this.selected = []
            const selectedItem = this.allMyWarehouses.find(elm => elm.id === item);
            this.title = selectedItem ? selectedItem.name : '';

            this.infoArticles = this.allArticlesLocation
                .filter(elm => elm.warehouse_id === item)
                .map(elm => {
                    const selectedItem = this.allInfoArticles.find(item => item.id === elm.article_id);
                    return {
                        ...elm,
                        name: selectedItem.name,
                        units_complete_send: 0,
                        units_incomplete_send: 0,
                        key: elm.id
                    };
                });

        },

        async saveForm() {
            try {
                this.editedItem.articles = []; //Articulos que vamos a modificar

                if (!this.editedItem.warehouse_origin_id) {
                    throw "No ha seleccionado la bodega";
                }

                if (!(this.selected.length > 0)) {
                    throw "No ha seleccionado elementos para enviar";
                }
                for (const element of this.selected) {
                    if (isNaN(element.units_complete_send)) {
                        throw `El valor en unidades completas para ${element.name} no es numérico`
                    }

                    if (isNaN(element.units_incomplete_send)) {
                        throw `El valor en unidades incompletas para ${element.name} no es numérico`
                    }

                    if (element.units_full_available < element.units_complete_send) {
                        throw `El artículo ${element.name} no tiene suficientes existencias de unidades completas`
                    }

                    if (element.units_incomplete_available < element.units_incomplete_send) {
                        throw `El artículo ${element.name} no tiene suficientes existencias de unidades incompletas`
                    }

                    if (element.units_complete_send == 0 && element.units_incomplete_send == 0) {
                        throw `Debe definir un valor para realizar el préstamo del artículo ${element.name} `

                    }

                    const { name, article_id, units_complete_send, units_incomplete_send } = element;

                    this.editedItem.articles.push({
                        article_id: article_id,
                        name: name,
                        units_complete_send: units_complete_send,
                        units_incomplete_send: units_incomplete_send
                    });
                }

                this.$swal({
                    title: "Confirmación",
                    text: "¿Está seguro de guardar la información registrada?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, deseo guardar',
                    cancelButtonText: 'Cancelar'
                }).then(async (res) => {
                    if (res.isConfirmed) {
                        this.chgLoad(true);
                        try {
                            var msg = "";
                            msg = await this.saveDelivery(this.editedItem);
                            this.$root.$alert({ text: msg.message, color: "success", timeout: 2000 });
                            await this.$emit('initialize3');
                            this.closeDialog();
                        } catch (error) {
                            this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 5000 });
                            this.chgLoad(false);
                        }
                        this.chgLoad(false);
                    }
                });

            }
            catch (error) {
                this.chgLoad(false);
                this.$root.$alert({ text: error, color: "error", timeout: 5000 });
            }
        }
    },
    computed: {
        ...mapGetters(['loading'])
    },

    mounted() { }

}
</script>

<style lang="scss" scoped></style>