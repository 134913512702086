<template>
  <div>
    <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
      <v-card>
        <v-toolbar color="primary" dense dark>
          Trasladar Equipos
          <v-spacer/>
          <v-btn icon small>
            <v-icon small @click="close">fa-times</v-icon>
          </v-btn>
        </v-toolbar>
          <v-card-text>
            <v-form ref="formTransfer" v-model="valid">
              <v-container>
                  <v-row>
                    <template v-if="editedItem.fields.length > 0">
                      <template v-for="(field, index) in equipmentFields">
                        <template v-if="field.column_id=='third_id'">
                          <v-col cols=12 md=6 :key="'col1'+field.id+index">
                              <v-text-field readonly
                                v-model="third.identification"
                                label="Identificación Responsable"
                                type="text" :disabled="editedItem.id?true:false"
                                clearable
                                @click:clear="setThird(defaultThird)"
                              >
                                <template v-slot:append-outer>
                                  <template v-if="!editedItem.id">
                                    <v-tooltip
                                      bottom
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="info" @click="$refs.formThird.editItem()">
                                          fa-plus
                                        </v-icon>
                                      </template>
                                      Agregar Responsable
                                    </v-tooltip>
                                  </template>
                                </template>
                                <template v-slot:prepend>
                                  <template v-if="!editedItem.id">
                                    <v-tooltip
                                      bottom
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="success" @click="$refs.selectThird.show()">
                                          fa-clipboard-list
                                        </v-icon>
                                      </template>
                                      Seleccionar Responsable
                                    </v-tooltip>
                                  </template>
                                </template>
                              </v-text-field>
                          </v-col>
                          <v-col cols=12 md=6 :key="'col2'+field.id+index">
                            <v-text-field disabled
                              v-model="third.name"
                              label="Nombre" 
                              required 
                              type="text"
                            />
                          </v-col>
                        </template>
                        <v-col cols=12 md=6 v-if="field.column_id=='cost_center_id'" :key="'col3'+field.id+index">
                          <v-autocomplete
                            v-model="equipmentFields[index].pivot.value" 
                            item-value="id" 
                            item-text="name" :disabled="editedItem.id?true:false"
                            :items="allCostCenters"
                            label="Centro de Costos"
                            placeholder="Seleccione el Centro de Costos"
                            clearable
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols=12 md=6 v-if="field.column_id=='location_id'" :key="'col4'+field.id+index">
                          <v-autocomplete
                            v-model="equipmentFields[index].pivot.value" 
                            item-value="id" 
                            item-text="name" :disabled="editedItem.id?true:false"
                            :items="allLocations"
                            label="Ubicación"
                            placeholder="Seleccione la Ubicación"
                            clearable
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols=12 md=6 v-if="field.column_id=='campus_id'" :key="'col5'+field.id+index">
                          <v-autocomplete
                            v-model="equipmentFields[index].pivot.value" 
                            item-value="id" 
                            item-text="name" 
                            :rules="rules.campus_id"
                            :items="allCampuses"
                            label="Campus"
                            placeholder="Seleccione el Campus"
                            required :disabled="editedItem.id?true:false"
                            clearable
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols=12 md=6 v-if="field.column_id=='building_id'" :key="'col6'+field.id+index">
                          <v-autocomplete clearable
                            v-model="equipmentFields[index].pivot.value" 
                            item-text="name" 
                            item-value="id" :disabled="editedItem.id?true:false"
                            :items="allBuildings"
                            label="Edificio"
                            placeholder="Seleccione el Edificio de Localización"
                          />
                        </v-col>
                        <v-col cols=12 md=6 v-if="field.column_id=='tier_id'" :key="'col7'+field.id+index">
                          <v-autocomplete clearable
                            v-model="equipmentFields[index].pivot.value" 
                            item-text="name" 
                            item-value="id" :disabled="editedItem.id?true:false"
                            :items="allTiers"
                            label="Piso"
                            placeholder="Seleccione el Piso de Localización"
                          />
                        </v-col>
                        <v-col cols=12 md=6 v-if="field.column_id=='area_id'" :key="'col8'+field.id+index">
                          <v-autocomplete clearable
                            v-model="equipmentFields[index].pivot.value" 
                            item-text="name" 
                            item-value="id" :disabled="editedItem.id?true:false"
                            :items="allAreas"
                            label="Área"
                            placeholder="Seleccione el Área de Localización"
                          />
                        </v-col>
                      </template>
                    </template>
                    <template v-else>
                      <v-col cols=12 md=6 >
                        <v-autocomplete v-if="editedItem.id==0"
                          v-model="editedItem.old_group_tic_id" 
                          item-value="id" 
                          item-text="name" 
                          :rules="rules.old_group_tic_id"
                          :items="allGroupTics"
                          label="Grupo Anterior"
                          placeholder="Seleccione el Grupo Anterior"
                          clearable
                        ></v-autocomplete>
                        <template v-else>
                          <b>Grupo Anterior: </b>{{editedItem.old_group.name}}
                        </template>
                      </v-col>
                      <v-col cols=12 md=6 >
                        <v-autocomplete v-if="editedItem.id==0"
                          v-model="editedItem.new_group_tic_id" 
                          item-value="id" 
                          item-text="name" 
                          :rules="rules.new_group_tic_id"
                          :items="selGroupTics"
                          label="Nuevo Grupo"
                          placeholder="Seleccione el nuevo Grupo"
                          clearable
                        ></v-autocomplete>
                        <template v-else>
                          <b>Nuevo Grupo: </b>{{editedItem.new_group.name}}
                        </template>
                      </v-col>
                    </template>
                    <v-col cols="12">
                      <v-data-table v-if="editedItem.id==0 && isgroup"
                        :headers="headers"
                        :items="editedItem.details"
                        item-key="id"
                        sort-by="description"
                        hide-default-footer
                      >
                        <template v-slot:top>
                          <v-toolbar color="white">
                            Equipos
                            <v-spacer></v-spacer>
                            <template >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="$refs.equipmentAdd.editedItem()" id="newEquipment">
                                    <v-icon small dark>fa-plus</v-icon>
                                  </v-btn>
                                </template>
                                <span>Nuevo</span>
                              </v-tooltip>
                            </template>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.action="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon @click="deleteItem(item)" v-on="on" class="mr-1 my-1" color="error">fa-trash</v-icon>
                            </template>
                            <span>Eliminar</span>
                          </v-tooltip>
                        </template>
                        <template v-slot:item.quantity="props">
                          <v-text-field v-if="!props.item.equipment.inventory" class="text-short"
                            v-model="props.item.quantity"
                            label="Cantidad" single-line counter
                            type="number"
                            hide-details
                            required
                          />
                          <template v-else>{{ props.item.quantity }}</template>
                        </template>
                      </v-data-table>
                      <v-simple-table dense v-else class="elevation-1">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-center body-2 font-weight-bold" colspan="2">Equipos</th>
                            </tr>
                            <tr>
                              <th class="text-left">Descripción</th>
                              <th class="text-left">Serial</th>
                              <th class="text-left">Inventario</th>
                              <template v-if="editedItem.id">
                                <th class="text-left" v-if="editedItem.details[0].value">Valor Anterior</th>
                                <th class="text-left" v-if="editedItem.details[0].quantity">Cantidad</th>
                              </template>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, indexd) in editedItem.details" :key="`eq${item.equipment.id}id${indexd}`">
                              <td>{{ item.equipment.description }}</td>
                              <td>{{ item.equipment.serial }}</td>
                              <td>{{ item.equipment.inventory }}</td>
                              <template v-if="editedItem.id">
                                <td v-if="item.value">{{ item.field.name }}: {{ item.value }}</td>
                                <td v-if="item.quantity">{{ item.quantity }}</td>
                              </template>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                    <v-col cols="12" v-if="editedItem.id">
                      {{editedItem.observation}}
                    </v-col>
                    <v-col v-else>
                      <v-textarea
                        required
                        label="Observación"
                        v-model="editedItem.observation"
                        :rules="rules.observation"
                      /> 
                    </v-col>
                  </v-row>
                </v-container>                
              </v-form>
            </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn outlined color="secondary darken-1" @click="close">Cancelar</v-btn>
            <v-btn outlined color="primary darken-1" @click="save" :disabled="!can('loan.equipment_transfer.create')">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <SelectThird ref="selectThird" @setThird="setThird"/>
    <EquipmentAdd ref="equipmentAdd" @addEquipment="addEquipment" :list_equipment="list_equipment"/>
    <FormThird ref="formThird" />
  </div>
</template>
<script>
  import { can } from '../../../util/functions';
  import { mapActions, mapGetters } from 'vuex'
  import { URL_APP } from "../../../util/constants";
  import SelectThird from '../../general/Third/SelectThird'
  import EquipmentAdd from '../equipment/EquipmentAdd'
  import FormThird from '../../general/Third/FormThird'
  export default{
    name: 'FormEquipmentTransfer',
    components: {
      SelectThird, FormThird, EquipmentAdd
    },
    props: {
      isgroup: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        dialog: false,
        rules: {
          observation: [
            v => !!v || 'Campo Requerido',
          ],
          old_group_tic_id:[
            v => !!v || 'Campo Requerido',
          ],
          new_group_tic_id:[
            v => !!v || 'Campo Requerido',
          ]
        },
        headers: [
          { text: 'Id', value: 'equipment.id'},
          { text: 'Descripcion', value: 'equipment.description'},
          { text: 'Serial', value: 'equipment.serial'},
          { text: 'Inventario', value: 'equipment.inventory'},
          { text: 'Cnt', value: 'quantity'},
          { text: 'Acciones', value: 'action', sortable: false },
        ],
        list_equipment: [],
        indexThird: -1,
        equipmentFields: [],
        third:{id:null, identification:'', name:''},
        defaultThird:{id:0, identification:'', name:''},
        editedItem: { details: [], fields: [], old_group: {}, new_group:{}, id: 0, new_group_tic_id: null,
          observation: "", old_group_tic_id: null, user: {}, user_id: 1 },
        defaultItem: { details: [], fields: [], old_group: {}, new_group:{}, id: 0, new_group_tic_id: null,
          observation: "", old_group_tic_id: null, user: {}, user_id: 1 },
        valid: true
      }
    },

    computed: {
      ...mapGetters(['allManufacturers','lastManufacturer','loading', 'allEquipmentFields',
          'allEquipmentTypes', 'allCampuses', 'allBuildings', 'allTiers',
          'allAreas', 'allCostCenters', 'allLocations','selGroupTics', 'allGroupTics']),

      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    mounted () {
      this.initialize()
      var this_2 = this
      window.addEventListener('keydown', function(e) {
        if (e.altKey == true && e.code.includes('KeyS')){
          this_2.save()
        }
      });
    },
    watch:{
      allEquipmentFields(){
        this.initialize()
      },
      'editedItem.old_group_tic_id'(val){
        this.getEquipmentLocal(val)
      },
      selGroupTics(){
        this.getEquipmentLocal()
      }
    },
    methods: {
      ...mapActions(['chgLoad']),
      can:can,
      initialize () {
        this.equipmentFields = []
        this.indexThird = -1
        var i = 0
        this.allEquipmentFields.forEach(field =>{
          if(field.column_id=='third_id')
            this.indexThird = i
          field.pivot = {value: ""}
          this.equipmentFields.push(field)
          i++
        })
        //this.editedItem.fields = this.defaultItem.fields
      },

      async getEquipmentLocal(val = this.editedItem.old_group_tic_id){
        var group = null
        var _this2 = this
        await Promise.all(_this2.selGroupTics.map( g => {
          if(g.id == val){
            group = g
          }
        }))
        if(group)
          this.list_equipment = group.equipment
      },

      transferItems (equipment) {
        var _this2 = this
        this.editedItem.details =[]
        equipment.forEach(e=> {
          if(e.type.inventory && e.state=="Activo")
            _this2.editedItem.details.push({equipment:e})
        })
        if(this.editedItem.details.length>0){
          this.dialog = true
          this.editedItem.fields = this.equipmentFields
        }else{
          this.$root.$alert({text:"No ha Seleccionado Equipos Válidos",color:"error",timeout:2000})
        }
      },

      editItem (item= null, group = null){
        if (item!=null)
          this.editedItem = item
        this.equipmentFields = []
        if(this.editedItem.id)
          this.equipmentFields = item.fields.filter(f=>{
            if(f.column_id == "third_id")
              this.getThird(f.pivot.value)
            return true
          })
        else if (group!=null)
          this.editedItem.old_group_tic_id = group
        this.dialog = true
      },

      async addEquipment(item){
        var _this2 = this
        var valid = true
        await Promise.all(_this2.editedItem.details.map(e=> {
          if(item.state=="Activo"){
            if(item.id==e.equipment.id){
              valid = false
              this.$root.$alert({text:"Equipo ya existe (2)",color:"error",timeout:2000})
            }
          }else{
            valid = false
            _this2.$root.$alert({text:"No tiene el estado Necesario",color:"error",timeout:2000})
          }
        }))
        
        if(valid){
          var detail = {equipment: item, quantity: 1}
          this.editedItem.details.push(detail)
          this.$root.$alert({text:"Añadido con éxito",color:"success",timeout:2000})
        }
        console.log(this.editedItem.details)
        this.$refs.equipmentAdd.editedItem()
      },

      async save(){
            this.$refs.formTransfer.validate()
            if(this.valid){
              if(this.equipmentFields.length>0){
                var newfields = this.equipmentFields.filter(e=>e.pivot.value)
                if(this.editedItem.fields.length<=0){
                  this.$root.$alert({text:"Debe realizar al menos un cambio",color:"error",timeout:2000})
                  this.valid = false
                }else{
                  this.editedItem.fields = newfields
                }
              }else if(this.editedItem.old_group_tic_id == this.editedItem.new_group_tic_id){
                this.$root.$alert({text:"Grupo inicial y final no puedenser iguales",color:"error",timeout:2000})
                this.valid = false
              }
              if(this.valid){
                this.chgLoad(true)
                try{
                  var response = await this.$http.post(URL_APP+"/api/loan/equipment_transfers",this.editedItem)
                  this.$root.$alert({text:response.data.message,color:"success",timeout:2000})
                  this.chgLoad(false)
                  this.close()
                  this.$emit('initialize')
                }catch(error){
                  this.chgLoad(false)
                  console.error(error)
                  this.$root.$alert({text:"Ocurrió un error al transferir los equipos",color:"error",timeout:2000})
                }
              }
            }else{
              this.$root.$alert({text:"Por favor Revise los campos obligatorios",color:"error",timeout:2000})
            }
      },

      async getThird (id) {
          await this.$http.get(URL_APP+"/api/third/"+id, { params:{type:"id"}}).then(response => {
            if (response.data.id) Object.assign(this.third, response.data)
            else this.$root.$alert({text:"No se encontro el tercero",color:"error",timeout:2000})
          }).catch((err)=> {
            console.error(err)
          })
      },

      setThird(third){
        this.third = third
        this.equipmentFields[this.indexThird].pivot.value = third.id
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedItem.equipment = this.defaultItem.equipment
          this.initialize()
        }, 300)
      },

    }
  }
</script>
