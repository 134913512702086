<template>
  <div>
    <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
      <v-card>
        <v-toolbar color="warning" dense>
          <v-icon class="mx-2 my-2">fa-exclamation</v-icon>
          <v-toolbar-title>
              Importante
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                X
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <p class="body-1 my-3">
            <b>Apreciado Colaborador:</b><br>
            Recuerde Informar al Solicitante que debe revisar la Notificación del Préstamo y Devolución de Salas y Equipos enviados al Correo Institucional: <b>{{ email? email : 'Sin Correo Registrado' }}</b>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" outlined @click="close">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  export default{
    name: 'AlertInformation',
    props: {
      email: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        dialog: false
      }
    },

    methods: {

      close () {
        this.dialog = false
      },
      open(){
        this.dialog = true
      }

    }
  }
</script>
