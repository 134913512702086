<template>
    <div>
        <v-card>
            <v-card-title class="primary-title">
                <span class="headline ml-4 mt-2">Inventario de consumibles en bodegas</span>
            </v-card-title>

            <v-card-text>
                <v-data-table :headers="headers" :items="allArticles" class="mr-4 tl-4" :search="search">
                    <template v-slot:top>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-autocomplete v-model="warehouse_id" item-value="id" item-text="name" label="* Bodega"
                                    placeholder="Seleccione la bodega" @change="change" :items="allWarehouses" clearable
                                    class="ml-4 mr-4"></v-autocomplete>

                            </v-col>
                            <v-spacer></v-spacer>

                            <v-col cols="12" md="5">
                                <v-text-field v-model="search" prepend-icon="fa-search" label="Buscar..." clearable
                                    single-line hide-details v-if="warehouse_id"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="1">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on: tooltip }">
                                        <v-btn v-on="{ ...tooltip }" small color="green dark-3" class="mx-2 my-2" fab
                                            dark @click="exportInfo(warehouse_id)">

                                            <v-icon>fa-file-download</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Exportar</span>
                                </v-tooltip>
                            </v-col>

                        </v-row>


                        <!-- <v-toolbar class="white" v-if="warehouse_id">
                            <v-text-field v-model="search" prepend-icon="fa-search" label="Buscar..." clearable single-line
                                hide-details></v-text-field>
                            <v-spacer></v-spacer>
                        </v-toolbar> -->
                    </template>

                    <template v-slot:item.disponibilidad="{ item }">
                        <div :style="{ color: getDisponibilidadColor(item).color }">
                            {{ getDisponibilidadColor(item).texto }}
                        </div>


                    </template>


                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { URL_APP } from '../../../util/constants';
import { createLinkFile } from "../../../util/functions";

export default {
    name: 'InventoryConsumables',
    data: () => {
        return {
            headers: [
                {
                    text: 'Artículo / Consumible',
                    align: 'start',
                    value: 'name',
                    sortable: false
                },
                { text: 'Unidades completas disponibles', value: 'units_full_available' },
                { text: 'Unidades incompletas disponibles', value: 'units_incomplete_available' },
                { text: 'Unidades completas en préstamo', value: 'units_full_loan', sortable: false },
                { text: 'Unidades incompletas en préstamo', value: 'units_incomplete_loan', sortable: false },
                { text: 'Unidades consumidas', value: 'units_consumed', sortable: false },
                { text: 'Frecuencia de consumo', value: 'article.frequency_consumption', sortable: false },
                { text: 'Disponibilidad', value: 'disponibilidad', sortable: false },
            ],
            warehouse_id: null,
            allArticles: [],
            search: ''
        }
    },
    methods: {
        ...mapActions(['chgLoad', 'getAllInfoArticles', 'getWarehouses']),
        async initialize() {
            this.chgLoad(true);
            await this.getWarehouses();
            this.chgLoad(false)
        },

        async getInventory() {
            try {
                await this.$http.get(URL_APP + "/api/loan_consumables/consumables_inventory", {
                    params: { 'warehouse_id': this.warehouse_id }
                })
                    .then((res) => {

                        const selectedItem = this.allWarehouses.find(elm => elm.id === this.warehouse_id);
                        this.title = selectedItem ? selectedItem.name : '';

                        this.allArticles = res.data
                            .filter(elm => elm.warehouse_id === this.warehouse_id)
                            .map(elm => {
                                const selectedItem = this.allInfoArticles.find(item => item.id === elm.article_id);
                                const unitsFullLoan = elm.units_full_loan || 0;
                                const unitsIncompleteLoan = elm.units_incomplete_loan || 0;
                                const unitsFullAvailable = elm.units_full_available || 0;
                                const unitsIncompleteAvailable = elm.units_incomplete_available || 0;
                                const unitsConsumed = elm.units_consumed || 0;

                                return {
                                    ...elm,
                                    name: selectedItem.name,
                                    'units_full_available': unitsFullAvailable,
                                    'units_incomplete_available': unitsIncompleteAvailable,
                                    'units_full_loan': unitsFullLoan,
                                    'units_incomplete_loan': unitsIncompleteLoan,
                                    'units_consumed': unitsConsumed,
                                    key: elm.id
                                };
                            });


                        this.chgLoad(false);
                    })
                    .catch((err) => {
                        console.log(err)
                    })

            }
            catch (error) {
                this.$root.$alert({ text: "Se han generado errores", color: "error", timeout: 2000 })

            }
        },

        async exportInfo(item) {
            var url = URL_APP + "/api/loan_consumables/inventory_export";
            this.chgLoad(true);
            try {
                const response = await this.$http.get(url, {
                    responseType: 'blob',
                    params: {
                        warehouse: item
                    }
                });
                createLinkFile(response);
                this.$root.$alert({ text: "Descarga exitosa", color: "success", timeout: 2000 });
            } catch (error) {
                this.$root.$alert({ text: "Error al Obtener el Excel", color: "error", timeout: 2000 });
            }
            this.chgLoad(false);
        },


        async change() {
            if (this.warehouse_id) {
                await this.chgLoad(true)
                await this.getInventory();
                await this.chgLoad(false)
            }
            else {
                this.allArticles = []
            }
        }, // TODO implementar metodo para cambiar el inventario seleccionado.

        selectNameArticle() {
            const SelectedItem = this.allInfoArticles.find(item => item.id === this.options.article_id);
            this.options.name = SelectedItem ? SelectedItem.name : '';
        },

        getDisponibilidadColor(item) {

            const frequencyConsumption = item.article.frequency_consumption;

            // Suponiendo que 'disponibilidad' está disponible en el objeto 'item'.
            // Reemplaza 'disponibilidad' por el nombre real de la propiedad si es diferente.
            const disponibilidad = item.units_full_available;

            // Determina los valores de umbral según 'frequency_consumption'.
            let threshold;
            switch (frequencyConsumption) {
                case 'Alto':
                    threshold = 20;
                    break;
                case 'Medio':
                    threshold = 10;
                    break;
                case 'Bajo':
                    threshold = 5;
                    break;
                default:
                    threshold = 0; // Valor predeterminado del umbral
            }

            // Determina el color del v-chip según la condición.
            const color = disponibilidad <= threshold ? 'red' : 'green';
            // Determina el texto del v-chip según la condición.
            const texto = disponibilidad <= threshold ? 'Stock insuficiente' : 'Stock suficiente';

            // Devuelve un objeto con el color y el texto.
            return {
                color: color,
                texto: texto
            };
        },
    },

    computed: {
        ...mapGetters(['allWarehouses', 'allInfoArticles'])
    },

    mounted() {
        this.initialize()
        this.getAllInfoArticles()
    }
}
</script>

<style lang="scss" scoped></style>