import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/file_types"

const state = {
  file_types: []
}

const getters = {
  allFileTypes: state => state.file_types
}

const actions = {

  async getFileTypes({commit}){
    return new Promise(function(resolve, reject){
      commit('setFileTypes', [])
      axios.get(URL_).then(res=>{
        commit('setFileTypes', res.data)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async saveFileType({commit}, file_type){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, file_type).then(res=>{
        commit('newFileType', res.data.file_type)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async updateFileType({commit}, file_type){
    return new Promise((resolve, reject)=>{
      axios.put(URL_, file_type).then(res=>{
        //console.log(res.data)
        commit('updFileType', res.data.file_type)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deleteFileType({commit}, file_type){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${file_type.id}`).then(res=>{
        commit('delFileType', file_type)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setFileTypes : (state, file_types) => state.file_types = file_types,
  newFileType  : (state, file_type) => state.file_types.unshift(file_type),
  updFileType  : (state, file_type) => {
    const index = state.file_types.findIndex(u => u.id === file_type.id)
    if (index !== -1) state.file_types.splice(index, 1, file_type)
  },
  delFileType  : (state, file_type) => state.file_types = state.file_types.filter(c => c.id !== file_type.id)
}

export default{
  state, getters, actions, mutations
}