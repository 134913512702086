<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px" scrollable persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Nuevo Fabricante</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols=12>
                <v-text-field v-model="name" label="* Nombre" 
                id="name"
                @keyup.enter="save"
                :rules="rules.name" autofocus></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" outlined @click="dialog=false">Cancelar</v-btn>
          <v-btn color="primary darken-1" outlined @click="save" :loading="loading" :disabled="loading">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
  export default{
    name: 'FormManufacturer',
    data() {
      return {
        dialog: false,
        name:'',
        rules: {
          name: [
            v => !!v || 'Campo Requerido',
            v => v.length < 200 || 'Máximo 200 letras',
          ],
        },
        valid: true,
      }
    },

    computed: {
      ...mapGetters(['allManufacturers','loading']),
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    methods: {
      ...mapActions(['saveManufacturer','chgLoad']),

      newItem() {
        this.dialog = true
        setTimeout(function(){ document.getElementById('name').focus(); }, 500);
      },

      async save() {
        this.chgLoad(true)
        await this.saveManufacturer(this.name)
        this.name = ""
        this.chgLoad(false)
        this.dialog = false
      },
    }
  }
</script>
