import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP + "/api/loan_consumables/brands"

const state = {
    brands: []
}

const getters = {
    allBrands: state => state.brands
}

const actions = {
    async getBrands({commit}){
        return new Promise(function(resolve, reject){
            commit('setBrands', [])
            axios.get(URL_). then(res => {
                commit('setBrands', res.data)
                resolve("OK")
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async getActiveBrands({commit}){
        return new Promise(function(resolve, reject){
            commit('setActiveBrands', []);
            axios.get(URL_APP + '/api/loan_consumables/getBrands')
            .then( (res) => {
                commit('setActiveBrands', res.data);
                resolve("OK")
            })
            .catch( (err) => {
                reject(err)

            })
        })
    },

    async saveBrand({commit}, brand){
        return new Promise ((resolve, reject) => {
            axios.post(URL_, brand)
            .then((res) => {
                commit('newBrand', res.data.brand)
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
        })
    },

    async updateBrand({commit}, brand){
        return new Promise ((resolve, reject) => {
            axios.put(URL_, brand)
            .then((res) => {
                commit('updateBrand', res.data.brand)
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
        })
    },

    async deleteBrand({commit}, brand){
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_}/${brand}`)
            .then((res) => {
                commit('deleteBrand', brand)
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
        })
    }
} 

const mutations = {
    setBrands : (state, brands) => state.brands = brands,
    setActiveBrands : (state, brands) => state.brands = brands,
    newBrand : (state, brand) => state.brands.unshift(brand),
    updateBrand : (state, brand) => {
        const index = state.brands.findIndex(u => u.id === brand.id)
        if(index !== -1){
            state.brands.splice(index, 1, brand)
        }
    },
    deleteBrand : (state, brand) => state.brands = state.brands.filter(c => c.id !== brand.id)
}

export default{
    state, getters, actions, mutations
}