<template>
    <div>
        <v-dialog v-model="dialog" max-width="1000px" scrollable>
            <v-card>
                <v-toolbar dark color="primary" dense>
                    <v-toolbar-title>
                        Selección de Centro de Costos
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="closeItem">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>

                <v-card-text style="min-height:600px">
                    <v-text-field autofocus v-model="search" prepend-icon="fa-search" label="Buscar" clearable single-line
                        hide-details></v-text-field>
                    <v-data-table :headers="headers" :items="allCostCenters" class="elevation-1"
                        pagination.sync="pagination" item-key="id" :search="search">

                        <template v-slot:item="{ item }">
                            <tr @click="setCostCenter(item)" style="cursor: pointer">
                                <td>{{ item.name }}</td>
                                <td>{{ item.cost_center_code }}</td>
                               
                            </tr>
                        </template>

                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {

    name: "SelectCostCenter",
    data() {
        return {
            dialog: false,
            search: '',
            headers: [
                { text: 'Nombre del centro de costos', value: 'name' },
                { text: 'Código del centro de costos', value: 'cost_center_code' },
            ]
        }
    },

    methods: {
        ...mapActions(['chgLoad', 'getCostCenters']),

        async show() {
            this.chgLoad(true)
            await this.getCostCenters()
            this.chgLoad(false)
            this.dialog = true
        },

        setCostCenter(item) {
            this.$emit('setCostCenter', item)
            this.dialog = false
        },

        closeItem() {
            this.dialog = false
        },

    },
    computed: {
        ...mapGetters(['allCostCenters'])
    }

}
</script>

<style lang="scss" scoped></style>