import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/thirds"

const state = {
  thirds: [],
  thirdsT: 0
}

const getters = {
  allThirds: state => state.thirds,
  totalThirds: state => state.thirdsT
}

const actions = {

  async getThirds({commit}, params){
    return new Promise(function(resolve, reject){
      commit('setThirds', [])
      axios.get(URL_+'?page='+params.options.page, {
        params
      }).then(res=>{
        commit('setThirds', res.data.data)
        commit('setThirdsT', res.data.total)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async saveThird({commit},third){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, third).then(res=>{
        commit('newThird', res.data.third)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async updateThird({commit},third){
    return new Promise((resolve, reject)=>{
      axios.put(URL_, third).then(res=>{
        //console.log(res.data)
        commit('updThird', res.data.third)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deleteThird({commit}, third){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${third.id}`).then(res=>{
        commit('delThird', third)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setThirds : (state, thirds) => state.thirds = thirds,
  setThirdsT : (state, total) => state.thirdsT = total,
  newThird  : (state, third) => {
    state.thirds.unshift(third)
    state.thirdsT = state.thirdsT+1 
  },
  updThird  : (state, third) => {
    const index = state.thirds.findIndex(e => e.id === third.id)
    if (index !== -1) state.thirds.splice(index, 1, third)
  },
  delThird  : (state, third) => {
    state.thirds = state.thirds.filter(e => e.id !== third.id)
    state.thirdsT = state.thirdsT-1 
  }
}

export default{
  state, getters, actions, mutations
}