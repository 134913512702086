import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance;
const URL_ = URL_APP + "/api/loan_assets/thirds";

const state = {
  assetThirds: [],
  assetThirdsT: 0,
};

const getters = {
  allAssetThirds: (state) => state.assetThirds,
  totalAssetThirds: (state) => state.assetThirdsT,
};

const actions = {
  async getAssetThirds({ commit }, params) {
    return new Promise(function (resolve, reject) {
      commit("setAssetThirds", []);
      axios
        .get(URL_ + "?page=" + params.options.page, { params })
        .then((res) => {
          commit("setAssetThirds", res.data.data);
          commit("setAssetThirdsT", res.data.total);
          resolve("OK");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async saveAssetThird({ commit }, third) {
    return new Promise((resolve, reject) => {
      axios
        .post(URL_, third)
        .then((res) => {
          commit("newAssetThird", res.data.third);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async updateAssetThird({ commit }, third) {
    return new Promise((resolve, reject) => {
      axios
        .put(URL_, third)
        .then((res) => {
          commit("updateAssetThird", res.data.third);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async inactiveAssetThird({ commit }, third) {
    return new Promise((resolve, reject) => {
      axios
        .put(URL_APP + "/api/loan_assets/inactiveThird", third)
        .then((res) => {
          commit("inactiveAssetThird", res.data.third);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async activateAssetThird({ commit }, third) {
    return new Promise((resolve, reject) => {
      axios
        .put(URL_APP + "/api/loan_assets/activateThird", third)
        .then((res) => {
          commit("updateAssetThird", res.data.third);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  setAssetThirds: (state, assetThirds) => (state.assetThirds = assetThirds),
  setAssetThirdsT: (state, total) => (state.assetThirdsT = total),
  newAssetThird: (state, third) => {
    state.assetThirds.unshift(third);
    state.assetThirdsT = state.assetThirdsT + 1;
  },
  updateAssetThird: (state, third) => {
    const index = state.assetThirds.findIndex((u) => u.id === third.id);
    if (index !== -1) {
      state.assetThirds.splice(index, 1, third);
    }
  },
  inactiveAssetThird: (state, third) => {
    const index = state.assetThirds.findIndex((u) => u.id === third.id);
    if (index !== -1) {
      state.assetThirds.splice(index, 1, third);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
