import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/loan/equipment_transfers"

const state = {
  equipment_transfers: [],
  equipment_transferT: 0
}

const getters = {
  allEquipmentTransfers: state => state.equipment_transfers,
  totalEquipmentTransfer: state => state.equipment_transferT
}

const actions = {

  async getEquipmentTransfers({commit}, params){
    return new Promise(function(resolve, reject){
      commit('setEquipmentTransfers', [])
      axios.get(URL_+'?page='+params.options.page, {
        params
      }).then(res=>{
        commit('setEquipmentTransfers', res.data.data)
        commit('setEquipmentTransferT', res.data.total)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async saveEquipmentTransfer({commit},equipment_transfer){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, equipment_transfer).then(res=>{
        commit('newEquipmentTransfer', res.data.equipment_transfer)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async updateEquipmentTransfer({commit},equipment_transfer){
    return new Promise((resolve, reject)=>{
      axios.put(URL_, equipment_transfer).then(res=>{
        //console.log(res.data)
        commit('updEquipmentTransfer', res.data.equipment_transfer)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deleteEquipmentTransfer({commit}, equipment_transfer){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${equipment_transfer.id}`).then(res=>{
        commit('delEquipmentTransfer', equipment_transfer)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setEquipmentTransfers : (state, equipment_transfers) => state.equipment_transfers = equipment_transfers,
  setEquipmentTransferT : (state, total) => state.equipment_transferT = total,
  newEquipmentTransfer  : (state, equipment_transfer) => {
    state.equipment.unshift(equipment_transfer)
    state.equipment_transferT = state.equipment_transferT+1 
  },
  updEquipmentTransfer  : (state, equipment_transfer) => {
    const index = state.equipment_transfers.findIndex(e => e.id === equipment_transfer.id)
    if (index !== -1) state.equipment_transfers.splice(index, 1, equipment_transfer)
  },
  delEquipmentTransfer  : (state, equipment_transfer) => {
    state.equipment_transfers = state.equipment_transfers.filter(e => e.id !== equipment_transfer.id)
    state.equipment_transfertT = state.equipment_transferT-1 
  }
}

export default{
  state, getters, actions, mutations
}