import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/sectionals"

const state = {
  sectionals: []
}

const getters = {
  allSectionals: state => state.sectionals
}

const actions = {

  async getSectionals({commit}){
    return new Promise(function(resolve, reject){
      commit('setSectionals', [])
      axios.get(URL_).then(res=>{
        commit('setSectionals', res.data)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async saveSectional({commit},sectional){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, sectional).then(res=>{
        commit('newSectional', res.data.sectional)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async updateSectional({commit},sectional){
    return new Promise((resolve, reject)=>{
      axios.put(URL_, sectional).then(res=>{
        //console.log(res.data)
        commit('updSectional', res.data.sectional)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deleteSectional({commit}, sectional){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${sectional.id}`).then(res=>{
        commit('delSectional', sectional)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setSectionals : (state, sectionals) => state.sectionals = sectionals,
  newSectional  : (state, sectional) => state.sectionals.unshift(sectional),
  updSectional  : (state, sectional) => {
    const index = state.sectionals.findIndex(u => u.id === sectional.id)
    if (index !== -1) state.sectionals.splice(index, 1, sectional)
  },
  delSectional  : (state, sectional) => state.sectionals = state.sectionals.filter(s => s.id !== sectional.id)
}

export default{
  state, getters, actions, mutations
}