import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance;
const URL_ = URL_APP + "/api/loan_consumables/transfer";

const state = {
  consumables_transfers: [],
  consumables_transfers_T: 0,
  articlesLocation: [],
};

const getters = {
  allConsumablesTransfers: (state) => state.consumables_transfers,
  totalConsumablesTransfers: (state) => state.consumables_transfers_T,
  allArticlesLocation: (state) => state.articlesLocation,
};

const actions = {
  
  async getConsumablesTransfer({ commit }, params) {
    return new Promise(function(resolve, reject) {
      commit("setConsumablesTransfer", []);
      axios
        .get(URL_ + "?page=" + params.options.page, { params })
        .then((res) => {
          commit("setConsumablesTransfer", res.data.data);
          commit("setConsumablesTransferT", res.data.total);
          resolve("Ok");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async getArticlesLocation({ commit }, params) {
    return new Promise(function(resolve, reject) {
      commit("setArticlesLocation", []);
      axios
        .get(URL_APP + "/api/loan_consumables/articlesLocation", { params })
        .then((res) => {
          commit("setArticlesLocation", res.data);
          resolve("OK");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async saveTransfer({ commit }, transfer) {
    return new Promise((resolve, reject) => {
      axios
        .post(URL_, transfer)
        .then((res) => {
          commit("newTransfer", res.data.transfer);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async confirmTransfer({ commit }, transfer) {
    return new Promise((resolve, reject) => {
      axios
        .put(URL_, transfer)
        .then((res) => {
          commit("confirmTransfer", res.data.transfer);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  setConsumablesTransfer: (state, consumables_transfers) =>
    (state.consumables_transfers = consumables_transfers),
  setConsumablesTransferT: (state, total) =>
    (state.consumables_transfers_T = total),
  setArticlesLocation: (state, articlesLocation) =>
    (state.articlesLocation = articlesLocation),
  newTransfer: (state, transfer) => {
    state.consumables_transfers.unshift(transfer);
    state.consumables_transfers_T = state.consumables_transfers_T + 1;
  },
  confirmTransfer: (state, transfer) => {
    const index = state.consumables_transfers.findIndex((u) => u.id === transfer.id);
    if (index !== -1) {
      state.consumables_transfers.splice(index, 1, transfer);
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
