<template>
    <div>
        <v-card>
            <v-card-title primary-title>
                <span class="headline">Activos Fijos</span>
            </v-card-title>

            <v-card-text>
                <FilterQuery :filters="filters" :fieldFilters="fieldFilters" @freshFilters="initialize2(1)"
                    id="filterQuery"></FilterQuery>

                <v-data-table item-key="id" :headers="headers" :items="allAssetArticles" :search="search"
                    :options.sync="options" :sort-by="options.sortBy" :sort-desc.sync="options.sortDesc"
                    :items-per-page.sync="options.per_page" :server-items-length="totalAssetArticles" multi-sort
                    :loading="loading" loading-text="Cargando..." :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'fa-angle-double-left',
                        lastIcon: 'fa-angle-double-right',
                        prevIcon: 'fa-angle-left',
                        nextIcon: 'fa-angle-right'
                    }">

                    <template v-slot:top>
                        <v-toolbar color="white">
                            <v-text-field v-model="search" prepend-icon="fa-search" label="Buscar..." clearable
                                single-line hide-details @keypress.enter="initialize2(1)"
                                @click:clear="initialize2(1)"></v-text-field>
                            <v-spacer></v-spacer>

                            <!-- TODO: Aqui se debe cambiar el permiso  -->
                            <v-tooltip bottom v-if="can('asset.store')">
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn v-on="{ ...tooltip }" small color="primary" class="mx-2 my-2" fab dark
                                        @click.stop="$event => $refs.formAssetArticle_.openItem()">

                                        <v-icon>fa-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>Nuevo</span>
                            </v-tooltip>

                            <v-menu offset-y>
                                <template v-slot:activator="{ on: menu }">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn class="mx-2" icon color="primary" v-on="{ ...tooltip, ...menu }"
                                                id="newLoan">
                                                <v-icon dark>fa-ellipsis-v</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Mas...</span>
                                    </v-tooltip>

                                </template>

                                <v-list dense flat>
                                    <template v-slot:activator="{ on: menu }">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on: tooltip }">
                                                <v-btn class="mx-2" icon color="primary" v-on="{ ...tooltip, ...menu }"
                                                    id="newLoan">
                                                    <v-icon dark>fa-ellipsis-v</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Mas...</span>
                                        </v-tooltip>
                                    </template>

                                    <!-- Listas  -->
                                    <v-list-item-group>
                                        <!--TODO: Importación de activos MASIVAMENTE -->
                                        <!--! Opciones para la importación -->
                                        <!--? 1: importar artículos -->
                                        <!--? 2: Editar artículos -->
                                        <!--? 3: Traslado Masivo -->
                                        <!--? 4: Baja Masiva -->
                                        <v-list-item @click="$event => $refs.formAttachment_.openItem(option = 1)">
                                            <v-list-item-icon>
                                                <v-icon color="light-blue darken-3">mdi-file-export</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Importar Artículos</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <!--TODO: Edición de articulos Masivamente -->
                                        <v-list-item @click="$event => $refs.formAttachment_.openItem(option = 2)">
                                            <v-list-item-icon>
                                                <v-icon color="orange darken-3">mdi-file-edit</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Editar Artículos Masivamente</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <!--TODO: Exportar activos -->
                                        <v-list-item @click="exportData()">
                                            <v-list-item-icon>
                                                <v-icon color="green darken-3">mdi-file-import</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Exportar artículos</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-divider></v-divider>

                                        <!--TODO: Formulario de traslado de equipos de un responsable a otro  -->
                                        <v-list-item @click="$event => $refs.formTransferBetweenUsers_.openItem()">
                                            <v-list-item-icon>
                                                <v-icon color="teal darken-3">mdi-transit-transfer</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Traslado entre responsables</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <!--TODO: Traslado masivo de artículos  -->
                                        <v-list-item @click="$event => $refs.formAttachment_.openItem(option = 3)">
                                            <v-list-item-icon>
                                                <v-icon color="blue darken-3">mdi-file-arrow-left-right</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Traslado Masivo</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-divider></v-divider>

                                        <!--TODO: Baja masiva de artículos  -->
                                        <v-list-item @click="$event => $refs.viewDeprecationMassive_.openItem()">
                                            <v-list-item-icon>
                                                <v-icon color="orange darken-3">mdi-file-cancel</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Baja Masiva</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-menu>

                        </v-toolbar>
                    </template>

                    <template v-slot:item.found="{ item }">
                        <span v-if="item.found">SI</span>
                        <span v-else>NO</span>
                    </template>

                    <template v-slot:item.actions="{ item }">

                        <v-tooltip left v-if="can('asset.store')">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2"
                                    @click="$event => $refs.viewInfoArticle_.openItem(item)" color="blue">
                                    fa-eye
                                </v-icon>
                            </template>
                            <span>Ver</span>
                        </v-tooltip>

                        <v-tooltip top v-if="can('asset.store')">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2"
                                    @click="$event => $refs.historyArticle_.openItem(item)" color="teal">
                                    fa-history
                                </v-icon>
                            </template>
                            <span>Histórico</span>
                        </v-tooltip>

                        <v-tooltip top v-if="can('asset.store') && item.status && item.status.description != 'Baja'">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2"
                                    @click="$event => $refs.formEditArticle_.openItem(item)" color="orange">
                                    fa-pen
                                </v-icon>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>

                        <v-tooltip right v-if="can('asset.store') && item.status && item.status.description != 'Baja'">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2" @click.prevent="saveDeprecation(item)"
                                    color="red">
                                    mdi-archive-cancel
                                </v-icon>
                            </template>
                            <span>Dar baja</span>
                        </v-tooltip>

                        <v-tooltip right v-if="can('asset.store') && item.status && item.status.description == 'Baja'">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2"
                                    @click="$event => $refs.formActivation_.openItem(item)" color="light-green">
                                    fa-check
                                </v-icon>
                            </template>
                            <span>Activar</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <FormArticles ref="formAssetArticle_" />
        <FormAttachment ref="formAttachment_" @initialize="initialize" />
        <FormTransferArticles ref="formTransferArticles_" />
        <FormTransferBetweenUsers ref="formTransferBetweenUsers_" @item-saved="initialize2" />
        <FormDisposalProcess ref="formDisposalProcess_" />
        <ViewInfoArticle ref="viewInfoArticle_" />
        <FormEditArticle ref="formEditArticle_" />
        <HistoryArticles ref="historyArticle_" />
        <FormActivation ref="formActivation_" />
        <ViewDeprecationMassive ref="viewDeprecationMassive_" @item-saved="initialize2" />
    </div>
</template>

<script>
import { can, createLinkFile } from '../../../util/functions';
import ViewDeprecationMassive from './actions/ViewDeprecationMassive'
import FilterQuery from '../../general/FilterQuery.vue';
import FormArticles from './FormArticles.vue';
import FormAttachment from '../general/FormAttachment.vue';
import FormTransferArticles from '../transfers/FormTransferArticles.vue';
import FormTransferBetweenUsers from '../transfers/FormTransferBetweenUsers.vue';
import FormDisposalProcess from '../disposal_process/FormDisposalProcess.vue';
import ViewInfoArticle from './ViewInfoArticle.vue';
import FormEditArticle from './FormEditArticle.vue';
import HistoryArticles from './HistoryArticles.vue';
import { mapActions, mapGetters } from 'vuex';
import { mostrarSweetAlert } from '../../../util/sweetAlertUtil';
import FormActivation from './actions/FormActivation.vue';
import { URL_APP } from '../../../util/constants';

export default {
    name: 'assets_articles',
    components: {
        FilterQuery, FormArticles, FormAttachment, FormTransferArticles, FormTransferBetweenUsers, FormDisposalProcess, ViewInfoArticle, FormEditArticle, HistoryArticles, FormActivation, ViewDeprecationMassive

    },
    data: () => {
        return {
            search: '',
            loading: false,
            editedIndex: -1,
            totalArticles: 0,

            headers: [
                { text: 'Descripción del artículo', value: 'description', width: '20%', sortable: false },
                { text: 'Inventario actual', value: 'inventory' },
                { text: 'Inventario anterior', value: 'old_inventory', sortable: false },
                { text: 'Serial', value: 'serial', sortable: false },
                { text: 'Estado', value: 'status.description', sortable: false },
                { text: 'Encontrado', value: 'found', sortable: false },
                { text: 'Documento del responsable', value: 'third.identification', sortable: false },
                { text: 'Nombre del responsable', value: 'third.name', sortable: false },
                // { text: 'Registrado por', value: 'user.name', sortable: false },
                { text: 'Acciones', value: 'actions', width: '10%', sortable: false, },
            ],

            filters: [
                { field: { field: 'active', name: 'Estado', type: 'select', options: ['Activo', 'Inactivo'] }, operator: '=', value_start: 'Activo', value_end: '' },

            ],
            fieldFilters: [
                { field: 'active', name: 'Estado', type: 'select' },

            ],
            options: { page: 1, per_page: 10, sortBy: ['inventory'], sortDesc: [false] },

        }
    },

    mounted() {
        this.initialize()
        var this_2 = this
        window.addEventListener('keydown', function (e) {
            if (e.altKey == true && e.code == 'KeyN') {
                this_2.$refs.formAssetArticle_.openItem()
            }
        });
    },

    methods: {
        ...mapActions(['getAssetArticles', 'saveAssetDeprecation', 'chgLoad']),
        can: can,
        async initialize() {
            this.chgLoad(true)

            var params = { filters: this.filters, options: this.options, search: this.search }
            try {
                await this.getAssetArticles(params)
            }
            catch (error) {
                this.$root.$alert({ text: "Error al Obtener los equipos", color: "error", timeout: 2000 })
            }
            this.loading = false;
            this.chgLoad(false)
        },

        async initialize2(page = null) {
            if (!this.loading) {
                if (page != null) this.options.page = page

                this.chgLoad(true);
                var params = { filters: this.filters, options: this.options, search: this.search }
                try {
                    await this.getAssetArticles(params)
                }
                catch (error) {
                    this.$root.$alert({ text: "Error al Obtener los equipos", color: "error", timeout: 2000 })
                }
                this.chgLoad(false)
            }
            else {
                this.chgLoad(false);
            }
        },

        async saveDeprecation(item) {
            mostrarSweetAlert({
                titulo: 'Confirmación',
                texto: '¿Estás seguro de que desea dar baja al activo ' + item.description + ' con número de inventario ' + item.inventory + ' ?',
                icono: 'warning',
                textoBotonConfirmar: 'Confirmar',
                textoBotonCancelar: 'Cancelar',
            })
                .then(async (res) => {
                    if (res.isConfirmed) {
                        this.chgLoad(true);
                        try {
                            var msg = "";
                            msg = await this.saveAssetDeprecation(item)
                            this.$root.$alert({ text: msg.message, color: "success", timeout: 2000 })
                            this.chgLoad(false);
                        }
                        catch (error) {
                            this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 });
                            this.chgLoad(false);
                        }
                    }
                })
        },

        openItem(item) {
            alert(item)
        },

        editItem(item) {
            alert(item)
        },

        deleteItem(item) {
            alert(item)
        },

        async exportData() {
            var url = URL_APP + "/api/loan_assets/export_assets";
            this.chgLoad(true);
            await this.$http.get(url, { responseType: 'blob' })
                .then((response) => {
                    createLinkFile(response)
                    this.$root.$alert({ text: "Descarga exitosa", color: "success", timeout: 2000 })
                    this.chgLoad(false);
                })
                .catch(() => {
                    this.$root.$alert({ text: "Error al Obtener el Excel", color: "error", timeout: 2000 })
                    this.chgLoad(false);
                })
        },
    },

    computed: {
        ...mapGetters(['allAssetArticles', 'totalAssetArticles', 'allAssetStatus',])
    },

    watch: {
        'options'() {
            this.initialize2()
        }
    },


}
</script>

<style lang="scss" scoped></style>