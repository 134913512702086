<template>
    <div>
        <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="1200px" transition="dialog-transition">
            <v-card>
                <v-toolbar dense color="primary" dark>
                    <v-toolbar-title>Entrega de consumibles - información general</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="closeDialog">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>

                <v-card-text>
                    <!-- <span class="subtitle-1 font-weight-bold my-0 py-0">Datos de la entrega</span> -->
                    <v-container fluid mt-3>
                        <v-row>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Identificación: </span>
                                <span class="info--text">{{ editedItem.third.identification }}</span>
                            </v-col>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Nombre: </span>
                                <span class="info--text">{{ editedItem.third.name }}</span>
                            </v-col>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Email: </span>
                                <span class="info--text">{{ editedItem.third.email }}</span>
                            </v-col>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Teléfono(s): </span>
                                <span class="info--text">{{ (editedItem.third.phone ? editedItem.third.phone + ' - ' :
                                    '')
                                    +
                                    (editedItem.third.mobile ? editedItem.third.mobile : '') }}</span>
                            </v-col>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Tipo de Solicitante: </span>
                                <span class="info--text">{{ editedItem.type_borrower }}</span>
                            </v-col>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Datos del Solicitante: </span>
                                <span class="info--text">{{ editedItem.data_borrower }}</span>
                            </v-col>
                            <v-col cols="12" md="6" v-if="editedItem.date" class="my-0 py-0">
                                <span class="font-weight-bold">Fecha de entrega: </span>
                                <span class="info--text">{{ editedItem.date }}</span>
                            </v-col>
                            <v-col cols="12" md="6" v-if="editedItem.user_loan" class="my-0 py-0">
                                <span class="font-weight-bold">Colaborador que entrega: </span>
                                <span class="info--text">{{ editedItem.user_loan.name }}</span>
                            </v-col>
                            <v-col cols="12" md="12" v-if="editedItem.observations" class="my-0 py-0">
                                <span class="font-weight-bold">Observaciones: </span>
                                <span class="info--text">{{ editedItem.observations }}</span>
                            </v-col>
                        </v-row>
                    </v-container>

                    <v-container fluid mt-2>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table dense :headers="headers" :items="editedItem.articles" class="elevation-1">

                                    <template v-slot:item.returned="{ item }">
                                        <div>
                                            <v-icon v-if="item.returned" color="success">mdi-check</v-icon>
                                            <v-icon v-else color="red">mdi-close</v-icon>
                                        </div>
                                    </template>

                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="secondary" @click.stop="closeDialog">Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
export default {
    name: 'viewDelivery',
    props: {
        allInfoArticles: {
            type: Array,
            default: null
        },
    },
    data: () => {
        return {
            dialog: false,
            headers: [
                { text: 'Nombre del Artículo', value: 'name' },
                { text: 'Unidades completas', value: 'units_full_sent', align: 'center' },
                { text: 'Unidades Incompletas', value: 'units_incomplete_sent', align: 'center' },
                { text: 'Unidades completas recibidas', value: 'units_full_received', align: 'center' },
                { text: 'Unidades Incompletas recibidas', value: 'units_incomplete_received', align: 'center' },
                { text: 'Unidades consumidas', value: 'units_consumed', align: 'center' },
                { text: 'Devolución confirmada', value: 'returned', align: 'center' }

            ],
            editedItem: {
                third: {},
                user_loan: {},
                articles: [],

            },
        }
    },
    methods: {
        openDialog(item) {
            this.editedItem = {
                'id': item.id,
                'type_borrower': item.type_borrower,
                'data_borrower': item.data_borrower,
                'date': item.dateRegister,
                'observations': item.observations,
                third: {
                    'identification': item.user_receive.identification,
                    'name': item.user_receive.name,
                    'email': item.user_receive.email,
                    'phone': item.user_receive.phone,
                    'mobile': item.user_receive.mobile,
                },
                user_loan: {
                    'name': item.user_send.name
                },
                'articles': item.delivery_details,

            };


            this.editedItem.articles.forEach(elm => {
                const selectedItem = this.allInfoArticles.find(item => item.id === elm.article_id)
                if (elm.delivery_return_details && elm.delivery_return_details.length > 0) {
                    let returnDetail = elm.delivery_return_details[0]
                    elm['units_full_received'] = returnDetail.units_full_received
                    elm['units_incomplete_received'] = returnDetail.units_incomplete_received
                    elm['units_consumed'] = returnDetail.units_consumed
                }
                else {
                    elm['units_full_received'] = 0
                    elm['units_incomplete_received'] = 0
                    elm['units_consumed'] = 0
                }
                elm['name'] = selectedItem.name
            })

            this.dialog = true;

        },
        closeDialog() {
            this.editedItem = {}
            this.editedItem.third = {}
            this.editedItem.user_loan = {}
            this.editedItem.articles = []
            this.dialog = false

        }
    },
    computed: {},
    mounted() { }
}
</script>

<style lang="scss" scoped></style>