<template>
  <div>
    <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
      <v-card>
        <v-toolbar dense color="primary" dark>
          <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
          <v-spacer/>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                <v-icon>fa-times</v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-form v-model="valid" ref="formBuilding" @submit.prevent="save">
            <v-container>
              <v-row>
                <v-col cols=12>
                  <v-text-field v-model="editedItem.name" label="Nombre"
                  :disabled="!can('building.create')"
                  :rules="rules.name" autofocus></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
          <v-btn color="primary darken-1" outlined @click="save" :loading="loading"
           :disabled="loading" v-if="can('building.create')">{{ viewMode }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import { can } from '../../../util/functions';
  export default{
    name: 'FormBuilding',
    data() {
      return {
        dialog: false,
        rules: {
          name: [
            v => !!v || 'Campo requerido',
            v => v.length >= 3 || 'Mínimo 3 caracteres',
            v => v.length < 100 || 'Máximo 100 letras',
          ],
        },
        editedItem: {id: 0, name: '', active: true},
        defaultItem: {id: 0, name: '', active: true},
        valid: true
      }
    },

    computed: {
      ...mapGetters(['loading']),
      formTitle () {
        return !this.editedItem.id ? 'Nuevo Registro' : 'Editar Registro'
      },
      viewMode () {
        return !this.editedItem.id ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },
    methods: {
      ...mapActions(['saveBuilding', 'updateBuilding', 'chgLoad']),
      can:can,
      newItem () {
        this.dialog = true
      },

      editItem (item) {
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        }, 300)
      },

      async save () {
        if(!this.loading){
          this.$refs.formBuilding.validate()
          if (this.valid){
            this.chgLoad(true)
            try{
              var msg = ""
              if (this.editedItem.id){
                msg = await this.updateBuilding(this.editedItem)
              }else{
                msg = await this.saveBuilding(this.editedItem)
              }
              this.$root.$alert({text:msg.message,color:"success",timeout:2000})
              this.close()
            }catch(error){
              this.$root.$alert({text:"Ha Ocurrido un Error al guardar el Registro",color:"error",timeout:2000})
            }
            this.chgLoad(false)
          }else{
            this.$root.$alert({text:"Por favor revise los errores",color:"error",timeout:2000})
          }
        }
      },
    }
  }
</script>
