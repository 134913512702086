import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP + "/api/loan_consumables/type_articles"

const state = {
    typeArticles: []
}

const getters = {
    allTypeArticles: state => state.typeArticles
}

const actions = {
    async getTypeArticles({ commit }) {
        return new Promise(function (resolve, reject) {
            commit('setTypeArticles', [])
            axios.get(URL_).then(res => {
                commit('setTypeArticles', res.data)
                resolve("OK")
            })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async getActiveTypeArticles({commit}){
        return new Promise(function(resolve, reject){
            commit('setActiveTypeArticles', []);
            axios.get(URL_APP + '/api/loan_consumables/getType_articles')
            .then( (res) => {
                commit('setActiveTypeArticles', res.data);
                resolve("OK")
            })
            .catch( (err) => {
                reject(err)

            })
        })
    },




    async saveTypeArticles({ commit }, typeArticle) {
        return new Promise((resolve, reject) => {
            axios.post(URL_, typeArticle)
                .then((res) => {
                    commit('newTypeArticle', res.data.typeArticle)
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },

    //puedes copiar el código de updatearticle que está en articleJs
    async updateTypeArticles({ commit }, typeArticle) {
        return new Promise((resolve, reject) => {
            axios.put(URL_, typeArticle)
                .then((res) => {
                    commit('updateTypeArticle', res.data.typeArticle)
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },

    //Puedes escribir el código para el delete
    async deleteTypeArticles({ commit }, typeArticle) {
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_}/${typeArticle}`)
                .then((res) => {
                    commit('deleteTypeArticles', typeArticle)
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }
}

const mutations = {
    //Puedes escribir todas las mutaciones
    setTypeArticles : (state, typeArticles) => state.typeArticles = typeArticles,
    setActiveTypeArticles : (state, typeArticles) => state.typeArticles = typeArticles,
    newTypeArticle : (state, typeArticle) => state.typeArticles.unshift(typeArticle),
    updateTypeArticle : (state, typeArticle) => {
        const index = state.typeArticles.findIndex(u => u.id === typeArticle.id)
        if(index !== -1){
            state.typeArticles.splice(index, 1, typeArticle)
        }
    },
    deleteTypeArticles : (state, typeArticle) => state.typeArticles = state.typeArticles.filter(c => c.id !== typeArticle.id)

}


export default {
    state, getters, actions, mutations
}