<template>
    <div>
        <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="1000px" transition="dialog-transition">
            <v-card>
                <v-toolbar dense color="primary" dark>
                    <v-toolbar-title>Formulario de edición</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="closeItem">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-card-text>

                    <div>
                        <v-row>
                            <v-col cols=12>
                                <v-autocomplete v-model="options" item-value="id" item-text="name" :items="allOptions"
                                    id="searchFormType" label="Seleccione la acción a realizar"
                                    placeholder="Seleccione la acción" required clearable></v-autocomplete>
                            </v-col>
                        </v-row>
                    </div>

                    <div v-if="options === 1">
                        <FormChangeInformation ref="formChangeInformation_" :edited-item="editedItem" :options="options"
                            :allAssetStatus="allAssetStatus" @updateItem="updateItem" @closeItem="closeItem" />
                    </div>

                    <div v-if="options === 2">
                        <FormTransfer ref="formTransfer_" :edited-item="editedItem" :options="options"
                            :allCampuses="allCampuses" :allBuildings="allBuildings" :allAreas="allAreas"
                            :allTiers="allTiers" @updateItem="updateItem" @closeItem="closeItem" />
                    </div>

                </v-card-text>
            </v-card>
        </v-dialog>
    </div> 
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormChangeInformation from './actions/FormChangeInformation.vue'
import FormTransfer from '@/views/loan_assets/articles/actions/FormTransfer'
import { mostrarSweetAlert } from '../../../util/sweetAlertUtil';
export default {
    name: "FormEditArticle",
    props: {},
    components: { FormChangeInformation, FormTransfer },
    data: () => {
        return {
            dialog: false,
            options: 0,
            //Valores para all_options 1. traslado 2. baja
            allOptions: [
                { id: 1, name: 'Editar información general' },
                { id: 2, name: 'Traslado' },
            ],
            editedItem: {},
        }
    },
    methods: {
        ...mapActions(['chgLoad', 'getCampuses', 'getBuildings', 'getTiers', 'getAreas', 'updateAssetArticle', 'getAssetStatus']),
        async openItem(item) {
            this.chgLoad(true);
            this.editedItem = item;
            await Promise.all([
                this.getCampuses(),
                this.getBuildings(),
                this.getTiers(),
                this.getAreas(),
                this.getAssetStatus()
            ]);
            this.chgLoad(false);
            this.dialog = true
        },

        async updateItem(item) {
            mostrarSweetAlert({
                titulo: 'Confirmación',
                texto: '¿Estás seguro de que deseas guardar?',
                icono: 'warning',
                textoBotonConfirmar: 'Confirmar',
                textoBotonCancelar: 'Cancelar',
            })
                .then(async (res) => {
                    if (res.isConfirmed) {
                        this.chgLoad(true);
                        try {
                            var msg = "";
                            msg = await this.updateAssetArticle(item)
                            this.$root.$alert({ text: msg.message, color: "success", timeout: 2000 })
                            this.closeItem();
                            this.chgLoad(false);
                        }
                        catch (error) {
                            this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 5000 });
                            this.chgLoad(false);
                        }
                        this.chgLoad(false);
                    }
                })
        },

        closeItem() {
            this.dialog = false
            this.options = 0
        },
    },
    computed: {
        ...mapGetters(['allCampuses', 'allBuildings', 'allTiers', 'allAreas', 'allAssetStatus']),
    }

}
</script>

<style lang="scss" scoped></style>