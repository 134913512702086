<template>
  <div>
    <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
      <v-card>
        <v-toolbar dense color="primary" dark>
          <v-app-bar-title>{{ formTitle }}</v-app-bar-title>
        </v-toolbar>
        <v-card-text>
          <v-form v-model="valid" ref="formThird" @submit.prevent="save">
            <v-container>
              <v-row>
                <v-col cols=12>
                  <v-text-field v-model="editedItem.name" label="Nombre"
                  :rules="rules.name" autofocus></v-text-field>
                </v-col>
                <v-col cols=12 md=6>
                  <v-autocomplete
                    v-model="editedItem.type_id" 
                    item-value="id"
                    item-text="name"
                    :rules="rules.type_id"
                    :items="identification_types"
                    label="* Tipo de Identificación"
                    placeholder="Seleccione el Tipo de Identificación"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols=12 md=6>
                  <v-text-field v-model="editedItem.identification" label="* Identificación"
                  :rules="rules.identification" required/>
                </v-col>
                <v-col cols=12 md=6>
                  <v-text-field v-model="editedItem.email" label="* E-mail" type="email"
                  :rules="rules.email" required/>
                </v-col>
                <v-col cols=12 md=6>
                  <v-autocomplete
                    v-model="editedItem.gender" 
                    item-value="id"
                    item-text="name"
                    :rules="rules.gender"
                    :items="genders"
                    label="* Género"
                    placeholder="Seleccione el Género"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols=12 md=6>
                  <v-text-field v-model="editedItem.phone" label="Teléfono"
                  :rules="rules.phone"/>
                </v-col>
                <v-col cols=12 md=6>
                  <v-text-field v-model="editedItem.mobile" label="Celular"
                  :rules="rules.mobile"/>
                </v-col>
                <v-col cols=12 md=6>
                  <v-text-field v-model="editedItem.address" label="Dirección"
                  :rules="rules.address"/>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
          <v-btn color="primary darken-1" outlined @click="save">{{ viewMode }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { URL_APP } from "../../util/constants";
  export default {
    name: 'ThirdForm',
    data() {
      return {
        dialog: false,
        search: '',
        identification_types: [
          {id:'CC', name:'Cédula de Ciudadanía'},
          {id:'CE', name:'Cédula de Extranjería'},
          {id:'TI', name:'Tarjeta de Identidad'},
          {id:'RC',  name:'Registro Civil'},
          {id:'P',  name:'Pasaporte'},
          {id:'N',  name:'NIT'},
          {id:'O',  name:'Otro'}
        ],
        genders: [
          {id:'F', name:'Femenino'},
          {id:'M', name:'Masculino'},
          {id:'N', name:'No Aplica'}
        ],
        rules: {
          name: [
            v => !!v || 'Campo requerido',
            v => v.length >= 3 || 'Mínimo 3 caracteres',
            v => v.length < 100 || 'Máximo 100 letras',
          ],
          address: [
            v => !!v || 'Campo Requerido',
            v => v.length < 200 || 'Máximo 200 letras',
          ],
          email: [
            v => !!v || 'Correo Requerido',
            v => (v ? /.+@.+\..+/.test(v):true) || 'Correo Invalido',
            v => (v ? v.length < 200:true) || 'Máximo 200 letras',
          ],
          type_id: [
            v => !!v || 'Campo Requerido',
          ],
          phone: [
            v => !!v || 'Campo Requerido',
          ],
          gender: [
            v => !!v || 'Campo Requerido',
          ],
        },
        editedIndex: -1,
        editedItem: {id: null, name: '', identification: '', email:'', state: 'Activo'},
        defaultItem: {id: null, name: '', identification: '', email:'', state: 'Activo'},
        valid: true
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Tercero' : 'Editar Tercero'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
      },

      show(item = this.editedItem){
        this.editedItem = item
        this.dialog = true;
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        }, 300)
      },

      async save () {
        if(!this.loading){
          if (this.valid){
            this.$root.$loadApp(true)
            var method1 = "post"
            if (this.editedIndex.id) {
              //Editar Item
              method1 = "put"
            }
            await this.$http({
              method: method1,
              url: URL_APP+'/api/thirds',
              data: this.editedItem
            })
            .then(response => {
              this.close()
              this.initialize();
              this.$root.$alert({text:response.data.message,color:"success",timeout:2000})
            }).catch(error => {
              this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
            })
            this.$root.$loadApp(false)
          }else{
            this.$root.$alert({text:"Por favor revise los errores",color:"error",timeout:2000})
          }
        }
      },
    }
  }
</script>
