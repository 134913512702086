const state = {
  genders: [
    {id: "F", name:"Femenino"},
    {id: "M", name:"Masculino"},
    {id: "O", name:"Otro"},
    {id: "N", name:"No Aplica"},
  ],
  identification_types: [
    {id:'CC', name:"Cédula de Ciudadanía"},
    {id:'TI', name:"Tarjeta de Identidad"},
    {id:'RC', name:"Registro Civil"},
    {id:'CE', name:"Cédula de Extrangería"},
    {id:'N', name:"Nit"},
    {id:'O', name:"Otro"},
  ]
}

const getters = {
  allGenders: state => state.genders,
  allIdentificationTypes: state => state.identification_types
}

const actions = {

}

const mutations = {

}

export default{
  state, getters, actions, mutations
}