  <template>
    <div>
    <v-card>
      <v-card-title>
        <span class="headline">Préstamos</span>
      </v-card-title>
      <v-card-text>
        <FilterQuery :filters="filters" :fieldFilters="fieldFilters" @freshFilters="initialize" id="filterQuery"></FilterQuery>
        <v-data-table
          :headers="headers"
          :items="allLoans"
          :search="search"
          :loading="loading"
          loading-text="Cargando Registros ..."
          multi-sort
          class="elevation-1 mt-1"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-text-field
                v-model="search"
                append-icon="fa-search"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-menu offset-y v-if="can('loan-create')">
                <template v-slot:activator="{ on: menu }">
                  <v-tooltip right>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn class="mx-2" fab dark small color="primary" v-on="{ ...tooltip, ...menu }" id="newLoan">
                        <v-icon dark>fa-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Nuevo Préstamo</span>
                  </v-tooltip>
                </template>
                <v-list dense flat>
                  <v-list-item-group>
                    <v-list-item @click="$refs.formLoan.newLoan('Equipo')">
                      <v-list-item-icon>
                        <v-icon>fa-chalkboard</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Equipo</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="$refs.formLoan.newLoan('Lugar')">
                      <v-list-item-icon>
                        <v-icon>fa-archway</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Lugar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-toolbar>
          </template>

          <template v-slot:item.equipment="{ item }">
            <template v-for="(eq, indexEqu) in item.equipment" >
              <v-chip color="secondary" :key="indexEqu" class="mt-1 mr-1" v-if="(eq.pivot.quantity-eq.pivot.quantity_return)>0" dark>
                {{ eq.description +"("+ (eq.pivot.quantity-eq.pivot.quantity_return)+")" }}
              </v-chip>
              <v-chip color="green" :key="indexEqu" v-else dark class="mt-1 mr-1">
                {{ eq.description +"("+ eq.pivot.quantity +")"}}
              </v-chip>
            </template>
          </template>
          <template v-slot:item.action="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon @click="$refs.formLoan.editItem(item)" v-on="on" class="mr-1 my-1" color="info">fa-eye</v-icon>
              </template>
              <span>Detalle</span>
            </v-tooltip>
            <v-tooltip bottom v-if="can('loan-delete')">
              <template v-slot:activator="{ on }">
                <v-icon @click="deleteItem(item)" v-on="on" class="mr-1 my-1" color="error">fa-trash</v-icon>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.state=='Prestado' && can('loan-create')">
              <template v-slot:activator="{ on }">
                <v-icon @click="returnLoan(item)" v-on="on" class="mr-1 my-1" color="orange">fa-reply</v-icon>
              </template>
              <span>Devolución</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn text icon color="green" dark @click="initialize()" v-on="on"><v-icon dark v-on="on">fa-sync-alt</v-icon></v-btn>
              </template>
              <span>Actualizar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <FormLoan ref="formLoan" />
    <LoanReturn :dialog="dialogReturn" @closeReturn="closeReturn" @saveReturn="saveReturn" :editedLoan="editedItem"/>
    <AlertInformation :email="notifyEmail" ref="alertInformation"/>
    <ThirdForm ref="formThird"/>
    </div>
</template>
<script>
  import { can } from '../../../util/functions';
  import { mapActions, mapGetters } from 'vuex'
  import { URL_APP } from "../../../util/constants";
  import { fechaHoy } from '../../../util/functions';
  import FormLoan from './FormLoan';
  import LoanReturn from '../LoanReturn';
  import AlertInformation from '../AlertInformation';
  import ThirdForm from '../../general/ThirdForm';
  import FilterQuery from '../../general/FilterQuery';

  export default{
    name: 'Loan',
    components: { LoanReturn, FormLoan,
        FilterQuery, AlertInformation,
        ThirdForm },
    data() {
      return {
        dialogReturn: false,
        dialogFilter: false,
        notifyEmail: '',
        search: '',
        fileImport: null,
        filters:[
          {field:{field:'state', name:'Estado', type:'select', options:["Prestado", "Devuelto"]}, operator:'=', value_start:'Prestado', value_end:''}
        ],
        fieldFilters:[
          {field:'type', name:'Tipo', type:'select', options:["Lugar", "Equipo"]},
          {field:'third.identification', name:'Identificación Solicitante', type:'number'},
          {field:'third.name', name:'Nombre Solicitante', type:'text'},
          {field:'state', name:'Estado', type:'select', options:["Prestado", "Devuelto"]},
          {field:'date', name:'Fecha Préstamo', type:'date'},
          {field:'loan_return.date', name:'Fecha Devolución', type:'date'},
          {field:'equipment.serial', name:'Serial de Equipo', type:'text'},
          {field:'equipment.inventory', name:'Inventario de Equipo', type:'text'},
          {field:'equipment.description', name:'Descripción de Equipo', type:'text'},
          {field:'place.name', name:'Lugar', type:'text'},
        ],
        headers: [
            { text: 'Tipo', value: 'type' },
            { text: 'Identificacion', value: 'third.identification' },
            { text: 'Nombre', value: 'third.name' },
            { text: 'Lugar', value: 'place.name' },
            { text: 'Equipos', value: 'equipment' },
            { text: 'Fecha Préstamo', value: 'date' },
            { text: 'Estado', value: 'state' },
            { text: 'Acciones', value: 'action', sortable: false },
        ],
        types:[
          'Estudiante', 'Docente', 'Administrativo', 'Externo'
        ],
        editedIndex: -1,
        thirdItem: {id: null, name: '', identification: '', email:'', state: 'Activo'},
        editedItem: {id: 0, type:0, date: '', observation: '', user_id:'', user_loan:'', type_borrower:'', data_borrower:'', state:'Prestado', place_id:'', equipment:[], place:{name:'',campus:{name:''}}, third_id:'', third:{id:0, identification:'', name:''}, loan_return:[]},
        defaultItem: {id: 0, type:0, date: '', observation: '', user_id:'', user_loan:'', type_borrower:'', data_borrower:'', state:'Prestado', place_id:'', equipment:[], place:{name:'',campus:{name:''}}, third_id:'', third:{id:0, identification:'', name:''}, loan_return:[]},
        valid: true
      }
    },

    computed: {
      ...mapGetters(['allLoans','loading']),
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Préstamo' : 'Editar Préstamo'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    mounted () {
      this.initialize()
    },
    methods: {
      ...mapActions(['chgLoad', 'getLoans']),
      can:can,
      async initialize () {
        this.chgLoad(true)
        await this.getLoans(this.filters)
        .catch(error => { console.error(error)
          this.$root.$alert({text:"Error al Obtener los Préstamos",color:"error",timeout:2000})
        });
        this.chgLoad(false)
      },

      fechaHoy(){
        fechaHoy()
      },

      deleteItem (item) {
        var url = URL_APP+"/api/loan/loans/" + item.id;
        this.$root.$confirm('Eliminar Préstamo', 'Esta Seguro?', { color: 'red' })
        .then((res) => {
          if(res){
            this.$http.delete(url)
            .then(response => { console.error(response)
              this.initialize();
              this.$root.$alert({text:"¡ Registro Eliminado !",color:"success",timeout:2000})
            })
            .catch(error => { console.error(error)
              this.$root.$alert({text:"Imposible Eliminar Registro",color:"error",timeout:2000})
            });
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

      getPlaces (type) {
        this.$http.get(URL_APP+"/api/loan/myplaces?type="+type).then(response => {
          this.places = response.data
          //this.$root.$alert({text:"PDF Generado con Éxito",color:"success",timeout:1000})
        }).catch(error => {
          this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
        });
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedItem.equipment = Array()
          this.editedIndex = -1
        }, 300)
      },

      closeReturn() {
        this.dialogReturn = false
        this.close()
      },

      returnLoan(item){
        if(item.state=="Prestado"){
          this.editedIndex = this.allLoans.indexOf(item)
          this.editedItem = Object.assign({}, item)
          this.notifyEmail=this.editedItem.third.email;
          this.dialogReturn = true
        }else{
          this.$root.$alert({text:"Préstamo ya fué devuelto",color:"error",timeout:2000})
        }
      },

      async saveReturn(loadReturn){
        if(loadReturn.agree){
          this.chgLoad(true)
          await this.$http.put(URL_APP+"/api/loan/loanreturn", loadReturn)
          .then(response => {
            this.closeReturn()
            this.initialize();
            this.$root.$alert({text:response.data.message,color:"success",timeout:2000})
            this.$refs.alertInformation.open()
          })
          .catch(error => { console.error(error)
            this.$root.$alert({text:"Error al Guardar la devolucion",color:"error",timeout:2000})
          })
          this.chgLoad(false)
        }else{
          this.$root.$alert({text:"Debe Aceptar que es la Información Correcta",color:"error",timeout:2000})
        }
      },

    }
  }
</script>
