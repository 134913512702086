import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/loan/equipmenttypes"

const state = {
  equipmentTypes: []
}

const getters = {
  allEquipmentTypes: state => state.equipmentTypes
}

const actions = {

  async getEquipmentTypes({commit}){
    return new Promise(function(resolve, reject){
      commit('setEquipmentTypes', [])
      axios.get(URL_).then(res=>{
        commit('setEquipmentTypes', res.data)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async saveEquipmentType({commit},equipmentType){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, equipmentType).then(res=>{
        commit('newEquipmentType', res.data.equipmentType)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async updateEquipmentType({commit}, equipmentType){
    return new Promise((resolve, reject)=>{
      axios.put(URL_, equipmentType).then(res=>{
        commit('updEquipmentType', res.data.equipmentType)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deleteEquipmentType({commit}, equipmentType){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${equipmentType.id}`).then(res=>{
        commit('delEquipmentType', equipmentType)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setEquipmentTypes : (state, equipmentTypes) => state.equipmentTypes = equipmentTypes,
  newEquipmentType  : (state, equipmentType) => state.equipmentTypes.unshift(equipmentType),
  updEquipmentType  : (state, equipmentType) => {
    const index = state.equipmentTypes.findIndex(u => u.id === equipmentType.id)
    if (index !== -1) state.equipmentTypes.splice(index, 1, equipmentType)
  },
  delEquipmentType  : (state, equipmentType) => state.equipmentTypes = state.equipmentTypes.filter(u => u.id !== equipmentType.id)
}

export default{
  state, getters, actions, mutations
}