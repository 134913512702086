<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      persistent :overlay="false"
      max-width="600px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar dense color="primary" dark>
          Importar Equipos para Baja
          <v-spacer></v-spacer>
          <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
              <v-btn small icon dark v-bind="attrs" v-on="on" class="mx-1" @click="close()"> 
                <v-icon dark>fa-times</v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-toolbar>
          <v-card-text>
            <v-form ref="formImport" v-model="valid" @submit.prevent="save()">
              <v-row>
                <v-col cols="12">
                  <v-file-input accept=".xls,.xlsx" label="Seleccionar Archivo"
                  :rules="rules.fileImport"
                    v-model="formImport.fileImport"></v-file-input>
                </v-col>
                <v-col cols="12">
                    <v-textarea
                      required
                      label="Observación"
                      v-model="formImport.observation"
                      :rules="rules.observation"
                    /> 
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
          <v-btn color="primary darken-1" outlined @click="save" 
            :loading="loading" 
            :disabled="loading">Aceptar</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogError" max-width="500px" scrollable persistent>
      <v-card>
        <v-toolbar color="error" dense dark>
          <v-icon class="mx-2 my-2">fa-exclamation-triangle</v-icon>
          <v-toolbar-title>
              Errores Encontrados
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn dark class="mx-2 my-2" text fab small v-on="on" @click.stop="dialogError=false">
                X
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <p class="body-1 mt-3">
            <b>Errores Encontrados:</b><br>
          </p>
          <template v-for="error in errors">
              <v-row :key="error.row">
                <v-col cols="12" class="my-0 py-0">
                  <template v-for="(error2, index2) in error.errors">
                    <v-sheet
                      class="text-caption px-2 mt-3 error--text"
                      color="red lighten-5"
                      elevation="1"
                      :key="'error2'+index2"
                    ><b>Fila: </b>{{ error.row }} - <b>Error: </b>{{ error2 }}</v-sheet>
                  </template>
                </v-col>
              </v-row>            
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="dialogError=false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import { URL_APP } from "../../../util/constants";
  import { objectToFormData } from "../../../util/functions"
  export default{
    name: 'ImportDrop',
    props: {

    },
    data() {
      return {
        dialog: false,
        dialogError: false,
        errors: [],
        rules: {
          fileImport: [
            v => {
              if (v) {
                return true
              } else {
                return 'Campo requerido'
              }
            },
            v => !v || v.size < 10000000 || 'No puede Subir Archivos mayor a 10 MB!'
          ],
          observation: [
            v => !!v || 'Campo Requerido',
          ],
        },
        formImport: {fileImport:null, observation:''},
        formDefault: {fileImport:null, observation:''},
        valid: true
      }
    },

    computed: {
      ...mapGetters(['loading']),
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    methods: {
      ...mapActions(['chgLoad']),

      async save(){
        this.$refs.formImport.validate()
        if(!this.loading){
          if(this.valid){
            var formData = objectToFormData(this.formImport)
            this.chgLoad(true)
            try{
              var response = await this.$http.post(URL_APP+"/api/loan/import_drop", formData)
              this.$root.$alert({text:response.data.message,color:"success",timeout:3000})
              this.chgLoad(false)
              this.close()
              await this.$emit('initialize')
            }catch(error){
              this.chgLoad(false)
              console.log(error.response)
              this.errors = error.response.data.errors
              this.dialogError = true
              this.$root.$alert({text:"Ocurrió un error al dar de baja los equipos",color:"error",timeout:3000})
            }
          }
        }
      },

      open(){
        this.dialog = true
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.formImport = this.formDefault
        }, 300)
      },

    }
  }
</script>
