<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Terceros</span>
      </v-card-title>
      <v-card-text>
        <FilterQuery :filters="filters" :fieldFilters="fieldFilters" @freshFilters="initialize(1)" id="filterQuery"></FilterQuery>
        <v-data-table
          v-model="thirdSelected"
          item-key="id"
          show-select
          :headers="headers"
          :items="allThirds"
          :search="search"
          :loading="loading"
          :options.sync="options"
          :sort-by.sync="options.sortBy"
          :sort-desc.sync="options.sortDesc"
          :items-per-page.sync="options.per_page"
          :server-items-length="totalThirds"
          multi-sort
          loading-text="Cargando Registros ..."
          class="elevation-1"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'fa-angle-double-left',
            lastIcon: 'fa-angle-double-right',
            prevIcon: 'fa-angle-left',
            nextIcon: 'fa-angle-right'
          }"
        >
          <template v-slot:top>
            <v-toolbar color="white">
              <v-text-field
                v-model="search"
                prepend-icon="fa-search"
                label="Buscar"
                clearable
                single-line
                hide-details
                @keypress.enter="initialize(1)"
                @click:clear="initialize(1)"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-tooltip bottom v-if="can('third-create')">
                <template v-slot:activator="{ on }">
                  <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="$refs.formThird.editItem()">
                    <v-icon dark>fa-plus</v-icon>
                  </v-btn>
                </template>
                <span>Nuevo</span>
              </v-tooltip>
              <v-menu offset-y >
                <template v-slot:activator="{ on: menu }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn class="mx-2" icon color="primary" v-on="{ ...tooltip, ...menu }" id="newLoan">
                        <v-icon dark>fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>
                    <span>Mas...</span>
                  </v-tooltip>
                </template>
                <v-list dense flat>
                  <v-list-item-group>
                    <v-list-item @click="$refs.importThird.open()" v-if="can('third-create')">
                      <v-list-item-icon>
                        <v-icon color="light-blue darken-3">fa-file-import</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Importar Terceros</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="exportSelected()">
                      <v-list-item-icon>
                        <v-icon color="green darken-3">fa-check-square</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Exportar Seleccionados</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="exportThirds()">
                      <v-list-item-icon>
                        <v-icon color="green darken-3">fa-filter</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Exportar Filtro</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-toolbar>
          </template>
          <template v-slot:item.action="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon @click="$refs.formThird.editItem(item)" v-on="on" class="mr-1 my-1" color="info">fa-pen</v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom v-if="can('third-delete')">
              <template v-slot:activator="{ on }">
                <v-icon @click="deleteItem(item)" v-on="on" class="mr-1 my-1" color="error">fa-trash</v-icon>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn text icon color="green" dark @click="initialize()" v-on="on"><v-icon dark v-on="on">fa-sync-alt</v-icon></v-btn>
              </template>
              <span>Actualizar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <FormThird ref="formThird"/>
    <ImportThird ref="importThird" @initialize="initialize"/>
 </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import { URL_APP } from "../../../util/constants";
  import { createLinkFile, can } from '../../../util/functions';
  import FilterQuery from '../../general/FilterQuery';
  import FormThird from './FormThird'
  import ImportThird from './ImportThird'
  export default{
    name: 'Third',
    components: {
      FormThird, FilterQuery, ImportThird
    },
    data() {
      return {
        dialogDrop: false,
        thirdSelected:[],
        options: {page:1, per_page:10, sortBy:['identification'], sortDesc:[false]},
        search: '',
        filters:[],
        fieldFilters:[
          {field:'identification', name:'Identificación', type:'text'},
          {field:'name', name:'Nombre', type:'text'},
        ],
        headers: [
          { text: 'Identification', value: 'identification' },
          { text: 'Nombre', value: 'name' },
          { text: 'Acciones', value: 'action', sortable: false },
        ],
      }
    },

    computed: {
      ...mapGetters(['allThirds', 'allGenders', 'allIdentificationTypes', 'loading','totalThirds']),
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },
    watch: {
      'options.page' () {
        this.initialize()
      },
      'options.per_page' () {
        this.initialize()
      },
      'options.sortBy' () {
        this.initialize()
      },
      'options.sortDesc' () {
        this.initialize()
      },
    },

    mounted() {
      this.initialize()
      this.fieldFilters.push({field:'gender', name:'Género', type:'select', options:this.allGenders})
      this.fieldFilters.push({field:'type_id', name:'Tipo de Identificación', type:'select', options:this.allIdentificationTypes})
      var this_2=this
      window.addEventListener('keydown', function(e) {
        if (e.altKey == true && e.code == 'KeyN'){
          this_2.$refs.formThird.editItem()
        }
      });
    },

    methods: {
      ...mapActions(['getThirds','deleteThird', 'chgLoad']),
      can:can,
      async initialize (page=null) {
        if(!this.loading){
          if (page!=null) this.options.page = page
          this.chgLoad(true)
          var params = {filters : this.filters, options : this.options, search : this.search}
          try{
            await this.getThirds(params)
          }catch(error){ console.error(error)
            this.$root.$alert({text:"Error al Obtener los Terceros",color:"error",timeout:2000})
          }
          this.chgLoad(false)
        }
      },

      exportSelected() {
        var third_send = []
        this.thirdSelected.forEach(function(third){
          third_send.push(third.id)
        })
        this.exportThirds(third_send)
      },

      async exportThirds(third = null) {
        var url = third == null? URL_APP+"/api/export_thirds" : URL_APP+"/api/export_thirds?thirds="+JSON.stringify(third)
        this.chgLoad(true)
        var params = {filters : this.filters, options : this.options, search : this.search}
        await this.$http.get(url, {responseType: 'blob', params}).then(response => {
          createLinkFile(response)
        }).catch(error => { console.error(error)
          this.$root.$alert({text:"Error al Obtener el Excel",color:"error",timeout:2000})
        });
        this.chgLoad(false)
      },

      deleteItem (item) {
        this.$root.$confirm('Eliminar Tercero', 'Esta Seguro?', { color: 'red' })
        .then(async (res) => {
          if(res){
            this.chgLoad(true)
            try{
              var msg = await this.deleteThird(item)
              this.$root.$alert({text:msg,color:"success",timeout:3000})
            }catch(error){
              console.error(error)
              this.$root.$alert({text:"Imposible Eliminar Registro",color:"error",timeout:3000})
            }
            this.chgLoad(false)
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

    }
  }
</script>
