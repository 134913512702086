import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/loan/locations"

const state = {
  locations: []
}

const getters = {
  allLocations: state => state.locations
}

const actions = {

  async getLocations({commit}){
    return new Promise(function(resolve, reject){
      commit('setLocations', [])
      axios.get(URL_).then(res=>{
        commit('setLocations', res.data)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async saveLocation({commit}, location){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, location).then(res=>{
        commit('newLocation', res.data.location)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async updateLocation({commit}, location){
    return new Promise((resolve, reject)=>{
      axios.put(URL_, location).then(res=>{
        //console.log(res.data)
        commit('updLocation', res.data.location)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deleteLocation({commit}, location){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${location.id}`).then(res=>{
        commit('delLocation', location)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setLocations : (state, locations) => state.locations = locations,
  newLocation  : (state, location) => state.locations.unshift(location),
  updLocation  : (state, location) => {
    const index = state.locations.findIndex(u => u.id === location.id)
    if (index !== -1) state.locations.splice(index, 1, location)
  },
  delLocation  : (state, location) => state.locations = state.locations.filter(c => c.id !== location.id)
}

export default{
  state, getters, actions, mutations
}