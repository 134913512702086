<template>
    <div>
        <v-dialog v-model="dialog" max-width="1000px" scrollable>
            <v-card>
                <v-toolbar dark color="primary" dense>
                    <v-toolbar-title>
                        Selección de Proveedores
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="closeItem">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>

                <v-card-text style="min-height:600px">
                    <v-text-field autofocus v-model="search" prepend-icon="fa-search" label="Buscar" clearable single-line
                        hide-details></v-text-field>
                    <v-data-table :headers="headers" :items="allSuppliers" class="elevation-1" pagination.sync="pagination"
                        item-key="id" :search="search">

                        <template v-slot:item="{ item }">
                            <tr @click="setSupplier(item)" style="cursor: pointer">
                                <td>{{ item.name }}</td>
                                <td>{{ item.nit }}</td>
                                <td>{{ item.responsible }}</td>
                            </tr>
                        </template>

                    </v-data-table>
                </v-card-text>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {

    name: "SelectSupplier",
    data() {
        return {
            dialog: false,
            search: '',
            headers: [
                { text: 'Nombre del proveedor', value: 'name' },
                { text: 'NIT', value: 'nit' },
                { text: 'Responsable', value: 'responsible' },
            ]
        }
    },
    methods: {
        ...mapActions(['chgLoad', 'getSuppliers']),
        async show() {

            this.chgLoad(true)
            await this.getSuppliers()
            this.chgLoad(false)
            this.dialog = true
        },

        closeItem() {
            this.dialog = false
        },

        setSupplier(item) {
            this.$emit('setSupplier', item)
            this.dialog = false
        }

    },
    computed: {
        ...mapGetters(['allSuppliers'])
    }
}
</script>

<style lang="scss" scoped></style>