import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/loan/equipment_drops"

const state = {
  equipment_drops: [],
  equipment_dropT: 0
}

const getters = {
  allEquipmentDrops: state => state.equipment_drops,
  totalEquipmentDrop: state => state.equipment_dropT
}

const actions = {

  async getEquipmentDrops({commit}, params){
    return new Promise(function(resolve, reject){
      commit('setEquipmentDrops', [])
      axios.get(URL_+'?page='+params.options.page, {
        params
      }).then(res=>{
        commit('setEquipmentDrops', res.data.data)
        commit('setEquipmentDropT', res.data.total)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async saveEquipmentDrop({commit},equipment_drop){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, equipment_drop).then(res=>{
        commit('newEquipmentDrop', res.data.equipment_drop)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async updateEquipmentDrop({commit},equipment_drop){
    return new Promise((resolve, reject)=>{
      axios.put(URL_, equipment_drop).then(res=>{
        //console.log(res.data)
        commit('updEquipmentDrop', res.data.equipment_drop)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deleteEquipmentDrop({commit}, equipment_drop){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${equipment_drop.id}`).then(res=>{
        commit('delEquipmentDrop', equipment_drop)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setEquipmentDrops : (state, equipment_drops) => state.equipment_drops = equipment_drops,
  setEquipmentDropT : (state, total) => state.equipment_dropT = total,
  newEquipmentDrop  : (state, equipment_drop) => {
    state.equipment.unshift(equipment_drop)
    state.equipment_dropT = state.equipment_dropT+1 
  },
  updEquipmentDrop  : (state, equipment_drop) => {
    const index = state.equipment_drops.findIndex(e => e.id === equipment_drop.id)
    if (index !== -1) state.equipment_drops.splice(index, 1, equipment_drop)
  },
  delEquipmentDrop  : (state, equipment_drop) => {
    state.equipment_drops = state.equipment_drops.filter(e => e.id !== equipment_drop.id)
    state.equipment_droptT = state.equipment_dropT-1 
  }
}

export default{
  state, getters, actions, mutations
}