<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="editedItem.signatures"
      :items-per-page="50"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Firmas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="$refs.selectThird.show()">
                <v-icon dark>fa-plus</v-icon>
              </v-btn>
            </template>
            <span>Nuevo</span>
          </v-tooltip>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon @click="deleteItem(item)" v-on="on" class="mr-1 my-1" color="error">fa-trash</v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <SelectThird ref="selectThird" @setThird="setThird"/>
  </div>
</template>

<script>
  import SelectThird from '../Third/SelectThird';
  export default {
    props: {
      editedItem: {
        type: Object,
        default: null,
      },
    },
    name:'Signature',
    components: {SelectThird},
    data(){
      return {
        headers:[
          { text: 'Id', value: 'identification' },
          { text: 'Nombre', value: 'name' },
          { text: 'Acciones', value: 'action', sortable: false },
        ],
        defaultItem: {id: 0, file: null, is_public:false, signatures:[], permissions:[]},
      }
    },
    methods: {
      async setThird(third){
        var _this2 = this
        var valid = true
        await Promise.all(_this2.editedItem.signatures.map(s=> {
          if(third.id==s.id){
            valid = false
            this.$root.$alert({text:"Tercero ya existe",color:"error",timeout:2000})
          }
        }))
        
        if(valid){
          this.editedItem.signatures.push(third)
          this.$root.$alert({text:"Añadido con éxito",color:"success",timeout:2000})
        }
        this.$refs.selectThird.show()
      },
      deleteItem (item) {
        this.$root.$confirm('Eliminar Firma', 'Esta Seguro?', { color: 'red' })
        .then(async (res) => {
          if(res){
            try{
              this.editedItem.signatures = this.editedItem.signatures.filter(s=>s.id!=item.id)
            }catch(error){
              console.error(error)
              this.$root.$alert({text:"Imposible Eliminar Registro",color:"error",timeout:3000})
            }
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },
    }

  }
</script>

<style>

</style>