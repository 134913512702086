import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@fortawesome/fontawesome-free/css/all.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  iconfont: 'fa',
  theme: {
    themes: {
      light: {
        primary: '#3F51B5',
        secondary: '#F6B417',
        accent: '#ff9800',
        error: '#E62028',
        success: '#4caf50',
        warning: '#ffeb3b',
        info: '#2196f3',        
      },
    },
  },
});
