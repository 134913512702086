import Welcome from "../views/Welcome";
import Login from "../views/auth/Login";
import Recover from "../views/auth/Recover";
import FindRecover from "../views/auth/FindRecover";
//Configuración
import Profile from "../views/config/Profiles";
import User from "../views/config/Users";
//Configuración
import Third from "../views/general/Third/Third";
import Campus from "../views/general/Campus";
import Tier from "../views/general/Tier/Tier";
import Building from "../views/general/Building/Building";
import Area from "../views/general/Area/Area";
import CostCenter from "../views/general/CostCenter/CostCenter";
import Place from "../views/general/Place";
import EquipmentType from "../views/loan/EquipmentType";
import Equipment from "../views/loan/equipment/Equipment";
import GroupTic from "../views/loan/GroupTic";
import FileType from "../views/general/FileType/FileType";
import InventoryFile from "../views/general/InventoryFile/InventoryFile";
//Préstamos
import Loan from "../views/loan/Loan/Loan";
import TicReport from "../views/loan/TicReport";
//Bajas
import EquipmentDrop from "../views/loan/EquipmentDrop";
//Traslados
import EquipmentTransfer from "../views/loan/transfer/EquipmentTransfer";
//Traslados
import SignaturePad from "../components/SignaturePad";
//CONFIGURACIÓN CONSUMIBLES
import ConsumablesBrands from "../views/loan_consumables/brands/brandsView.vue";
import ConsumablesSuppliers from "../views/loan_consumables/suppliers/suppliersView.vue";
import ConsumablesShelf from "../views/loan_consumables/shelf/shelfView.vue";
import ConsumablesUnitsMeasurement from "../views/loan_consumables/units_measurement/UnitsMeasurement.vue";
import ConsumablesArticles from "../views/loan_consumables/articles/Articles.vue";
import ConsumablesTypeArticles from "../views/loan_consumables/type_articles/TypeArticles.vue";
import ConsumablesPurchases from "../views/loan_consumables/purchases/Purchases.vue";
import ConsultPurchase from "../views/loan_consumables/purchases/ConsultPurchase.vue";
import ConsumablesWarehouses from "../views/loan_consumables/warehouse/Warehouse.vue";
import ConsumablesMove from "../views/loan_consumables/transfers/move/Move.vue";
import ConsumablesInventory from "../views/loan_consumables/inventory/inventory.vue";
import ConsumablesDelivery from "../views/loan_consumables/delivery/Delivery.vue";

//TODO: Rutas de activos fijos
import AssetsArticles from "../views/loan_assets/articles/Articles.vue";
import AssetThirds from "../views/loan_assets/thirds/Thirds.vue";

export const routes = [
  {
    path: "/welcome",
    name: "welcome",
    component: Welcome,
    meta: { title: "Bienvenido" },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { title: "Iniciar Sesión" },
  },
  {
    path: "/recover",
    name: "recover",
    component: Recover,
    meta: { title: "Recuperar Contraseña" },
  },
  {
    path: "/findrecover/:t",
    name: "findrecover",
    component: FindRecover,
    meta: { title: "Recuperar Contraseña" },
  },
  {
    path: "/profiles",
    name: "profiles",
    component: Profile,
    meta: { requiresAuth: true, title: "Perfiles" },
  },
  {
    path: "/users",
    name: "users",
    component: User,
    meta: { requiresAuth: true, title: "Usuarios" },
  },
  //Tablas básicas
  {
    path: "/thirds",
    name: "thirds",
    component: Third,
    meta: { requiresAuth: true, title: "Terceros" },
  },
  {
    path: "/campuses",
    name: "campuses",
    component: Campus,
    meta: { requiresAuth: true, title: "Campuses" },
  },
  {
    path: "/tiers",
    name: "tiers",
    component: Tier,
    meta: { requiresAuth: true, title: "Pisos" },
  },
  {
    path: "/buildings",
    name: "buildings",
    component: Building,
    meta: { requiresAuth: true, title: "Edificios" },
  },
  {
    path: "/areas",
    name: "areas",
    component: Area,
    meta: { requiresAuth: true, title: "Areas" },
  },
  {
    path: "/costcenters",
    name: "costcenter",
    component: CostCenter,
    meta: { requiresAuth: true, title: "Centros de Costo" },
  },
  {
    path: "/places",
    name: "places",
    component: Place,
    meta: { requiresAuth: true, title: "Lugares" },
  },
  {
    path: "/equipment",
    name: "equipment",
    component: Equipment,
    meta: { requiresAuth: true, title: "Equipos" },
  },
  {
    path: "/equipmenttypes",
    name: "equipmenttypes",
    component: EquipmentType,
    meta: { requiresAuth: true, title: "Tipos Equipo" },
  },
  {
    path: "/grouptics",
    name: "grouptics",
    component: GroupTic,
    meta: { requiresAuth: true, title: "Grupos" },
  },
  {
    path: "/file_types",
    name: "file_types",
    component: FileType,
    meta: { requiresAuth: true, title: "Tipos de Archivos" },
  },
  {
    path: "/files",
    name: "files",
    component: InventoryFile,
    meta: { requiresAuth: true, title: "Archivos" },
  },
  //Préstamos
  {
    path: "/loans",
    name: "loans",
    component: Loan,
    meta: { requiresAuth: true, title: "Prestar" },
  },
  {
    path: "/equipment_drops",
    name: "equipment_drops",
    component: EquipmentDrop,
    meta: { requiresAuth: true, title: "Bajas de Equipos" },
  },
  {
    path: "/equipment_transfers",
    name: "equipment_transfers",
    component: EquipmentTransfer,
    meta: { requiresAuth: true, title: "Traslados de Equipos" },
  },
  {
    path: "/ticreports",
    name: "ticreport",
    component: TicReport,
    meta: { requiresAuth: true, title: "Reportes" },
  },
  {
    path: "/signature",
    name: "signature",
    component: SignaturePad,
    meta: { title: "Firma Digital" },
  },

  //Consumibles
  {
    path: "/consumables_brands",
    name: "consumables_brands",
    component: ConsumablesBrands,
    meta: { requiresAuth: true, title: "Marcas" },
  },
  {
    path: "/consumables_suppliers",
    name: "consumables_suppliers",
    component: ConsumablesSuppliers,
    meta: { requiresAuth: true, title: "proveedores" },
  },
  {
    path: "/consumables_shelf",
    name: "consumables_shelf",
    component: ConsumablesShelf,
    meta: { requiresAuth: true, title: "Estantes" },
  },
  {
    path: "/consumables_units_measurement",
    name: "consumables_units_measurement",
    component: ConsumablesUnitsMeasurement,
    meta: { requiresAuth: true, title: "Unidades de Medida" },
  },
  {
    path: "/consumables_articles",
    name: "consumables_articles",
    component: ConsumablesArticles,
    meta: { requiresAuth: true, title: "Articulos" },
  },
  {
    path: "/consumables_type_articles",
    name: "consumables_type_articles",
    component: ConsumablesTypeArticles,
    meta: { requiresAuth: true, title: "Tipos de Artículos" },
  },
  {
    path: "/consumables_purchases",
    name: "consumables_purchases",
    component: ConsumablesPurchases,
    meta: { requiresAuth: true, title: "Listado de compra de artículos" },
  },
  {
    path: "/consumables_purchase_all",
    name: "consumables_purchase_all",
    component: ConsultPurchase,
    meta: { requiresAuth: true, title: "Listado de compra de artículos" },
  },
  {
    path: "/consumables_warehouses",
    name: "consumables_warehouses",
    component: ConsumablesWarehouses,
    meta: { requiresAuth: true, title: "Listado de Bodegas" },
  },
  {
    path: "/consumables_move",
    name: "consumables_move",
    component: ConsumablesMove,
    meta: { requiresAuth: true, title: "Listado de Traslados" },
  },
  {
    path: "/consumables_inventory",
    name: "consumables_inventory",
    component: ConsumablesInventory,
    meta: { requiresAuth: true, title: "Inventario de consumibles" },
  },
  {
    path: "/consumables_delivery",
    name: "consumables_delivery",
    component: ConsumablesDelivery,
    meta: { requiresAuth: true, title: "Entrega de consumibles" },
  },
  {
    path: "/assets_articles",
    name: "assets_articles",
    component: AssetsArticles,
    meta: { requiresAuth: true, title: "Artículos de activos fijos" },
  },
  {
    path: "/assets_thirds",
    name: "assets_thirds",
    component: AssetThirds,
    meta: { requiresAuth: true, title: "Lista de terceros" },
  },
];
