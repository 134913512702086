<template>
  <div>
    <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
      <v-card>
        <v-toolbar dense color="primary" dark>
          <v-app-bar-title>{{ formTitle }}</v-app-bar-title>
        </v-toolbar>
        <v-card-text>
          <v-form v-model="valid" ref="formThird" @submit.prevent="save">
            <v-container>
              <v-row>
                <v-col cols=12>
                  <v-text-field v-model="editedItem.name" label="* Nombre"
                  :rules="rules.name" autofocus></v-text-field>
                </v-col>
                <v-col cols=12 md=6>
                  <v-autocomplete :disabled="!can('third-create')"
                    v-model="editedItem.type_id" 
                    item-value="id"
                    item-text="name"
                    :rules="rules.type_id"
                    :items="allIdentificationTypes"
                    label="* Tipo de Identificación"
                    placeholder="Seleccione el Tipo de Identificación"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols=12 md=6>
                  <v-text-field v-model="editedItem.identification" label="* Identificación"
                  :disabled="!can('third-create')"
                  :rules="rules.identification" required/>
                </v-col>
                <v-col cols=12 md=6>
                  <v-text-field v-model="editedItem.email" label="E-mail" type="email"
                  :disabled="!can('third-create')"
                  :rules="rules.email" />
                </v-col>
                <v-col cols=12 md=6>
                  <v-autocomplete :disabled="!can('third-create')"
                    v-model="editedItem.gender" 
                    item-value="id"
                    item-text="name"
                    :rules="rules.gender"
                    :items="allGenders"
                    label="Género"
                    placeholder="Seleccione el Género"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols=12 md=6>
                  <v-text-field v-model="editedItem.phone" label="Teléfono"
                  :disabled="!can('third-create')"
                  :rules="rules.phone"/>
                </v-col>
                <v-col cols=12 md=6>
                  <v-text-field v-model="editedItem.mobile" label="Celular"
                  :disabled="!can('third-create')"
                  :rules="rules.mobile"/>
                </v-col>
                <v-col cols=12 md=6>
                  <v-text-field v-model="editedItem.address" label="Dirección"
                  :disabled="!can('third-create')"
                  :rules="rules.address"/>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
          <v-btn color="primary darken-1" outlined @click="save" v-if="can('third-create')">{{ viewMode }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { can } from '../../../util/functions';
  import { mapActions, mapGetters } from 'vuex'
  export default {
    name: 'ThirdForm',
    data() {
      return {
        dialog: false,
        search: '',
        rules: {
          name: [
            v => !!v || 'Campo requerido',
            v => v.length >= 3 || 'Mínimo 3 caracteres',
            v => v.length < 100 || 'Máximo 100 letras',
          ],
          identification: [
            v => !!v || 'Campo requerido',
            v => v.length >= 4 || 'Mínimo 4 caracteres',
            v => v.length < 20 || 'Máximo 100 letras',
          ],
          type_id: [
            v => !!v || 'Campo Requerido',
          ],
          /*address: [
            v => !!v || 'Campo Requerido',
            v => v ? v.length < 200:false || 'Máximo 200 letras',
          ],
          phone: [
            v => !!v || 'Campo Requerido',
          ],*/
          email: [
            v => !!v || 'Correo Requerido',
            v => (v ? /.+@.+\..+/.test(v):true) || 'Correo Invalido',
            v => (v ? v.length < 200:true) || 'Máximo 200 letras',
          ],
          gender: [
            v => !!v || 'Campo Requerido',
          ],
        },
        editedIndex: -1,
        editedItem: {id: null, name: '', identification: '', address:'', email:'', phone:'', state: 'Activo'},
        defaultItem: {id: null, name: '', identification: '', address:'', email:'', phone:'', state: 'Activo'},
        valid: true
      }
    },

    computed: {
      ...mapGetters(['loading', 'allGenders', 'allIdentificationTypes']),
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Tercero' : 'Editar Tercero'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    mounted () {
      this.initialize()
      var this_2=this
      window.addEventListener('keydown', function(e) {
        if (e.altKey == true && e.code.includes('KeyS')){
          this_2.save()
        }
      });
    },
    methods: {

      ...mapActions(['saveThird', 'updateThird', 'chgLoad']),
      can:can,

      initialize () {
      },

      editItem(item = this.defaultItem){
        this.editedItem = item
        this.dialog = true;
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        }, 300)
      },

      async save () {
        if(!this.loading){
          this.$refs.formThird.validate()
          if (this.valid){
            this.chgLoad(true)
            try{
              var msg = ""
              if (this.editedItem.id){
                msg = await this.updateThird(this.editedItem)
              }else{
                msg = await this.saveThird(this.editedItem)
              }
              this.$root.$alert({text:msg.message,color:"success",timeout:2000})
              this.close()
            }catch(error){
              this.$root.$alert({text:"Ha Ocurrido un Error al guardar el Registro",color:"error",timeout:2000})
            }
            this.chgLoad(false)
          }else{
            this.$root.$alert({text:"Por favor revise los errores",color:"error",timeout:2000})
          }
        }
      },
    }
  }
</script>
