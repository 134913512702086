import Vue from 'vue'
import VueRouter from 'vue-router'
//Informacion de las rutas
import {routes} from './routes';

Vue.use(VueRouter)

const router = new VueRouter({
  routes
})

export default router
