import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance;
const URL_ = URL_APP + "/api/loan_consumables/purchases";

const state = {
  purchases: [],
  purchasesT: 0,
};

const getters = {
  allPurchases: (state) => state.purchases,
  totalPurchases: (state) => state.purchasesT,
};

const actions = {
  async getPurchases({ commit }, params) {
    return new Promise(function(resolve, reject) {
      commit("setPurchases", []);
      axios
        .get(URL_ + "?page=" + params.options.page, { params })
        .then((res) => {
          commit("setPurchases", res.data.data);
          commit("setPurchasesT", res.data.total);
          resolve("Ok");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async savePurchase({ commit }, purchase) {
    return new Promise((resolve, reject) => {
      axios
        .post(URL_, purchase)
        .then((res) => {
          commit("newPurchase", res.data.purchase);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async saveLocate({ commit }, purchase) {
    return new Promise((resolve, reject) => {
      axios
        .post(URL_APP + "/api/loan_consumables/purchase_locate", purchase)
        .then((res) => {
          commit("saveLocate", res.data.purchase);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async updatePurchase({ commit }, purchase) {
    return new Promise((resolve, reject) => {
      axios
        .put(URL_, purchase)
        .then((res) => {
          commit("updatePurchase", res.data.purchase);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async activePurchase({ commit }, purchase) {
    return new Promise((resolve, reject) => {
      axios
        .put(URL_APP + "/api/loan_consumables/active_purchase", purchase)
        .then((res) => {
          commit("activePurchase", res.data.purchase);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  setPurchases: (state, purchases) => (state.purchases = purchases),
  setPurchasesT: (state, total) => (state.purchasesT = total),
  newPurchase: (state, purchase) => {
    state.purchases.unshift(purchase);
    state.purchasesT = state.purchasesT + 1;
  },
  updatePurchase: (state, purchase) => {
    const index = state.purchases.findIndex((u) => u.id === purchase.id);
    if (index !== -1) {
      state.purchases.splice(index, 1, purchase);
    }
  },
  activePurchase: (state, purchase) => {
    const index = state.purchases.findIndex((u) => u.id === purchase.id);
    if (index !== -1) {
      state.purchases.splice(index, 1, purchase);
    }
  },
  saveLocate: (state, purchase) => {
    const index = state.purchases.findIndex((u) => u.id === purchase.id);
    if (index !== -1) {
      state.purchases.splice(index, 1, purchase);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
