import { render, staticRenderFns } from "./FormSuppliers.vue?vue&type=template&id=ff515542&scoped=true"
import script from "./FormSuppliers.vue?vue&type=script&lang=js"
export * from "./FormSuppliers.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff515542",
  null
  
)

export default component.exports