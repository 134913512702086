<template>
    <div>
        <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="800px" transition="dialog-transition">
            <v-card>
                <v-toolbar dense color="primary" dark>
                    <v-toolbar-title>Importar artículos</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="closeItem">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>


                <v-card-text>
                    <v-form ref="formImportItems_" @submit.prevent="save()">
                        <v-row>
                            <v-col cols="12">
                                <v-file-input accept=".xls,.xlsx" label="Seleccionar Archivo" :rules="rules.fileImport"
                                    v-model="formImport.fileImport"></v-file-input>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="secondary" @click.stop="closeItem">Cancelar</v-btn>
                    <v-btn color="primary" @click.stop="save">Enviar</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import * as XLSX from 'xlsx';
export default {
    name: "FormImportItems",
    props: {
        allInfoArticles: {
            type: Array,
            default: null
        }
    },
    data: () => {
        return {
            dialog: false,
            formImport: {
                fileImport: null,
            },
            rules: {
                fileImport: [
                    (v) => !!v || 'El archivo es requerido',
                    v => (v && v.size < 10000000) || 'No puede Subir Archivos mayor a 10 MB!'
                ]
            },
            importedData: null,
            infoArticles: [],
            editedItems: []
        }
    },
    methods: {
        closeItem() {
            this.formImport = {
                fileImport: null,
            },
                this.dialog = false,
                this.importedData = null,
                this.infoArticles = [],
                this.editedItems = []
        },

        openItem() {
            this.dialog = true;
        },

        save() {
            const file = this.formImport.fileImport;

            if (!file) {
                // Handle caso sin archivo seleccionado
                return;
            }

            const reader = new FileReader();

            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];

                // Convertir los datos del archivo a un array de objetos
                const importedData = XLSX.utils.sheet_to_json(worksheet);

                // Normalizar las claves a minúsculas
                const normalizedData = importedData.map(item => {
                    const newItem = {};
                    for (const key in item) {
                        newItem[key.toLowerCase()] = item[key];
                    }
                    return newItem;
                });

                // Validación de las cabeceras
                const expectedHeaders = ['inventario', 'unidades', 'valor'];
                // const actualHeaders = Object.keys(importedData[0]).map(header => header.toLowerCase());
                const actualHeaders = Object.keys(normalizedData[0]);

                if (!this.areHeadersValid(actualHeaders, expectedHeaders)) {
                    this.$root.$alert({ text: "Las cabeceras no son validas", color: "error", timeout: 2000 });
                    return;
                }

                //Validación de la existencia de inventarios
                const allArticlesInventory = this.allInfoArticles.map(item => item.inventory.toLowerCase()); //Estos los obtenemos por el props y seleccionamos solo el inventario
                const importedInventory = normalizedData.map(item => String(item.inventario).toLowerCase()); //Estons los los que el usuario diligencia

                const invalidInventoryItems = importedInventory.filter(item => !allArticlesInventory.includes(item)); // Almacenamos los inventarios que no existan
                // Array para almacenar errores
                const errors = []; //Array para almacenar los errores

                if (invalidInventoryItems.length > 0) {
                    // Agregar mensaje de error a la lista
                    errors.push(`Los siguentes números de inventario no están registrados: ${invalidInventoryItems.join(', ')}.`);
                }

                //Validamos que los cambios Unidades y Valor tengan información
                const missingValueQuantityItems = normalizedData.filter(item => !item.unidades || !item.valor);

                if (missingValueQuantityItems.length > 0) {
                    errors.push(' Algunos elementos no tienen información en los campos valor o cantidad.');
                }

                //Si existen los errores mostramos el error en un sweetalert
                if (errors.length > 0) {
                    // Construir el mensaje de error
                    const errorMessage = errors.join('<br><br>');
                    // Mostrar SweetAlert2 con los errores
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Errores',
                        html: errorMessage,
                    });

                    return;
                }

                // Si todo está bien, guardaremos el los datos en un array de objetos con el fin de enviar la información al formulario
                normalizedData.forEach(importedItem => {
                    // Encontrar el elemento correspondiente en allInfoArticles por el inventario
                    const matchingInfoArticle = this.allInfoArticles.find(infoArticle =>
                        String(infoArticle.inventory).toLowerCase() === String(importedItem.inventario).toLowerCase()
                    );

                    if (matchingInfoArticle) {
                        // Obtener el ID de allInfoArticles
                        const id = matchingInfoArticle.id;

                        // Almacenar la información en editedItems
                        this.editedItems.push({
                            article_id: id,
                            name: matchingInfoArticle.name,
                            // inventory: matchingInfoArticle.inventory,
                            units: importedItem.unidades,
                            unit_price: importedItem.valor,
                            total_price: importedItem.unidades * importedItem.valor,
                        });
                    }
                });

                const searchDuplicates = this.itemsDuplicates(this.editedItems);
                if (searchDuplicates) {
                    this.$root.$alert({ text: "Existen elementos repetidos", color: "error", timeout: 2000 });
                    this.editedItems = []
                    return;
                }


                this.addImportItems(this.editedItems)
            };

            reader.readAsArrayBuffer(file);
        },

        addImportItems(item) {
            this.$emit('addImportItems', item)
            // this.$root.$alert({ text: "Información cargada con éxito", color: "success", timeout: 5000 });
            this.closeItem()
        },

        areHeadersValid(actualHeaders, expectedHeaders) {
            //Función para validar las cabeceras
            return expectedHeaders.every(header => actualHeaders.includes(header));
        },

        selectItemFromAllInfoArticles(selectedItemId) {
            // Buscar el artículo por ID en allInfoArticles
            const selectedItem = this.allInfoArticles.find(item => item.id === selectedItemId);

            // Actualizar editedItem con los datos del artículo seleccionado
            this.editedItem.id = selectedItem.id;
            this.editedItem.inventario = selectedItem.inventario;
            this.editedItem.cantidad = 0; // Puedes establecer un valor predeterminado o dejarlo como nulo
            this.editedItem.valor = 0; // Puedes establecer un valor predeterminado o dejarlo como nulo
        },

        itemsDuplicates(items) {
            const itemsFound = {};

            for (let i = 0; i < items.length; i++) {
                const article_id = items[i].article_id;

                // Si el inventario ya está en el objeto auxiliar, entonces hay un duplicado
                if (itemsFound[article_id]) {
                    return true; // Hay duplicados
                }
                else {
                    // Si no, agrega el inventario al objeto auxiliar
                    itemsFound[article_id] = true;
                }
            }

            // No se encontraron duplicados
            return false;
        }
    }
}
</script>

<style lang="scss" scoped></style>