import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import {initialize} from './helpers/general';
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "./plugins/vuetify-money.js";

import Vue2Filters from 'vue2-filters';

import './registerServiceWorker'
axios.defaults.headers.common['Accept'] = 'application/json;charset=UTF-8'
axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.headers.common['Access-Control-Allow-Headers'] = '*'

const options = {
  confirmButtonColor: '#3F51B5',
  cancelButtonColor: '#f6ba2a',
  customClass: {
    // Clase para personalizar el tipo de letra
    popup: 'my-sweetalert-font',
  },
};

Vue.use(VueSweetalert2, options)
Vue.use(Vue2Filters)


Vue.config.productionTip = false
Vue.prototype.$http = axios;


initialize(store, router, Vue.prototype.$http);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
