<template>
  <div>
    <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>
            {{formTitle}}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                X
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-toolbar>

        <v-card-text>
          <v-text-field autofocus
            v-model="search"
            append-icon="fa-search"
            label="Buscar"
            id="equipment_id"
            single-line
            hide-details
            @click:row="addItem"
          ></v-text-field>
          <v-data-table 
            :headers="header"
            :items="list_equipment"
            :search="search"
            sort-by="description"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'fa-angle-double-left',
              lastIcon: 'fa-angle-double-right',
              prevIcon: 'fa-angle-left',
              nextIcon: 'fa-angle-right'
            }"
          >
          <template v-slot:item="{ item }">
              <tr @click="addItem(item)" class="cursor:pointer">
                <td>{{ item.id }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.serial }}</td>
                <td>{{ item.inventory }}</td>
                <td>{{ item.type.name }}</td>
              </tr>
          </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info darken-1" outlined @click="close">Terminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  export default{
    name: 'EquipmentAdd',
    props: {
      list_equipment: {
        type: Array,
        default: null
      },
    },
    data() {
      return {
        search:'',
        dialog: false,
        header: [
            { text: 'Id', value: 'id' },
            { text: 'Descripción', value: 'description' },
            { text: 'Serial', value: 'serial' },
            { text: 'Inventario', value: 'inventory' },
            { text: 'Tipo', value: 'type.name' },
        ],
        equipment: [],
        equip: {id:0, description:'', serial:'', inventory:'', type:{name:''}, pivot:{group_tic_id: 0, equipment_id: 0, stock: 1, borrowed: 0}},
        defaultEquip: {id:0, description:'', serial:'', inventory:'', type:{name:''}, pivot:{group_tic_id: 0, equipment_id: 0, stock: 1, borrowed: 0}},
        stockIndex: -1,
        stockItem: {pivot:{stock:0,borrowed:0}},
        defaultPivot: {group_tic_id: 0, equipment_id: 0, stock: 1, borrowed: 0},
        newPivot: {group_tic_id: 0, equipment_id: 0, stock: 1, borrowed: 0},
      }
    },

    computed: {
      formTitle () {
        return 'Seleccionar Equipos'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      editedItem (val) {
        return val
      }
    },
    mounted () {
      //this.initialize()
    },
    methods: {
      initialize () {
      },

      editedItem(equipment=[]){
        this.equipment = equipment
        this.dialog = true
      },

      close () {
        this.dialog = false
      },

      addItem(item){
        var valid = true
        var _this2 = this
        //Revisar si ya existe el equipo
        if (Array.isArray(this.equipment)) {
          this.equipment.forEach(e=>{
            if(item.id == e.id){
              _this2.$root.$alert({text:"Equipo ya Existe (1)",color:"error",timeout:2000})
              valid = false
            }
          })
        }
        if(valid){
          this.$emit('addEquipment',item)
        }
      },

    }
  }
</script>
