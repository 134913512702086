<template>
    <div>
        <v-card>
            <v-card-title primary-title>
                Compra de artículos consumibles
            </v-card-title>

            <v-card-text>
                <FilterQuery :filters="filters" :fieldFilters="fieldFilters" @freshFilters="initialize2(1)"
                    id="filterQuery"></FilterQuery>
                <v-data-table :headers="headers" item-key="id" :items="allPurchases" :search="search"
                    :options.sync="options" :sort-by="options.sortBy" :sort-desc.sync="options.sortDesc"
                    :items-per-page.sync="options.per_page" :server-items-length="totalPurchases" multi-sort
                    :loading="loading" loading-text="Cargando..." :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'fa-angle-double-left',
                        lastIcon: 'fa-angle-double-right',
                        prevIcon: 'fa-angle-left',
                        nextIcon: 'fa-angle-right'
                    }">
                    <template v-slot:top>
                        <v-toolbar color="white">
                            <v-text-field v-model="search" prepend-icon="fa-search" label="Buscar..." clearable single-line
                                hide-details @keypress.enter="initialize2(1)" @click:clear="initialize2(1)"></v-text-field>
                            <v-spacer></v-spacer>

                            <v-menu offset-y v-if="can('consumables.purchases-save')">
                                <template v-slot:activator="{ on: menu }">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn class="mx-2" icon color="primary" v-on="{ ...tooltip, ...menu }"
                                                id="newPurchase">
                                                <v-icon dark>fa-ellipsis-v</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Mas...</span>
                                    </v-tooltip>

                                </template>
                                <v-list dense flat>
                                    <template v-slot:activator="{ on: menu }">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on: tooltip }">
                                                <v-btn class="mx-2" icon color="primary" v-on="{ ...tooltip, ...menu }"
                                                    id="newPurchase">
                                                    <v-icon dark>fa-ellipsis-v</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Mas...</span>
                                        </v-tooltip>
                                    </template>
                                    <v-list-item-group>
                                        <v-list-item @click.stop="$event => $refs.formPurchase_.newItem()">
                                            <v-list-item-icon>
                                                <v-icon color="blue darken-3">fa-plus-circle</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Registrar nueva compra</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item @click.stop="$refs.formNewReference_.newReference()">
                                            <v-list-item-icon>
                                                <v-icon color="green darken-3">fa-box</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Agregar referencia de compra</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                    </v-list-item-group>
                                </v-list>
                            </v-menu>

                        </v-toolbar>
                    </template>

                    <!-- Boton de acciones  -->

                    <template v-slot:item.actions="{ item }">

                        <v-tooltip right v-if="can('consumables.purchases-list') && item.active">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2"
                                    @click="$event => $refs.formPurchase_.editItem(item, params = { action: 'Ver' })"
                                    color="blue">
                                    fa-eye
                                </v-icon>
                            </template>
                            <span>Ver</span>
                        </v-tooltip>

                        <v-tooltip top v-if="can('consumables.purchases-save') && item.active && !item.confirmed">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2"
                                    @click="$event => $refs.formPurchase_.editItem(item, params = { action: 'Editar' })"
                                    color="orange">
                                    fa-pen
                                </v-icon>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>

                        <v-tooltip right v-if="can('consumables.purchases-save') && !item.confirmed">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2" @click="activeItem(item)" color="green">
                                    fa-check
                                </v-icon>
                            </template>
                            <span>Confirmar compra</span>
                        </v-tooltip>

                        <v-tooltip right v-if="can('consumables.transfer-save') && (item.confirmed && !item.located)">
                            <!-- v-if="can('consumables.transfer-save') && item.confirmed && (!item.transfer || (item.transfer.confirmed == false))"> -->
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2" @click="$event => $refs.formLocate_.open(item)"
                                    color="green">
                                    mdi mdi-package-variant-plus
                                </v-icon>
                            </template>
                            <span>Agregar a bodega</span>
                        </v-tooltip>

                    </template>
                    <!-- fin de boton de acciones  -->

                    <template v-slot:item.total_amount="{ item }">
                        {{ item.total_amount | currency }}
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <FormPurchase :allSuppliers="allSuppliers" :allInfoArticles="allInfoArticles" ref="formPurchase_" />
        <FormNewReference :allSuppliers="allSuppliers" :allInfoArticles="allInfoArticles" ref="formNewReference_" />
        <!-- <FormTransferReference ref="formTransferReference_" :allInfoArticles="allInfoArticles" /> -->
        <!-- <FormLocate ref="formLocate_" :allInfoArticles="allInfoArticles" :allShelfs="allShelfs" /> -->
        <FormLocate ref="formLocate_" :allInfoArticles="allInfoArticles" :allMyWarehouses="allMyWarehouses" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { can } from '../../../util/functions';
import FormPurchase from './FormPurchases.vue';
import FilterQuery from '../../general/FilterQuery';
import FormNewReference from './FormNewReference.vue';
import FormLocate from '../transfers/relocation/FormLocate.vue';

export default {
    name: 'consumables_purchases',
    components: {
        FormPurchase, FilterQuery, FormNewReference, FormLocate,
    },
    data: () => {
        return {
            search: '',
            loading: false,
            editedIndex: -1,
            headers: [
                {
                    text: 'Número de Orden',
                    align: 'start',
                    value: 'invoice'
                },
                { text: 'Referencia', value: 'reference' },
                { text: 'Nombre del Proveedor', value: 'supplier.name', sortable: false },
                { text: 'Fecha de compra', value: 'buy_date', sortable: false },
                { text: 'Valor total', value: 'total_amount', sortable: false },
                { text: 'Registrado por:', value: 'user.name', sortable: false },
                { text: 'Acciones', value: 'actions', sortable: false }

            ],
            filters: [
                { field: { field: 'active', name: 'Estado', type: 'select', options: ['Activo', 'Inactivo'] }, operator: '=', value_start: 'Activo', value_end: '' }
            ],
            fieldFilters: [
                { field: 'active', name: 'Estado', type: 'select' },
                { field: 'invoice', name: 'Número de orden', type: 'text' },
                { field: 'buy_date', name: 'Fecha de compra', type: 'date' },
                { field: 'created_at', name: 'Fecha de creación', type: 'date' },
            ],
            options: { page: 1, per_page: 10, sortBy: ['created_at'], sortDesc: [true] },

        }
    },

    mounted() {
        this.initialize()
        var this_2 = this
        window.addEventListener('keydown', function (e) {
            if (e.altKey == true && e.code == 'KeyN') {
                this_2.$refs.formPurchase_.newItem()
            }
        });
    },

    computed: {
        ...mapGetters(['allSuppliers', 'allInfoArticles', 'allPurchases', 'totalPurchases', 'allMyWarehouses']),
        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro'
        },
        viewMode() {
            return this.editedIndex === -1 ? 'Guardar' : 'Editar'
        },
        currentUser() {
            return this.$store.getters.currentUser
        }
    },

    watch: {
        'options.page'() {
            this.initialize2()
        },
        'options.per_page'() {
            this.initialize2()
        },
        'options.sortBy'() {
            this.initialize2()
        },
        'options.sortDesc'() {
            this.initialize2()
        },
    },

    methods: {
        ...mapActions(['chgLoad', 'getSuppliers', 'getAllInfoArticles', 'getPurchases', 'activePurchase', 'getMyWarehouses']),
        can: can,

        async initialize() {
            this.chgLoad(true);
            this.getSuppliers();
            this.getAllInfoArticles();
            // this.getNameShelfs();
            this.getMyWarehouses();

            var params = { filters: this.filters, options: this.options, search: this.search }
            try {
                await this.getPurchases(params)
            }
            catch (error) {
                this.$root.$alert({ text: "Error al Obtener las compras", color: "error", timeout: 2000 })
            }
            this.chgLoad(false);
        },

        async initialize2(page = null) {
            if (!this.loading) {
                if (page != null) this.options.page = page
                this.chgLoad(true);
                var params = { filters: this.filters, options: this.options, search: this.search }
                try {
                    await this.getPurchases(params)
                }
                catch (error) {
                    this.$root.$alert({ text: "Error al Obtener los equipos", color: "error", timeout: 2000 })
                }
                this.chgLoad(false)
                this.loading = false;
            }
            else {
                this.chgLoad(false);

            }
        },

        async activeItem(item) {
            this.$swal({
                title: 'Confirmación',
                text: "Recuerde que al hacer clic en confirmar, no se podrá modificar la información de la compra",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar'
            })
                .then(async (res) => {
                    if (res.isConfirmed) {
                        this.chgLoad(true)
                        try {
                            var msg = await this.activePurchase(item)
                            await this.initialize()
                            this.chgLoad(false)
                            await this.$swal(
                                {
                                    title: 'Registro exitoso',
                                    text: msg.message,
                                    icon: 'success',
                                    timer: 2000
                                }
                            )
                        }
                        catch (err) {
                            this.chgLoad(false)
                            await this.$swal(
                                {
                                    title: 'Error!',
                                    text: 'La compra no se ha confirmado.',
                                    icon: 'error',
                                    timer: 2000
                                }
                            )
                        }
                    }

                })
        }


    },


}
</script>

<style lang="scss" scoped></style>