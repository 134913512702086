<template>
    <div>
        <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="1200px" transition="dialog-transition">
            <v-card>
                <v-toolbar dense color="primary" dark>
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-card-text>
                    <v-form v-model="valid" ref="formPurchases_">

                        <v-stepper v-model="step" vertical>
                            <v-stepper-step step="1" editable>Información general de la compra</v-stepper-step>
                            <v-stepper-content step="1">
                                <v-form ref="formPurchase_" @submit.prevent="save" :disabled="!edit">
                                    <v-col cols="12" md="12">
                                        <v-text-field v-model="editedItem.invoice" label="* Número de la orden de compra"
                                            :rules="rules.invoice" required :counter="100"
                                            :disabled="editedItem.reference > 1">
                                            <template v-slot:append v-if="editedItem.id">
                                                <v-subheader><b>Número de referencia: {{ editedItem.reference }} </b>
                                                </v-subheader>
                                            </template>
                                        </v-text-field>
                                    </v-col>


                                    <v-col cols="12" md="12">
                                        <v-autocomplete v-model="editedItem.supplier_id" item-value="id" item-text="name"
                                            :items="allSuppliers" :rules="rules.supplier_id" label="* Proveedor"
                                            placeholder="Seleccione el proveedor" required></v-autocomplete>
                                    </v-col>

                                    <v-col cols="12" md="12">
                                        <v-menu v-model="menFechIni" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="editedItem.buy_date" label="Fecha de compra"
                                                    prepend-icon="fa-calendar-alt" readonly v-on="on" id="buy_date"
                                                    clearable placeholder="Seleccione la fecha de compra"
                                                    :rules="rules.buy_date"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="editedItem.buy_date" @input="menFechIni = false"
                                                :max="fechaHoy()"></v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col cols="12" md="12">
                                        <v-textarea v-model="editedItem.observations"
                                            label="Observaciones de la compra"></v-textarea>
                                    </v-col>
                                </v-form>
                                <v-btn class="mt-3" color="primary" @click="step = 2">
                                    Siguiente
                                </v-btn>
                            </v-stepper-content>

                            <v-stepper-step step="2" editable>Registro de articulos</v-stepper-step>
                            <v-stepper-content step="2">
                                <v-spacer></v-spacer>

                                <v-tooltip bottom>

                                    <template v-slot:activator="{ on }">
                                        <v-btn class="mx-2 my-2" fab dark color="primary" small v-on="on"
                                            @click.stop="$refs.formAddItem_.newAddItem()" :disabled="!edit">
                                            <v-icon>fa-plus</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Agregar</span>
                                </v-tooltip>

                                <v-tooltip bottom>

                                    <template v-slot:activator="{ on }">
                                        <v-btn class="mx-2 my-2" fab dark color="green" small v-on="on"
                                            @click.stop="$refs.formImportItems_.openItem()" :disabled="!edit">
                                            <v-icon>fa-file-excel</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Importar Excel</span>
                                </v-tooltip>


                                <v-data-table :headers="headers" :item-key="itemKey" :items="editedItem.options">
                                    <template v-slot:item.unit_price="{ item }">
                                        {{ item.unit_price | currency }}
                                    </template>

                                    <template v-slot:item.total_price="{ item }">
                                        {{ item.total_price | currency }}
                                    </template>

                                    <template v-slot:item.actions="{ item }">
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on: tooltip }">
                                                <v-icon small v-on="{ ...tooltip }" class="mr-2" @click="editAddItem(item)"
                                                    color="orange" :disabled="!edit">
                                                    fa-pen
                                                </v-icon>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip right>
                                            <template v-slot:activator="{ on: tooltip }">
                                                <v-icon small v-on="{ ...tooltip }" class="mr-2"
                                                    @click="deleteArrayItem(item)" color="red" :disabled="!edit">
                                                    fa-trash
                                                </v-icon>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>

                            </v-stepper-content>
                        </v-stepper>
                    </v-form>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="secondary" @click.stop="close">Cancelar</v-btn>
                    <v-btn color="primary" @click.stop="save" :disabled="!edit" v-if="edit">{{ viewMode
                    }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <FormAddItem :allSuppliers="allSuppliers" :allInfoArticles="allInfoArticles" @saveItem="saveItem"
            ref="formAddItem_" />
        <FormImportItems ref="formImportItems_" :allInfoArticles="allInfoArticles" @addImportItems="addImportItems" />
    </div>
</template>

<script>
import FormAddItem from './FormAddItem.vue';
import { mapGetters, mapActions } from 'vuex';
import { can } from '../../../util/functions';
import FormImportItems from './FormImportItems.vue';
export default {
    name: 'FormConsumablePurchase',
    props: {
        allSuppliers: {
            type: Array,
            default: null
        },
        allInfoArticles: {
            type: Array,
            default: null
        }
    },
    components: { FormAddItem, FormImportItems },
    data: () => {
        return {
            //para el input de moneda

            configCurrency: {
                locale: "es-CO",
                prefix: "$",
                suffix: "",
                length: 15,
                precision: 0
            },
            // hasta aca 
            dialog: false,
            dialogAdd: false,
            itemKey: 'name',
            valid: true,
            editArray: false,
            editIndex: '',
            step: 1,
            searchInput: '',
            options: {},
            editedItem: { options: [] },
            menFechIni: false,
            editedIndex: -1,
            edit: false,
            // viewItem: false,
            rules: {
                'invoice': [
                    (v) => !!v || 'El número de la orden de compra es obligatorio',
                    //El lenght no debe ser mayor a 100
                    (v) => (v && v.length) <= 100 || 'El número de orden no puede contener más de 100 carácteres',
                ],
                'supplier_id': [
                    (v) => !!v || 'El proveedor es obligatorio',
                ],
                'article_id': [
                    (v) => !!v || 'El artículo es obligatorio',
                ],
                'buy_date': [
                    (v) => !!v || 'La fecha de compra es obligatoria',
                ],
                'units': [
                    (v) => !!v || 'El número de unidades es obligatorio',
                    (v) => v > 0 || 'El valor unitario debe ser mayor que 0',

                ],
                'unit_price': [
                    (v) => !!v || 'El precio unitario es obligatorio',
                    (v) => parseFloat(v) > 0 || 'El valor unitario debe ser mayor que 0',
                ],


            },
            headers: [
                { text: 'Articulo', align: 'start', value: 'name', sorteable: false, },
                { text: 'Unidades', value: 'units' },
                { text: 'Precio unitario', value: 'unit_price' },
                { text: 'Precio total', value: 'total_price' },
                { text: 'Acciones', value: 'actions' }
            ]

        }
    },

    methods: {
        ...mapActions(['chgLoad', 'savePurchase', 'updatePurchase']),
        can: can,

        newItem() {
            this.dialog = true
            this.edit = true
        },

        editItem(item, params) {
            if (params.action === 'Editar') {
                this.edit = true
            }
            else {
                this.edit = false
            }


            this.editedItem = {
                'id': item.id,
                'invoice': item.invoice,
                'observations': item.observations,
                'supplier_id': item.supplier_id,
                'buy_date': item.buy_date,
                'options': item.purchase_details,
                'reference': item.reference
            }

            this.editedItem.options.forEach(elm => {
                const selectedItem = this.allInfoArticles.find(item => item.id === elm.article_id)
                elm['name'] = selectedItem.name
            })

            this.dialog = true
        },

        close() {
            this.dialog = false
            this.editedItem = {}
            this.editedItem = { options: [] }
            this.$refs.formPurchase_.reset()
            this.edit = false

        },

        fechaHoy() {
            return new Date().toISOString().substr(0, 10)
        },

        openDialogAdd() {
            this.dialogAdd = true;
        },

        closeDialogAdd() {
            this.dialogAdd = false;
            this.editArray = false;
        },

        saveItem(data) {
            const validName = this.editedItem.options.findIndex(e => e.name === data.name);

            if (validName != -1 && data.edit == false) {
                this.$root.$alert({ text: "Ya registró el artículo", color: "error", timeout: 2000 });
            }

            else if ((data.edit && (validName >= 0) && (validName != this.editIndex))) {
                this.$root.$alert({ text: "El articulo que intenta actualizar ya está registrado", color: "error", timeout: 2000 });
            }

            else {
                if (!data.edit) {
                    delete data.edit;
                    this.editedItem.options.push(data);
                    this.$refs.formAddItem_.close()
                }
                else {
                    delete data.edit;
                    this.$set(this.editedItem.options, this.editIndex, data)
                    this.$refs.formAddItem_.close()
                }
            }

        },

        existeNombre(name) {
            return this.editedItem.options.find(item => item.name === name);
        },

        editAddItem(item) {
            this.editIndex = this.editedItem.options.findIndex(e => e.name === item.name)
            const dataSend = Object.assign({}, item)
            this.$refs.formAddItem_.editAddItem(dataSend)
        },

        deleteArrayItem(item) {
            const opt = Object.assign({}, item);
            this.editIndex = this.editedItem.options.findIndex(e => e.name === opt.name);
            if (this.editIndex != -1) {
                this.editedItem.options.splice(this.editIndex, 1);
                this.editIndex = "";
            }

        },

        async save() {
            if (!this.editedItem.options.length > 0) {
                this.$root.$alert({ text: "No ha registrado articulos", color: "error", timeout: 2000 });
            }
            else if (this.$refs.formPurchase_.validate()) {
                this.$swal({
                    title: "Confirmación",
                    text: "¿Está seguro de guardar la información registrada?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, deseo guardar',
                    cancelButtonText: 'Cancelar'
                })
                    .then(async (res) => {
                        if (res.isConfirmed) {
                            this.chgLoad(true);
                            try {
                                var msg = "";
                                if (this.editedItem.id) {
                                    msg = await this.updatePurchase(this.editedItem)
                                }
                                else {
                                    msg = await this.savePurchase(this.editedItem)
                                }

                                this.$root.$alert({ text: msg.message, color: "success", timeout: 2000 });
                                this.close();
                            }
                            catch (error) {
                                this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 });
                                this.chgLoad(false);
                            }
                            this.chgLoad(false);

                        }
                    })
            }
            else {
                this.$root.$alert({ text: "No se ha diligenciado valores obligatorios", color: "error", timeout: 2000 });
            }
        },

        selectNameArticle() {
            const SelectedItem = this.allInfoArticles.find(item => item.id === this.options.article_id);
            this.options.name = SelectedItem ? SelectedItem.name : '';
        },

        async addImportItems(item) {
            //    crea un for para item
            const items = JSON.parse(JSON.stringify(item));
            const duplicates = this.findDuplicate(items)
            if (duplicates) {
                this.$root.$alert({ text: "Existen elementos duplicados", color: "error", timeout: 5000 });

            }
            else {
                items.forEach(elm => {
                    this.editedItem.options.push(elm);
                })
                this.$root.$alert({ text: "Los elementos se agregaron correctamente", color: "success", timeout: 5000 });
            }

        },

        findDuplicate(items) {
            var itemsFound = {};

            if (this.editedItem.options.length > 0) {
                const dataRegister = this.editedItem.options.map(item => item.article_id)

                dataRegister.forEach(elm => {
                    itemsFound[elm] = true
                })
            }

            for (let j = 0; j < items.length; j++) {
                const article_id = items[j].article_id;
                // Si el inventario ya está en el objeto auxiliar, entonces hay un duplicado
                if (itemsFound[article_id]) {
                    return true; // Hay duplicados
                }
                else {
                    // Si no, agrega el inventario al objeto auxiliar
                    itemsFound[article_id] = true;
                }
            }

            return false;
        }

    },

    computed: {
        ...mapGetters(['loading']),
        currentuser() {
            return this.$store.getters.currentuser
        },
        formTitle() {
            return !this.editedItem.id ? 'Nuevo Registro' : 'Editar Registro'
        },
        viewMode() {
            return !this.editedItem.id ? 'Guardar' : 'Editar'
        },
    }
}
</script>

<style lang="scss" scoped></style>