<template>
    <div>
        <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="800px" transition="dialog-transition">
            <v-card>
                <v-toolbar dense color="primary" dark>
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>

                <v-card-text>
                    <v-form v-model="valid" ref="formShelf_" @submit.prevent="save">
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-col cols=12>
                                        <v-text-field v-model="editedItem.name" label="* Nombre" :counter="100"
                                            :rules="rules.name" required></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-autocomplete v-model="editedItem.warehouse_id" item-value="id" item-text="name"
                                            :items="allWarehouses" :rules="rules.warehouse_id" label="* Bodega"
                                            placeholder="Seleccione la bodega" required>
                                            <template v-slot:selection="{ item }">
                                                <v-chip v-if="item" small>
                                                    {{ item.name }} - {{ item.campus.name }}
                                                </v-chip>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-col>


                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="secondary" @click.stop="close">Cancelar</v-btn>
                    <v-btn color="primary" @click.stop="save">{{ viewMode }}</v-btn>
                </v-card-actions>

            </v-card>

        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { can } from '../../../util/functions';
export default {
    name: 'FormConsumableShelf',
    props: {
        allWarehouses: {
            type: Array,
            default: null,
        }
    },
    data: () => {
        return {
            dialog: false,
            valid: true,
            editedItem: {},
            rules: {
                name: [
                    (v) => !!v || 'El nombre es requerido',
                    (v) => (v && v.length <= 100) || 'El nombre no puede contener más de 100 carácteres'
                ],
                warehose_id: [
                    (v) => !!v || 'La bodega es requerida',
                ]
            }
        }
    },
    methods: {
        ...mapActions(['chgLoad', 'saveShelf', 'updateShelf']),
        can: can,

        newItem() {
            this.dialog = true
        },

        editItem(item) {
            this.editedItem = Object.assign({}, item);
            this.dialog = true
        },

        close() {
            this.dialog = false
            this.editedItem = {}
            this.$refs.formShelf_.reset()
        },

        async save() {
            if (this.$refs.formShelf_.validate()) {
                this.chgLoad(true);
                try {
                    var msg = "";
                    if (this.editedItem.id) {
                        msg = await this.updateShelf(this.editedItem)
                    }
                    else {
                        msg = await this.saveShelf(this.editedItem)
                    }

                    // await this.$emit('initialize');
                    await this.$root.$alert({ text: msg.message, color: "success", timeout: 2000 });
                    await this.close();
                }
                catch (error) {
                    this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 });
                }
                this.chgLoad(false);

            }
            else {
                this.$root.$alert({ text: "Revise los errores", color: "error", timeout: 2000 });
            }
        }
    },

    computed: {
        ...mapGetters(['loading']),
        currentuser() {
            return this.$store.getters.currentuser
        },
        formTitle() {
            return !this.editedItem.id ? 'Nuevo Registro' : 'Editar Registro'
        },
        viewMode() {
            return !this.editedItem.id ? 'Guardar' : 'Editar'
        },
    }

}
</script>

<style lang="scss" scoped></style>