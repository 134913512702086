import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/tiers"

const state = {
  tiers: []
}

const getters = {
  allTiers: state => state.tiers
}

const actions = {

  async getTiers({commit}){
    return new Promise(function(resolve, reject){
      commit('setTiers', [])
      axios.get(URL_).then(res=>{
        commit('setTiers', res.data)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async saveTier({commit}, tier){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, tier).then(res=>{
        commit('newTier', res.data.tier)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async updateTier({commit}, tier){
    return new Promise((resolve, reject)=>{
      axios.put(URL_, tier).then(res=>{
        //console.log(res.data)
        commit('updTier', res.data.tier)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deleteTier({commit}, tier){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${tier.id}`).then(res=>{
        commit('delTier', tier)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setTiers : (state, tiers) => state.tiers = tiers,
  newTier  : (state, tier) => state.tiers.unshift(tier),
  updTier  : (state, tier) => {
    const index = state.tiers.findIndex(u => u.id === tier.id)
    if (index !== -1) state.tiers.splice(index, 1, tier)
  },
  delTier  : (state, tier) => state.tiers = state.tiers.filter(c => c.id !== tier.id)
}

export default{
  state, getters, actions, mutations
}