<template>
    <div>
        <v-dialog v-model="dialog" max-width="800px" scrollable persistent>
            <v-card>
                <v-toolbar dark color="primary">

                    <v-toolbar-title>
                        Generar reporte de entregas
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                                X
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-card-text>
                    <v-form v-model="valid" ref="reportDelivery_" @submit.prevent="save">
                        <v-container>
                            <v-row align="center">
                                <v-col cols="12" md="12">
                                    <v-autocomplete v-model="warehouse_id" item-value="id" item-text="name"
                                        label="* Bodega" placeholder="Seleccione la bodega" :items="allWarehouses"
                                        clearable prepend-icon="fa-warehouse" class="mr-2"
                                        :rules="rules.warehouse"></v-autocomplete>

                                </v-col>

                                <v-col cols="12" align="center">
                                    <!-- <span class="font-weight-bold">Fecha Inicial</span> -->
                                    <v-menu v-model="menFechIni" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="report.date_start" label="Fecha Inicial"
                                                prepend-icon="fa-calendar-alt" readonly v-on="on" id="date_start"
                                                clearable></v-text-field>
                                        </template>
                                        <v-date-picker v-model="report.date_start" @input="menFechIni = false"
                                            :max="fechaHoy()"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <!-- <v-col cols="8" align="center">
                                <v-menu v-model="menFechIni" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="report.date_start" label="Fecha Inicial"
                                            prepend-icon="fa-calendar-alt" readonly v-on="on" id="date_start"
                                            clearable></v-text-field>
                                    </template>
                                    <v-date-picker v-model="report.date_start" @input="menFechIni = false"
                                        :max="fechaHoy()"></v-date-picker>
                                </v-menu> -->
                                <!-- </v-col> -->
                                <v-col cols="12" align="center">
                                    <!-- <span class="font-weight-bold">Fecha Final</span> -->
                                    <v-menu v-model="menFechFin" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="report.date_end" label="Fecha Final"
                                                prepend-icon="fa-calendar-alt" readonly v-on="on" id="date_end"
                                                clearable></v-text-field>
                                        </template>
                                        <v-date-picker v-model="report.date_end" @input="menFechFin = false"
                                            :max="fechaHoy()"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <!-- <v-col cols="8" align="center">
                                <v-menu v-model="menFechFin" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="report.date_end" label="Fecha Final"
                                            prepend-icon="fa-calendar-alt" readonly v-on="on" id="date_end"
                                            clearable></v-text-field>
                                    </template>
                                    <v-date-picker v-model="report.date_end" @input="menFechFin = false"
                                        :max="fechaHoy()"></v-date-picker>
                                </v-menu>
                            </v-col> -->
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
                    <v-btn color="primary darken-1" outlined @click="save">Aceptar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { URL_APP } from '../../../util/constants';
import { createLinkFile } from '../../../util/functions';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'reportDelivery',

    data() {
        return {
            dialog: false,
            menFechIni: false,
            menFechFin: false,
            warehouse_id: 0,
            report: { date_start: '', date_end: '' },
            rules: {
                warehouse: [
                    (v) => !!v || 'El nombre es requerido',
                ]
            }
        }
    },


    methods: {
        ...mapActions(['chgLoad', 'getWarehouses']),
        fechaHoy() {
            return new Date().toISOString().substr(0, 10)
        },

        async initialize() {
            await this.chgLoad(true)
            await this.getWarehouses()
            await this.chgLoad(false)
        },

        async show() {
            await this.chgLoad(true)
            await this.getWarehouses()
            await this.chgLoad(false)
            this.dialog = true
        },

        close() {
            this.dialog = false
            this.menFechIni = false
            this.menFechFin = false
            this.warehouse_id = 0
            this.report = { date_start: '', date_end: '' }
            this.$refs.reportDelivery_.reset()

        },

        async save() {
            if (this.$refs.reportDelivery_.validate()) {
                var url = URL_APP + "/api/loan_consumables/reportDelivery";
                this.chgLoad(true);
                try {
                    const response = await this.$http.get(url, {
                        responseType: 'blob',
                        params: {
                            warehouse_id: this.warehouse_id,
                            date_start: this.report.date_start,
                            date_end: this.report.date_end
                        }
                    });
                    createLinkFile(response);
                    this.close();
                    this.$root.$alert({ text: "Descarga exitosa", color: "success", timeout: 2000 });
                } catch (error) {
                    this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 });
                }
            }
            else {
                this.$root.$alert({ text: "Revise los errores", color: "error", timeout: 2000 });
            }

            this.chgLoad(false);
        },
    },

    mounted() {
        this.initialize()
    },

    computed: {
        ...mapGetters(['allWarehouses'])
    }

}
</script>