<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px" scrollable persistent>
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>
            {{options.title}}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                X
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row align="center">
              <v-col cols="4" align="center">
                <span class="font-weight-bold">Fecha Inicial</span>
              </v-col>
              <v-col cols="8" align="center">
                <v-menu 
                  v-model="menFechIni"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="report.date_start"
                      label="Fecha Inicial"
                      prepend-icon="fa-calendar-alt"
                      readonly
                      v-on="on"
                      id="date_start"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="report.date_start" @input="menFechIni = false" :max="fechaHoy()"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4" align="center">
                <span class="font-weight-bold">Fecha Final</span>
              </v-col>
              <v-col cols="8" align="center">
                <v-menu 
                  v-model="menFechFin"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="report.date_end"
                      label="Fecha Final"
                      prepend-icon="fa-calendar-alt"
                      readonly
                      v-on="on"
                      id="date_end"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="report.date_end" @input="menFechFin = false" :max="fechaHoy()"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
          <v-btn color="primary darken-1" outlined @click="save">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { createLinkFile } from '../../../util/functions';
  export default{
    name: 'DateReport',
    props: {
      options: {
        type: Object,
        default() {
          return {title:'', url:''}
        }
      },
    },
    data() {
      return {
        dialog: false,
        menFechIni: false,
        menFechFin: false,
        report:{date_start:'', date_end:''}
      }
    },

    methods: {

      fechaHoy(){
        return new Date().toISOString().substr(0, 10)
      },

      show(){
        this.dialog = true
      },

      close () {
        this.dialog = false
      },

      save () {
        var url = this.options.url+ JSON.stringify(this.report);
        this.$root.$loadApp(true)
        this.$http.get(url, {responseType: 'blob'}).then(response => {
          createLinkFile(response)
          //this.$root.$alert({text:"PDF Generado con Éxito",color:"success",timeout:1000})
          this.$root.$loadApp(false)
        }).catch(error => { console.error(error)
          this.$root.$alert({text:"Error al Obtener el Reporte",color:"error",timeout:2000})
          this.$root.$loadApp(false)
        });
      },
    }
  }
</script>
