<template>
  <div>
    <v-card class="v-cardp">
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>
          <template>Reportes</template>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <h2 class="my-2">Préstamo de devolutivos</h2>

          <v-row justify="center" align="center">

            <v-col cols="12" sm="6" md="4">
              <v-hover v-slot:default="{ hover }">
                <v-card color="success" @click="dialogInventory = true" dark :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }">
                  <v-card-text>
                    <v-container>
                      <v-row justify="center" align="center">
                        <v-col cols="12" justify="center" align="center">
                          <v-icon dark x-large>fa-box-open</v-icon>
                        </v-col>
                        <v-col cols="12" justify="center" align="center">
                          <p class="body-1 text-center font-weight-bold">Inventario de Equipos</p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-hover v-slot:default="{ hover }">
                <v-card color="error" @click="dialogEquipment = true" dark :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }">
                  <v-card-text>
                    <v-container>
                      <v-row justify="center" align="center">
                        <v-col cols="4" justify="center" align="right">
                          <v-icon dark x-large>fa-plug</v-icon>
                        </v-col>
                        <v-col cols="4" justify="center" align="center">
                          <v-icon dark x-large>fa-mouse</v-icon>
                        </v-col>
                        <v-col cols="4" justify="center" align="left">
                          <v-icon dark x-large>fa-laptop</v-icon>
                        </v-col>
                        <v-col cols="12" justify="center" align="center">
                          <p class="body-1 text-center font-weight-bold">Equipos Pendientes</p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-hover v-slot:default="{ hover }">
                <v-card color="info" @click="dialogLoan = true" dark :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }">
                  <v-card-text>
                    <v-container>
                      <v-row justify="center" align="center">
                        <v-col cols="12" justify="center" align="center">
                          <v-icon dark x-large>fa-file-alt</v-icon>
                        </v-col>
                        <v-col cols="12" justify="center" align="center">
                          <p class="body-1 text-center font-weight-bold">Detallado de Prestamos</p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-hover v-slot:default="{ hover }">
                <v-card color="orange lighten-1" @click="$refs.reportDrop_.show()" dark :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }">
                  <v-card-text>
                    <v-container>
                      <v-row justify="center" align="center">
                        <v-col cols="12" justify="center" align="center">
                          <v-icon dark x-large>fa-times-circle</v-icon>
                        </v-col>
                        <v-col cols="12" justify="center" align="center">
                          <p class="body-1 text-center font-weight-bold">Bajas de Equipos</p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-hover v-slot:default="{ hover }">
                <v-card color="teal darken-3" @click="$refs.reportTransfer_.show()" dark :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }">
                  <v-card-text>
                    <v-container>
                      <v-row justify="center" align="center">
                        <v-col cols="12" justify="center" align="center">
                          <v-icon dark x-large>fa-people-arrows</v-icon>
                        </v-col>
                        <v-col cols="12" justify="center" align="center">
                          <p class="body-1 text-center font-weight-bold">Traslados de Equipos</p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>

          </v-row>


        </v-container>

        <v-divider class="my-4"></v-divider>
        <v-container v-if="can('asset.list  ')">
          <h2 class="my-2">Consumibles</h2>
          <v-row justify="center" align="center">

            <v-col cols="12" sm="6" md="4">
              <v-hover v-slot:default="{ hover }">
                <v-card color="indigo" @click="$refs.reportDelivery_.show()" dark :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }">
                  <v-card-text>
                    <v-container>
                      <v-row justify="center" align="center">
                        <v-col cols="4" justify="center" align="right">
                          <v-icon dark x-large>fa-calendar</v-icon>
                        </v-col>
                        <v-col cols="4" justify="center" align="center">
                          <v-icon dark x-large>fa-wrench</v-icon>
                        </v-col>
                        <v-col cols="4" justify="center" align="left">
                          <v-icon dark x-large>fa-tools</v-icon>
                        </v-col>
                        <v-col cols="12" justify="center" align="center">
                          <p class="body-1 text-center font-weight-bold">Reporte de entregas</p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <InventoryEquipment :dialog="dialogInventory" @close="closeInventory"></InventoryEquipment>
    <LoanDetails :dialog="dialogLoan" @close="closeLoan"></LoanDetails>
    <LoanPending :dialog="dialogEquipment" @close="closeEquipment"></LoanPending>
    <DateReport ref="reportTransfer_" :options="optionsTransfer" />
    <DateReport ref="reportDrop_" :options="optionsDrop" />
    <ReportDelivery ref="reportDelivery_" />
  </div>
</template>
<script>
import { URL_APP } from "../../util/constants";
import InventoryEquipment from './reports/InventoryEquipment';
import LoanDetails from './reports/LoanDetails';
import LoanPending from './reports/LoanPending';
import DateReport from './reports/DateReport';
import ReportDelivery from "../loan_consumables/delivery/reportDelivery.vue";
import { can } from "../../util/functions";
export default {
  name: 'TicReport',
  components: { InventoryEquipment, LoanDetails, LoanPending, DateReport, ReportDelivery },
  data() {
    return {
      optionsTransfer: {
        title: 'Reporte de Traslados',
        url: URL_APP + "/api/loan/report_equipment_transfers/"
      },
      optionsDrop: {
        title: 'Reporte de Bajas',
        url: URL_APP + "/api/loan/report_equipment_drops/"
      },
      dialogInventory: false,
      dialogEquipment: false,
      dialogLoan: false,
      dialogDrop: false,
    }
  },

  computed: {
  },

  watch: {
  },

  mounted() {
  },
  methods: {
    can:can,

    closeInventory() {
      this.dialogInventory = false
    },
    closeLoan() {
      this.dialogLoan = false
    },
    closeEquipment() {
      this.dialogEquipment = false
    },
    closeDrop() {
      this.dialogDrop = false
    }
  }
}
</script>
<style scoped>
.v-cardp {
  transition: none !important;
  opacity: 1 !important;
}

.v-card {
  transition: opacity .4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.8;
}
</style>
