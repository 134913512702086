import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/buildings"

const state = {
  buildings: []
}

const getters = {
  allBuildings: state => state.buildings
}

const actions = {

  async getBuildings({commit}){
    return new Promise(function(resolve, reject){
      commit('setBuildings', [])
      axios.get(URL_).then(res=>{
        commit('setBuildings', res.data)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async saveBuilding({commit}, building){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, building).then(res=>{
        commit('newBuilding', res.data.building)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async updateBuilding({commit}, building){
    return new Promise((resolve, reject)=>{
      axios.put(URL_, building).then(res=>{
        //console.log(res.data)
        commit('updBuilding', res.data.building)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deleteBuilding({commit}, building){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${building.id}`).then(res=>{
        commit('delBuilding', building)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setBuildings : (state, buildings) => state.buildings = buildings,
  newBuilding  : (state, building) => state.buildings.unshift(building),
  updBuilding  : (state, building) => {
    const index = state.buildings.findIndex(u => u.id === building.id)
    if (index !== -1) state.buildings.splice(index, 1, building)
  },
  delBuilding  : (state, building) => state.buildings = state.buildings.filter(c => c.id !== building.id)
}

export default{
  state, getters, actions, mutations
}