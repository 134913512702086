import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/campuses"

const state = {
  campuses: []
}

const getters = {
  allCampuses: state => state.campuses
}

const actions = {

  async getCampuses({commit}){
    return new Promise(function(resolve, reject){
      commit('setCampuses', [])
      axios.get(URL_).then(res=>{
        commit('setCampuses', res.data)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async saveCampus({commit}, campus){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, campus).then(res=>{
        commit('newCampus', res.data.campus)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async updateCampus({commit}, campus){
    return new Promise((resolve, reject)=>{
      axios.put(URL_, campus).then(res=>{
        //console.log(res.data)
        commit('updCampus', res.data.campus)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deleteCampus({commit}, campus){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${campus.id}`).then(res=>{
        commit('delCampus', campus)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setCampuses : (state, campuses) => state.campuses = campuses,
  newCampus  : (state, campus) => state.campuses.unshift(campus),
  updCampus  : (state, campus) => {
    const index = state.campuses.findIndex(u => u.id === campus.id)
    if (index !== -1) state.campuses.splice(index, 1, campus)
  },
  delCampus  : (state, campus) => state.campuses = state.campuses.filter(c => c.id !== campus.id)
}

export default {
  state, getters, actions, mutations
}