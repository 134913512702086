<template>
    <div>
        <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="800px" transition="dialog-transition">
            <v-card>
                <v-toolbar dense color="primary" dark>
                    <v-toolbar-title>Agregar a Bodega</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>

                </v-toolbar>
                <v-card-text>

                    <v-container>
                        <v-col cols="12" md="12">
                            <v-autocomplete v-model="editedItem.warehouse_destination_id" item-value="id" item-text="name"
                                :items="allMyWarehouses" label="* Bodega de destino"
                                placeholder="Seleccione la bodega de destino" :rules="[requiredRule]" required
                                clearable></v-autocomplete>
                        </v-col>

                        <v-data-table :items="editedItem.purchase_details" :headers="headers" dense mt-5>

                        </v-data-table>


                    </v-container>

                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="secondary" @click.stop="close">Cancelar</v-btn>
                    <v-btn color="primary" @click.stop="saveLocation">Guardar</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { can } from '../../../../util/functions'
export default {
    name: 'FormLocateVue',
    props: {
        allInfoArticles: {
            type: Array,
            default: null
        },

        allMyWarehouses: {
            type: Array,
            default: null
        }
    },
    components: {},
    data: () => {
        return {
            requiredRule: [(v) => !!v || 'Este campo es requerido'],
            dialog: false,
            valid: true,
            editedItem: {
                purchase_details: []
            },
            defaultItem: {
                purchase_details: []
            },
            headers: [
                { text: 'Nombre del Artículo', value: 'name' },
                { text: 'Unidades', value: 'units' },
            ]

        }
    },
    methods: {
        ...mapActions(['chgLoad', 'saveLocate']),
        can: can,

        open(item) {


            this.editedItem = {
                'id': item.id,
                'invoice': item.invoice,
                'reference': item.reference,
                'buy_date': item.buy_date,
                'purchase_details': []
            }

            if (item.purchase_details.length > 0) {
                item.purchase_details.forEach(element => {

                    this.editedItem.purchase_details.push({
                        'id': element.id,
                        'article_id': element.article_id,
                        'units': element.units,
                    });
                });
            }

            this.editedItem.purchase_details.forEach(elm => {
                const selectedItem = this.allInfoArticles.find(item => item.id === elm.article_id)
                elm['name'] = selectedItem.name
            })

            this.dialog = true
        },

        close() {
            this.dialog = false
            this.editedItem = this.defaultItem
        },

        async saveLocation() {

            if (!this.editedItem.warehouse_destination_id) {
                this.showError = true;
                this.errorMessage = 'Por favor, seleccione la bodega de destino.';
                this.$root.$alert({ text: this.errorMessage, color: "error", timeout: 3000 });
                return;
            }

            this.$swal({
                title: 'Confirmación',
                text: "Recuerde que al hacer clic en confirmar, no se podrá modificar la ubicación del artículo seleccionado.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar'
            })
                .then(async (res) => {
                    if (res.isConfirmed) {
                        this.chgLoad(true);
                        try {
                            var msg = "";
                            msg = await this.saveLocate(this.editedItem)
                            this.$root.$alert({
                                text: msg.message,
                                color: 'success',
                                timeout: 2000
                            })
                            this.close();
                            this.chgLoad(false);

                        }
                        catch (error) {
                            this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 });
                            this.chgLoad(false);

                        }
                    }
                })



            this.chgLoad(false);
        }
    },

}
</script>

<style lang="scss" scoped></style>
