import Vue from "vue";
import Vuex from "vuex";
import users from "./modules/general/users.js";
import roles from "./modules/general/roles.js";
import permissions from "./modules/general/permissions.js";
import thirds from "./modules/general/thirds.js";
import sectionals from "./modules/general/sectionals.js";
import equipmentTypes from "./modules/loan/equipmentTypes.js";
import equipment from "./modules/loan/equipment.js";
import manufacturers from "./modules/loan/manufacturers.js";
import campuses from "./modules/general/campuses.js";
import cities from "./modules/general/cities.js";
import areas from "./modules/general/areas.js";
import costcenters from "./modules/general/costcenters.js";
import buildings from "./modules/general/buildings.js";
import tiers from "./modules/general/tiers.js";
import locations from "./modules/general/locations.js";
import places from "./modules/loan/places.js";
import loans from "./modules/loan/loans.js";
import equipment_fields from "./modules/loan/equipment_fields.js";
import equipment_drops from "./modules/loan/equipment_transfers.js";
import equipment_transfers from "./modules/loan/equipment_drops.js";
import groups from "./modules/loan/groups.js";
import file_types from "./modules/general/file_types.js";
import inventory_files from "./modules/general/inventory_files.js";
import constants from "./constants.js";
import { getLocalUser } from "../helpers/auth";

//Consumibles
import consumables_brands from "./modules/loan_consumables/brand";
import consumables_suppliers from "./modules/loan_consumables/supplier";
import consumables_shelf from "./modules/loan_consumables/shelf";
import consumables_units_measurement from "./modules/loan_consumables/unitsMeasurement";
import consumable_articles from "./modules/loan_consumables/articles";
import consumable_type_articles from "./modules/loan_consumables/typeArticles";
import consumable_purchases from "./modules/loan_consumables/purchases";
import consumable_warehouses from "./modules/loan_consumables/warehouses.js";
import consumable_transfers from "./modules/loan_consumables/transfer";
import consumable_deliverys from "./modules/loan_consumables/deliverys.js";

//TODO: Activos fijos 
import asset_articles from "./modules/loan_assets/assetArticles.js";
import asset_third from "./modules/loan_assets/assetThird.js";

const user = getLocalUser();

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: user,
    load: false,
    configuration: {},
  },
  getters: {
    currentUser(state) {
      return state.currentUser;
    },
    loading(state) {
      return state.load;
    },
  },
  mutations: {
    loginSuccess(state, payload) {
      var fecexpire = new Date() / 1000 + payload.token.expires_in;
      var token = payload.token;
      token.expire = fecexpire;
      state.currentUser = Object.assign({}, payload.user, { token: token });
      localStorage.setItem("user", JSON.stringify(state.currentUser));
    },
    logout(state) {
      localStorage.removeItem("user");
      state.currentUser = null;
    },
    setLoad: (state, load) => (state.load = load),
  },
  actions: {
    /*login(context){
      context.commit('login');
    }*/
    chgLoad({ commit }, load) {
      commit("setLoad", load);
    },
  },
  modules: {
    users,
    roles,
    sectionals,
    permissions,
    equipmentTypes,
    equipment,
    manufacturers,
    campuses,
    areas,
    buildings,
    tiers,
    cities,
    places,
    costcenters,
    locations,
    loans,
    equipment_drops,
    groups,
    equipment_transfers,
    thirds,
    equipment_fields,
    constants,
    file_types,
    inventory_files,
    consumables_brands,
    consumables_suppliers,
    consumables_shelf,
    consumables_units_measurement,
    consumable_articles,
    consumable_type_articles,
    consumable_purchases,
    consumable_warehouses,
    consumable_transfers,
    consumable_deliverys,
    
    asset_articles,
    asset_third
  },
});
