<template>
    <div>
        <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="1200px" transition="dialog-transition">
            <v-card>
                <v-toolbar dense color="primary" dark>
                    <v-toolbar-title>Referencia de compra</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-card-text>
                    <v-form v-model="valid" ref="formNewReference_">
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="8">
                                    <v-text-field v-model="searchOrder" label="* Orden de compra" :rules="rules.searchOrder"
                                        required @keyup.enter.stop="searchOrderPurchase(searchOrder)">
                                        <template v-slot:append>
                                            <v-icon @click.stop="searchOrderPurchase(searchOrder)">fa-search</v-icon>
                                        </template>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-text-field v-model="editedItem.reference" v-if="editedItem.reference"
                                        label="Número de referencia" disabled @change="editedItem.reference">

                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="8">
                                    <v-text-field v-model="editedItem.supplier_name" v-if="editedItem.supplier_name"
                                        label="Nombre del proveedor" disabled @change="editedItem.supplier_name">

                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" md="4" v-if="editedItem.reference">
                                    <v-menu v-model="menFechIni" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="editedItem.buy_date" label="Fecha de compra"
                                                prepend-icon="fa-calendar-alt" readonly v-on="on" id="buy_date" clearable
                                                placeholder="Seleccione la fecha de compra"
                                                :rules="rules.buy_date"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="editedItem.buy_date" @input="menFechIni = false"
                                            :max="fechaHoy()"></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" md="12" v-if="editedItem.reference">
                                    <v-textarea v-model="editedItem.observations" label="Observaciones de la referencia"
                                        rows="2"></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>

                        <v-container v-if="editedItem.reference">
                            <v-divider></v-divider>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mx-2 my-2" fab dark color="primary" small v-on="on"
                                        @click.stop="$refs.formAddItem_.newAddItem()">
                                        <v-icon>fa-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>Agregar</span>
                            </v-tooltip>

                            <v-tooltip bottom>

                                <template v-slot:activator="{ on }">
                                    <v-btn class="mx-2 my-2" fab dark color="green" small v-on="on"
                                        @click.stop="$refs.formImportItems_.openItem()">
                                        <v-icon>fa-file-excel</v-icon>
                                    </v-btn>
                                </template>
                                <span>Importar Excel</span>
                            </v-tooltip>

                            <v-data-table :headers="headers" :item-key="itemKey" :items="editedItem.options">
                                <template v-slot:item.unit_price="{ item }">
                                    {{ item.unit_price | currency }}
                                </template>

                                <template v-slot:item.total_price="{ item }">
                                    {{ item.total_price | currency }}
                                </template>

                                <template v-slot:item.actions="{ item }">
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-icon small v-on="{ ...tooltip }" class="mr-2" @click="editAddItem(item)"
                                                color="orange">
                                                fa-pen
                                            </v-icon>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                    <v-tooltip right>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-icon small v-on="{ ...tooltip }" class="mr-2" @click="deleteArrayItem(item)"
                                                color="red">
                                                fa-trash
                                            </v-icon>
                                        </template>
                                        <span>Eliminar</span>
                                    </v-tooltip>
                                </template>
                            </v-data-table>
                        </v-container>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="secondary" @click.stop="close">Cancelar</v-btn>
                    <v-btn color="primary" @click.stop="saveReference">Guardar</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
        <FormAddItem :allSuppliers="allSuppliers" :allInfoArticles="allInfoArticles" @saveItem="saveItem"
            ref="formAddItem_" />
        <FormImportItems ref="formImportItems_" :allInfoArticles="allInfoArticles" @addImportItems="addImportItems" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { URL_APP } from '../../../util/constants'
import FormAddItem from './FormAddItem.vue';
import FormImportItems from './FormImportItems.vue';

export default {
    name: "FormConsumableNewReference",
    props: {
        allSuppliers: {
            type: Array,
            default: null
        },
        allInfoArticles: {
            type: Array,
            default: null
        }
    },
    components: { FormAddItem, FormImportItems },

    data: () => {
        return {
            dialog: false,
            openDialogAdd: false,
            valid: true,
            itemKey: 'name',
            editIndex: '',
            menFechIni: false,
            searchOrder: '',
            oldOptions: [],
            editedItem: {
                reference: null,
                options: []
            },
            rules: {
                "searchOrder": [
                    (v) => !!v || "Número de orden requerido"
                ],
                'buy_date': [
                    (v) => !!v || 'La fecha de compra es obligatoria',
                ],
            },
            headers: [
                { text: "Articulo", align: "start", value: "name", sorteable: false, },
                { text: "Unidades", value: "units" },
                { text: "Precio unitario", value: "unit_price" },
                { text: "Precio total", value: "total_price" },
                { text: "Acciones", value: "actions" }
            ]
        };
    },
    methods: {
        ...mapActions(['chgLoad', 'savePurchase', 'updatePurchase']),
        newReference() {
            this.dialog = true;
        },

        fechaHoy() {
            return new Date().toISOString().substr(0, 10)
        },

        async searchOrderPurchase(item) {
            if (this.searchOrder) {
                this.chgLoad(true);
                try {
                    var msg = await this.$http.get(URL_APP + "/api/loan_consumables/search_purchase", {
                        params: { item }
                    });
                    if (msg.data.newReference > 0) {
                        this.editedItem.invoice = msg.data.invoice;
                        this.editedItem.reference = msg.data.newReference;
                        this.editedItem.supplier_name = msg.data.supplier.name;
                        this.editedItem.buy_date = '';
                        this.editedItem.supplier_id = msg.data.supplier_id;
                        this.chgLoad(false);
                    }
                }
                catch (error) {
                    this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 });
                    this.chgLoad(false);
                }
            }
            else {
                this.$root.$alert({ text: "La orden de compra es requerida", color: "error", timeout: 2000 });
                this.chgLoad(false);
            }
        },

        editAddItem(item) {
            this.editIndex = this.editedItem.options.findIndex(e => e.name === item.name)
            const dataSend = Object.assign({}, item)
            this.$refs.formAddItem_.editAddItem(dataSend)
        },

        deleteArrayItem(item) {
            const opt = Object.assign({}, item);
            this.editIndex = this.editedItem.options.findIndex(e => e.name === opt.name);
            if (this.editIndex != -1) {
                this.editedItem.options.splice(this.editIndex, 1);
                this.editIndex = "";
            }

        },

        //AQUI ESTAMOS VALIDANDO CON EL COMPONENTE DEL FORMULARIO
        saveItem(data) {

            const validName = this.editedItem.options.findIndex(e => e.name === data.name);

            if (validName != -1 && data.edit == false) {
                this.$root.$alert({ text: "Ya registró el artículo", color: "error", timeout: 2000 });
            }

            else if ((data.edit && (validName >= 0) && (validName != this.editIndex))) {
                this.$root.$alert({ text: "El articulo que intenta actualizar ya está registrado", color: "error", timeout: 2000 });
            }

            else {
                if (!data.edit) {
                    delete data.edit;
                    this.editedItem.options.push(data);
                    this.$refs.formAddItem_.close()
                }
                else {
                    delete data.edit;
                    this.$set(this.editedItem.options, this.editIndex, data)
                    this.$refs.formAddItem_.close()
                }
            }

        },

        existeNombre(name) {
            return this.editedItem.options.find(item => item.name === name);
        },

        close() {
            this.dialog = false
            this.searchOrder = ''
            this.editedItem = {
                reference: null,
                options: [],
            }
            this.$refs.formNewReference_.reset()
        },

        async saveReference() {
            if (!this.editedItem.options.length > 0) {
                this.$root.$alert({ text: "No ha registrado articulos", color: "error", timeout: 2000 });
            }
            else if (this.$refs.formNewReference_.validate()) {
                this.$swal({
                    title: "Confirmación",
                    text: "¿Está seguro de guardar la información registrada?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, deseo guardar',
                    cancelButtonText: 'Cancelar'
                })
                    .then(async (res) => {
                        if (res.isConfirmed) {
                            this.chgLoad(true);
                            try {
                                var msg = "";
                                msg = await this.savePurchase(this.editedItem)
                                this.$root.$alert({ text: msg.message, color: "success", timeout: 2000 });
                                this.close();
                            }
                            catch (error) {
                                this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 });
                                this.chgLoad(false);
                            }
                            this.chgLoad(false);

                        }
                    })
            }
            else {
                this.$root.$alert({ text: "No se ha diligenciado valores obligatorios", color: "error", timeout: 2000 });
            }
        },

        async addImportItems(item) {
            //    crea un for para item
            const items = JSON.parse(JSON.stringify(item));
            const duplicates = this.findDuplicate(items)
            if (duplicates) {
                this.$root.$alert({ text: "Existen elementos duplicados", color: "error", timeout: 5000 });
            }
            else {
                items.forEach(elm => {
                    this.editedItem.options.push(elm);
                })
                this.$root.$alert({ text: "Los elementos se agregaron correctamente", color: "success", timeout: 5000 });
            }

        },

        findDuplicate(items) {
            var itemsFound = {};

            if (this.editedItem.options.length > 0) {
                const dataRegister = this.editedItem.options.map(item => item.article_id)
                dataRegister.forEach(elm => {
                    itemsFound[elm] = true
                })
            }

            for (let j = 0; j < items.length; j++) {
                const article_id = items[j].article_id;
                // Si el inventario ya está en el objeto auxiliar, entonces hay un duplicado
                if (itemsFound[article_id]) {
                    return true; // Hay duplicados
                }
                else {
                    // Si no, agrega el inventario al objeto auxiliar
                    itemsFound[article_id] = true;
                }
            }

            return false;
        }


    },
    computed: {
        ...mapGetters(["loading"]),
    }
}
</script>

<style lang="scss" scoped></style>