  <template>
    <div>
      <v-data-table
        :headers="headers"
        :items="allCampuses"
        :search="search"
        sort-by="title"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fa-angle-double-left',
          lastIcon: 'fa-angle-double-right',
          prevIcon: 'fa-angle-left',
          nextIcon: 'fa-angle-right'
        }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Campus</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" small fab dark color="green" v-on="on" @click.stop="exportItems()">
                  <v-icon dark>fa-file-excel</v-icon>
                </v-btn>
              </template>
              <span>Exportar a Excel</span>
            </v-tooltip>
            <v-tooltip bottom v-if="can('campus-create')">
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="dialog = true">
                  <v-icon dark>fa-plus</v-icon>
                </v-btn>
              </template>
              <span>Nuevo</span>
            </v-tooltip>
            <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-form v-model="valid" ref="formCampus" @submit.prevent="save">
                    <v-container>
                      <v-row>
                        <v-col cols=12>
                          <v-text-field v-model="editedItem.name" label="* Nombre"
                          :disabled="!can('campus-create')"
                          :rules="rules.name" autofocus></v-text-field>
                        </v-col>
                        <v-col cols=12>
                          <v-text-field v-model="editedItem.address" label="* Direccion"
                          :disabled="!can('campus-create')"
                          :rules="rules.address"></v-text-field>
                        </v-col>
                        <v-col cols=12>
                          <v-autocomplete :disabled="!can('campus-create')"
                            v-model="editedItem.city_id" 
                            item-value="id"
                            item-text="name"
                            :rules="rules.city_id"
                            :items="allCities"
                            label="* Ciudad"
                            placeholder="Seleccione la Ciudad"
                            required
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols=12>
                          <v-switch v-model="editedItem.active" label="Estado" color="primary"
                          :disabled="!can('campus-create')"></v-switch>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
                  <v-btn color="primary darken-1" outlined @click="save" v-if="can('campus-create')">{{ viewMode }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.active="props">
          {{stateItem(props.item.active)}}
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="editItem(item)" v-on="on" class="mr-1 my-1" color="info">fa-pen</v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="deleteItem(item)" v-on="on" class="mr-1 my-1" color="error" v-if="can('campus-delete')">fa-trash</v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn text icon color="green" dark @click="initialize()" v-on="on"><v-icon dark v-on="on">fa-sync-alt</v-icon></v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
</template>
<script>
  import XLSX from 'xlsx';
  import { can, createLinkBinaryFile, changeHeaderJson } from '../../util/functions';
  import { mapActions, mapGetters } from 'vuex'
  export default{
    name: 'Campus',
    data() {
      return {
        dialog: false,
        search: '',
        rules: {
          name: [
            v => !!v || 'Campo requerido',
            v => v.length >= 5 || 'Mínimo 5 caracteres',
            v => v.length < 100 || 'Máximo 100 letras',
          ],
          address: [
            v => !!v || 'Campo Requerido',
            v => v.length < 200 || 'Máximo 200 letras',
          ],
          city_id: [
            v => !!v || 'Campo Requerido',
          ],
        },
        headers: [
            { text: 'Id', value: 'id' },
            { text: 'Nombre', value: 'name' },
            { text: 'Dirección', value: 'address' },
            { text: 'Activo', value: 'active' },
            { text: 'Acciones', value: 'action', sortable: false },
        ],
        editedIndex: -1,
        editedItem: {id: 0, name: '', address: '', city_id: '', active: true},
        defaultItem: {id: 0, name: '', address: '', city_id: '', active: true},
        valid: true
      }
    },

    computed: {
      ...mapGetters(['loading','allCampuses','allCities']),
      formTitle () {
        return !this.editedItem.id ? 'Nuevo Registro' : 'Editar Registro'
      },
      viewMode () {
        return !this.editedItem.id ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    mounted () {
      this.initialize()
    },
    methods: {
      ...mapActions(['getCampuses','deleteCampus','saveCampus', 'updateCampus',
            'chgLoad', 'getCities']),
      can:can,
      async initialize () {
        this.getCities()
        this.chgLoad(true)
        await this.getCampuses()
        this.chgLoad(false)
      },

      stateItem (stateIt) {
        return stateIt ? 'Activo' : 'Inactivo'
      },

      editItem (item) {
        this.editedIndex = this.allCampuses.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      async exportItems() {
        let header = ['id','name','active','created_at','updated_at'];
        let hreplace = ['Id','Nombre','Activo','Creado','Actualizado'];
        var fileName = "areas.xlsx"
        var wb = XLSX.utils.book_new();
        wb.Props = {
          Title: "Campuses",
          Author: this.currentUser.name,
          CreatedDate: new Date()
        };
        var workSheet = XLSX.utils.json_to_sheet(await changeHeaderJson(this.allCampuses, header, hreplace));
        XLSX.utils.book_append_sheet(wb, workSheet, fileName.split('.')[1]);
        var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
        console.log(wbout);
        createLinkBinaryFile(wbout,"Campuses.xlsx");
      },

      deleteItem (item) {
        this.$root.$confirm('Eliminar Campus', 'Esta Seguro?', { color: 'red' })
        .then(async (res) => {
          if(res){
            this.chgLoad(true)
            try{
              var msg = await this.deleteCampus(item)
              this.$root.$alert({text:msg.message,color:"success",timeout:3000})
            }catch(error){
              console.error(error)
              this.$root.$alert({text:"Imposible Eliminar Registro",color:"error",timeout:3000})
            }
            this.chgLoad(false)
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        }, 300)
      },

      async save () {
        if(!this.loading){
          this.$refs.formCampus.validate()
          if (this.valid){
            this.chgLoad(true)
            try{
              var msg = ""
              if (this.editedItem.id){
                msg = await this.updateCampus(this.editedItem)
              }else{
                msg = await this.saveCampus(this.editedItem)
              }
              this.$root.$alert({text:msg.message,color:"success",timeout:2000})
              this.close()
            }catch(error){
              this.$root.$alert({text:"Ha Ocurrido un Error al guardar el Campus",color:"error",timeout:2000})
            }
            this.chgLoad(false)
          }else{
            this.$root.$alert({text:"Por favor revise los errores",color:"error",timeout:2000})
          }
        }
      },
    }
  }
</script>
