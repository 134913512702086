<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Equipos</span>
      </v-card-title>
      <v-card-text>
        <FilterQuery :filters="filters" :fieldFilters="fieldFilters" @freshFilters="initialize2(1)" id="filterQuery">
        </FilterQuery>
        <v-data-table v-model="equipmentSelected" item-key="id" show-select :headers="headers" :items="allEquipment"
          :search="search" :loading="loading" :options.sync="options" :sort-by.sync="options.sortBy"
          :sort-desc.sync="options.sortDesc" :items-per-page.sync="options.per_page"
          :server-items-length="totalEquipment" multi-sort loading-text="Cargando Registros ..." class="elevation-1"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'fa-angle-double-left',
            lastIcon: 'fa-angle-double-right',
            prevIcon: 'fa-angle-left',
            nextIcon: 'fa-angle-right'
          }">
          <template v-slot:top>
            <v-toolbar color="white">
              <v-text-field v-model="search" prepend-icon="fa-search" label="Buscar" clearable single-line hide-details
                @keypress.enter="initialize2(1)" @click:clear="initialize2(1)"></v-text-field>
              <v-spacer></v-spacer>
              <v-tooltip bottom v-if="can('equipment-create')">
                <template v-slot:activator="{ on }">
                  <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on"
                    @click.stop="$refs.formEquipment.newItem()">
                    <v-icon dark>fa-plus</v-icon>
                  </v-btn>
                </template>
                <span>Nuevo</span>
              </v-tooltip>
              <v-menu offset-y>
                <template v-slot:activator="{ on: menu }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn class="mx-2" icon color="primary" v-on="{ ...tooltip, ...menu }" id="newLoan">
                        <v-icon dark>fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>
                    <span>Mas...</span>
                  </v-tooltip>
                </template>
                <v-list dense flat>
                  <v-list-item-group>
                    <v-list-item @click="$refs.importEquipment.open()" v-if="can('equipment-create')">
                      <v-list-item-icon>
                        <v-icon color="light-blue darken-3">fa-file-import</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Importar Equipos</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="exportSelected()">
                      <v-list-item-icon>
                        <v-icon color="green darken-3">fa-check-square</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Exportar Seleccionados</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="exportEquipment()">
                      <v-list-item-icon>
                        <v-icon color="green darken-3">fa-filter</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Exportar Filtro</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="dropItems()" v-if="can('loan.equipment_drop.create')">
                      <v-list-item-icon>
                        <v-icon color="orange lighten-1">fa-times-circle</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Dar de Baja</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="$refs.importDrop.open()" v-if="can('loan.equipment_drop.create')">
                      <v-list-item-icon>
                        <v-icon color="orange lighten-1">fa-file-import</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Dar de Baja desde Plano</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="$refs.formEquipmentTransfer.transferItems(equipmentSelected)"
                      v-if="can('loan.equipment_transfer.create')">
                      <v-list-item-icon>
                        <v-icon color="teal lighten-1">fa-exchange-alt</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Trasladar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="$refs.importTransfer.open()" v-if="can('loan.equipment_transfer.create')">
                      <v-list-item-icon>
                        <v-icon color="teal lighten-1">fa-file-import</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Trasladar desde Plano</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <!-- Se agrega opción para editar desde plano  -->
                    <v-list-item @click="$refs.importEditEquipment.open()" v-if="can('equipment-create')">
                      <v-list-item-icon>
                        <v-icon color="green darken-3">fa-file-signature</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Editar Equipos Masivamente</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-toolbar>
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on: menu }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn class="mx-2" icon color="primary" v-on="{ ...tooltip, ...menu }" id="plusmore">
                      <v-icon dark>fa-ellipsis-v</v-icon>
                    </v-btn>
                  </template>
                  <span>Acciones...</span>
                </v-tooltip>
              </template>
              <v-list dense flat>
                <v-list-item-group>
                  <template v-if="item.state=='Activo'">
                    <v-list-item @click="$refs.formEquipment.editItem(item)">
                      <v-list-item-icon>
                        <v-icon color="info">fa-pen</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="deleteItem(item)" v-if="can('loan.equipment_drop.create')">
                      <v-list-item-icon>
                        <v-icon color="error">fa-trash</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Eliminar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <template v-if="item.type.inventory && can('loan.equipment_drop.create')">
                      <v-list-item @click="dropItem(item)">
                        <v-list-item-icon>
                          <v-icon color="orange lighten-1">fa-times-circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Dar de Baja</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </template>
                  <v-divider></v-divider>
                  <v-subheader>Traslados</v-subheader>
                  <v-list-item @click="$refs.formEquipmentTransfer.transferItems([item])"
                    v-if="can('loan.equipment_transfer.create')">
                    <v-list-item-icon>
                      <v-icon color="teal lighten-1">fa-exchange-alt</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Trasladar</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="$refs.formEquipment.editItem(item,'tabTransfer')">
                    <v-list-item-icon>
                      <v-icon color="teal lighten-1">fa-eye</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Ver Traslados</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:no-data>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn text icon color="green" dark @click="initialize()" v-on="on">
                  <v-icon dark v-on="on">fa-sync-alt</v-icon>
                </v-btn>
              </template>
              <span>Actualizar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <!--Formulario de Baja-->
    <v-dialog v-model="dialogDrop" max-width="1000px" scrollable persistent>
      <v-card>
        <v-toolbar color="primary" dense dark>
          Dar de Baja equipos
          <v-spacer />
          <v-btn icon small>
            <v-icon small @click="close">fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="formDrop" v-model="validDrop">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-simple-table dense class="elevation-1">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center body-2 font-weight-bold" colspan="2">Equipos</th>
                        </tr>
                        <tr>
                          <th class="text-left">Descripción</th>
                          <th class="text-left">Serial</th>
                          <th class="text-left">Inventario</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in itemDrop.equipment" :key="item.id">
                          <td>{{ item.description }}</td>
                          <td>{{ item.serial }}</td>
                          <td>{{ item.inventory }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col cols="12">
                  <v-textarea required label="Observación" v-model="itemDrop.observation" :rules="rules.observation" />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn outlined color="secondary darken-1" @click="close">Cancelar</v-btn>
          <v-btn outlined color="primary darken-1" @click="saveDropItem" v-if="can('loan.equipment_drop.create')">
            Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <FormEquipment ref="formEquipment" />
    <FormEquipmentTransfer ref="formEquipmentTransfer" :isgroup="false" @initialize="initialize" />
    <ImportTransfer ref="importTransfer" @initialize="initialize" />
    <ImportEquipment ref="importEquipment" @initialize="initialize" />
    <ImportEditEquipment ref="importEditEquipment" @initialize="initialize" />
    <ImportDrop ref="importDrop" @initialize="initialize" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { URL_APP } from "../../../util/constants";
import { createLinkFile, can } from '../../../util/functions';
import FilterQuery from '../../general/FilterQuery';
import FormEquipment from './FormEquipment'
import ImportEquipment from './ImportEquipment'
import ImportDrop from './ImportDrop'
import FormEquipmentTransfer from '../transfer/FormEquipmentTransfer';
import ImportTransfer from './ImportTransfer'
// Importanto el edit de equipos 
import ImportEditEquipment from './ImportEditEquipment.vue';
export default {
  name: 'Equipment',
  components: {
    FormEquipment, ImportEquipment, FilterQuery, ImportDrop,
    FormEquipmentTransfer, ImportTransfer, ImportEditEquipment
  },
  data() {
    return {
      dialogDrop: false,
      equipmentSelected: [],
      options: { page: 1, per_page: 10, sortBy: ['description'], sortDesc: [false] },
      search: '',
      rules: {
        observation: [
          v => !!v || 'Campo Requerido',
        ],
      },
      filters: [
        { field: { field: 'state', name: 'Estado', type: 'select', options: ['Activo', 'Baja', 'Prestado'] }, operator: '!=', value_start: 'Baja', value_end: '' }
      ],
      fieldFilters: [
        { field: 'state', name: 'Estado', type: 'select', options: ['Activo', 'Baja', 'Prestado'] },
        { field: 'type.name', name: 'Tipo de Equipo', type: 'text' },
        { field: 'serial', name: 'Serial', type: 'text', },
        { field: 'inventory', name: 'Inventario', type: 'text', },
        { field: 'created_at', name: 'Fecha Creación', type: 'date', },
        { field: 'updated_at', name: 'Fecha Actualización', type: 'date', },
        { field: 'buy_date', name: 'Fecha de Compra', type: 'date' },
        { field: 'third.identification', name: 'Identificación Responsable', type: 'text' },
        { field: 'third.name', name: 'Nombre de Responsable', type: 'text' },
        { field: 'campus.name', name: 'Campus', type: 'text' },
        { field: 'cost_center.name', name: 'Centro de Costos', type: 'text' },
        { field: 'area.name', name: 'Area', type: 'text' },
        { field: 'location.name', name: 'Ubicación', type: 'text' },
        { field: 'tier.name', name: 'Piso', type: 'text' },
        { field: 'building.name', name: 'Edificio', type: 'text' },
      ],
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Descripcion', value: 'description' },
        { text: 'Serial', value: 'serial' },
        { text: 'Inventario', value: 'inventory' },
        { text: 'Tipo', value: 'type.name' },
        { text: 'Estado', value: 'state' },
        { text: 'Acciones', value: 'action', sortable: false },
      ],
      states: ['Activo', 'Baja', 'Nulo', 'Prestado'],
      editedIndex: -1,
      itemDrop: { id: null, observation: '', equipment: [] },
      validDrop: true
    }
  },

  computed: {
    ...mapGetters(['allEquipment', 'loading', 'totalEquipment']),
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro'
    },
    viewMode() {
      return this.editedIndex === -1 ? 'Guardar' : 'Editar'
    },
    currentUser() {
      return this.$store.getters.currentUser
    }
  },
  watch: {
    'options.page'() {
      this.initialize2()
    },
    'options.per_page'() {
      this.initialize2()
    },
    'options.sortBy'() {
      this.initialize2()
    },
    'options.sortDesc'() {
      this.initialize2()
    },
  },

  mounted() {
    this.initialize()
    var this_2 = this
    window.addEventListener('keydown', function (e) {
      if (e.altKey == true && e.code == 'KeyN') {
        this_2.$refs.formEquipment.newItem()
      }
    });
  },

  methods: {
    ...mapActions(['getEquipment', 'deleteEquipment', 'getManufacturers', 'getLocations',
      'getCampuses', 'getEquipmentTypes', 'getBuildings', 'getEquipmentFields',
      'getTiers', 'getAreas', 'getCostCenters', 'chgLoad']),
    can: can,
    async initialize() {
      if (!this.loading) {
        this.chgLoad(true)
        this.getEquipmentTypes()
        this.getEquipmentFields()
        this.getCampuses()
        this.getBuildings()
        this.getTiers()
        this.getLocations()
        this.getAreas()
        this.getCostCenters()
        this.getManufacturers()
        var params = { filters: this.filters, options: this.options, search: this.search }
        try {
          await this.getEquipment(params)
        } catch (error) {
          console.error(error)
          this.$root.$alert({ text: "Error al Obtener los equipos", color: "error", timeout: 2000 })
        }
        this.chgLoad(false)
      }
    },

    async initialize2(page = null) {
      if (!this.loading) {
        if (page != null) this.options.page = page
        this.chgLoad(true)
        var params = { filters: this.filters, options: this.options, search: this.search }
        try {
          await this.getEquipment(params)
        } catch (error) {
          console.error(error)
          this.$root.$alert({ text: "Error al Obtener los equipos", color: "error", timeout: 2000 })
        }
        this.chgLoad(false)
      }
    },

    exportSelected() {
      var equipment_send = []
      this.equipmentSelected.forEach(function (equipment) {
        equipment_send.push(equipment.id)
      })
      this.exportEquipment(equipment_send)
    },

    async exportEquipment(equipment = null) {
      var url = equipment == null ? URL_APP + "/api/loan/export_equipment" : URL_APP + "/api/loan/export_equipment?equipment=" + JSON.stringify(equipment)
      this.chgLoad(true)
      var params = { filters: this.filters, options: this.options, search: this.search }
      await this.$http.get(url, { responseType: 'blob', params }).then(response => {
        createLinkFile(response)
      }).catch(error => {
        console.error(error)
        this.$root.$alert({ text: "Error al Obtener el Excel", color: "error", timeout: 2000 })
      });
      this.chgLoad(false)
    },

    deleteItem(item) {
      this.$root.$confirm('Eliminar Equipo', 'Esta Seguro?', { color: 'red' })
        .then(async (res) => {
          if (res) {
            this.chgLoad(true)
            try {
              var msg = await this.deleteEquipment(item)
              this.$root.$alert({ text: msg, color: "success", timeout: 3000 })
            } catch (error) {
              console.error(error)
              this.$root.$alert({ text: "Imposible Eliminar Registro", color: "error", timeout: 3000 })
            }
            this.chgLoad(false)
          } else {
            this.$root.$alert({ text: "Acción Cancelada", color: "warning", timeout: 1000 })
          }
        })
    },

    dropItem(item) {
      this.editedIndex = this.allEquipment.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.itemDrop.equipment[0] = this.editedItem
      this.dialogDrop = true
    },

    dropItems() {
      this.itemDrop.equipment = this.equipmentSelected.filter(e => (e.type.inventory && e.state == "Activo"))
      if (this.itemDrop.equipment.length > 0) {
        this.dialogDrop = true
      } else {
        this.$root.$alert({ text: "No ha Seleccionado Equipos Válidos", color: "error", timeout: 2000 })
      }
    },

    async saveDropItem() {
      this.$refs.formDrop.validate()
      if (this.validDrop) {
        this.chgLoad(true)
        try {
          var response = await this.$http.post(URL_APP + "/api/loan/equipment_drops", this.itemDrop)
          this.$root.$alert({ text: response.data.message, color: "success", timeout: 2000 })
          this.close()
          await this.initialize2()
        } catch (error) {
          console.error(error)
          this.$root.$alert({ text: "Ocurrió un error al dar de baja el equipo", color: "error", timeout: 2000 })
        }
        this.chgLoad(false)
      }
    },

    close() {
      this.dialogDrop = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

  }
}
</script>
