import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP + "/api/loan_consumables/warehouses"

const state = {
    warehouses: [],
    myWarehouses: [],
    // userWarehouses: [] //Guardar los usuarios que pertenecen a las bodegas
}

const getters = {
    allWarehouses: state => state.warehouses,
    allMyWarehouses: state => state.myWarehouses,

    // userWarehouses: state => state.userWarehouses //usuarios que pertenecen a las bodegas
}

const actions= {
    async getWarehouses({commit}){
        return new Promise(function(resolve, reject){
            commit('setWarehouses', [])
            axios.get(URL_). then(res => {
                commit('setWarehouses', res.data)
                resolve("OK")
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async getMyWarehouses({commit}){
        return new Promise(function(resolve, reject){
            commit('setMyWarehouses', [])
            axios.get(URL_APP + '/api/loan_consumables/getMyWarehouses'). then(res => {
                commit('setMyWarehouses', res.data)
                resolve("OK")
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async saveWarehouse({commit}, warehouse){
        return new Promise ((resolve, reject) => {
            axios.post(URL_, warehouse)
            .then((res) => {
                commit('newWarehouse', res.data.warehouse)
                resolve(res.data)   
            })
            .catch((err) => {
                reject(err)
            })
        })
    },

    async updateWarehouse({commit}, warehouse){
        return new Promise ((resolve, reject) => {
            axios.put(URL_, warehouse)
            .then((res) => {
                commit('updateWarehouse', res.data.warehouse)
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
        })
    },

    async deleteWarehouse({commit}, warehouse){
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_}/${warehouse}`)
            .then((res) => {
                commit('deleteWarehouse', warehouse)
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
        })
    },

}

const mutations = {
    setWarehouses : (state, warehouses) => state.warehouses = warehouses,
    setMyWarehouses : (state, myWarehouses) => state.myWarehouses = myWarehouses,
    newWarehouse : (state, warehouse) => state.warehouses.unshift(warehouse),
    updateWarehouse : (state, warehouse) => {
        const index = state.warehouses.findIndex(u => u.id === warehouse.id)
        if(index !== -1){
            state.warehouses.splice(index, 1, warehouse)
        }
    },
    deleteWarehouse : (state, warehouse) => state.warehouses = state.warehouses.filter(c => c.id !== warehouse.id),

    //Usuario - bodega
    // setUsersWarehouse : (state, userWarehouses) => state.userWarehouses = userWarehouses,
}

export default{
    state, getters, actions, mutations
}