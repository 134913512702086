<template>
  <div>
    <v-dialog v-model="dialog" max-width="1000px" scrollable>
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>
            Selección de Usuarios
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="dialog = false">
                X
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-toolbar>

        <v-card-text style="min-height:600px">
          <v-text-field autofocus
            v-model="search"
            prepend-icon="fa-search"
            label="Buscar"
            clearable
            single-line
            hide-details
          ></v-text-field>
          <v-data-table 
            item-key="id"
            :headers="headers"
            :items="allUsers"
            :search="search"
            :loading="loading"
            multi-sort
            loading-text="Cargando Registros ..."
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'fa-angle-double-left',
              lastIcon: 'fa-angle-double-right',
              prevIcon: 'fa-angle-left',
              nextIcon: 'fa-angle-right'
            }"
          >
          <template v-slot:item="{ item }">
              <tr @click="setUser(item)" style="cursor: pointer">
                <td>{{ item.identity }}</td>
                <td>{{ item.name }}</td>
              </tr>
          </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
 </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  export default{
    name: 'SelectUser',
    data() {
      return {
        dialog: false,
        options: {page:1, per_page:10, sortBy:['name'], sortDesc:[false], itemsPerPage:10},
        search: '',
        filters:[],
        headers: [
          { text: 'Identification', value: 'identity' },
          { text: 'Nombre', value: 'name' },
        ],
      }
    },

    computed: {
      ...mapGetters(['allUsers', 'loading']),
      currentUser(){
        return this.$store.getters.currentUser
      }
    },
    watch: {
      'options.page' () {
        this.initialize()
      },
      'options.per_page' () {
        this.initialize()
      },
      'options.sortBy' () {
        this.initialize()
      },
      'options.sortDesc' () {
        this.initialize()
      },
    },

    mounted() {
      this.initialize()
      var this_2=this
      window.addEventListener('keydown', function(e) {
        if (e.altKey == true && e.code.includes('Enter')){
          this_2.setUser(this_2.allUsers[0])
        }
      });
    },

    methods: {
      ...mapActions(['selectUsers', 'chgLoad']),

      async initialize () {
          //var params = {filters : this.filters, options : this.options, search : this.search}
          try{
            await this.selectUsers()
          }catch(error){ console.error(error)
            this.$root.$alert({text:"Error al Obtener los Usuarios",color:"error",timeout:2000})
          }
      },

      async initialize2 (page=null) {
        if(!this.loading){
          if (page!=null) this.options.page = page
          this.chgLoad(true)
          //var params = {filters : this.filters, options : this.options, search : this.search}
          try{
            await this.selectUsers()
          }catch(error){ console.error(error)
            this.$root.$alert({text:"Error al Obtener los Usuarios",color:"error",timeout:2000})
          }
          this.chgLoad(false)
        }
      },

      setUser(item){
        this.$emit('setUser',item)
        this.dialog = false
      },

      show(){
        this.dialog = true
      },

    }
  }
</script>
