import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/loan/manufacturers"

const state = {
  manufacturers: [],
  lManufacturer: null,
}

const getters = {
  allManufacturers: state => state.manufacturers,
  lastManufacturer: state => state.lManufacturer
}

const actions = {

  async getManufacturers({commit}){
    return new Promise(function(resolve, reject){
      commit('setManufacturers', [])
      axios.get(URL_).then(res=>{
        commit('setManufacturers', res.data)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async saveManufacturer({commit},manufacturer){
    return new Promise((resolve, reject)=>{
      try{
        commit('newManufacturer', manufacturer)
        commit('updLastManufacturer', manufacturer)
        resolve({message:"Añadido con éxito"})
      }catch(err){
        reject(err)
      }
    })
  },

  async updateManufacturer({commit},manufacturer){
    return new Promise((resolve, reject)=>{
      axios.put(URL_, manufacturer).then(res=>{
        //console.log(res.data)
        commit('updManufacturer', res.data.manufacturer)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deleteManufacturer({commit}, manufacturer){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${manufacturer.id}`).then(res=>{
        commit('delManufacturer', manufacturer)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setManufacturers : (state, manufacturers) => state.manufacturers = manufacturers,
  newManufacturer  : (state, manufacturer) => state.manufacturers.unshift(manufacturer),
  updLastManufacturer  : (state, manufacturer) => state.lManufacturer = manufacturer,
  updManufacturer  : (state, manufacturer) => {
    const index = state.manufacturers.findIndex(m => m === manufacturer)
    if (index !== -1) state.manufacturers.splice(index, 1, manufacturer)
  },
  delManufacturer  : (state, manufacturer) => state.manufacturers = state.manufacturers.filter(m => m !== manufacturer)
}

export default{
  state, getters, actions, mutations
}