var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"max-width":"1000px","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary","dense":""}},[_c('v-toolbar-title',[_vm._v(" Selección de Terceros ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mx-2 my-2",attrs:{"text":"","fab":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog = false}}},on),[_vm._v(" X ")])]}}])},[_c('span',[_vm._v("Cerrar")])])],1),_c('v-card-text',{staticStyle:{"min-height":"600px"}},[_c('v-text-field',{attrs:{"autofocus":"","prepend-icon":"fa-search","label":"Buscar","clearable":"","single-line":"","hide-details":""},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.initialize2(1)},"click:clear":function($event){return _vm.initialize2(1)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.allThirds,"search":_vm.search,"loading":_vm.loading,"options":_vm.options,"sort-by":_vm.options.sortBy,"sort-desc":_vm.options.sortDesc,"items-per-page":_vm.options.per_page,"server-items-length":_vm.totalThirds,"multi-sort":"","loading-text":"Cargando Registros ...","footer-props":{
             showFirstLastPage: true,
             firstIcon: 'fa-angle-double-left',
             lastIcon: 'fa-angle-double-right',
             prevIcon: 'fa-angle-left',
             nextIcon: 'fa-angle-right'
           }},on:{"update:options":function($event){_vm.options=$event},"update:sortBy":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.options, "per_page", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.options, "per_page", $event)}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.setThird(item)}}},[_c('td',[_vm._v(_vm._s(item.type_id))]),_c('td',[_vm._v(_vm._s(item.identification))]),_c('td',[_vm._v(_vm._s(item.name))])])]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }