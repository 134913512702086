<template>
  <div>
    <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="600px" transition="dialog-transition">
      <v-card>
        <v-toolbar dense color="primary" dark>
          Importar Equipos
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small icon dark v-bind="attrs" v-on="on" class="mx-1" @click="close()">
                <v-icon dark>fa-times</v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-form ref="formImport" v-model="valid" @submit.prevent="save()">
            <v-row>
              <v-col cols="12">
                <v-file-input accept=".xls,.xlsx" label="Seleccionar Archivo" :rules="rules.fileImport"
                  v-model="formImport.fileImport"></v-file-input>
              </v-col>
              <v-col cols="12">
                <v-switch label="Generar automáticamente número de inventario" v-model="formImport.generate_inventory">
                </v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
          <v-btn color="primary darken-1" outlined @click="save" :loading="loading" :disabled="loading">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { URL_APP } from "../../../util/constants";
import { objectToFormData, createLinkFile } from "../../../util/functions"
export default {
  name: 'ImportEquipment',
  data() {
    return {
      dialog: false,
      rules: {
        fileImport: [
          v => {
            if (v) {
              return true
            } else {
              return 'Campo requerido'
            }
          },
          v => !v || v.size < 10000000 || 'No puede Subir Archivos mayor a 10 MB!'
        ],
      },
      formImport: { fileImport: null, generate_inventory: false },
      valid: true
    }
  },

  computed: {
    ...mapGetters(['loading']),
    currentUser() {
      return this.$store.getters.currentUser
    }
  },

  methods: {
    ...mapActions(['chgLoad']),

    async save() {
      this.$refs.formImport.validate()
      if (!this.loading) {
        if (this.valid) {
          var formData = objectToFormData(this.formImport)
          this.chgLoad(true)
          try {
            var response = await this.$http.post(URL_APP + "/api/loan/import_equipment", formData, { responseType: 'blob' })
            createLinkFile(response, "ErrorsImportEquipment.txt")
            this.$root.$alert({ text: "Importación correcta...", color: "success", timeout: 2000 })
            this.chgLoad(false)
            this.close()
            await this.$emit('initialize')
          } catch (error) {
            this.chgLoad(false)
            console.error(error)
            this.$root.$alert({ text: "Ocurrió un error al importar los equipos", color: "error", timeout: 2000 })
          }
        }
      }
    },

    open() {
      this.dialog = true
    },

    close() {
      this.dialog = false
      this.$refs.formImport.reset()
      setTimeout(() => {
        this.formImport = { fileImport: null, generate_inventory: false }
      }, 300)
    },

  }
}
</script>
