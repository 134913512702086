<template>
    <div>
        <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="600px"
            transition="dialog-transition">
            <v-card>
                <v-toolbar dense color="primary" dark>
                    Editar equipo masivamente
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small icon dark v-bind="attrs" v-on="on" class="mx-1" @click="close()">
                                <v-icon dark>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-card-text>
                    <v-form ref="formEditImport" v-model="valid" @submit.prevent="save()">
                        <v-row>
                            <v-col cols="12">
                                <v-file-input accept=".xls,.xlsx" label="Seleccionar Archivo"
                                    :rules="rules.fileEditImport" v-model="formImport.fileEditImport"></v-file-input>
                            </v-col>

                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
                    <v-btn color="primary darken-1" outlined @click="save" :loading="loading" :disabled="loading">
                        Aceptar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { URL_APP } from "../../../util/constants";
import { objectToFormData, createLinkFile } from "../../../util/functions"

export default {
    name: 'ImportEditEquipment',
    data() {
        return {
            dialog: false,
            valid: true,
            rules: {
                fileEditImport: [
                    v => {
                        if (v) {
                            return true
                        } else {
                            return 'Campo requerido'
                        }
                    },
                    v => !v || v.size < 10000000 || 'No puede Subir Archivos mayor a 10 MB!'
                ],
            },
            formImport: { fileEditImport: null },
        }
    },
    computed: {
        ...mapGetters(['loading']),
        currentUser() {
            return this.$store.getters.currentUser
        }
    },
    methods: {
        ...mapActions(['chgLoad']),
        async save() {
            this.$refs.formEditImport.validate();
            if (!this.loading) {
                if (this.valid) {
                    var formData = objectToFormData(this.formImport)
                    this.chgLoad(true)
                    try {
                        var response = await this.$http.post(URL_APP + "/api/loan/import_edit_equipment", formData, { responseType: 'blob' })
                        createLinkFile(response, "ErrorsImportEditEquipment.txt")
                        this.$root.$alert({ text: "Importación correcta...", color: "success", timeout: 5000 })
                        this.chgLoad(false)
                        this.close()
                        await this.$emit('initialize')
                    }
                    catch (error) {
                        this.chgLoad(false)
                        console.error(error)
                        this.$root.$alert({ text: "Ocurrió un error al importar los equipos", color: "error", timeout: 10000 })
                    }
                }
            }
        },
        open() {
            this.dialog = true
        },

        close() {
            this.dialog = false
            this.formImport.fileEditImport = null;
            this.$refs.formEditImport.reset()
            setTimeout(() => {
                this.formImport = { fileEditImport: null, generate_inventory: false }
            }, 300)
        },
    }
}
</script>