import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/areas"

const state = {
  areas: []
}

const getters = {
  allAreas: state => state.areas
}

const actions = {

  async getAreas({commit}){
    return new Promise(function(resolve, reject){
      commit('setAreas', [])
      axios.get(URL_).then(res=>{
        commit('setAreas', res.data)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async saveArea({commit}, area){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, area).then(res=>{
        commit('newArea', res.data.area)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async updateArea({commit}, area){
    return new Promise((resolve, reject)=>{
      axios.put(URL_, area).then(res=>{
        //console.log(res.data)
        commit('updArea', res.data.area)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deleteArea({commit}, area){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${area.id}`).then(res=>{
        commit('delArea', area)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setAreas : (state, areas) => state.areas = areas,
  newArea  : (state, area) => state.areas.unshift(area),
  updArea  : (state, area) => {
    const index = state.areas.findIndex(u => u.id === area.id)
    if (index !== -1) state.areas.splice(index, 1, area)
  },
  delArea  : (state, area) => state.areas = state.areas.filter(c => c.id !== area.id)
}

export default{
  state, getters, actions, mutations
}