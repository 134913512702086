<template>
    <div>
        <v-dialog v-model="dialog" :overlay="false" max-width="1500px" transition="dialog-transition">
            <v-card>
                <v-toolbar dense color="primary" dark>
                    <v-toolbar-title>Iniciar transferencia</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="closeDialog">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-card-text>
                    <v-form v-model="valid" ref="formStartTransfer_">
                        <v-row class="mt-2">
                            <v-col cols="12" md="6">
                                <v-autocomplete v-model="editedItem.warehouse_origin_id" :items="allMyWarehouses"
                                    item-value="id" item-text="name" id="myWarehouse_id" placeholder="Bodega de Origen"
                                    label="* Bodega de origen" clearable :rules="rules.warehouse_origin_id"
                                    @change="changeOrigin(editedItem.warehouse_origin_id)"></v-autocomplete>

                            </v-col>

                            <v-col cols="12" md="6">
                                <v-autocomplete v-model="editedItem.warehouse_destination_id" :items="allWarehouses"
                                    item-value="id" item-text="name" id="warehouse_id" placeholder="Bodega de destino"
                                    label="* Bodega de destino" :rules="rules.warehouse_destination_id"
                                    clearable></v-autocomplete>
                            </v-col>

                            <v-col cols="12" md="12">
                                <v-textarea name="input-7-1" label="Observaciones" rows="2"
                                    v-model="editedItem.observations"></v-textarea>
                            </v-col>
                        </v-row>

                        <div v-if="editedItem.warehouse_origin_id">
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line
                                hide-details></v-text-field>
                            <v-data-table :headers="headers" :items="infoArticles" hide-default-footer :loading="loading"
                                :search="search" v-model="selected" :single-select="singleSelect" show-select item-key="id">

                                <template v-slot:item.units_complete_send="{ item }">
                                    <v-edit-dialog :return-value.sync="item.units_complete_send" large persistent
                                        @save="save(item)" @cancel="cancel" @open="open" @close="close"
                                        @keyup.enter="save(item)" v-model="item.units_complete_send" save-text="Guardar"
                                        cancel-text="Cancelar">
                                        <div>
                                            {{ item.units_complete_send }}
                                        </div>
                                        <template v-slot:input>
                                            <div class="mt-4 text-h6">
                                                Número de unidades completas
                                            </div>
                                            <v-text-field v-model="item.units_complete_send" label="Ingrese el valor"
                                                single-line autofocus></v-text-field>
                                        </template>
                                    </v-edit-dialog>
                                </template>

                                <template v-slot:item.units_incomplete_send="{ item }">
                                    <v-edit-dialog :return-value.sync="item.units_incomplete_send" large persistent
                                        @save="save(item)" @cancel="cancel" @open="open" @keyup.enter="save(item)"
                                        v-model="item.units_incomplete_send" save-text="Guardar" cancel-text="Cancelar">
                                        <div>
                                            {{ item.units_incomplete_send }}
                                        </div>
                                        <template v-slot:input>
                                            <div class="mt-4 text-h6">
                                                Número de unidades incompletas
                                            </div>
                                            <v-text-field v-model="item.units_incomplete_send" label="Ingrese el valor"
                                                single-line autofocus></v-text-field>
                                        </template>
                                    </v-edit-dialog>
                                </template>

                                <template v-slot:header.units_complete_send="{}">
                                    <span>Digite unidades completas </span>
                                    <v-icon small color="orange">mdi-pencil</v-icon>
                                </template>


                                <template v-slot:header.units_incomplete_send="{}">
                                    <span>Digite unidades incompletas </span>
                                    <v-icon small color="orange">mdi-pencil</v-icon>
                                </template>
                            </v-data-table>
                        </div>

                        <v-snackbar v-model="snack" :timeout="6000" :color="snackColor">
                            {{ snackText }}

                            <template v-slot:action="{ attrs }">
                                <v-btn v-bind="attrs" text @click="snack = false">
                                    Cerrar
                                </v-btn>
                            </template>
                        </v-snackbar>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="secondary" @click.stop="closeDialog">Cancelar</v-btn>
                    <v-btn color="primary" @click.stop="saveForm">Guardar</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

    </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    name: "FormStartMove",
    props: {
        allWarehouses: {
            type: Array,
            default: null
        },
        allMyWarehouses: {
            type: Array,
            default: null
        },
        allArticlesLocation: {
            type: Array,
            default: null
        },
        allInfoArticles: {
            type: Array,
            default: null
        },
    },
    data() {
        return {
            dialog: false,
            snack: false,
            snackColor: '',
            snackText: '',
            valid: true,
            pagination: {},
            editedItem: {
                warehouse_origin_id: '',
                warehouse_destination_id: ''
            },
            defaultItem: {
                warehouse_origin_id: '',
                warehouse_destination_id: ''
            },
            loading: false,
            search: '',
            selected: [],
            infoArticles: [],
            singleSelect: false,
            headers: [{
                text: 'ID',
                align: 'start',
                value: 'id'
            },
            { text: 'Nombre', value: 'name' },
            { text: 'Unidades completas disponibles', value: 'units_full_available', sorteable: false },
            { text: 'Digite unidades completas', value: 'units_complete_send', sorteable: false },
            { text: 'Unidades incompletas disponibles', value: 'units_incomplete_available', sorteable: false },
            { text: 'Digite las unidades incompletas', value: 'units_incomplete_send', sorteable: false },
            ],

            rules: {
                warehouse_origin_id: [
                    (v) => !!v || 'La bodega de origen es requerida',
                ],
                warehouse_destination_id: [
                    (v) => !!v || 'La bodega de destino es requerida',
                ]
            }
        }
    },
    methods: {
        ...mapActions(['chgLoad', 'saveTransfer']),
        save(item) {
            if (item.units_full_available < item.units_complete_send) {
                this.snack = true
                this.snackColor = 'error'
                this.snackText = 'El número de unidades completas a envíar superan el stock'
            }
            else if (item.units_incomplete_available < item.units_incomplete_send) {
                this.snack = true
                this.snackColor = 'error'
                this.snackText = 'El número de unidades incompletas a envíar superan el stock'
            }
            else {

                this.snack = true;
                this.snackColor = 'success';
                this.snackText = 'Información guardada';
            }

        },

        cancel() {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Cancelado'
        },

        open() {
            this.snack = true
            this.snackColor = 'info'
            this.snackText = 'Edición Abierta'
        },
        close() {
            console.log('cerrado')
        },
        openDialog() {
            this.dialog = true
        },

        closeDialog() {
            this.dialog = false
            this.editedItem = this.defaultItem
            this.$refs.formStartTransfer_.reset()
        },

        updateSelected(selected) {
            const selectedItems = this.infoArticles.filter(item => selected.includes(item));
            const nonSelectedItems = this.infoArticles.filter(item => !selected.includes(item));
            this.infoArticles = [...selectedItems, ...nonSelectedItems];
        },

        changeOrigin(item) {
            this.selected = []
            const selectedItem = this.allMyWarehouses.find(elm => elm.id === item);
            this.title = selectedItem ? selectedItem.name : '';

            this.infoArticles = this.allArticlesLocation
                .filter(elm => elm.warehouse_id === item)
                .map(elm => {
                    const selectedItem = this.allInfoArticles.find(item => item.id === elm.article_id);
                    return {
                        ...elm,
                        name: selectedItem.name,
                        units_complete_send: 0,
                        units_incomplete_send: 0,
                        key: elm.id
                    };
                });
        },

        async saveForm() {
            if (this.$refs.formStartTransfer_.validate()) {
                try {
                    this.editedItem.articles = []; //Articulos que vamos a modificar

                    //Validaciones!!!!
                    if (this.editedItem.warehouse_origin_id === this.editedItem.warehouse_destination_id) {
                        throw "Las bodegas deben ser diferentes";
                    }
                    //Hagamos un foreach de selected
                    if (!(this.selected.length > 0)) {
                        throw "No ha seleccionado elementos para enviar";
                    }

                    for (const element of this.selected) {
                        //Validación de cada elemento seleccionado
                        if (isNaN(element.units_complete_send)) {
                            throw `El valor en unidades completas para ${element.name} no es numérico`
                        }

                        if (isNaN(element.units_incomplete_send)) {
                            throw `El valor en unidades incompletas para ${element.name} no es numérico`
                        }

                        if (element.units_full_available < element.units_complete_send) {
                            throw `El artículo ${element.name} no tiene suficientes existencias de unidades completas`
                        }

                        if (element.units_incomplete_available < element.units_incomplete_send) {
                            throw `El artículo ${element.name} no tiene suficientes existencias de unidades incompletas`
                        }

                        const { name, article_id, units_complete_send, units_incomplete_send } = element;

                        this.editedItem.articles.push({
                            article_id: article_id,
                            name: name,
                            units_complete_send: units_complete_send,
                            units_incomplete_send: units_incomplete_send
                        });
                    }

                    this.$swal({
                        title: "Confirmación",
                        text: "¿Está seguro de guardar la información registrada?",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, deseo guardar',
                        cancelButtonText: 'Cancelar'
                    }).then(async (res) => {
                        if (res.isConfirmed) {
                            this.chgLoad(true);
                            try {
                                var msg = "";
                                msg = await this.saveTransfer(this.editedItem);
                                this.$root.$alert({ text: msg.message, color: "success", timeout: 2000 });
                                await this.$emit('initialize3');
                                this.closeDialog();
                            } catch (error) {
                                this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 5000 });
                                this.chgLoad(false);
                            }
                            this.chgLoad(false);
                        }
                    });
                }
                catch (error) {
                    this.chgLoad(false);
                    this.$root.$alert({ text: error, color: "error", timeout: 5000 });
                }

            }
            else {
                this.$root.$alert({ text: "No se ha diligenciado valores obligatorios", color: "error", timeout: 2000 });
            }


        }



    },

    watch: {
        // Vigilar los cambios en el array 'selected' y actualizar el orden en 'Articles'
        selected: {
            handler: 'updateSelected',
            deep: true,
        },
    },
};
</script>

<style lang="scss" scoped></style>