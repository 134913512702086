<template>
    <div>
        <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="800px"
            transition="dialog-transition">
            <v-card>
                <v-toolbar dense color="primary" dark>
                    <v-toolbar-title>{{ titleForOption }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="closeItem">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>

                <v-card-text>
                    <v-form ref="formAttachment_" @submit.prevent="save()">
                        <v-row>
                            <v-col cols="12">
                                <v-file-input accept=".xls,.xlsx" label="Seleccionar Archivo" :rules="rules.fileImport"
                                    v-model="formImport.fileImport"></v-file-input>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="secondary" @click.stop="closeItem">Cancelar</v-btn>
                    <v-btn color="primary" @click.stop="saveItem">Enviar</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { createLinkFile, objectToFormData } from '../../../util/functions';
import { URL_APP } from '../../../util/constants';

export default {
    name: "FormImport",
    data() {
        return {
            dialog: false,
            optionSelected: 0,
            action: '',
            formImport: {
                fileImport: null,
            },
            rules: {
                fileImport: [
                    (v) => !!v || 'El archivo es requerido',
                    v => (v && v.size < 10000000) || 'No puede Subir Archivos mayor a 10 MB!'
                ]
            }
        }
    },

    methods: {
        ...mapActions(['chgLoad']),

        openItem(option) {
            this.dialog = true
            this.optionSelected = option
        },

        closeItem() {
            this.optionSelected = 0
            this.dialog = false
            this.$refs.formAttachment_.reset()
            this.fileImport = null
        },

        async saveItem() {
            if (this.$refs.formAttachment_.validate()) {
                var formData = objectToFormData(this.formImport)
                formData.append("optionSelected", this.optionSelected);
                this.chgLoad(true);
                try {
                    var response = await this.$http.post(URL_APP + '/api/loan_assets/import_assets', formData, {
                        responseType: 'blob'
                    })
                    if (response.data.size == 0) {
                        this.$root.$alert({ text: "Importación realizada correctamente", color: "success", timeout: 3000 })
                        this.chgLoad(false)
                        this.closeItem()
                        await this.$emit('initialize');
                    }
                    else {
                        createLinkFile(response, "ErrorsImportAssets.txt");
                        this.$root.$alert({ text: "Se ha presentado errores, revise el archivo.", color: "orange", timeout: 3000 })
                        this.chgLoad(false)
                        this.closeItem()
                        await this.$emit('initialize');
                    }
                }
                catch (error) {
                    this.chgLoad(false)
                    this.$root.$alert({ text: "No se realizaron importaciones.", color: "error", timeout: 3000 })
                }
            }

        },
    },

    computed: {
        ...mapGetters((['loading'])),
        currentUser() {
            return this.$store.getters.currentUser
        },
        titleForOption() {
            const titles = {
                1: 'Importar artículos',
                2: 'Edición de artículos masivamente',
                3: 'Traslado masivo',
                // 4: 'Baja masiva',
            };

            return titles[this.optionSelected] || null;
        },
    },

}
</script>

<style lang="scss" scoped></style>