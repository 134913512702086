<template>
    <div>
        <v-form v-model="valid" ref="formTransfer_" @submit.prevent="saveItem">
            <v-row mt-2 my-2>
                <div class="row mx-0 py-0" cols="12">
                    <v-col md="4">
                        <v-checkbox v-model="ex4" label="Ubicación" color="primary" value="locations"
                            :disabled="ex4 !== null && ex4 !== 'locations'" class="my-0 py-0"></v-checkbox>
                    </v-col>
                    <v-col md="4">
                        <v-checkbox v-model="ex4" label="Resposable" color="primary" value="responsible"
                            :disabled="ex4 !== null && ex4 !== 'responsible'" class="my-0 py-0"></v-checkbox>
                    </v-col>
                    <v-col md="4">
                        <v-checkbox v-model="ex4" label="Ambos" color="primary" value="all"
                            :disabled="ex4 !== null && ex4 !== 'all'" class="my-0 py-0"></v-checkbox>
                    </v-col>
                </div>

                <v-container class="my-0 py-0" row v-if="ex4 == 'locations' || ex4 == 'all'">
                    <!-- <div v-if="ex4 == 'locations' || ex4 == 'all'">

                </div> -->
                    <!--? Campus  -->
                    <v-col cols=12 md="6" class="my-0 py-0">
                        <v-autocomplete v-model="tempEdited.campus_id" item-value="id" item-text="name"
                            :items="allCampuses" id="searchFormType" label="* Campus" placeholder="Seleccione el Campus"
                            required clearable :rules="rules.generalRequired"></v-autocomplete>
                    </v-col>

                    <!--? Edificio  -->
                    <v-col cols=12 md="6" class="my-0 py-0">
                        <v-autocomplete v-model="tempEdited.building_id" item-value="id" item-text="name"
                            :items="allBuildings" id="searchFormType" label="* Edificio"
                            placeholder="Seleccione el Edificio" required clearable
                            :rules="rules.generalRequired"></v-autocomplete>
                    </v-col>

                    <!--? Piso  -->
                    <v-col cols=12 md="6" class="my-0 py-0">
                        <v-autocomplete v-model="tempEdited.tier_id" item-value="id" item-text="name" :items="allTiers"
                            id="searchFormType" label="* Piso" placeholder="Seleccione el piso" required clearable
                            :rules="rules.generalRequired"></v-autocomplete>
                    </v-col>

                    <!--? Area  -->
                    <v-col cols=12 md="6" class="my-0 py-0">
                        <v-autocomplete v-model="tempEdited.area_id" item-value="id" item-text="name" :items="allAreas"
                            id="searchFormType" label="* Area" placeholder="Seleccione el área" required clearable
                            :rules="rules.generalRequired"></v-autocomplete>
                    </v-col>

                    <!--? Código del centro de costos  -->
                    <v-col cols=12 md="6" class="my-0 py-0">
                        <v-text-field v-model="tempEdited.cost_center.cost_center_code"
                            label="* Código centro de costos" readonly :rules="rules.generalRequired">
                            <template v-slot:append v-if="!disabled">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="green darken-3"
                                            @click="$refs.selectCostCenter_.show()">
                                            fa-city
                                        </v-icon>
                                    </template>
                                    Seleccionar Centro de Costros
                                </v-tooltip>

                                <v-tooltip bottom v-if="tempEdited.cost_center.id && !disabled">
                                    <template v-slot:activator="{ on }" pr-2>
                                        <v-icon v-on="on" color="orange darken-3" @click="clearCostCenter()">
                                            fa-times-circle
                                        </v-icon>
                                    </template>
                                    Limpiar datos
                                </v-tooltip>
                            </template>
                        </v-text-field>
                    </v-col>

                    <!--? Nombre del centro de costos  -->
                    <v-col cols=12 md="6" class="my-0 py-0">
                        <v-text-field v-model="tempEdited.cost_center.name" label="Nombre del centro de costos" readonly
                            :rules="rules.generalRequired"></v-text-field>
                    </v-col>
                    <!-- </v-col> -->

                </v-container>

                <!-- <div v-if="ex4 == 'responsible' || ex4 == 'all'"> -->

                <v-container row cols="12" class="my-0 py-0" v-if="ex4 == 'responsible' || ex4 == 'all'">
                    <!--? Identificación del responsable  -->
                    <v-col cols=12 md="6" class="my-0 py-0">
                        <v-text-field v-model="tempEdited.third.identification"
                            label="* Número de documento del responsable" readonly :rules="rules.generalRequired">
                            <template v-slot:append v-if="!disabled">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="green darken-3" @click="$refs.selectThird_.show()">
                                            fa-user-circle
                                        </v-icon>
                                    </template>
                                    Seleccionar Responsable
                                </v-tooltip>

                                <v-tooltip bottom v-if="tempEdited.third.id && !disabled">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="orange darken-3" @click="clearThird()" ml-2>
                                            fa-times-circle
                                        </v-icon>
                                    </template>
                                    Limpiar datos
                                </v-tooltip>

                            </template>
                        </v-text-field>
                    </v-col>

                    <!--? Nombre del responsable  -->
                    <v-col cols=12 md="6" class="my-0 py-0">
                        <v-text-field v-model="tempEdited.third.name" label="Nombre del responsable" readonly
                            :rules="rules.generalRequired"></v-text-field>
                    </v-col>

                </v-container>

            </v-row>
        </v-form>


        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" @click="close">Cancelar</v-btn>
            <v-btn color="primary" @click="saveItem" :disabled="!ex4">Guardar</v-btn>

        </v-card-actions>

        <SelectAssetThird ref="selectThird_" @setThird="setThird" />
        <SelectCostCenter ref="selectCostCenter_" @setCostCenter="setCostCenter" />

    </div>
</template>

<script>
// import SelectThird from '../../../general/Third/SelectThird'
import SelectAssetThird from '../../thirds/SelectAssetThird';
import SelectCostCenter from '../../../general/CostCenter/SelectCostCenter.vue';
export default {
    name: "FormTransfer",
    components: { SelectAssetThird, SelectCostCenter },
    props: {
        editedItem: {
            type: Object,
            required: true
        },
        options: {
            type: Number, // Se espera recibir 2
            required: true
        },
        allCampuses: {
            type: Array,
            required: true
        },
        allBuildings: {
            type: Array,
            required: true
        },
        allAreas: {
            type: Array,
            required: true
        },
        allTiers: {
            type: Array,
            required: true
        }
    },
    data: () => {
        return {
            ex4: null,
            valid: true,
            tempEdited: {
                third: {},
                cost_center: {
                    id: '',
                    description: '',
                    cost_center_code: '',
                }
            },
            disabled: false,
            rules: {
                generalRequired: [
                    (v) => !!v || 'El campo es requerido',
                ]
            }
        }
    },
    watch: {
        ex4(newValue) {
            if (newValue !== null) {
                this.$nextTick(() => {
                    this.ex4 = newValue;
                });
            }
        },
        'tempEdited.cost_center': {
            handler: function (newVal) {
                if (newVal && newVal.id) {
                    this.tempEdited.cost_center_id = newVal.id;
                } else {
                    this.tempEdited.cost_center_id = null;
                }
            },
            deep: true
        },
        'tempEdited.third': {
            handler: function (newVal) {
                if (newVal && newVal.id) {
                    this.tempEdited.third_id = newVal.id;
                } else {
                    this.tempEdited.third_id = null;
                }
            },
            deep: true
        }
    }
    ,
    methods: {
        setThird(third) {
            this.tempEdited.third = third
        },

        clearThird() {
            this.tempEdited.third = {}
        },
        setCostCenter(cost_center) {
            this.tempEdited.cost_center = cost_center
        },

        clearCostCenter() {
            this.tempEdited.cost_center = {}
        },
        saveItem() {
            //Agregar el valo del prop options en el objeto tempEdited
            if (this.$refs.formTransfer_.validate()) {
                this.tempEdited.options = this.options;
                this.tempEdited.actionTransfer = this.ex4
                this.$emit('updateItem', this.tempEdited);
            }
            else {
                this.$root.$alert({ text: "Revise los errores", color: "error", timeout: 2000 });
            }
        },
        close() {
            this.$emit('closeItem');
            this.tempEdited = {
                third: {},
                cost_center: {}
            }
        }
    },
    mounted() {
        // Asigna el valor del prop editedItem al objeto tempEdited
        Object.assign(this.tempEdited, this.editedItem);
        if (!this.editedItem.cost_center) {
            this.tempEdited.cost_center = {};
        }
        if (!this.editedItem.third) {
            this.tempEdited.third = {};
        }
    }

}
</script>

<style lang="scss" scoped></style>