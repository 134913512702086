<template>
  <v-container fluid fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12 pa-3">
          <v-card-text class="mb-0 pb-0">
            <div class="layout column align-center">
              <img class="primary" src="../../assets/santoto.png" alt="USTAB" width="90%">
              <h1 class="flex my-4 primary--text">Inicio de Sesión</h1>
            </div>
            <v-form v-model="formHasErrors" ref="login">
              <v-row align="center">
                <v-col cols="12" align="center">
                  <v-text-field append-icon="fa-user-alt" @keyup.enter="authenticate" name="login"
                    label="Usuario / Email" type="text" v-model="form.email" :rules="rules.emailRules" required
                    autofocus></v-text-field>
                </v-col>
                <v-col cols="12" align="center">
                  <v-text-field append-icon="fa-lock" @keyup.enter="authenticate" name="password" label="Contraseña"
                    id="password" type="password" :rules="[rules.min]" required v-model="form.password"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" align="center" class="my-0 py-0" v-if="this.$route.name!='logstudent'">
                  <v-switch v-model="form.rememberme" label="Recordarme"></v-switch>
                </v-col>
                <v-col cols="12" sm="6" align="center" class="my-0 py-0" v-if="this.$route.name!='logstudent'">
                  <p class="text-center">
                    <router-link to="/recover" class="text-align-right">
                      ¿Olvidaste tu Contraseña?
                    </router-link>
                  </p>
                </v-col>
                <v-col cols="12" sm="3" align="center" class="my-0 py-0" v-if="this.$route.name!='logstudent'">
                  Inicie Sesión :
                </v-col>
                <v-col cols="12" sm="9" align="center" class="my-0 py-0">
                  <v-radio-group v-model="form.type" row>
                    <v-radio label="SAC" value="sac"></v-radio>
                    <v-radio label="Local" value="adm"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="mt-0 pt-0">
            <v-row>
              <v-col cols="12" class="my-2">
                <v-btn block color="primary" @click="authenticate" :loading="loading" :disabled="loading">Iniciar Sesión
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { login } from '../../helpers/auth.js';
import { mapGetters, mapActions } from 'vuex'
//import AuthService from '@/js/helpers/auth.service';
//import GraphService from '@/js/helpers/graph.service';
export default {
  name: 'login',
  data() {
    return {
      form: {
        email: '',
        password: '',
        remember: '',
        type: 'adm',
        system: 'loan',
        version: 1.1
      },
      error: null,
      rules: {
        min: v => v.length >= 4 || 'Mínimo 4 caracteres',
        emailRules: [
          v => !!v || 'Usuario Requerido',
          //v => /.+@.+\..+/.test(v) || 'Correo Invalido',
        ],
      },
      formHasErrors: false,
      msg: 'Welcome to Your Vue.js + MSAL.js App',
      user: null,
      userInfo: null,
      apiCallFailed: false,
      loginFailed: false
    }
  },
  props: {
    source: String
  },
  computed: {
    ...mapGetters(['loading']),
    message() {
      return this.$route.name == "logstudent" ? "Apreciado Estudiante, Por favor suministre los datos de ingreso que corresponden al SAC" : "Apreciado usuario, Por favor suministre los datos de ingreso";
    },
  },
  created() {
    /*this.authService = new AuthService();
    this.graphService = new GraphService();*/
  },
  methods: {
    ...mapActions(['chgLoad']),
    authenticate() {
      if (!this.loading) {
        this.$refs.login.validate()
        if (this.formHasErrors) {
          this.chgLoad(true)
          //this.$store.dispatch('login');
          login(this.$data.form)
            .then((res) => {
              if (res) {
                //La Respuesta es Correcta
                this.chgLoad(false)
                this.$root.$alert({ text: "Ingreso Correcto", color: "success", timeout: 1000 })
                this.$store.commit('loginSuccess', res);
                this.$router.push({ path: '/loans' });
                //Crear promesa para que extienda la sesión
                //window.getApp.createTimeToken();
              } else {
                this.$root.$alert({ text: "Ha ocurrido un Error, por favor intentelo nuevamente", color: "error", timeout: 3000 })
                this.chgLoad(false)
              }
            })
            .catch((error) => {
              this.chgLoad(false)
              //this.$store.commit('loginFailed',{error});
              this.$root.$alert({ text: error, color: "error", timeout: 3000 })
            });
        } else {
          this.$root.$alert({ text: 'Por favor revise los Errores', color: "error", timeout: 3000 })
        }
      }
    },

    /*callAPI() {
      this.apiCallFailed = false;
      this.authService.getToken().then(
        token => {
          this.graphService.getUserInfo(token).then(
            data => {
              this.userInfo = data;
            },
            error => {
              console.error(error);
              this.apiCallFailed = true;
            }
          );
        },
        error => {
          console.error(error);
          this.apiCallFailed = true;
        }
      );
    },
    signout() {
      this.authService.logout();
    },
    signin() {
      this.loginFailed = false;
      this.authService.login().then(
        user => {
          if (user) {
            this.user = user;
          } else {
            this.loginFailed = true;
          }
        },
        () => {
          this.loginFailed = true;
        }
      );
    }*/
  }
}
</script>
<style scoped lang="css">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
</style>
