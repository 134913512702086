<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="headline">Unidades de Medida</span>
            </v-card-title>
            <v-card-text>
                <v-data-table :headers="headers" :items="allUnitsMeasurements" :search="search">
                    <template v-slot:top>
                        <v-toolbar color="white">
                            <v-text-field v-model="search" prepend-icon="fa-search" label="Buscar..." clearable single-line
                                hide-details></v-text-field>
                            <v-spacer></v-spacer>

                            <v-tooltip bottom v-if="can('consumables.settings-save')">
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn v-on="{ ...tooltip }" small color="primary" class="mx-2 my-2" fab dark
                                        @click.stop="$event => $refs.formUnitsMeasurement_.newItem()">

                                        <v-icon>fa-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>Nuevo</span>
                            </v-tooltip>

                            <v-menu offset-y>
                                <template v-slot:activator="{ on: menu }">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn class="mx-2" icon color="primary" v-on="{ ...tooltip, ...menu }"
                                                id="newLoan">
                                                <v-icon dark>fa-ellipsis-v</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Mas...</span>
                                    </v-tooltip>

                                </template>
                                <v-list dense flat>
                                    <template v-slot:activator="{ on: menu }">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on: tooltip }">
                                                <v-btn class="mx-2" icon color="primary" v-on="{ ...tooltip, ...menu }"
                                                    id="newLoan">
                                                    <v-icon dark>fa-ellipsis-v</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Mas...</span>
                                        </v-tooltip>
                                    </template>
                                    <v-list-item-group>
                                        <v-list-item @click="$refs.importUnitsMeasurement_.open()"
                                            v-if="can('consumables.settings-save')">
                                            <v-list-item-icon>
                                                <v-icon color="light-blue darken-3">fa-file-upload</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Importar Unidades</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item @click="exportUnitsMeasurement()" v-if="can('consumables.settings-list')">
                                            <v-list-item-icon>
                                                <v-icon color="green darken-3">fa-file-download</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Exportar Unidades</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                    </v-list-item-group>
                                </v-list>
                            </v-menu>

                        </v-toolbar>
                    </template>

                    <!-- Boton de acciones  -->

                    <template v-slot:item.actions="{ item }">

                        <v-tooltip left v-if="can('consumables.settings-save') && item.active">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2"
                                    @click="$event => $refs.formUnitsMeasurement_.editItem(item)" color="orange">
                                    fa-pen
                                </v-icon>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>

                        <v-tooltip right v-if="can('consumables.settings-delete') && item.active">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2" @click="deleteItem(item.id)" color="red">
                                    fa-trash
                                </v-icon>
                            </template>
                            <span>Eliminar</span>
                        </v-tooltip>

                        <v-tooltip right  v-if="can('consumables.settings-delete') && !item.active">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2" @click="activateItem(item.id)" color="green">
                                    fa-toggle-on
                                </v-icon>
                            </template>
                            <span>Activar</span>
                        </v-tooltip>
                    </template>
                    <!-- fin de boton de acciones  -->

                </v-data-table>
            </v-card-text>
        </v-card>
        <FormUnitsMeasurement ref="formUnitsMeasurement_" />
        <ImportUnitsMeasurement ref="importUnitsMeasurement_" @initialize="initialize" />
    </div>
</template>

<script>
import FormUnitsMeasurement from './FormUnitsMeasurement.vue';
import ImportUnitsMeasurement from './ImportUnitsMeasurement.vue';
import { mapActions, mapGetters } from 'vuex';
import { can, createLinkFile, } from '../../../util/functions';
import { URL_APP } from '../../../util/constants';

export default {

    name: 'consumables_units_measurement',
    components: {
        FormUnitsMeasurement, ImportUnitsMeasurement
    },
    data: () => {
        return {
            search: '',
            headers: [
                {
                    text: 'ID',
                    align: 'start',
                    value: 'id',
                },
                { text: 'Nombre', value: 'name' },
                { text: 'Símbolo', value: 'symbol' },
                { text: 'Acciones', value: 'actions' },

            ],
        }
    },

    mounted() {
        this.initialize()
    },

    computed: {
        ...mapGetters(['loading', 'allUnitsMeasurements']),
        currentuser() {
            return this.$store.getters.currentuser
        },
    },

    methods: {
        ...mapActions(['chgLoad', 'getUnitsMeasurements', 'deleteUnitsMeasurement']),
        can: can,

        async initialize() {
            this.chgLoad(true);
            await this.getUnitsMeasurements();
            this.chgLoad(false);
        },

        deleteItem(item) {
            this.$swal({
                title: 'Confirmación',
                text: "¿Desea eliminar la unidad de medida?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, deseo eliminar',
                cancelButtonText: 'Cancelar'
            })
                .then(async (res) => {
                    if (res.isConfirmed) {
                        this.chgLoad(true)
                        try {
                            var msg = await this.deleteUnitsMeasurement(item)
                            this.initialize()
                            await this.chgLoad(false)
                            await this.$swal(
                                {
                                    title: 'Confirmación',
                                    text: msg.message,
                                    icon: 'success',
                                    timer: 2000
                                }
                            )
                        }
                        catch (err) {
                            await this.chgLoad(false)
                            await this.$swal(
                                {
                                    title: 'Error!',
                                    text: 'La unidad de medida no se ha eliminado.',
                                    icon: 'error',
                                    timer: 2000
                                }
                            )
                        }
                    }
                })
        },

        activateItem(item) {
            this.$swal({
                title: 'Confirmación',
                text: "¿Desea activar la unidad de medida?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, deseo activar',
                cancelButtonText: 'Cancelar'
            })
                .then(async (res) => {
                    if (res.isConfirmed) {
                        this.chgLoad(true)
                        try {
                            var msg = await this.deleteUnitsMeasurement(item)
                            this.initialize()
                            await this.chgLoad(false)
                            await this.$swal(
                                {
                                    title: 'Confirmación',
                                    text: msg.message,
                                    icon: 'success',
                                    timer: 2000
                                }
                            )
                        }
                        catch (err) {
                            await this.chgLoad(false)
                            await this.$swal(
                                {
                                    title: 'Error!',
                                    text: 'La unidad de medida no se ha activado.',
                                    icon: 'error',
                                    timer: 2000
                                }
                            )
                        }
                    }
                })
        },

        async exportUnitsMeasurement() {
            var url = URL_APP + "/api/loan_consumables/export_units_measurements";
            this.chgLoad(true);
            await this.$http.get(url, { responseType: 'blob' })
                .then((response) => {
                    createLinkFile(response)
                    this.$root.$alert({ text: "Descarga exitosa", color: "success", timeout: 2000 })
                    this.chgLoad(false);
                })
                .catch(() => {
                    this.$root.$alert({ text: "Error al Obtener el Excel", color: "error", timeout: 2000 })
                    this.chgLoad(false);
                })
        },


    }



}
</script>

<style lang="scss" scoped></style>