import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance;
const URL_ = URL_APP + "/api/loan_consumables/articles";

const state = {
    articles: [],
    infoArticles: [],
  articlesT: 0

 }

 const getters = {
    allArticles: state => state.articles,
    totalArticles: state => state.articlesT,
    allInfoArticles: state => state.infoArticles
 }

 const actions = {
    async getArticles({commit}, params){
        return new Promise(function(resolve, reject){
            commit('setArticles', []);
            axios.get(URL_ + '?page=' + params.options.page, {params})
            .then(res => {
                commit('setArticles', res.data.data);
                commit('setArticlesT', res.data.total);
                resolve("OK")
            })
            .catch((err) => {
                reject(err)
            })
        })
    },

    async getAllInfoArticles({commit}){
        return new Promise(function(resolve, reject){
            commit('setAllInfoArticles', []);
            axios.get(URL_APP + '/api/loan_consumables/allInfoArticles')
            .then((res) => {
                commit('setAllInfoArticles', res.data);
                resolve("OK")
            })
            .catch((err) => {
                reject(err)
            })
        })
    },

    async getActiveArticles({commit}){
        return new Promise(function(resolve, reject){
            commit('setActiveArticles', []);
            axios.get(URL_APP + '/api/loan_consumables/getActiveArticles').then(res => {
                commit('setActiveArticles', res.data);
                resolve("OK")
            })
            .catch(err => {
                reject(err);
            })
        })
    },

    async saveArticle({commit}, article){
        return new Promise ((resolve, reject) => {
            axios.post(URL_, article)
            .then((res) => {
                commit('newArticle', res.data.article)
                resolve(res.data)   
            })
            .catch((err) => {
                reject(err)
            })
        })
    },

    async updateArticle({commit}, article){
        return new Promise ((resolve, reject) => {
            axios.put(URL_, article)
            .then((res) => {
                commit('updateArticle', res.data.article)
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
        })
    },

    async deleteArticle({commit}, article){
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_}/${article}`)
            .then((res) => {
                commit('deleteArticle', article)
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
        })
    }

 }

 const mutations = {
    setArticles : (state, articles) => state.articles = articles,
    setArticlesT: (state, total) => state.articlesT = total,
    setAllInfoArticles: (state, articles) => state.infoArticles = articles,
    setActiveArticles: (state, articles) => state.articles = articles,
    newArticle : (state, article) => {
        state.articles.unshift(article)
        state.articlesT = state.articlesT+1
    
    },
    updateArticle : (state, article) => {
        const index = state.articles.findIndex(u => u.id === article.id)
        if(index !== -1){
            state.articles.splice(index, 1, article)
        }
    },
    deleteArticle : (state, article) => state.articles = state.articles.filter(c => c.id !== article.id)

 }

 export default {
    state, getters, actions, mutations
 }