<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="headline">Bodegas</span>
            </v-card-title>
            <v-card-text>
                <v-data-table :headers="headers" :items="allWarehouses" :search="search">
                    <template v-slot:top>
                        <v-toolbar color="white">
                            <v-text-field v-model="search" prepend-icon="fa-search" label="Buscar..." clearable single-line
                                hide-details></v-text-field>
                            <v-spacer></v-spacer>

                            <v-tooltip bottom v-if="can('consumables.admin')">
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn v-on="{ ...tooltip }" small color="primary" class="mx-2 my-2" fab dark
                                        @click.stop="$event => $refs.formWarehouse_.newItem()">

                                        <v-icon>fa-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>Nuevo</span>
                            </v-tooltip>

                            <v-menu offset-y>
                                <template v-slot:activator="{ on: menu }">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn class="mx-2" icon color="primary" v-on="{ ...tooltip, ...menu }"
                                                id="newLoan">
                                                <v-icon dark>fa-ellipsis-v</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Mas...</span>
                                    </v-tooltip>

                                </template>
                                <v-list dense flat>
                                    <template v-slot:activator="{ on: menu }">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on: tooltip }">
                                                <v-btn class="mx-2" icon color="primary" v-on="{ ...tooltip, ...menu }"
                                                    id="newLoan">
                                                    <v-icon dark>fa-ellipsis-v</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Mas...</span>
                                        </v-tooltip>
                                    </template>
                                    <v-list-item-group>
                                        <v-list-item @click="$refs.importWarehouse_.open()"
                                            v-if="can('consumables.admin')">
                                            <v-list-item-icon>
                                                <v-icon color="light-blue darken-3">fa-file-upload</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Importar Bodegas</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item @click="exportWarehouse()" v-if="can('consumables.settings-list')">
                                            <v-list-item-icon>
                                                <v-icon color="green darken-3">fa-file-download</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Exportar Bodegas</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                    </v-list-item-group>
                                </v-list>
                            </v-menu>

                        </v-toolbar>
                    </template>

                    <!-- Boton de acciones  -->

                    <template v-slot:item.actions="{ item }">

                        <v-tooltip left v-if="can('consumables.admin') && item.active">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2"
                                    @click="$event => $refs.formWarehouse_.editItem(item)" color="orange">
                                    fa-pen
                                </v-icon>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>

                        <v-tooltip top v-if="can('consumables.admin') && item.active">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2" @click="deleteItem(item.id)" color="red">
                                    fa-trash
                                </v-icon>
                            </template>
                            <span>Eliminar</span>
                        </v-tooltip>

                        <v-tooltip right v-if="can('consumables.admin') && !item.active">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2" @click="activateItem(item.id)" color="green">
                                    fa-toggle-on
                                </v-icon>
                            </template>
                            <span>Activar</span>
                        </v-tooltip>

                        <!-- <v-tooltip right v-if="can('consumables.admin') && item.active"> -->
                        <v-tooltip left v-if="can('consumables.admin') && item.active">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2" @click="editUser(item)" color="teal">
                                    fa-user-tag
                                </v-icon>
                            </template>
                            <span>Agregar Usuarios</span>
                        </v-tooltip>
                        <!-- fin de boton de acciones  -->
                    </template>



                </v-data-table>
            </v-card-text>
        </v-card>
        <FormWarehouse ref="formWarehouse_" :allCampuses="allCampuses" @initialize="initialize" />
        <ImportWarehouse ref="importWarehouse_" :allCampuses="allCampuses" @initialize="initialize" />
        <FormWarehouseUsers ref="formWarehouseUsers_" :editedItem="editedItem" @close="close" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { URL_APP } from '../../../util/constants';
import { can, createLinkFile } from '../../../util/functions';
import FormWarehouse from './FormWarehouse.vue';
import ImportWarehouse from './ImportWarehouse.vue';
import FormWarehouseUsers from './users/FormWarehouseUsers.vue';


export default {
    name: 'consumables_warehouse',
    components: {
        FormWarehouse, ImportWarehouse, FormWarehouseUsers
    },
    data: () => {
        return {
            search: '',
            editIndex: -1,
            editedItem: { id: 0, name: '', users: [] },
            defaultItem: { id: 0, name: '', users: [] },
            headers: [
                {
                    text: 'ID',
                    align: 'start',
                    value: 'id',
                },
                { text: 'Nombre', value: 'name' },
                { text: 'Campus', value: 'campus.name' },
                { text: 'Acciones', value: 'actions' },

            ],
        }
    },

    mounted() {
        this.initialize()
    },

    computed: {
        ...mapGetters([ 'allWarehouses', 'allCampuses']),
        currentuser() {
            return this.$store.getters.currentuser
        },
    },

    methods: {
        ...mapActions(['getWarehouses', 'getCampuses', 'deleteWarehouse', 'chgLoad']),
        can: can,

        async initialize() {
            this.chgLoad(true);
            await this.getWarehouses();
            this.getCampuses();
            this.chgLoad(false);
        },

        deleteItem(item) {
            this.$swal({
                title: 'Confirmación',
                text: "¿Desea eliminar la bodega?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, deseo eliminar',
                cancelButtonText: 'Cancelar'
            })
                .then(async (res) => {
                    if (res.isConfirmed) {
                        this.chgLoad(true)
                        try {
                            var msg = await this.deleteWarehouse(item)
                            await this.initialize()
                            this.chgLoad(false)
                            await this.$swal(
                                {
                                    title: 'Confirmación',
                                    text: msg.message,
                                    icon: 'success',
                                    timer: 2000
                                }
                            )
                        }
                        catch (err) {
                            this.chgLoad(false)
                            await this.$swal(
                                {
                                    title: 'Error!',
                                    text: 'La bodega no se ha eliminado.',
                                    icon: 'error',
                                    timer: 2000
                                }
                            )
                        }
                    }
                })
        },

        activateItem(item) {
            this.$swal({
                title: 'Confirmación',
                text: "¿Desea activar la bodega?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, deseo activar',
                cancelButtonText: 'Cancelar'
            })
                .then(async (res) => {
                    if (res.isConfirmed) {
                        this.chgLoad(true)
                        try {
                            var msg = await this.deleteWarehouse(item)
                            this.initialize()
                            await this.chgLoad(false)
                            await this.$swal(
                                {
                                    title: 'Confirmación',
                                    text: msg.message,
                                    icon: 'success',
                                    timer: 2000
                                }
                            )
                        }
                        catch (err) {
                            await this.chgLoad(false)
                            await this.$swal(
                                {
                                    title: 'Error!',
                                    text: 'la bodega no se ha activado.',
                                    icon: 'error',
                                    timer: 2000
                                }
                            )
                        }
                    }
                })
        },

        async exportWarehouse() {
            var url = URL_APP + "/api/loan_consumables/export_warehouses";
            this.chgLoad(true);
            await this.$http.get(url, { responseType: 'blob' })
                .then((response) => {
                    createLinkFile(response)
                    this.$root.$alert({ text: "Descarga exitosa", color: "success", timeout: 2000 })
                    this.chgLoad(false);
                })
                .catch(() => {
                    this.$root.$alert({ text: "Error al Obtener el Excel", color: "error", timeout: 2000 })
                    this.chgLoad(false);
                })
        },

        editItemBefore(item) {
            this.editIndex = this.allWarehouses.indexOf(item);
            this.editedItem = Object.assign({}, item)
            this.editedItem.action = 'userAdd'

        },

        editUser(item) {
            this.editItemBefore(item)
            this.$refs.formWarehouseUsers_.open()
            setTimeout(function () { document.getElementById('userSearch').focus(); }, 500);
        },

        close() {
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            }, 300)
        },

    }
}
</script>

<style lang="scss" scoped></style>