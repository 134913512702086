<template>
  <div>
    <v-data-table v-if="editedLoan.id==0"
      :headers="headers"
      :items="editedLoan.equipment"
      item-key="pivot_id"
      sort-by="description"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar color="white">
          Equipos
          <v-spacer></v-spacer>
          <template v-if="editedLoan.id == 0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="newEquipment" id="newEquipment">
                  <v-icon small dark>fa-plus</v-icon>
                </v-btn>
              </template>
              <span>Nuevo</span>
            </v-tooltip>
          </template>
          <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
            <v-card>
              <v-toolbar dark color="primary" dense>
                <v-toolbar-title>
                  {{formTitle}}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                      X
                    </v-btn>
                  </template>
                  <span>Cerrar</span>
                </v-tooltip>
              </v-toolbar>

              <v-card-text>
                <v-text-field
                  v-model="search"
                  append-icon="fa-search"
                  label="Buscar"
                  id="equipment_id"
                  single-line
                  hide-details
                  @click:row="save"
                ></v-text-field>
                <v-data-table v-if="editedLoan.id==0"
                  v-model="selEquipment"
                  :headers="header2"
                  :items="equipment"
                  item-key="pivot_id"
                  :search="search"
                  sort-by="description"
                  show-select
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'fa-angle-double-left',
                    lastIcon: 'fa-angle-double-right',
                    prevIcon: 'fa-angle-left',
                    nextIcon: 'fa-angle-right'
                  }"
                >
                  <template v-slot:item.available="props">
                    {{ props.item.stock - props.item.borrowed }}
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
                <v-btn color="primary darken-1" outlined @click="save">{{ viewMode }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <template v-if="editedLoan.id == 0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small @click="deleteItem(item)" v-on="on" class="mr-1" color="error">fa-trash</v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </template>
      <template v-slot:item.quantity="props">
        <v-edit-dialog v-if="!props.item.serial"
          :return-value.sync="props.item.quantity"
        > {{ props.item.quantity }}
          <template v-slot:input>
            <v-text-field
              v-model="props.item.quantity"
              label="Cantidad"
              single-line
              counter
              type="number"
            ></v-text-field>
          </template>
        </v-edit-dialog>
        <template v-else>{{ props.item.quantity }}</template>
      </template>
    </v-data-table>
    <v-simple-table dense v-else class="elevation-1">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-center body-2 font-weight-bold" colspan="2">Equipos Prestados</th>
        </tr>
        <tr>
          <th class="text-left">Descripción</th>
          <th class="text-left">Serial</th>
          <th class="text-left">Inventario</th>
          <th class="text-left">Cantidad</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in editedLoan.equipment" :key="item.id">
          <td>{{ item.description }}</td>
          <td>{{ item.serial }}</td>
          <td>{{ item.inventory }}</td>
          <td>{{ item.pivot.quantity }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  </div>
</template>
<script>
  import { URL_APP } from "../../util/constants";
  export default{
    name: 'LoanEquipment',
    props: {
      editedLoan: {
        type: Object,
        default: null
      },
    },
    data() {
      return {
        search:'',
        dialog: false,
        rules: {
          id: [
            v => !!v || 'Campo Requerido',
            //v => v < this.fechaHoy() || 'La fecha debe ser mayor a hoy',
          ],
        },
        headers: [
            { text: 'Descripción', value: 'description' },
            { text: 'Grupo', value: 'name' },
            { text: 'Serial', value: 'serial' },
            { text: 'Inventario', value: 'inventory' },
            { text: 'Cant', value: 'quantity' },
            { text: 'Acciones', value: 'action', sortable: false },
        ],
        header2: [
            { text: 'Id', value: 'id' },
            { text: 'Descripción', value: 'description' },
            { text: 'Grupo', value: 'name' },
            { text: 'Serial', value: 'serial' },
            { text: 'Inventario', value: 'inventory' },
            { text: 'Disponible', value: 'available' },
        ],
        selEquipment: [],
        equipment: [],
        editedIndex: -1,
        editedItem: {id: 0, equipment_id: 0, description:'', serial: '', equipment_type_id: 0, type: {}},
        defaultItem: {id: 0, equipment_id: 0, description:'', serial: '', equipment_type_id: 0, type: {}},
        valid: true
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Equipo' : 'Editar Equipo'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      async initialize () {
        if(this.editedLoan.id==0){
          //if(typeof this.$root.$loadApp === 'function') this.$root.$loadApp(true)
          await this.$http.get(URL_APP+"/api/loan/myequipment")
          .then(response => {
            this.equipment = response.data
          }).catch(error => {
            this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
          });
          //this.$root.$loadApp(false)
        }
      },

      newEquipment(){
        this.dialog = true
        this.selected = this.editedLoan.equipment
        setTimeout(function(){ document.getElementById('equipment_id').focus(); }, 500);
      },

      deleteItem (item) {
        this.editedIndex = this.editedLoan.equipment.indexOf(item)
        this.$root.$confirm('Eliminar Equipo', 'Esta Seguro?', { color: 'red' })
        .then((res) => {
          if(res){
            this.editedLoan.equipment.splice(this.editedIndex, 1)
            this.$root.$alert({text:"¡ Equipo Eliminado !",color:"success",timeout:1000})
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          document.getElementById('newEquipment').focus()
        }, 300)
      },

      save () {
        this.editedLoan.equipment = this.selEquipment
        this.close()
      },
    }
  }
</script>
