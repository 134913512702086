<template>
  <v-app id="inspire" class="back">
    <template v-if="!currentUser">
      <app-toolbar :show_drawer="drawer" v-bind:drawer.sync="drawer"></app-toolbar>
      <v-main>
        <router-view></router-view>
      </v-main>
    </template>
    <template v-else>
      <app-toolbar :show_drawer="drawer" v-bind:drawer.sync="drawer"></app-toolbar>
      <app-drawer class="app--drawer" :drawer="drawer" @drawerStatus="drawer = $event" @changePasswd="changePasswd">
      </app-drawer>
      <v-main>
        <v-container fluid>
          <router-view></router-view>
        </v-container>
        <ChangePass :drawer="drawpasswd" @changePasswd="changePasswd"></ChangePass>
      </v-main>
    </template>
    <v-footer inset app>
      <v-spacer />
      <div class="text-center">&copy; Universidad Santo Tomás - {{ new Date().getFullYear() }} || Versión: {{ version }}
      </div>
      <v-spacer />
      <!-- Botón Flotante
      <v-speed-dial open-on-hover top right direction="top"
        v-model="fab" absolute class="mt-n12"
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn elevation="3"
            v-model="fab"
            color="blue darken-2"
            dark
            fab
          >
            <v-icon v-if="fab">
              mdi-close
            </v-icon>
            <v-icon v-else>
              mdi-account-circle
            </v-icon>
          </v-btn>
        </template>
        <v-btn
          fab
          dark
          small
          color="green"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          fab
          dark
          small
          color="indigo"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn
          fab
          dark
          small
          color="red"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-speed-dial>
      -->
    </v-footer>
    <SnackbarAlert ref="alert"></SnackbarAlert>
    <Confirm ref="confirm"></Confirm>
    <LoadApp ref="loadApp"></LoadApp>
    <!--<ExtendSession ref="extSession" ></ExtendSession>-->
    <!-- -->
  </v-app>
</template>

<script>
import AppDrawer from './components/AppDrawer';
import AppToolbar from './components/AppToolbar';
import AppEvents from './util/event';
import ChangePass from './components/ChangePass';
import Confirm from './components/Confirm';
import SnackbarAlert from './components/SnackbarAlert';
import LoadApp from './components/LoadApp';
import { refresh } from './helpers/auth.js';

//probando 
import { version } from '../package.json';

export default {
  name: 'App',
  components: {
    Confirm,
    ChangePass,
    SnackbarAlert,
    LoadApp,
    AppDrawer,
    //ExtendSession,
    AppToolbar //,
  },
  data() {
    return {
      fab: false,
      drawer: false,
      overlay: false,
      drawpasswd: false,
      timeToken: null,
      timeExtend: 0,
      restSeconds: 60,
      version: null,
    }
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open
    this.$root.$alert = this.$refs.alert.open
    this.$root.$loadApp = this.$refs.loadApp.change
    //this.$root.$extSession = this.$refs.extSession.open
    //Revisar si ya expiró el token
    this.inspectToken()
    this.version = version
  },
  created() {
    AppEvents.forEach(evnt => {
      this.$on(evnt.name, evnt.callback);
    });
    window.getApp = this;
  },
  watch: {
    drawer() {
      if (this.currentUser.menus.length == 0 && this.drawer) {
        this.drawer = false
      }
      // console.log(this.currentUser.menus.length)
    },
    $route(to) {
      document.title = ".::" + (to.meta.title + " - " || "") + process.env.VUE_APP_TITLE + "::.";
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser
    }
  },
  methods: {
    changePasswd(value) {
      this.drawpasswd = value;
    },

    createTimeToken() {
      //Calcular cuanto queda de tiempo para mostrar la extención de sesión (en segundos)
      this.timeExtend = Math.round(this.$store.getters.currentUser.token.expire - (new Date() / 1000));
      this.timeExtend = Math.round((this.timeExtend - 70) * 1000) //Pasarlo a Milisegundos
      if (this.timeExtend <= 1) {
        clearTimeout(this.timeToken)
        this.$root.$loadApp("false")
        this.$store.commit('logout');
        this.$router.push({ path: '/' });
      } else {
        this.timeToken = setTimeout(this.refreshToken, this.timeExtend)
      }
    },

    refreshToken() {
      clearTimeout(this.timeToken)
      //Extender Session
      this.restSeconds = Math.round(this.$store.getters.currentUser.token.expire - (new Date() / 1000) - 5); //Segundos - 5 chance
      if (this.restSeconds <= 1) {
        this.$root.$loadApp("false")
        this.$router.push({ path: '/login' });
        this.$store.commit('logout');
        this.$root.$alert({ text: "Sesión Finalizada, Ingrese Nuevamente", color: "error", timeout: 2000 })
      } else {
        this.$root.$extSession(this.restSeconds)
          .then((res) => {
            if (res) {
              //Realiza extensión de Sesión
              refresh()
                .then((res) => {
                  this.$root.$alert({ text: "Se Extiende la Sesión ...", color: "success", timeout: 1000 })
                  this.$store.commit('loginSuccess', res);
                  this.createTimeToken()
                })
                .catch((err) => {
                  console.log(err)
                  this.$router.push({ path: '/login' });
                  this.$store.commit('logout');
                  this.$root.$alert({ text: "No se pudo extender la sesión, Ingrese Nuevamente", color: "error", timeout: 2000 })
                });
            } else {
              //Hacer el Logout
              this.$root.$loadApp("false")
              this.$router.push({ path: '/login' });
              this.$store.commit('logout');
            }
          })
      }
    },

    inspectToken() {
      const user = this.$store.getters.currentUser
      if (user) {
        this.createTimeToken()
      }
    },
  }
};
</script>
<style scoped lang="css">
.back {
  background-image: url("./assets/Login.jpg") !important;
  /* The image used */
  background-color: #cccccc !important;
  /* Used if the image is unavailable */
  background-position: center !important;
  /* Center the image */
  background-repeat: no-repeat !important;
  /* Do not repeat the image */
  background-size: cover !important;
  /* Resize the background image to cover the entire container */
}

#inspire {
  background: none;
}

.my-sweetalert-font {
  font-family: 'Roboto', sans-serif;
  /* Reemplaza 'TuTipoDeLetra' con el tipo de letra deseado */
}

/* This is for documentation purposes and will not be needed in your application */
/*#create .v-speed-dial {
    position: absolute;
    margin:-50rem !important;
  }

  #create .v-btn--floating {
    position: absolute;
    margin:-50rem !important;
  }*/
</style>
