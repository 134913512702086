<template>
    <div>
        <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="800px" transition="dialog-transition">
            <v-card>
                <v-toolbar dense color="primary" dark>
                    <v-toolbar-title>Agregar Articulos</v-toolbar-title>
                </v-toolbar>

                <v-card-text>
                    <v-form ref="formArrayPurchase_" @submit.prevent="saveArray">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-autocomplete v-model="options.article_id" :items="allInfoArticles" item-value="id"
                                    item-text="name" id="article_id" label="* Articulo" placeholder="Seleccione el articulo"
                                    required clearable :rules="rules.article_id"
                                    @change="selectNameArticle()"></v-autocomplete>
                            </v-col>

                            <v-col cols="12" md="6">
                                <v-text-field type="number" v-model="options.units" label="* Número de unidades"
                                    :rules="rules.units" required>

                                </v-text-field>
                            </v-col>

                            <v-col cols="12" md="6">
                                <div>
                                    <vuetify-money v-model="options.unit_price" label="Valor unitario en pesos colombianos"
                                        placeholder="Escriba el valor unitario en pesos colombianos" :readonly="false"
                                        :disabled="false" :outlined="false" clearable valueWhenIsEmpty="0"
                                        :options="configCurrency" :rules="rules.unit_price" />
                                </div>
                            </v-col>
                        </v-row>
                    </v-form>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="secondary" @click.stop="close">Cancelar</v-btn>
                    <v-btn color="primary" @click.stop="save">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'FormAddItem',
    props: {
        allSuppliers: {
            type: Array,
            default: null
        },
        allInfoArticles: {
            type: Array,
            default: null
        }
    },
    data: () => {
        return {
            configCurrency: {
                locale: "es-CO",
                prefix: "$",
                suffix: "",
                length: 15,
                precision: 0
            },
            dialog: false,
            options: {},
            edit: false,
            rules: {

            }
        }
    },
    methods: {
        close() {
            this.dialog = false;
            this.options = {};
            this.edit = false;
        },

        newAddItem() {
            this.dialog = true
        },

        editAddItem(item) {
            this.options = item
            this.edit = true
            this.dialog = true
        },

        selectNameArticle() {
            const SelectedItem = this.allInfoArticles.find(item => item.id === this.options.article_id);
            this.options.name = SelectedItem ? SelectedItem.name : '';
        },

        save() {

            const dataItem = {
                edit: this.edit,
                name: this.options.name,
                unit_price: this.options.unit_price,
                units: this.options.units,
                article_id: this.options.article_id,
                total_price: this.options.unit_price * this.options.units
            }

            const dataItemCopy = Object.assign({}, dataItem);

            this.$emit('saveItem', dataItemCopy);

        }

    },
    computed: {

    }
}
</script>

<style lang="scss" scoped></style>