<template>
    <div>
        <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="800px" transition="dialog-transition">
            <v-card>
                <v-toolbar dense color="primary" dark>
                    <v-toolbar-title>FORMULARIO DE BAJA</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="closeItem">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-card-text>
                    <v-container grid-list-xs>
                        <v-row>
                            <v-col cols="12" md="12" class="my-0 py-0 mt-3">
                                <h3>
                                    Información del artículo
                                </h3>
                            </v-col>
                            <v-col cols="12" md="12" class="my-0 py-0">
                                <span class="font-weight-bold">Descripción: </span>
                                <span class="info--text">{{ editedItem.description }}</span>
                            </v-col>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Inventario Actual: </span>
                                <span class="info--text">{{ editedItem.inventory }}</span>
                            </v-col>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Inventario Anterior: </span>
                                <span class="info--text">{{ editedItem.old_inventory }}</span>
                            </v-col>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Estado: </span>
                                <span class="info--text">{{ editedItem.status.description }}</span>
                            </v-col>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Serial: </span>
                                <span class="info--text">{{ editedItem.serial }}</span>
                            </v-col>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Fecha de compra: </span>
                                <span class="info--text">{{ editedItem.buy_date }}</span>
                            </v-col>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Costo: </span>
                                <span class="info--text">{{ formatCurrency(editedItem.cost) }}</span>
                            </v-col>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Documento del responsable: </span>
                                <span class="info--text">{{ editedItem.third.identification }}</span>
                            </v-col>

                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">NIT del proveedor: </span>
                                <span class="info--text">{{ editedItem.supplier.nit }}</span>
                            </v-col>

                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Nombre del responsable: </span>
                                <span class="info--text">{{ editedItem.third.name }}</span>
                            </v-col>

                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Nombre del Proveedor: </span>
                                <span class="info--text">{{ editedItem.supplier.name }}</span>
                            </v-col>
                           
                            <v-col cols="12" md="12" class="my-0 py-0 mt-3">
                                <h3>
                                    Ubicación
                                </h3>
                            </v-col>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Campus: </span>
                                <span class="info--text">{{ editedItem.campus.name }}</span>
                            </v-col>

                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Edificio: </span>
                                <span class="info--text">{{ editedItem.building.name }}</span>
                            </v-col>

                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Piso: </span>
                                <span class="info--text">{{ editedItem.tier.name }}</span>
                            </v-col>

                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Área: </span>
                                <span class="info--text">{{ editedItem.area.name }}</span>
                            </v-col>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Código del centro de costos: </span>
                                <span class="info--text">{{ editedItem.cost_center.cost_center_code }}</span>
                            </v-col>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Nombre del centro de costos: </span>
                                <span class="info--text">{{ editedItem.cost_center.name }}</span>
                            </v-col>

                            <v-col cols="12" md="12" class="my-0 py-0">
                                <v-textarea label="Observaciones de la baja" name="name" rows="2"></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click.stop="closeItem">Cancelar</v-btn>
                    <v-btn color="primary" @click.stop="saveItem">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "FormDisposalProcess",
    data() {
        return {
            dialog: false,
            editedItem: {
                status: {},
                third: {},
                cost_center: {},
                supplier: {},
                campus: {},
                building: {},
                tier: {},
                area: {},
            },

        }
    },
    methods: {
        openItem(item) {
            this.editedItem = Object.assign({}, item);
            this.dialog = true
        },

        closeItem() {
            this.dialog = false
        },

        formatCurrency(value) {
            // Formatear el valor como costo con 2 decimales y separadores de miles
            const formattedValue = new Intl.NumberFormat('es-CO', {
                style: 'currency',
                currency: 'COP',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(value);

            return formattedValue;
        },

    },
}


</script>

<style lang="scss" scoped></style>