<template>
    <div>
        <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="800px" transition="dialog-transition">
            <v-card>

                <v-toolbar dense color="primary" dark>
                    <v-toolbar-title>Usuarios de {{ this.editedItem.name }}</v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>

                <v-card-text>
                    <v-text-field v-model="search" append-icon="fa-search" label="Buscar" id="userSearch" single-line
                        hide-details @click:row="save"></v-text-field>
                    <v-form v-model="valid" ref="formWarehouseUsers_" @submit.prevent="save">

                        <v-data-table v-model="editedItem.users" item-key="id" :show-select="true" :search="search"
                            :headers="headers" :items="users" :loading="loading">
                        </v-data-table>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary darken-1" @click="close">Cancelar</v-btn>
                    <v-btn color="primary darken-1" @click="save">Aceptar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { URL_APP } from '../../../../util/constants'
import { mapActions } from 'vuex'

export default {
    name: "FormWarehouseUsers",
    props: {
        editedItem: {
            type: Object,
            default: null
        },
    },
    data: () => {
        return {
            selected: [],
            valid: true,
            dialog: false,
            loading: false,
            search: '',
            users: [],

            headers: [
                { text: 'Identidad', value: 'identity' },
                { text: 'Nombre', value: 'name' },
            ],

        }
    },
    methods: {
        // ...mapActions(['getUsersWarehouse']),
        ...mapActions(['chgLoad', 'saveWarehouse', 'updateWarehouse']),
        open() {
            this.dialog = true
        },

        close() {
            this.dialog = false
            this.$emit('close')
        },

        async save() {

            this.chgLoad(true);
            try {
                var msg = "";
                msg = await this.updateWarehouse(this.editedItem)
                // await this.$emit('initialize');
                await this.$root.$alert({ text: msg.message, color: "success", timeout: 2000 });
                await this.close();
            }
            catch (error) {
                this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 });
            }
            this.chgLoad(false);

        },

        initialize() {
            this.loading = true
            try {
                // this.getUsersWarehouse()
                this.$http.get(URL_APP + "/api/users/selectusers")
                    .then(response => {
                        this.users = response.data
                        this.loading = false

                    }).catch(error => {
                        this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 })
                        this.loading = false

                    });
            }
            catch (error) {
                console.log(error)
            }

        }
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo Equipo' : 'Editar Equipo'
        },
        viewMode() {
            return this.editedIndex === -1 ? 'Guardar' : 'Editar'
        },
        currentUser() {
            return this.$store.getters.currentUser
        }
    },

    mounted() {
        this.initialize()
    }
}
</script>

<style lang="scss" scoped></style>