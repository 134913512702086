<template>
    <div>
        <v-card>
            <v-card-title class="primary-title">
                <span class="headline">Consultar artículos de orden de compra</span>
            </v-card-title>
            <v-card-text>
                <v-data-table :headers="headers" :items="itemsPurchase">
                    <!-- Columnas personalizadas -->
                    <template v-slot:item.unit_price="{ item }">
                        {{ item.unit_price | currency }}
                    </template>
                    <template v-slot:item.total_price="{ item }">
                        {{ item.total_price | currency }}
                    </template>
                    <!-- Barra de búsqueda -->
                    <template v-slot:top>
                        <v-toolbar class="white">
                            <v-text-field v-model="search" prepend-icon="fa-search" label="Buscar..." clearable single-line
                                hide-details @keypress.enter="searchPurchase(search)">
                            </v-text-field>
                        </v-toolbar>
                        <!-- Detalles de la orden de compra -->
                        <v-card-text v-if="info.invoice">
                            <div>
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <v-card shaped outlined>
                                            <v-card-title primary-title>
                                                Número de orden de compra
                                            </v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-subtitle>
                                                {{ info.invoice }}
                                            </v-card-subtitle>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="12" md="4">
                                        <v-card shaped outlined>
                                            <v-card-title primary-title>
                                                Proveedor
                                            </v-card-title>
                                            <v-divider></v-divider>

                                            <v-card-subtitle>
                                                {{ info.supplier }}
                                            </v-card-subtitle>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="12" md="4">
                                        <v-card shaped outlined>
                                            <v-card-title primary-title>
                                                Costo total de la compra
                                            </v-card-title>
                                            <v-divider></v-divider>

                                            <v-card-subtitle>
                                                {{ info.full_purchase_value | currency }}
                                            </v-card-subtitle>
                                        </v-card>
                                    </v-col>

                                </v-row>
                            </div>
                        </v-card-text>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { URL_APP } from '../../../util/constants';

export default {
    name: 'ConsumablesConsultPurchase',
    data: () => {
        return {
            search: '',
            info: [],
            itemsPurchase: [],
            headers: [
                {
                    text: 'Artículo / Consumible',
                    align: 'start',
                    value: 'name',
                    sortable: false
                },
                { text: 'Referencia', value: 'reference' },
                { text: 'Unidades', value: 'units' },
                { text: 'Valor Unitario', value: 'unit_price', sortable: false },
                { text: 'Valor Total', value: 'total_price', sortable: false },
            ],

        }
    },
    methods: {
        ...mapActions(['chgLoad']),
        async searchPurchase(item) {
            if (this.search) {
                this.chgLoad(true);
                try {
                    var msg = await this.$http.get(URL_APP + '/api/loan_consumables/consult_purchase', {
                        params: { item }
                    })
                    this.itemsPurchase = msg.data.elements
                    this.info.invoice = msg.data.invoice
                    this.info.supplier = msg.data.supplier_name
                    this.info.full_purchase_value = msg.data.full_purchase_value
                    this.chgLoad(false);

                }
                catch (error) {
                    this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 });
                    this.chgLoad(false);
                }
            }

            else {
                this.$root.$alert({ text: "La orden de compra es requerida", color: "error", timeout: 2000 });
                this.chgLoad(false);
            }


        },

        initialize() {
            this.info = []
            this.itemsPurchase = []
        }
    },

    mounted() {
        this.initialize()
    }
}
</script>

<style lang="scss" scoped></style>