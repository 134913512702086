  <template>
    <div>
      <v-data-table
        :headers="headers"
        :items="allGroupTics"
        :search="search"
        sort-by="title"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fa-angle-double-left',
          lastIcon: 'fa-angle-double-right',
          prevIcon: 'fa-angle-left',
          nextIcon: 'fa-angle-right'
        }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Grupos</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            
            <v-tooltip bottom v-if="can('group_tic-create')">
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="newItem">
                  <v-icon dark>fa-plus</v-icon>
                </v-btn>
              </template>
              <span>Nuevo</span>
            </v-tooltip>
            <v-tooltip bottom v-if="can('group_tic-create')">
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" small fab dark color="green" v-on="on" @click.stop="$refs.importGroupTic.open()">
                  <v-icon dark>fa-file-import</v-icon>
                </v-btn>
              </template>
              <span>Importar Existencias</span>
            </v-tooltip>
            <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container grid-list-md>
                    <v-row>
                      <v-col cols=12 md=6>
                        <v-autocomplete :disabled="!can('group_tic-create')"
                          v-model="editedItem.campus_id" 
                          item-value="id"
                          item-text="name"
                          :rules="rules.campus_id"
                          :items="campuses"
                          label="* Campus"
                          placeholder="Seleccione el Campus"
                          required
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols=12>
                        <v-text-field v-model="editedItem.name" label="Nombre"
                        id="name" :disabled="!can('group_tic-create')"
                        @keyup.enter="save"
                        :rules="rules.name"></v-text-field>
                      </v-col>
                      <v-col cols=12>
                        <v-switch v-model="editedItem.administrator" label="Administrador" color="primary"
                         :disabled="!can('group_tic-create')"></v-switch>
                      </v-col>
                      <v-col cols=12>
                        <v-switch v-model="editedItem.active" label="Estado" color="primary"
                         :disabled="!can('group_tic-create')"></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
                  <v-btn color="primary darken-1" outlined @click="save" :disabled="!can('group_tic-create')">{{ viewMode }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.active="props">
          {{stateItem(props.item.active)}}
        </template>

        <template v-slot:item.action="{ item }">
          <v-menu offset-y >
            <template v-slot:activator="{ on: menu }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn class="mx-2" icon color="primary" v-on="{ ...tooltip, ...menu }" id="newLoan">
                    <v-icon dark>fa-ellipsis-v</v-icon>
                  </v-btn>
                </template>
                <span>Acciones...</span>
              </v-tooltip>
            </template>
            <v-list dense flat>
              <v-list-item-group>
                <v-list-item @click="editItem(item)">
                  <v-list-item-icon>
                    <v-icon color="info">fa-pen</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="deleteItem(item)" v-if="can('group_tic-delete')">
                  <v-list-item-icon>
                    <v-icon color="error">fa-trash</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Eliminar</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="editUser(item)">
                  <v-list-item-icon>
                    <v-icon color="primary">fa-users</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Usuarios</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="editPlace(item)">
                  <v-list-item-icon>
                    <v-icon color="brown">fa-archway</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Lugares</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="editEquipment(item)">
                  <v-list-item-icon>
                    <v-icon color="blue-grey darken-2">fa-chalkboard</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Equipos</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="$refs.formEquipmentTransfer.editItem(null,item.id)" v-if="can('equipment_transfer-create')">
                  <v-list-item-icon>
                    <v-icon color="teal">fa-exchange-alt</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Trasladar</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>

        </template>
        <template v-slot:no-data>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn text icon color="green" dark @click="initialize()" v-on="on"><v-icon dark v-on="on">fa-sync-alt</v-icon></v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <!--Formulario de Baja-->
      <v-dialog v-model="dialogDrop" max-width="1000px" scrollable persistent>
        <v-card>
          <v-toolbar color="primary" dense dark>
            Dar de Baja equipos
            <v-spacer/>
            <v-btn icon small>
              <v-icon small @click="closeDrop">fa-times</v-icon>
            </v-btn>
          </v-toolbar>
            <v-card-text>
              <v-form ref="formDrop" v-model="validDrop">
                <v-container>
                    <v-row>
                      <v-col cols="12">
                          <v-textarea
                            required
                            label="Observación"
                            v-model="editedItem.observation_drop"
                            :rules="rules.observation"
                          /> 
                      </v-col>
                    </v-row>
                  </v-container>                
                </v-form>
              </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn outlined color="secondary darken-1" @click="closeDrop">Cancelar</v-btn>
              <v-btn outlined color="primary darken-1" @click="saveDrop">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>
      <SelectUser ref="users" :dialog="dialogUser" :editedItem="editedItem" @close="close" @save="save"/>
      <SelectPlace ref="places" :dialog="dialogPlace" :editedItem="editedItem" @close="close" @save="save"/>
      <SelectEquipment ref="equipment" :dialog="dialogEquipment" :editedItem="editedItem" @close="close" @save="save" @showDrop="showDrop"/>
      <FormEquipmentTransfer ref="formEquipmentTransfer" :isgroup="true"/>
      <ImportGroupTic ref="importGroupTic" />
    </div>
</template>
<script>
  import { can } from '../../util/functions';
  import { mapActions, mapGetters } from 'vuex'
  import { URL_APP } from "../../util/constants";
  import SelectUser from './SelectUser';
  import SelectPlace from './SelectPlace';
  import SelectEquipment from './SelectEquipment';
  import FormEquipmentTransfer from './transfer/FormEquipmentTransfer';
  import ImportGroupTic from './ImportGroupTic';

  export default{
    name: 'GroupTic',
    components: { SelectUser, SelectPlace, SelectEquipment, 
        FormEquipmentTransfer, ImportGroupTic },
    data() {
      return {
        dialog: false,
        dialogUser: false,
        dialogPlace: false,
        dialogEquipment: false,
        dialogDrop: false,
        search: '',
        rules: {
          campus_id: [
            v => !!v || 'Campo requerido',
          ],
          name: [
            v => !!v || 'Campo requerido',
            v => v.length >= 5 || 'Mínimo 5 caracteres',
            v => v.length < 200 || 'Máximo 200 letras',
          ],
        },
        headers: [
            { text: 'Id', value: 'id' },
            { text: 'Nombre', value: 'name' },
            { text: 'Campus', value: 'campus.name' },
            { text: 'Administrador', value: 'administrator' },
            { text: 'Activo', value: 'active' },
            { text: 'Acciones', value: 'action', sortable: false },
        ],
        campuses: [],
        editedIndex: -1,
        editedItem: {id: 0, name: '', observation_drop:'', administrator: false, active: true, users:[], equipment:[], places:[]},
        defaultItem: {id: 0, name: '', observation_drop:'', administrator: false, active: true, users:[], equipment:[], places:[]},
        valid: true,
        validDrop: true
      }
    },

    computed: {
      ...mapGetters(['loading', 'allGroupTics', 'selGroupTics']),
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    mounted () {
      this.initialize()
    },

    methods: {
      ...mapActions(['getGroupTics','getSelGroupTics', 'chgLoad', 'getEquipmentFields']),
      can:can,
      initialize () {
        try{
          this.getEquipmentFields()
          this.getGroupTics()
          this.getSelGroupTics()
        }catch(error){
          console.log(error)
        }
        this.getCampuses()
      },

      stateItem (stateIt) {
        return stateIt ? 'Activo' : 'Inactivo'
      },

      getCampuses () {
        this.$http.get(URL_APP+'/api/campuses')
        .then(response => {
          this.campuses = response.data;
        })
        .catch(error => { console.error(error)
          this.$root.$alert({text:"Error al Obtener los Campuses",color:"error",timeout:2000})
        });
      },

      editedItemBefore(item){
        this.editedIndex = this.allGroupTics.indexOf(item)
        this.editedItem = Object.assign({}, item)
      },

      editPlace(item) {
        var _this2 = this
        this.editedItemBefore(item)
        setTimeout(function(){ _this2.$refs.places.initialize(); }, 200);
        this.dialogPlace = true
        setTimeout(function(){ document.getElementById('placeSearch').focus(); }, 500);
      },

      editEquipment(item) {
        var _this2 = this
        this.editedItemBefore(item)
        setTimeout(function(){ _this2.$refs.equipment.initialize(); }, 200);
        this.dialogEquipment = true
        setTimeout(function(){ document.getElementById('equipmentSearch').focus(); }, 500);
      },

      editUser(item) {
        this.editedItemBefore(item)
        this.dialogUser = true
        setTimeout(function(){ document.getElementById('userSearch').focus(); }, 500);
      },

      newItem() {
        this.dialog = true
        setTimeout(function(){ document.getElementById('name').focus(); }, 500);
      },

      editItem (item) {
        this.editedItemBefore(item)
        this.dialog = true
      },

      deleteItem (item) {
        var url = URL_APP+"/api/loan/grouptics/" + item.id;
        this.$root.$confirm('Eliminar Grupo', 'Esta Seguro?', { color: 'red' })
        .then((res) => {
          if(res){
            this.$http.delete(url)
            .then(response => { console.error(response)
              this.initialize();
              this.$root.$alert({text:"¡ Registro Eliminado !",color:"success",timeout:2000})
            })
            .catch(error => { console.error(error)
              this.$root.$alert({text:"Imposible Eliminar Registro",color:"error",timeout:2000})
            });
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

      showDrop () {
        this.dialogDrop = true
      },

      closeDrop () {
        this.editedItem.observation_drop = ''
        this.dialogDrop = false
      },

      saveDrop () {
        this.dialogDrop = false
      },

      close () {
        this.initialize();
        this.dialog = false
        this.dialogUser = false
        this.dialogPlace = false
        this.dialogEquipment = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        if (this.valid){
          this.$root.$loadApp(true)
          var method1 = "POST"
          if (this.editedIndex > -1) {
            //Editar Item
            method1 = "PUT"
          }
          this.$http({
            method: method1,
            url: URL_APP+'/api/loan/grouptics',
            data: this.editedItem
          })
          .then(response => {
            this.close()
            this.$root.$loadApp(false)
            this.initialize();
            this.$root.$alert({text:response.data.message,color:"success",timeout:2000})
          }).catch(error => {
            this.$root.$loadApp(false)
            this.$root.$alert({text:error.response.data.message,color:"error",timeout:3000})
            console.log(error);
          })
        }else{
          this.$root.$alert({text:"Por favor revise los errores",color:"error",timeout:2000})
        }
      },
    }
  }
</script>
