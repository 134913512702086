<template>
  <div>
    <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
      <v-card>
        <v-toolbar color="error" dense dark>
          <v-icon class="mx-2 my-2">fa-exclamation-triangle</v-icon>
          <v-toolbar-title>
              Pendientes
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn dark class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                X
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <p class="body-1 mt-3">
            <b>Apreciado Colaborador:</b><br>
            El solicitante se encuentra con los siguientes pendientes:
          </p>
        <v-card outlined v-for="loan in loans" :key="loan.id" class="body-1 mt-3" color="red lighten-5">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" class="my-0 py-0">
                <b>Tipo: </b>{{ loan.type }}
              </v-col>
              <v-col cols="12" md="6" class="my-0 py-0">
                <b>Fecha:</b> {{ loan.date }}
              </v-col>
              <v-col cols="12" md="6" class="my-0 py-0">
                <b>{{ loan.place.type.name }}:</b> {{ loan.place.name }} - {{ loan.place.location }} - {{ loan.place.campus.name }}
              </v-col>
              <v-col cols="12" md="6" class="my-0 py-0">
                <b>Observacion:</b> {{ loan.observation }}
              </v-col>
              <v-col cols="12" class="my-0 py-0" v-if="loan.equipment.length>0">
                <b>Equipos:</b> 
                <template v-for="equip in loan.equipment">
                  <v-chip
                    class="ma-2"
                    color="red lighten-4"
                    :key="equip.id"
                    v-if="equip.pivot.loan_return_id == null"
                  >
                    {{ equip.type.name+" - "+equip.description }}
                  </v-chip>
                </template>
              </v-col>
            </v-row>            
          </v-card-text>
        </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="close">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  export default{
    name: 'AlertInformation',
    props: {
      loans: {
        type: Array,
        default: null
      },
    },
    data() {
      return {
        dialog: false,
      }
    },

    methods: {

      close () {
        this.dialog = false;
      },

      open () {
        this.dialog = true;
      },

    }
  }
</script>
