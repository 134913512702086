<template>
  <div>
    <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>
            Usuarios del Grupo {{ editedItem.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                X
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="search"
            append-icon="fa-search"
            label="Buscar"
            id="userSearch"
            single-line
            hide-details
            @click:row="save"
          ></v-text-field>
          <v-data-table v-model="editedItem.users"
            item-key="id"
            :show-select="can('group_tic-create')"
            :search="search"
            :headers="headers"
            :items="users"
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
          <v-btn color="primary darken-1" outlined @click="save" :disabled="!can('group_tic-create')">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { can } from '../../util/functions';
  import { URL_APP } from "../../util/constants";
  export default{
    name: 'SelectUser',
    props: {
      editedItem: {
        type: Object,
        default: null
      },
      dialog: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        search:'',
        loading: false,
        headers: [
            { text: 'Identidad', value: 'identity' },
            { text: 'Nombre', value: 'name' },
        ],
        users: [],
        selected: []
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Equipo' : 'Editar Equipo'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      can:can,
      initialize () {
        this.loading = true
        this.$http.get(URL_APP+"/api/users/selectusers")
        .then(response => {
          this.users = response.data
          this.loading = false
        }).catch(error => {
          this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
          this.loading = false
        });
      },

      close () {
        this.$emit('close')
      },

      save() {
          this.$emit('save')
      },
    }
  }
</script>
