  <template>
    <div>
    <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>
            <template v-if="!searchForm.found">Datos del Solicitante</template>
            <template v-else>Préstamo de 
              <template v-if="editedItem.type=='Equipo'">Equipos</template>
              <template v-else>Lugares</template>
            </template>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                X
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <span class="subtitle-1 font-weight-bold mt-6">Datos Préstamo </span>
          <v-container fluid>
              <template v-if="!searchForm.found">
                <v-form ref="formLoan_1" @submit.prevent="findId">
                  <v-row>
                    <v-col cols="12" md="6" class="my-0 py-0">
                      <v-autocomplete autofocus
                        v-model="searchForm.type" 
                        :items="types"
                        id="searchFormType"
                        label="* Tipo de Solicitante"
                        placeholder="Seleccione el Tipo de Solicitante"
                        required
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" class="my-0 py-0">
                      <v-text-field v-model="searchForm.id" label="Identificación / Código"
                      id="searchFormId" @keyup.enter="findId"
                      append-outer-icon="fa-greater-than"
                      required
                      @click:append-outer="findId"></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </template>
              <template v-else>
                <v-row>
                <v-col cols="12" md="6" class="my-0 py-0">
                  <span class="font-weight-bold">Identificación: </span>
                  <span class="info--text">{{editedItem.third.identification}}</span>
                </v-col>
                <v-col cols="12" md="6" class="my-0 py-0">
                  <span class="font-weight-bold">Nombre: </span>
                  <span class="info--text">{{editedItem.third.name}}</span>
                </v-col>
                <v-col cols="12" md="6" class="my-0 py-0">
                  <span class="font-weight-bold">Email: </span>
                  <span class="info--text">{{editedItem.third.email}}</span>
                </v-col>
                <v-col cols="12" md="6" class="my-0 py-0">
                  <span class="font-weight-bold">Teléfono(s): </span>
                  <span class="info--text" >{{(editedItem.third.phone?editedItem.third.phone+' - ':'')+(editedItem.third.mobile?editedItem.third.mobile:'')}}</span>
                </v-col>
                <v-col cols="12" md="6" class="my-0 py-0">
                  <span class="font-weight-bold">Tipo de Solicitante: </span>
                  <span class="info--text">{{editedItem.type_borrower}}</span>
                </v-col>
                <v-col cols="12" md="6" class="my-0 py-0">
                  <span class="font-weight-bold">Datos del Solicitante: </span>
                  <span class="info--text">{{editedItem.data_borrower}}</span>
                </v-col>
                <v-col cols="12" md="6" v-if="editedItem.date" class="my-0 py-0">
                  <span class="font-weight-bold">Fecha Préstamo: </span>
                  <span class="info--text">{{editedItem.date}}</span>
                </v-col>
                <v-col cols="12" md="6" v-if="editedItem.user_loan" class="my-0 py-0">
                  <span class="font-weight-bold">Colaborador Préstamo: </span>
                  <span class="info--text">{{editedItem.user_loan.name}}</span>
                </v-col>
                <v-col cols="12" v-if="editedItem.id == 0">
                  <v-autocomplete
                    v-model="editedItem.place_id" 
                    :items="allMyPlaces"
                    item-value="id"
                    item-text="name"
                    id="place_id"
                    label="* Lugar"
                    placeholder="Seleccione el Lugar"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" v-else class="my-0 py-0">
                  <span class="font-weight-bold">Lugar: </span>
                  <span class="info--text">{{editedItem.place.name}} - {{editedItem.place.location}} - {{editedItem.place.campus.name}}</span>
                </v-col>
                <v-col cols="12" v-if="editedItem.type=='Equipo'" class="my-0 py-0 px-3">
                  <LoanEquipment :editedLoan="editedItem"></LoanEquipment>
                </v-col>
                <v-col cols="12" v-if="editedItem.id == 0">
                  <v-textarea outlined
                    name="observation"
                    id="observation"
                    label="Observación Préstamo"
                    v-model="editedItem.observation" 
                  ></v-textarea>
                </v-col>
                <v-col cols="12" v-else>
                  {{editedItem.observation}}
                </v-col>                          
                </v-row>
              </template>
          </v-container>
          <template v-if="editedItem.loan_return.length > 0">
            <v-divider class="mb-6"></v-divider>
            <span class="subtitle-1 font-weight-bold">Datos de Devolución </span>
            <v-card outlined v-for="loanReturn in editedItem.loan_return" :key="loanReturn.id">
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6" v-if="loanReturn.date" class="my-0 py-0">
                    <span class="font-weight-bold">Fecha Devolución: </span>
                    <span class="info--text">{{loanReturn.date}}</span>
                  </v-col>
                  <v-col cols="12" md="6" v-if="loanReturn.user_return" class="my-0 py-0">
                    <span class="font-weight-bold">Colaborador Devolución: </span>
                    <span class="info--text">{{loanReturn.user_return.name}}</span>
                  </v-col>
                  <v-col cols="12" v-if="loanReturn.observation" class="my-0 py-0">
                    <span class="font-weight-bold">Observación Devolución: </span>
                    <span class="info--text">{{loanReturn.observation}}</span>
                  </v-col>
                  <v-col cols="12" v-if="editedItem.type=='Equipo'" class="my-0 py-0">
                    <span class="font-weight-bold">Equipos: </span>
                    <span class="info--text">
                      <template v-for="equip in loanReturn.equipment">
                        <v-chip
                          class="mt-1 mr-1"
                          :key="equip.id"
                        >
                          {{ equip.description +"("+equip.pivot.quantity_return+")" }}
                        </v-chip>
                      </template>
                    </span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
          <v-btn color="primary darken-1" outlined @click="save" v-if="searchForm.found" :loading="loading" 
          :disabled="loading || !can('loan-create')">{{ viewMode }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AlertInformation :email="notifyEmail" ref="alertInformation"/>
    <AlertPending :loans="pendLoans" ref="alertPending"/>
    <ThirdForm ref="formThird"/>
  </div>
</template>
<script>
  import { can } from '../../../util/functions';
  import { URL_APP } from "../../../util/constants";
  import { mapActions, mapGetters } from 'vuex'
  import LoanEquipment from '../LoanEquipment';
  import AlertInformation from '../AlertInformation';
  import AlertPending from '../AlertPending';
  import ThirdForm from '../../general/ThirdForm';

  export default{
    name: 'FormLoan',
    components: { LoanEquipment,
        AlertInformation, 
        AlertPending, ThirdForm },
    data() {
      return {
        dialog: false,
        notifyEmail: '',
        search: '',
        searchForm: {
          found: false,
          type:'',
          id: '',
        },
        types:[
          'Estudiante', 'Docente', 'Administrativo', 'Externo'
        ],
        pendLoans: [],
        thirdItem: {id: null, name: '', identification: '', email:'', state: 'Activo'},
        editedItem: {id: 0, type:0, date: '', observation: '', user_id:'', user_loan:'', type_borrower:'', data_borrower:'', state:'Prestado', place_id:'', equipment:[], place:{name:'',campus:{name:''}}, third_id:'', third:{id:0, identification:'', name:''}, loan_return:[]},
        defaultItem: {id: 0, type:0, date: '', observation: '', user_id:'', user_loan:'', type_borrower:'', data_borrower:'', state:'Prestado', place_id:'', equipment:[], place:{name:'',campus:{name:''}}, third_id:'', third:{id:0, identification:'', name:''}, loan_return:[]},
        valid: true
      }
    },

    computed: {
      ...mapGetters(['allMyPlaces','loading']),
      formTitle () {
        return this.editedItem.id === 0 ? 'Nuevo Préstamo' : 'Editar Préstamo'
      },
      viewMode () {
        return this.editedItem.id === 0 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    methods: {
      ...mapActions(['chgLoad', 'getMyPlaces','saveLoan']),

      can:can,

      newLoan(type){
        this.editedItem.type = type
        this.getMyPlaces(type)
        this.dialog = true
      },

      async findId(){
        if(!this.loading){
          if(this.searchForm.type==''){
            this.$root.$alert({text:"Tipo de Solicitante Obligatorio",color:"error",timeout:3000})
            document.getElementById('searchFormType').focus()
          }else if(this.searchForm.id==''){
            this.$root.$alert({text:"Identificación Obligatoria",color:"error",timeout:3000})
            document.getElementById('searchFormId').focus()
          }else{
            this.chgLoad(true)
            var params = {
              "type": this.searchForm.type,
              "id": this.searchForm.id
            }
            await this.$http.post(URL_APP+"/api/loan/tomasino", params )
            .then(response => {
              this.editedItem.third = response.data
              this.notifyEmail=this.editedItem.third.email;
              if(this.notifyEmail){
                this.searchForm.found = true
                this.editedItem.type_borrower = this.searchForm.type
                if(this.editedItem.third.department){
                  this.editedItem.data_borrower = this.editedItem.third.department
                }
                if (Array.isArray(this.editedItem.third.programs) ) {
                  if(this.editedItem.third.programs.length > 0){
                    this.editedItem.data_borrower = this.editedItem.third.programs[0].NOM_UNIDAD
                  }
                }
                if(this.searchForm.type=="Externo"){
                  this.editedItem.data_borrower = "Personal Externo - Contratistas"
                }
                this.editedItem.third_id = this.editedItem.third.id
                if (Array.isArray(this.editedItem.third.loans) ) {
                  if(this.editedItem.third.loans.length > 0 ){
                    //Alerta de Préstamos
                    this.pendLoans= this.editedItem.third.loans
                    this.$refs.alertPending.open()
                  }else{
                    setTimeout(function(){ document.getElementById('place_id').focus(); }, 500);
                  }
                }else{
                  setTimeout(function(){ document.getElementById('place_id').focus(); }, 500);
                }
              }else{
                this.$refs.formThird.show(this.editedItem.third)
              }
            }).catch(error => {
              this.$root.$alert({text:error.response.data.message,color:"error",timeout:3000})
              if(this.searchForm.type=="Externo"){
                this.thirdItem.identification = this.searchForm.id
                this.$refs.formThird.show(this.thirdItem)
              }
            })
            this.chgLoad(false)
          }
        }
      },

      editItem (item) {
        this.editedItem = Object.assign({}, item)
        this.searchForm.found = true
        this.dialog = true
      },

      fechaHoy(){
        return new Date().toISOString().substr(0, 10)
      },

      close () {
        this.dialog = false
        this.searchForm.found = false
        this.searchForm.type = ''
        this.searchForm.id = ''
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedItem.equipment = Array()
        }, 300)
      },

      async save () {
        if(!this.loading){
          var valid = true
          if(this.editedItem.place_id==''){
            valid = false
            this.$root.$alert({text:"Lugar Obligatorio",color:"error",timeout:3000})
            document.getElementById('place_id').focus()
          }else if(this.searchForm.id==''){
            valid = false
            this.$root.$alert({text:"Identificación Obligatoria",color:"error",timeout:3000})
            document.getElementById('searchFormId').focus()
          }else if(this.editedItem.type=='Equipo'){
            if(this.editedItem.equipment.length < 1){
              valid = false
              this.$root.$alert({text:"Debe Seleccionar al Menos un Equipo",color:"error",timeout:3000})
              document.getElementById('newEquipment').focus()
            }
          }
          if(valid){
            this.chgLoad(true)
            try{
              var data = await this.saveLoan(this.editedItem)
              this.close()
              this.$root.$alert({text:data.message,color:"success",timeout:2000})
              this.$refs.alertInformation.open()
            }catch(error){
              console.error(error)
              this.$root.$alert({text:"Ocurrió un error al Guardar el Préstamo",color:"error",timeout:2000})
            }
            this.chgLoad(false)
          }
        }
      },

    }
  }
</script>
