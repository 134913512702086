import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/loan/equipment"

const state = {
  equipment: [],
  equipmentT: 0
}

const getters = {
  allEquipment: state => state.equipment,
  totalEquipment: state => state.equipmentT
}

const actions = {

  async getEquipment({commit}, params){
    return new Promise(function(resolve, reject){
      commit('setEquipment', [])
      axios.get(URL_+'?page='+params.options.page, {
        params
      }).then(res=>{
        commit('setEquipment', res.data.data)
        commit('setEquipmentT', res.data.total)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async saveEquipment({commit},equipment){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, equipment).then(res=>{
        commit('newEquipment', res.data.equipment)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async updateEquipment({commit},equipment){
    return new Promise((resolve, reject)=>{
      axios.put(URL_, equipment).then(res=>{
        //console.log(res.data)
        commit('updEquipment', res.data.equipment)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deleteEquipment({commit}, equipment){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${equipment.id}`).then(res=>{
        commit('delEquipment', equipment)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setEquipment : (state, equipment) => state.equipment = equipment,
  setEquipmentT : (state, total) => state.equipmentT = total,
  newEquipment  : (state, equipment) => {
    state.equipment.unshift(equipment)
    state.equipmentT = state.equipmentT+1 
  },
  updEquipment  : (state, equipment) => {
    const index = state.equipment.findIndex(e => e.id === equipment.id)
    if (index !== -1) state.equipment.splice(index, 1, equipment)
  },
  delEquipment  : (state, equipment) => {
    state.equipment = state.equipment.filter(e => e.id !== equipment.id)
    state.equipmentT = state.equipmentT-1 
  }
}

export default{
  state, getters, actions, mutations
}