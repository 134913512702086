<template>
    <div>
        <v-dialog v-model="dialog" max-width="1400px" scrollable>
            <v-card>
                <v-toolbar dark color="primary" dense>
                    <v-toolbar-title>
                        Selección de Terceros
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="dialog = false">
                                X
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>

                <v-card-text >
                    <v-text-field autofocus v-model="search" prepend-icon="fa-search" label="Buscar" clearable
                        single-line hide-details @keypress.enter="initialize2(1)"
                        @click:clear="initialize2(1)"></v-text-field>
                    <v-data-table item-key="id" :headers="headers" :items="allAssetThirds" :search="search"
                        :loading="loading" :options.sync="options" :sort-by.sync="options.sortBy"
                        :sort-desc.sync="options.sortDesc" :items-per-page.sync="options.per_page"
                        :server-items-length="totalAssetThirds" multi-sort loading-text="Cargando Registros ..."
                        :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'fa-angle-double-left',
            lastIcon: 'fa-angle-double-right',
            prevIcon: 'fa-angle-left',
            nextIcon: 'fa-angle-right'
        }">
                        <!-- 
                        <template v-slot:item.status="{ item }">
                            <v-icon :color="item.status ? 'green' : 'red'">
                                {{ item.status ? 'mdi-checkbox-marked-circle' : 'mdi-close-circle' }}
                            </v-icon>
                        </template> -->

                        <template v-slot:item="{ item }">
                            <tr @click="setThird(item)" style="cursor: pointer">
                                <td>{{ item.type }}</td>
                                <td>{{ item.identification }}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.email }}</td>
                                <td>{{ item.position }}</td>
                                <td>{{ item.department }}</td>
                                <td> <v-icon :color="item.status ? 'green' : 'red'">
                                        {{ item.status ? 'mdi-checkbox-marked-circle' : 'mdi-close-circle' }}
                                    </v-icon></td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
//import { URL_APP } from "../../../util/constants";
export default {
    name: 'SelectThird',
    data() {
        return {
            dialog: false,
            options: { page: 1, per_page: 10, sortBy: ['identification'], sortDesc: [false], itemsPerPage: 10 },
            search: '',
            filters: [],
            headers: [
                { text: 'Tipo', value: 'type' },
                { text: 'Identification', value: 'identification' },
                { text: 'Nombre', value: 'name' },
                { text: 'Correo Electrónico', value: 'email' },
                { text: 'Cargo', value: 'position' },
                { text: 'Departamento Electrónico', value: 'department' },
                { text: 'Estado', value: 'status' },

            ],
        }
    },

    computed: {
        ...mapGetters(['allAssetThirds', 'loading', 'totalAssetThirds']),
        currentUser() {
            return this.$store.getters.currentUser
        }
    },
    watch: {
        'options.page'() {
            this.initialize()
        },
        'options.per_page'() {
            this.initialize()
        },
        'options.sortBy'() {
            this.initialize()
        },
        'options.sortDesc'() {
            this.initialize()
        },
    },

    mounted() {
        this.initialize()
        var this_2 = this
        window.addEventListener('keydown', function (e) {
            if (e.altKey == true && e.code.includes('Enter')) {
                this_2.setThird(this_2.allThirds[0])
            }
        });
    },

    methods: {
        ...mapActions(['getAssetThirds', 'chgLoad']),

        async initialize() {
            var params = { filters: this.filters, options: this.options, search: this.search }
            try {
                await this.getAssetThirds(params)
            } catch (error) {
                console.error(error)
                this.$root.$alert({ text: "Error al Obtener los Terceros #2", color: "error", timeout: 2000 })
            }
        },

        async initialize2(page = null) {
            if (!this.loading) {
                if (page != null) this.options.page = page
                this.chgLoad(true)
                var params = { filters: this.filters, options: this.options, search: this.search }
                try {
                    await this.getAssetThirds(params)
                } catch (error) {
                    console.error(error)
                    this.$root.$alert({ text: "Error al Obtener los Terceros #3", color: "error", timeout: 2000 })
                }
                this.chgLoad(false)
            }
        },

        setThird(item) {
            this.$emit('setThird', item)
            this.dialog = false
        },

        show() {
            this.dialog = true
        },

    }
}
</script>