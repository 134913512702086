import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/loan/loans"

const state = {
  loans: [],
  myLoans: []
}

const getters = {
  allLoans: state => state.loans,
}

const actions = {

  async getLoans({commit}, filters){
    return new Promise(function(resolve, reject){
      commit('setLoans', [])
      axios.get(URL_,{params: { filters: filters }}).then(res=>{
        commit('setLoans', res.data)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async saveLoan({commit}, loan){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, loan).then(res=>{
        commit('newLoan', res.data.loan)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async updateLoan({commit}, loan){
    return new Promise((resolve, reject)=>{
      axios.put(URL_, loan).then(res=>{
        //console.log(res.data)
        commit('updLoan', res.data.loan)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deleteLoan({commit}, loan){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${loan.id}`).then(res=>{
        commit('delLoan', loan)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setLoans : (state, loans) => state.loans = loans,
  newLoan  : (state, loan) => state.loans.unshift(loan),
  updLoan  : (state, loan) => {
    const index = state.loans.findIndex(u => u.id === loan.id)
    if (index !== -1) state.loans.splice(index, 1, loan)
  },
  delLoan  : (state, loan) => state.loans = state.loans.filter(c => c.id !== loan.id)
}

export default {
  state, getters, actions, mutations
}