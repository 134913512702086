<template>
    <div>
        <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="800px" transition="dialog-transition">
            <v-card>
                <v-toolbar dense color="primary" dark>
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>

                <v-card-text>
                    <v-form v-model="valid" ref="formArticle_" @submit.prevent="save">
                        <v-container>
                            <v-row>

                                <v-col cols="12" md="12">
                                    <v-autocomplete v-model="editedItem.type_article_id" item-value="id" item-text="name"
                                        :items="allTypeArticles" :rules="rules.type_article_id" label="* Tipo de artículo"
                                        placeholder="Seleccione el tipo de artículo" required></v-autocomplete>
                                </v-col>

                                <v-col cols="12" md="12">
                                    <v-autocomplete v-model="editedItem.brands_id" item-value="id" item-text="name"
                                        :items="allBrands" :rules="rules.brands_id" label="* Marca"
                                        placeholder="Seleccione la Marca" required></v-autocomplete>
                                </v-col>

                                <v-col cols="12" md="12">
                                    <v-select v-model="editedItem.frequency_consumption" :items="frequency"
                                        :rules="rules.frequency_consumption" label="* Frecuencia de Consumo"
                                        placeholder="Seleccione la Frecuencia de Consumo" required></v-select>
                                </v-col>

                                <v-col cols="12" md="6">

                                    <v-text-field v-model="editedItem.quantity_unit_measurement" label="* Cantidad Escalar"
                                        :rules="rules.quantity_unit_measurement" required :counter="30">
                                        <template v-slot:append>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon color="green" v-on="on">
                                                        fa-question-circle

                                                    </v-icon>
                                                </template>
                                                <span>Recuerde que debe registrar la cantidad numérica de la medida.
                                                    Ejemplo: Si es 5mg solo registre 5</span>
                                            </v-tooltip>
                                        </template>
                                    </v-text-field>


                                </v-col>



                                <v-col cols="12" md="6">
                                    <v-autocomplete v-model="editedItem.units_measurement_id" item-value="id"
                                        item-text="name" :items="allUnitsMeasurements" :rules="rules.units_measurement_id"
                                        label="* Unidades de medida" placeholder="Seleccione la unidad de medida"
                                        required></v-autocomplete>
                                </v-col>

                                <v-col cols=12 v-if="this.editedItem.id">
                                    <v-text-field v-model="editedItem.inventory" label="Número de inventario"
                                        readonly></v-text-field>
                                </v-col>

                                <v-col cols="12">
                                    <v-textarea v-model="editedItem.observations" label="Observaciones"></v-textarea>
                                </v-col>
                            </v-row>

                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="secondary" @click.stop="close">Cancelar</v-btn>
                    <v-btn color="primary" @click.stop="save">{{ viewMode }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { can } from '../../../util/functions';

export default {
    name: 'FormConsumableArticle',
    props: {
        allUnitsMeasurements: {
            type: Array,
            default: null
        },

        allBrands: {
            type: Array,
            default: null
        },

        allTypeArticles: {
            type: Array,
            default: null
        }
    },
    data: () => {
        return {
            dialog: false,
            valid: true,
            editedItem: {},
            rules: {
                type_article_id: [
                    (v) => !!v || 'El tipo de artículo es requerido',
                ],

                brands_id: [
                    (v) => !!v || 'La marca es requerida',
                ],

                quantity_unit_measurement: [
                    (v) => !!v || 'La cantidad escalar es requerida',
                    (v) => (v && v.length <= 30) || 'El cantidad escalar no puede contener más de 30 carácteres'
                ],

                units_measurement_id: [
                    (v) => !!v || 'La marca es requerida',
                ],
                frequency_consumption: [
                    (v) => !!v || 'La frecuencia es requerida',

                ]

            },
            frequency: ['Alto', 'Medio', 'Bajo']
        }
    },

    methods: {
        ...mapActions(['chgLoad', 'updateArticle', 'saveArticle']),
        can: can,

        newItem() {
            this.dialog = true
        },

        editItem(item) {
            this.editedItem = Object.assign({}, item);
            this.dialog = true
        },

        async save() {
            if (this.$refs.formArticle_.validate()) {
                this.chgLoad(true);
                try {
                    var msg = "";
                    if (this.editedItem.id) {
                        msg = await this.updateArticle(this.editedItem)
                    }
                    else {
                        msg = await this.saveArticle(this.editedItem)
                    }

                    this.$root.$alert({ text: msg.message, color: "success", timeout: 2000 });
                    this.close();
                }
                catch (error) {
                    this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 });
                    this.chgLoad(false);

                }
                this.chgLoad(false);

            }
            else {
                this.$root.$alert({ text: "Revise los errores", color: "error", timeout: 2000 });
            }
        },

        close() {
            this.dialog = false
            this.editedItem = {}
            this.$refs.formArticle_.reset()
        },
    },

    computed: {
        ...mapGetters(['loading']),
        currentuser() {
            return this.$store.getters.currentuser
        },
        formTitle() {
            return !this.editedItem.id ? 'Nuevo Registro' : 'Editar Registro'
        },
        viewMode() {
            return !this.editedItem.id ? 'Guardar' : 'Editar'
        },
    }
}
</script>

<style lang="scss" scoped></style>