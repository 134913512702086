<template>
    <div>
        <v-card>
            <v-card-title primary-title>
                <span class="headline">Artículos</span>
            </v-card-title>

            <v-card-text>
                <!-- <FilterConsumable :filters="filters" :fieldFilters="fieldFilters" @freshFilters="initialize2(1)"
                    id="filterConsumable">
                </FilterConsumable> -->
                <FilterQuery :filters="filters" :fieldFilters="fieldFilters" @freshFilters="initialize2(1)"
                    id="filterQuery"></FilterQuery>
                <v-data-table item-key="id" :headers="headers" :items="allArticles" :search="search" :options.sync="options"
                    :sort-by="options.sortBy" :sort-desc.sync="options.sortDesc" :items-per-page.sync="options.per_page"
                    :server-items-length="totalArticles" multi-sort :loading="loading" loading-text="Cargando..."
                    :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'fa-angle-double-left',
                        lastIcon: 'fa-angle-double-right',
                        prevIcon: 'fa-angle-left',
                        nextIcon: 'fa-angle-right'
                    }">
                    <template v-slot:top>
                        <v-toolbar color="white">
                            <v-text-field v-model="search" prepend-icon="fa-search" label="Buscar..." clearable single-line
                                hide-details @keypress.enter="initialize2(1)" @click:clear="initialize2(1)"></v-text-field>
                            <v-spacer></v-spacer>

                            <v-tooltip bottom v-if="can('consumables.articles-save')">
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn v-on="{ ...tooltip }" small color="primary" class="mx-2 my-2" fab dark
                                        @click.stop="$event => $refs.formArticle_.newItem()">

                                        <v-icon>fa-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>Nuevo</span>
                            </v-tooltip>

                            <v-menu offset-y>
                                <template v-slot:activator="{ on: menu }">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn class="mx-2" icon color="primary" v-on="{ ...tooltip, ...menu }"
                                                id="newLoan">
                                                <v-icon dark>fa-ellipsis-v</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Mas...</span>
                                    </v-tooltip>

                                </template>
                                <v-list dense flat>
                                    <template v-slot:activator="{ on: menu }">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on: tooltip }">
                                                <v-btn class="mx-2" icon color="primary" v-on="{ ...tooltip, ...menu }"
                                                    id="newLoan">
                                                    <v-icon dark>fa-ellipsis-v</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Mas...</span>
                                        </v-tooltip>
                                    </template>
                                    <v-list-item-group>
                                        <v-list-item @click="$refs.importArticles_.open()"
                                            v-if="can('consumables.articles-save')">
                                            <v-list-item-icon>
                                                <v-icon color="light-blue darken-3">fa-file-upload</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Importar Articulos</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item @click="exportArticles()" v-if="can('consumables.articles-list')">
                                            <v-list-item-icon>
                                                <v-icon color="green darken-3">fa-file-download</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Exportar Articulos</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                    </v-list-item-group>
                                </v-list>
                            </v-menu>

                        </v-toolbar>
                    </template>

                    <!-- Boton de acciones  -->

                    <template v-slot:item.actions="{ item }">

                        <v-tooltip left v-if="can('consumables.articles-save') && item.active">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2"
                                    @click="$event => $refs.formArticle_.editItem(item)" color="orange">
                                    fa-pen
                                </v-icon>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>

                        <v-tooltip right v-if="can('consumables.articles-delete') && item.active">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2" @click="deleteItem(item.id)" color="red">
                                    fa-trash
                                </v-icon>
                            </template>
                            <span>Eliminar</span>
                        </v-tooltip>

                        <v-tooltip right  v-if="can('consumables.articles-delete') && !item.active">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2" @click="activateItem(item.id)" color="green">
                                    fa-toggle-on
                                </v-icon>
                            </template>
                            <span>Activar</span>
                        </v-tooltip>
                    </template>
                    <!-- fin de boton de acciones  -->
                </v-data-table>
            </v-card-text>
        </v-card>
        <FormArticles :allUnitsMeasurements="allUnitsMeasurements" :allBrands="allBrands" :allTypeArticles="allTypeArticles"
            ref="formArticle_" />
        <ImportArticles ref="importArticles_" @initialize="initialize" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { URL_APP } from '../../../util/constants';
import { can, createLinkFile } from '../../../util/functions';
import FormArticles from './FormArticles.vue';
import ImportArticles from './ImportArticles.vue';
// import FilterConsumable from '../general/FilterConsumable.vue';
import FilterQuery from '../../general/FilterQuery.vue';

export default {
    name: 'consumables_articles',
    components: {
        FormArticles,
        ImportArticles,
        // FilterConsumable
        FilterQuery
    },
    data: () => {
        return {
            search: '',
            loading: false,
            editedIndex: -1,
            headers: [
                {
                    text: 'Tipo de Articulo',
                    align: 'start',
                    value: 'type_article.name',
                    sortable: false
                },
                { text: 'Inventario', value: 'inventory' },
                { text: 'Marca', value: 'brand.name', sortable: false },
                { text: 'Cantidad Escalar', value: 'quantity_unit_measurement' },
                { text: 'Unidad de Medida', value: 'unit_measurement.name', sortable: false },
                { text: 'Frecuencia de Consumo', value: 'frequency_consumption' },
                { text: 'Acciones', value: 'actions' },
            ],

            filters: [
                { field: { field: 'active', name: 'Estado', type: 'select', options: ['Activo', 'Inactivo'] }, operator: '=', value_start: 'Activo', value_end: '' },

            ],
            fieldFilters: [
                { field: 'active', name: 'Estado', type: 'select' },
                { field: 'inventory', name: 'Inventario', type: 'number' },
                { field: 'type_article.name', name: 'Tipo de artículo', type: 'text' },
                { field: 'brand.name', name: 'Marca', type: 'text' },
                { field: 'created_at', name: 'Fecha de creación', type: 'date' },
            ],
            options: { page: 1, per_page: 10, sortBy: ['inventory'], sortDesc: [false] },

        }
    },

    mounted() {
        this.initialize()
        var this_2 = this
        window.addEventListener('keydown', function (e) {
            if (e.altKey == true && e.code == 'KeyN') {
                this_2.$refs.formArticle_.newItem()
            }
        });
    },

    computed: {
        ...mapGetters(['loading', 'allUnitsMeasurements', 'allBrands', 'allArticles', 'totalArticles', 'allTypeArticles']),
        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro'
        },
        viewMode() {
            return this.editedIndex === -1 ? 'Guardar' : 'Editar'
        },
        currentUser() {
            return this.$store.getters.currentUser
        }
    },

    watch: {
        'options.page'() {
            this.initialize2()
        },
        'options.per_page'() {
            this.initialize2()
        },
        'options.sortBy'() {
            this.initialize2()
        },
        'options.sortDesc'() {
            this.initialize2()
        },
    },

    methods: {
        ...mapActions(['chgLoad', 'getActiveTypeArticles', 'getActiveUnitsMeasurements', 'getActiveBrands', 'getArticles', 'deleteArticle']),
        can: can,

        async initialize() {
            this.chgLoad(true);
            // await this.getArticles();
            this.getActiveUnitsMeasurements();
            this.getActiveBrands();
            this.getActiveTypeArticles();

            var params = { filters: this.filters, options: this.options, search: this.search }
            try {
                await this.getArticles(params)
            }
            catch (error) {
                this.$root.$alert({ text: "Error al Obtener los equipos", color: "error", timeout: 2000 })
            }

            this.chgLoad(false);
        },

        deleteItem(item) {
            this.$swal({
                title: 'Confirmación',
                text: "¿Desea eliminar el artículo?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, deseo eliminar',
                cancelButtonText: 'Cancelar'
            })
                .then(async (res) => {
                    if (res.isConfirmed) {
                        this.chgLoad(true)
                        try {
                            var msg = await this.deleteArticle(item)
                            await this.initialize()
                            this.chgLoad(false)
                            await this.$swal(
                                {
                                    title: 'Confirmación',
                                    text: msg.message,
                                    icon: 'success',
                                    timer: 2000
                                }
                            )
                        }
                        catch (err) {
                            this.chgLoad(false)
                            await this.$swal(
                                {
                                    title: 'Error!',
                                    text: 'El artículo no se ha eliminado.',
                                    icon: 'error',
                                    timer: 2000
                                }
                            )
                        }
                    }
                })
        },

        async exportArticles() {
            var url = URL_APP + "/api/loan_consumables/export_articles";
            this.chgLoad(true);
            await this.$http.get(url, { responseType: 'blob' })
                .then((response) => {
                    createLinkFile(response)
                    this.$root.$alert({ text: "Descarga exitosa", color: "success", timeout: 2000 })
                    this.chgLoad(false);
                })
                .catch(() => {
                    this.$root.$alert({ text: "Error al Obtener el Excel", color: "error", timeout: 2000 })
                    this.chgLoad(false);
                })
        },

        async initialize2(page = null) {
            if (!this.loading) {
                if (page != null) this.options.page = page
                this.chgLoad(true);
                var params = { filters: this.filters, options: this.options, search: this.search }
                try {
                    await this.getArticles(params)
                }
                catch (error) {
                    this.$root.$alert({ text: "Error al Obtener los equipos", color: "error", timeout: 2000 })
                }
                this.chgLoad(false)
                this.loading = false;
            }
            else {
                this.chgLoad(false);

            }
        }
    }


}
</script>

<style lang="scss" scoped></style> 