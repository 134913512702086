<template>
    <div>
        <v-card>
            <v-card-title primary-title>
                <span class="headline">Terceros - Activos Fijos</span>
            </v-card-title>
            <v-card-text>
                <v-data-table item-key="id" :headers="headers" :items="allAssetThirds" :search="search"
                    :options.sync="options" :sort-by="options.sortBy" :sort-desc.sync="options.sortDesc"
                    :items-per-page.sync="options.per_page" :server-items-length="totalAssetThirds" multi-sort
                    :loading="loading" loading-text="Cargando..." :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'fa-angle-double-left',
                    lastIcon: 'fa-angle-double-right',
                    prevIcon: 'fa-angle-left',
                    nextIcon: 'fa-angle-right'
                }">

                    <template v-slot:item.status="{ item }">
                        <v-icon :color="item.status ? 'green' : 'red'">
                            {{ item.status ? 'mdi-checkbox-marked-circle' : 'mdi-close-circle' }}
                        </v-icon>
                    </template>

                    <template v-slot:top>
                        <v-toolbar color="white">
                            <v-text-field v-model="search" prepend-icon="fa-search" label="Buscar..." clearable
                                single-line hide-details @keypress.enter="initialize2(1)"
                                @click:clear="initialize2(1)"></v-text-field>
                            <v-spacer></v-spacer>
                            <!-- TODO: Aqui se debe cambiar el permiso  -->
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn v-on="{ ...tooltip }" small color="primary" class="mx-2 my-2" fab dark
                                        @click.stop="$event => $refs.formThird_.openItem()">

                                        <v-icon>fa-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>Nuevo</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn v-on="{ ...tooltip }" small color="green dark-3" class="mx-2 my-2" fab dark
                                        @click.stop="$event => $refs.importThird_.openItem()">

                                        <v-icon>fa-file-upload</v-icon>
                                    </v-btn>
                                </template>
                                <span>Importar</span>
                            </v-tooltip>

                        </v-toolbar>
                    </template>

                    <template v-slot:item.actions="{ item }">

                        <div v-if="item.status">
                            <v-tooltip left>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-icon v-on="{ ...tooltip }" class="mr-2" @click="update(item)" color="primary">
                                        fa-undo-alt
                                    </v-icon>
                                </template>
                                <span>Actualizar</span>
                            </v-tooltip>

                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-icon v-on="{ ...tooltip }" class="mr-2" @click="inactive(item)"
                                        color="red darken-3">
                                        fa-user-times
                                    </v-icon>
                                </template>
                                <span>Deshabilitar</span>
                            </v-tooltip>
                        </div>
                        <div v-else>
                            <v-tooltip left>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-icon v-on="{ ...tooltip }" class="mr-2" @click="activate(item)"
                                        color="green darken-3">
                                        fa-user-check
                                    </v-icon>
                                </template>
                                <span>Habilitar</span>
                            </v-tooltip>

                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-icon v-on="{ ...tooltip }" class="mr-2"  @click.stop="$event => $refs.formThird_.updateItem(item)"
                                        color="orange ">
                                        fa-pen
                                    </v-icon>
                                </template>
                                <span>Editar</span>
                            </v-tooltip>
                        </div>

                    </template>

                </v-data-table>
            </v-card-text>
        </v-card>
        <FormThird ref="formThird_" />
        <ImportThird ref="importThird_"  @initialize="initialize" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormThird from '../thirds/FormThird.vue';
import ImportThird from '../thirds/ImportThird.vue';
import { mostrarSweetAlert } from '../../../util/sweetAlertUtil';

export default {
    name: "Thirds",
    components: { FormThird, ImportThird },
    data() {
        return {
            search: '',
            loading: false,
            editedIndex: -1,
            headers: [
                { text: 'Nombres completos', value: 'name', sortable: false },
                { text: 'Tipo de documento', value: 'type', sortable: false },
                { text: 'Número de documento', value: 'identification', sortable: false },
                { text: 'Correo electrónico', value: 'email', sortable: false },
                { text: 'Cargo', value: 'position', sortable: false },
                { text: 'Departamento', value: 'department',  sortable: false },
                { text: 'Estado', value: 'status', sortable: false },
                { text: 'Acciones', value: 'actions',width: '8%', sortable: false, },
            ],
            options: { page: 1, per_page: 10, sortBy: ['id'], sortDesc: [false] },
            filters: [
                { field: { field: 'status', name: 'Estado', type: 'select', options: ['Activo', 'Inactivo'] }, operator: '=', value_start: 'Activo', value_end: '' },

            ],
            fieldFilters: [
                { field: 'status', name: 'Estado', type: 'select' },
            ],

        }
    },

    methods: {
        ...mapActions(['getAssetThirds', 'chgLoad', 'updateAssetThird', 'inactiveAssetThird', 'activateAssetThird']),

        async initialize() {
            this.chgLoad(true)

            var params = { filters: this.filters, options: this.options, search: this.search }
            try {
                await this.getAssetThirds(params)
            }
            catch (error) {
                this.$root.$alert({ text: "Error al obtener los terceros", color: "error", timeout: 2000 })
            }
            this.chgLoad(false)
        },

        async initialize2(page = null) {
            if (!this.loading) {
                if (page != null) this.options.page = page

                this.chgLoad(true);
                var params = { filters: this.filters, options: this.options, search: this.search }
                try {
                    await this.getAssetThirds(params)
                }
                catch (error) {
                    this.$root.$alert({ text: "Error al obtener los terceros", color: "error", timeout: 2000 })
                }
                this.chgLoad(false)
            }
            else {
                this.chgLoad(false);
            }
        },

        async update(item) {
            mostrarSweetAlert({
                titulo: 'Confirmación',
                texto: '¿Estás seguro de que deseas actualizar?',
                icono: 'warning',
                textoBotonConfirmar: 'Confirmar',
                textoBotonCancelar: 'Cancelar',
            })
                .then(async (res) => {
                    if (res.isConfirmed) {
                        this.chgLoad(true);
                        try {
                            var msg = "";
                            msg = await this.updateAssetThird(item)
                            this.$root.$alert({ text: msg.message, color: "success", timeout: 2000 })
                            this.chgLoad(false);
                        }
                        catch (error) {
                            this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 });
                            this.chgLoad(false);
                        }
                        this.chgLoad(false);
                    }
                })
        },

        async inactive(item) {
            mostrarSweetAlert({
                titulo: 'Confirmación',
                texto: '¿Estás seguro de que deseas inactivar?',
                icono: 'warning',
                textoBotonConfirmar: 'Confirmar',
                textoBotonCancelar: 'Cancelar',
            })
                .then(async (res) => {
                    if (res.isConfirmed) {
                        this.chgLoad(true);
                        try {
                            var msg = "";
                            msg = await this.inactiveAssetThird(item)
                            this.$root.$alert({ text: msg.message, color: "success", timeout: 2000 })
                            this.chgLoad(false);
                        }
                        catch (error) {
                            this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 });
                            this.chgLoad(false);
                        }
                        this.chgLoad(false);
                    }
                })
        },
        
        async activate(item) {
            mostrarSweetAlert({
                titulo: 'Confirmación',
                texto: '¿Estás seguro de que deseas activar?',
                icono: 'warning',
                textoBotonConfirmar: 'Confirmar',
                textoBotonCancelar: 'Cancelar',
            })
                .then(async (res) => {
                    if (res.isConfirmed) {
                        this.chgLoad(true);
                        try {
                            var msg = "";
                            msg = await this.activateAssetThird(item)
                            this.$root.$alert({ text: msg.message, color: "success", timeout: 2000 })
                            this.chgLoad(false);
                        }
                        catch (error) {
                            this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 });
                            this.chgLoad(false);
                        }
                        this.chgLoad(false);
                    }
                })
        }

    },

    computed: {
        ...mapGetters(['allAssetThirds', 'totalAssetThirds'])

    },

    watch: {
        'options'() {
            this.initialize2()
        }
    },


}
</script>

<style lang="scss" scoped></style>