<template>
    <div>
        <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="1400px"
            transition="dialog-transition">
            <v-card>
                <v-toolbar dense color="primary" dark>
                    <v-toolbar-title>FORMULARIO DE TRASLADO DE ARTICULOS ENTRE USUARIOS</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="closeItem">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-card-text>
                    <v-form ref="formTransfer_" @submit.prevent="saveItem()">
                        <v-container>
                            <v-stepper v-model="step" non-linear>
                                <v-stepper-header>
                                    <v-stepper-step editable :step="1">
                                        Selección del personal
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step editable :step="2" v-if="editedItem.assets.length > 0">
                                        Visualización de los activos
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step :step="3" editable v-if="editedItem.row == 'si'">
                                        Nueva ubicación
                                    </v-stepper-step>
                                </v-stepper-header>

                                <v-stepper-items>
                                    <v-stepper-content :step="1">
                                        <v-row>
                                            <v-col cols="12" sm="6" md="6">

                                                <v-col cols="12" class="my-0 py-0">
                                                    <v-text-field name="name"
                                                        label="Documento de la persona que entrega" id="id"
                                                        v-model="third1" @keyup.enter="findThird1()"
                                                        :rules="rules.tercero">

                                                        <template v-slot:append>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon v-on="on" color="primary darken-1"
                                                                        @click="findThird1()">
                                                                        mdi-magnify
                                                                    </v-icon>
                                                                </template>
                                                                Buscar
                                                            </v-tooltip>
                                                        </template>
                                                    </v-text-field>
                                                </v-col>

                                                <div v-if="(Object.keys(editedItem.third1).length > 0)">
                                                    <v-col cols="12" class="my-0 py-0">
                                                        <span class="font-weight-bold">Nombres completos: </span>
                                                        <span class="info--text">{{ editedItem.third1.name }}</span>
                                                    </v-col>

                                                    <v-col cols="12" class="my-0 py-0">
                                                        <span class="font-weight-bold">Cargo: </span>
                                                        <span class="info--text">{{ editedItem.third1.position }}</span>
                                                    </v-col>

                                                    <v-col cols="12" class="my-0 py-0">
                                                        <span class="font-weight-bold">Departamento: </span>
                                                        <span class="info--text">{{ editedItem.third1.department
                                                            }}</span>
                                                    </v-col>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">

                                                <v-col cols="12" class="my-0 py-0">
                                                    <v-text-field name="name" label="Documento de la persona que recibe"
                                                        id="id" cols="12" v-model="third2" @keyup.enter="findThird2()"
                                                        :rules="rules.tercero">
                                                        <template v-slot:append>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon v-on="on" color="primary darken-1"
                                                                        @click.stop="findThird2()">
                                                                        mdi-magnify
                                                                    </v-icon>
                                                                </template>
                                                                Buscar
                                                            </v-tooltip>
                                                        </template></v-text-field>
                                                </v-col>

                                                <div v-if="Object.keys(editedItem.third2).length > 0">
                                                    <v-col cols="12" class="my-0 py-0">
                                                        <span class="font-weight-bold">Nombres completos: </span>
                                                        <span class="info--text">{{ editedItem.third2.name }}</span>
                                                    </v-col>

                                                    <v-col cols="12" class="my-0 py-0">
                                                        <span class="font-weight-bold">Cargo: </span>
                                                        <span class="info--text">{{ editedItem.third2.position }}</span>
                                                    </v-col>

                                                    <v-col cols="12" class="my-0 py-0">
                                                        <span class="font-weight-bold">Departamento: </span>
                                                        <span class="info--text">{{ editedItem.third2.department
                                                            }}</span>
                                                    </v-col>
                                                </div>
                                            </v-col>

                                            <v-col cols="12" md="12" class="my-0 py-0"
                                                v-if="editedItem.third1.identification">
                                                <v-col cols="12" md="6">
                                                    <div class="d-flex align-center">
                                                        <span class="font-weight-bold">¿Se requiere traslado de
                                                            ubicación?
                                                        </span>
                                                        &nbsp;
                                                        &nbsp;

                                                        <v-radio-group v-model="editedItem.row" row>
                                                            <v-radio label="NO" value="no"></v-radio>
                                                            <v-radio label="SI" value="si"></v-radio>
                                                        </v-radio-group>
                                                    </div>
                                                </v-col>
                                            </v-col>
                                        </v-row>
                                    </v-stepper-content>

                                    <v-stepper-content :step="2" v-if="editedItem.assets.length > 0">
                                        <v-data-table :headers="headers" :items="editedItem.assets" class="mr-4 tl-4">

                                            <template v-slot:item.found="{ item }">
                                                <span v-if="item.found">SI</span>
                                                <span v-else>NO</span>
                                            </template>

                                        </v-data-table>
                                    </v-stepper-content>

                                    <v-stepper-content :step="3" v-if="editedItem.row == 'si'" mt-2>
                                        <!--? Campus  -->
                                        <v-container grid-list-xs>
                                            <v-row>
                                                <v-col cols=12 md="6" class="my-0 py-0">
                                                    <v-autocomplete v-model="editedItem.campus_id" item-value="id"
                                                        item-text="name" :items="allCampuses" id="searchFormType"
                                                        label="* Campus" placeholder="Seleccione el Campus" required
                                                        clearable :rules="getRequiredRule()"></v-autocomplete>
                                                </v-col>

                                                <!--? Edificio  -->
                                                <v-col cols=12 md="6" class="my-0 py-0">
                                                    <v-autocomplete v-model="editedItem.building_id" item-value="id"
                                                        item-text="name" :items="allBuildings" id="searchFormType"
                                                        label="* Edificio" placeholder="Seleccione el Edificio" required
                                                        clearable :rules="getRequiredRule()"></v-autocomplete>
                                                </v-col>

                                                <!--? Piso  -->
                                                <v-col cols=12 md="6" class="my-0 py-0">
                                                    <v-autocomplete v-model="editedItem.tier_id" item-value="id"
                                                        item-text="name" :items="allTiers" id="searchFormType"
                                                        label="* Piso" placeholder="Seleccione el piso" required
                                                        clearable :rules="getRequiredRule()"></v-autocomplete>
                                                </v-col>

                                                <!--? Area  -->
                                                <v-col cols=12 md="6" class="my-0 py-0">
                                                    <v-autocomplete v-model="editedItem.area_id" item-value="id"
                                                        item-text="name" :items="allAreas" id="searchFormType"
                                                        label="* Area" placeholder="Seleccione el área" required
                                                        clearable :rules="getRequiredRule()"></v-autocomplete>
                                                </v-col>

                                                <!--? Código del centro de costos  -->
                                                <v-col cols=12 md="6" class="my-0 py-0">
                                                    <v-text-field v-model="editedItem.cost_center.cost_center_code"
                                                        label="* Código centro de costos" readonly :rules="getRequiredRule()">
                                                        <template v-slot:append>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon v-on="on" color="green darken-3"
                                                                        @click="$refs.selectCostCenter_.show()"
                                                                        class="mr-2">
                                                                        fa-city
                                                                    </v-icon>
                                                                </template>
                                                                Seleccionar Centro de Costros
                                                            </v-tooltip>

                                                            <v-tooltip bottom v-if="editedItem.cost_center.id">
                                                                <template v-slot:activator="{ on }" ml-2>
                                                                    <v-icon v-on="on" color="orange darken-3"
                                                                        @click="clearCostCenter()">
                                                                        fa-times-circle
                                                                    </v-icon>
                                                                </template>
                                                                Limpiar datos
                                                            </v-tooltip>
                                                        </template>
                                                    </v-text-field>
                                                </v-col>

                                                <!--? Nombre del centro de costos  -->
                                                <v-col cols=12 md="6" class="my-0 py-0">
                                                    <v-text-field v-model="editedItem.cost_center.name"
                                                        label="Nombre del centro de costos" readonly :rules="getRequiredRule()"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-stepper-content>
                                </v-stepper-items>
                            </v-stepper>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="secondary" @click="closeItem">Cancelar</v-btn>
                    <v-btn color="primary" @click.stop="saveItem"
                        :disabled="!editedItem.assets.length > 0">Enviar</v-btn>
                </v-card-actions>
            </v-card>


        </v-dialog>
        <SelectCostCenter ref="selectCostCenter_" @setCostCenter="setCostCenter" />
    </div>
</template>

<script>
import { URL_APP } from '../../../util/constants';
import { mapActions, mapGetters } from 'vuex';
import SelectCostCenter from '../../general/CostCenter/SelectCostCenter.vue';
import { mostrarSweetAlert } from '../../../util/sweetAlertUtil';

export default {
    name: "FormTransferBetweenUsers",
    components: { SelectCostCenter },
    data() {
        return {
            dialog: false,
            step: 1,
            third1: null,
            third2: null,
            headers: [
                { text: 'Descripción del artículo', value: 'description', width: '20%', sortable: false },
                { text: 'Inventario actual', value: 'inventory' },
                { text: 'Inventario anterior', value: 'old_inventory', sortable: false },
                { text: 'Serial', value: 'serial', sortable: false },
                { text: 'Estado', value: 'status.description', sortable: false },
                { text: 'Encontrado', value: 'found', sortable: false },
            ],
            editedItem: {
                // transferLocation: false,
                row: 'no',
                third1: {}, //Tercero que entrega
                third2: {}, //Tercero que recibe
                assets: [],
                cost_center: {},
            },
            rules: {
                tercero: [
                    (v) => !!v || 'El documento del tercero es requerido',
                ],
            }
        }
    },
    methods: {
        ...mapActions(['chgLoad', 'getCampuses', 'getBuildings', 'getTiers', 'getAreas', 'transferBetweenUsers']),
        async openItem() {
            this.chgLoad(true)
            await Promise.all([
                this.getCampuses(),
                this.getBuildings(),
                this.getTiers(),
                this.getAreas(),
            ]);
            this.chgLoad(false)
            this.dialog = true

        },

        closeItem() {
            this.dialog = false;
            this.step = 1;
            this.third1 = null;
            this.third2 = null;
            this.editedItem = {
                row: 'no',
                third1: {}, //Tercero que entrega
                third2: {}, //Tercero que recibe
                assets: [],
                cost_center: {}
            }
            this.$refs.formTransfer_.reset()
        },

        async saveItem() {
            if (this.$refs.formTransfer_.validate()) {
                mostrarSweetAlert({
                    titulo: 'Confirmación',
                    texto: '¿Estás seguro de que desea realizar el traslado?',
                    icono: 'warning',
                    textoBotonConfirmar: 'Confirmar',
                    textoBotonCancelar: 'Cancelar',
                })
                    .then(async (res) => {
                        if (res.isConfirmed) {
                            this.chgLoad(true);
                            try {
                                var msg = "";
                                msg = await this.transferBetweenUsers(this.editedItem)
                                this.$root.$alert({ text: msg.message, color: "success", timeout: 6000 })
                                this.chgLoad(false);
                                this.closeItem()
                                this.$emit('item-saved');
                            }
                            catch (error) {
                                this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 });
                                this.chgLoad(false);

                            }
                        }
                    })
            }
            else {
                this.$root.$alert({ text: 'Por favor, complete los campos obligatorios', color: "error", timeout: 2000 });
            }
        },

        async findThird1() {
            if (this.third1 === this.third2) {
                this.$root.$alert({ text: 'Los números de documento son iguales', color: "error", timeout: 4000 })
            }
            else {
                this.chgLoad(true);
                try {
                    await this.$http.get(URL_APP + "/api/loan_assets/third_register", {
                        params: { 'identification': this.third1 }
                    })
                        .then((res) => {
                            this.editedItem.third1 = {
                                id: res.data.id,
                                identification: res.data.identification,
                                name: res.data.name,
                                position: res.data.position,
                                department: res.data.department,
                            };
                        })

                    await this.getAssetsForUser(this.editedItem.third1.id)
                    this.chgLoad(false)

                }
                catch (error) {
                    this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 })
                    this.chgLoad(false)
                }
            }

        },

        async findThird2() {
            if (this.third1 === this.third2) {
                this.$root.$alert({ text: 'Los números de documento son iguales', color: "error", timeout: 4000 })
            } else {
                this.chgLoad(true);
                try {
                    await this.$http.get(URL_APP + "/api/loan_assets/third_register", {
                        params: { 'identification': this.third2 }
                    })
                        .then((res) => {
                            this.editedItem.third2 = {
                                id: res.data.id,
                                identification: res.data.identification,
                                name: res.data.name,
                                position: res.data.position,
                                department: res.data.department,
                            };
                        })
                    this.chgLoad(false)

                }
                catch (error) {
                    this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 })
                    this.chgLoad(false)
                }
            }
        },

        getRequiredRule() {
            if (this.editedItem.row === 'si') {
                return [(v) => !!v || 'Este campo es requerido'];
            } else {
                return [];
            }
        },

        async getAssetsForUser(item) {
            try {
                await this.$http.get(URL_APP + "/api/loan_assets/assetsForUsers", {
                    params: { 'id': item }
                })
                    .then((res) => {
                        if (!res.data.length > 0) {
                            this.$root.$alert({ text: 'El responsable no tiene activos a su cargo', color: "orange", timeout: 5000 })
                        } else {
                            this.editedItem.assets = res.data;
                        }
                    })
            }
            catch (error) {
                this.$root.$alert({ text: 'Error en la búsqueda de activos', color: "error", timeout: 2000 })
                this.chgLoad(false)
            }
        },

        setCostCenter(cost_center) {
            this.editedItem.cost_center = cost_center
        },

        clearCostCenter() {
            this.editedItem.cost_center = {}
        },
    },

    computed: {
        ...mapGetters(['allCampuses', 'allBuildings', 'allTiers', 'allAreas']),
    }
}
</script>

<style lang="scss" scoped></style>