import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/roles/roles"

const state = {
  roles: []
}

const getters = {
  allRoles: state => state.roles
}

const actions = {

  async getRoles({commit}){
    return new Promise(function(resolve, reject){
      commit('setRoles', [])
      axios.get(URL_).then(res=>{
        commit('setRoles', res.data)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async saveRole({commit},role){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, role).then(res=>{
        commit('newRole', res.data.role)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async updateRole({commit},role){
    return new Promise((resolve, reject)=>{
      axios.put(URL_, role).then(res=>{
        //console.log(res.data)
        commit('updRole', res.data.role)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deleteRole({commit}, role){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${role.id}`).then(res=>{
        commit('delRole', role)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setRoles : (state, roles) => state.roles = roles,
  newRole  : (state, role) => state.roles.unshift(role),
  updRole  : (state, role) => {
    const index = state.roles.findIndex(r => r.id === role.id)
    if (index !== -1) state.roles.splice(index, 1, role)
  },
  delRole  : (state, role) => state.roles = state.roles.filter(r => r.id !== role.id)
}

export default{
  state, getters, actions, mutations
}