import { render, staticRenderFns } from "./FormUnitsMeasurement.vue?vue&type=template&id=794c4816&scoped=true"
import script from "./FormUnitsMeasurement.vue?vue&type=script&lang=js"
export * from "./FormUnitsMeasurement.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "794c4816",
  null
  
)

export default component.exports