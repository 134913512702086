<template>
  <div>
    <v-card outlined color="orange lighten-5" class="elevation-2 my-2">
      <v-card-text class="my-1 py-1">
        <v-row>
          <v-col cols="3" sm="4" md="1" class="ma-0 pa-2">
            <span><b>Filtros: </b></span>
          </v-col>
          <v-col cols="4" sm="4" md="1" class="my-0 pa-1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn class="mx-2" text small v-on="{ ...tooltip }" color="primary" id="newFilter" @click="newFilter()">
                  <v-icon left small>fa-filter</v-icon> Nuevo 
                </v-btn>
              </template>
              <span>Nuevo Filtro</span>
            </v-tooltip>
          </v-col>
          <v-col cols="5" sm="4" md="2" class="my-0 pa-1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn class="mx-2" text small v-on="{ ...tooltip }" id="initialize" @click="initialize" color="primary">
                  <v-icon left small>fa-sync-alt</v-icon> Actualizar 
                </v-btn>
              </template>
              <span>Actualizar Filtro</span>
            </v-tooltip>
          </v-col>
          
          <v-col cols="12" md="8" class="ma-0 pa-0">
            <v-chip-group
              active-class="primary--text"
              class="ma-0 pa-0"
            >
              <v-chip small class="ma-1" v-for="(filter, index) in filters" :key="`chip_${index}`" close @click="editFilter(filter)" @click:close="delFilter(filter)">
                {{ filter.field.name }}
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
        <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
          <v-card>
            <v-toolbar dark color="primary" dense>
              <v-toolbar-title>
                Editar Filtro
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                    X
                  </v-btn>
                </template>
                <span>Cerrar</span>
              </v-tooltip>
            </v-toolbar>
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="editedItem.field.field" 
                      :items="fieldFilters"
                      item-text="name"
                      item-value="field"
                      id="field"
                      label="* Campo a Filtrar"
                      placeholder="Seleccione el Campo a Filtrar"
                      @change="changeField"
                      required
                      :rules="rules.field"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="editedItem.operator" 
                      :items="operators"
                      id="operator"
                      label="* Operación"
                      placeholder="Seleccione la Operación"
                      required
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field v-model="editedItem.value_start" 
                      :label="editedItem.operator.includes('between') ? '* Valor Inicio': '* Valor'" 
                      v-if="editedItem.field.type=='text' || editedItem.field.type=='number'"
                      id="value_start"
                      :type="editedItem.field.type"
                      :rules="rules.value_start"
                      required
                      @keyup.enter="save"
                    ></v-text-field>

                    <v-autocomplete v-if="editedItem.field.type=='select'"
                      v-model="editedItem.value_start" 
                      :item-text="editedItem.field.options[0].name?'name':'value'"
                      :item-value="editedItem.field.options[0].id?'id':'value'"
                      :rules="rules.value_start"
                      :items="editedItem.field.options"
                      id="value_start"
                      :label="editedItem.operator.includes('between') ? '* Selección Inicial': '* Valor'" 
                      :placeholder="editedItem.operator.includes('between') ? '* Seleccione el Valor Inicial': 'Seleccione el Valor'" 
                      required
                    ></v-autocomplete>
                    
                    <v-menu v-if="editedItem.field.type=='date'"
                      v-model="menFechIni"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editedItem.value_start"
                          :label="editedItem.operator.includes('between') ? 'Fecha de Inicio': 'Fecha'"
                          :rules="rules.value_start"
                          prepend-icon="fa-calendar-alt"
                          readonly
                          v-on="on"
                          id="value_start"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="editedItem.value_start" @input="menFechIni = false" ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6" v-if="editedItem.operator.includes('between')">
                    <v-text-field v-model="editedItem.value_end" label="Valor Final"
                      v-if="editedItem.field.type=='text' || editedItem.field.type=='number'"
                      id="value_end"
                      :type="editedItem.field.type"
                      required
                      @keyup.enter="save"
                      :rules="rules.value_end"
                    ></v-text-field>
                    <v-autocomplete v-if="editedItem.field.type=='select'"
                      v-model="editedItem.value_end" 
                      :items="editedItem.field.options"
                      id="value_end"
                      label="* Valor Final"
                      placeholder="Seleccione el Valor Final"
                      required
                      :rules="rules.value_end"
                    ></v-autocomplete>
                    <v-menu v-if="editedItem.field.type=='date'"
                      v-model="menFechFin"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editedItem.value_end"
                          label="Fecha Final"
                          :rules="rules.value_end"
                          prepend-icon="fa-calendar-alt"
                          readonly
                          v-on="on"
                          id="value_end"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="editedItem.value_end" @input="menFechFin = false" :min="editedItem.value_start"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
              <v-btn color="primary darken-1" outlined @click="save">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
  export default{
    name: 'FilterQuery',
    props: {
      filters: {
        type: Array,
        default: null
      },
      fieldFilters: {
        type: Array,
        default: null
      },
    },
    data() {
      return {
        dialog: false,
        menFechIni: false,
        menFechFin: false,
        equipment: [],
        editedIndex: -1,
        editedItem:{field:{field:'', name:'', type:'', options:[]}, operator:'=', value_start:'', value_end:''},
        defaultItem:{field:{field:'', name:'', type:'', options:[]}, operator:'=', value_start:'', value_end:''},
        operators:["=", "!=","<=", ">=", "between", "ilike", 'like', "not between", "not ilike", ],
        rules: {
          field: [
            v => !!v || 'Campo Requerido',
          ],
          value_start: [
            v => !!v || 'Campo Requerido',
          ],
          value_end: [
            v => !!v || 'Campo Requerido',
          ],
          serial: [
            v => !!v || 'Campo Requerido',
            v => v.length < 50 || 'Máximo 50 letras',
          ],
          equipment_type_id: [
            v => !!v || 'Campo Requerido',
          ],
        },
      }
    },

    methods: {

      changeField(){
        var _this2 = this
        if (Array.isArray(this.fieldFilters)) {
          this.fieldFilters.forEach(function(field){
            if(_this2.editedItem.field.field == field.field ){
              _this2.editedItem.field = Object.assign({}, field)
            }
          })
        }
        this.editedItem.value_start = ''
        this.editedItem.value_end = ''
      },

      fechaHoy(){
        return new Date().toISOString().substr(0, 7)
      },

      close () {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItem.field = Object.assign({}, this.defaultItem.field)
        this.editedIndex = -1
        this.dialog = false
      },

      newFilter() {
        this.dialog = true
      },

      save() {
        var valid = true
        if(this.editedItem.field.field==""){
          valid = false
          this.$root.$alert({text:"El Tipo es Necesario",color:"error",timeout:2000})
          document.getElementById("field").focus()
        }else if(this.editedItem.value_start==""){
          valid = false
          this.$root.$alert({text:"El valor es Necesario",color:"error",timeout:2000})
          document.getElementById("value_start").focus()
        }
        if(valid){
          if (this.editedIndex > -1) {
            Object.assign(this.filters[this.editedIndex], this.editedItem)
          }else{
            this.filters.push(this.editedItem)
          }
          this.close()
          this.$emit('freshFilters')
        }
      },

      editFilter(item){
        this.editedIndex = this.filters.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      delFilter(item){
        var index
        index = this.filters.indexOf(item)
        this.filters.splice(index, 1)
        this.$emit('freshFilters')
      },

      initialize(){
        this.$emit('freshFilters')
      }
    }
  }
</script>
