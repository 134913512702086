<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px" scrollable persistent>
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>
            Préstamos Pendientes
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                X
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row align="center">
              <v-col cols="4" align="center">
                <span class="font-weight-bold">Grupo</span>
              </v-col>
              <v-col cols="8" align="center">
                <v-autocomplete
                  v-model="report.groups"
                  item-text="name"
                  item-value="id"
                  :items="groups"
                  chips
                  label="Grupo(s)"
                  multiple
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
          <v-btn color="primary darken-1" outlined @click="save">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { URL_APP } from "../../../util/constants";
  import { createLinkFile } from '../../../util/functions';
  export default{
    name: 'LoanPending',
    props: {
      dialog: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        equipment:[],
        groups:[],
        report:{equipment:[], groups:[]}
      }
    },

    computed: {
    },

    watch: {
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
        this.$http.get(URL_APP+"/api/loan/selgrouptics")
        .then(response => {
          this.groups = response.data
          this.loading = false
        }).catch(error => {
          this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
          this.loading = false
        });
      },

      close () {
        this.$emit('close')
      },

      save () {
        var url = URL_APP+"/api/loan/loanpending/" + JSON.stringify(this.report);
        this.$root.$loadApp(true)
        this.$http.get(url, {responseType: 'blob'}).then(response => {
          createLinkFile(response)
          //this.$root.$alert({text:"PDF Generado con Éxito",color:"success",timeout:1000})
          this.$root.$loadApp(false)
        }).catch(error => { console.error(error)
          this.$root.$alert({text:"Error al Obtener el Reporte",color:"error",timeout:2000})
          this.$root.$loadApp(false)
        });
      },
    }
  }
</script>
