<template>
    <div>
        <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="1500px" transition="dialog-transition">
            <v-card>
                <v-toolbar dense color="primary" dark>
                    <v-toolbar-title>Devolución de elementos de consumo</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="closeDialog">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-card-text>
                    <v-container fluid mt-3>
                        <v-row>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Identificación: </span>
                                <span class="info--text">{{ editedItem.third.identification }}</span>
                            </v-col>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Nombre: </span>
                                <span class="info--text">{{ editedItem.third.name }}</span>
                            </v-col>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Email: </span>
                                <span class="info--text">{{ editedItem.third.email }}</span>
                            </v-col>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Tipo de Solicitante: </span>
                                <span class="info--text">{{ editedItem.type_borrower }}</span>
                            </v-col>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Datos del Solicitante: </span>
                                <span class="info--text">{{ editedItem.data_borrower }}</span>
                            </v-col>
                            <v-col cols="12" md="6" v-if="editedItem.date" class="my-0 py-0">
                                <span class="font-weight-bold">Fecha de entrega: </span>
                                <span class="info--text">{{ editedItem.date }}</span>
                            </v-col>
                            <v-col cols="12" md="6" v-if="editedItem.user_loan" class="my-0 py-0">
                                <span class="font-weight-bold">Colaborador que entrega: </span>
                                <span class="info--text">{{ editedItem.user_loan.name }}</span>
                            </v-col>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <v-switch v-model="editedItem.agree" label="Información Verificada"
                                    :rules="[v => !!v || 'Debe Aceptar antes de Continuar!']" required
                                    hide-details></v-switch>
                            </v-col>

                        </v-row>
                    </v-container>
                    <v-container fluid mt-2>
                        <v-row>
                            <v-col cols="12">


                                <v-data-table item-key="id" :headers="headers" :items="articles"
                                    show-select single-line v-model="selected" >

                                    <template v-slot:item.data-table-select="{ item }">
                                        <v-checkbox v-if="(!item.returned)" v-model="selected" :value="item"
                                            style="margin:0px;padding:0px" hide-details></v-checkbox>
                                        <v-icon v-else small color="green">fa-check</v-icon>
                                    </template>


                                    <template v-slot:item.units_full_received="{ item }">
                                        <v-edit-dialog v-if="!item.returned" :return-value.sync="item.units_full_received"
                                            large persistent @save="save(item)" @cancel="cancel" @open="open" @close="close"
                                            @keyup.enter="save(item)" v-model="item.units_full_received" save-text="Guardar"
                                            cancel-text="Cancelar">
                                            <div>
                                                {{ item.units_full_received }}
                                            </div>
                                            <template v-slot:input>
                                                <div class="mt-4 text-h6">
                                                    Unidades completas recibidas
                                                </div>
                                                <v-text-field v-model="item.units_full_received" label="Ingrese el valor"
                                                    single-line autofocus></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                        <div v-else>
                                            {{ item.units_full_received }}
                                        </div>
                                    </template>

                                    <template v-slot:item.units_incomplete_received="{ item }">
                                        <v-edit-dialog v-if="!item.returned"
                                            :return-value.sync="item.units_incomplete_received" large persistent
                                            @save="save(item)" @cancel="cancel" @open="open" @close="close"
                                            @keyup.enter="save(item)" v-model="item.units_incomplete_received"
                                            save-text="Guardar" cancel-text="Cancelar">
                                            <div>
                                                {{ item.units_incomplete_received }}
                                            </div>
                                            <template v-slot:input>
                                                <div class="mt-4 text-h6">
                                                    Unidades incompletas recibidas
                                                </div>
                                                <v-text-field v-model="item.units_incomplete_received"
                                                    label="Ingrese el valor" single-line autofocus></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                        <div v-else>
                                            {{ item.units_incomplete_received }}
                                        </div>
                                    </template>

                                    <template v-slot:item.units_consumed="{ item }">
                                        <v-edit-dialog v-if="!item.returned" :return-value.sync="item.units_consumed" large
                                            persistent @save="save(item)" @cancel="cancel" @open="open" @close="close"
                                            @keyup.enter="save(item)" v-model="item.units_consumed" save-text="Guardar"
                                            cancel-text="Cancelar">
                                            <div>
                                                {{ item.units_consumed }}
                                            </div>
                                            <template v-slot:input>
                                                <div class="mt-4 text-h6">
                                                    Unidades consumidas
                                                </div>
                                                <v-text-field v-model="item.units_consumed" label="Ingrese el valor"
                                                    single-line autofocus></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                        <div v-else>
                                            {{ item.units_consumed }}
                                        </div>
                                    </template>

                                    <template v-slot:header.units_full_received="{ header }">
                                        <span>Unidades completas recibidas </span>
                                        <v-icon v-if="header.sortable" small color="orange">mdi-pencil</v-icon>
                                    </template>

                                    <template v-slot:header.units_incomplete_received="{ header }">
                                        <span>Unidades incompletas recibidas </span>
                                        <v-icon v-if="header.sortable" small color="orange">mdi-pencil</v-icon>
                                    </template>

                                    <template v-slot:header.units_consumed="{ header }">
                                        <span>Unidades consumidas </span>
                                        <v-icon v-if="header.sortable" small color="orange">mdi-pencil</v-icon>
                                    </template>


                                </v-data-table>
                            </v-col>
                        </v-row>
                        <v-snackbar v-model="snack" :timeout="5000" :color="snackColor">
                            {{ snackText }}

                            <template v-slot:action="{ attrs }">
                                <v-btn v-bind="attrs" text @click="snack = false">
                                    Cerrar
                                </v-btn>
                            </template>
                        </v-snackbar>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="secondary" @click.stop="closeDialog">Cancelar</v-btn>
                    <v-btn color="primary" @click.stop="saveForm" :disabled="!editedItem.agree">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { can } from '../../../util/functions';

export default {
    name: 'FormReturn',
    props: {
        allInfoArticles: {
            type: Array,
            default: null
        },
    },
    data: () => {
        return {
            dialog: false,
            singleSelect: false,
            search: '',
            snack: false,
            selected: [],
            snackColor: '',
            snackText: '',
            articles: [],
            editedItem: {
                agree: false,
                third: {},
                user_loan: {},
                articles: {}

            },
            headers: [
                { text: 'Nombre del Artículo', value: 'name' },
                { text: 'Unidades completas entregadas', value: 'units_full_sent', align: 'center' },
                { text: 'Unidades completas recibidas', value: 'units_full_received', align: 'center', sortable: true },
                { text: 'Unidades Incompletas', value: 'units_incomplete_sent', align: 'center' },
                { text: 'Unidades Incompletas', value: 'units_incomplete_received', align: 'center', sortable: true },
                { text: 'Unidades Consumidas', value: 'units_consumed', align: 'center', sortable: true },
            ],
        }
    },
    methods: {
        ...mapActions(['chgLoad', 'returnDelivery']),
        can: can,
        save(item) {
            if (isNaN(item.units_full_received) || isNaN(item.units_incomplete_received) || isNaN(item.units_consumed)) {
                this.snack = true;
                this.snackColor = 'error';
                this.snackText = `Error: Se ha registrado valores que no son numéricos en ${item.name}`;
            }

            else if (parseInt(item.units_full_received) < 0 || parseInt(item.units_incomplete_received) < 0 || parseInt(item.units_consumed) < 0) {
                this.snack = true;
                this.snackColor = 'error';
                this.snackText = `Error: Se ha registrado valores negativos en ${item.name}`;
            }

            else {
                this.snack = true;
                this.snackColor = 'success';
                this.snackText = 'Información guardada';
            }

        },

        cancel() {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Cancelado'
        },

        open() {
            this.snack = true
            this.snackColor = 'info'
            this.snackText = 'Edición Abierta'
        },

        close() {
        },

        openDialog(item) {
            this.editedItem = {
                'id': item.id,
                'type_borrower': item.type_borrower,
                'data_borrower': item.data_borrower,
                'date': item.dateRegister,
                'warehouse_origin_id': item.warehouse_origin_id,
                'observations': item.observations,
                third: {
                    'identification': item.user_receive.identification,
                    'name': item.user_receive.name,
                    'email': item.user_receive.email,
                    'phone': item.user_receive.phone,
                    'mobile': item.user_receive.mobile,
                },
                user_loan: {
                    'name': item.user_send.name
                },
                articles: []
            }

            this.articles = item.delivery_details.map(article => {
                const product = this.allInfoArticles.find(product => product.id === article.article_id);

                let returnDetail = (article.delivery_return_details && article.delivery_return_details.length > 0)
                let infoReturnDetail = article.delivery_return_details[0]
                return {
                    name: product.name,
                    units_full_sent: article.units_full_sent,
                    units_full_received: returnDetail ? infoReturnDetail.units_full_received : 0,
                    units_incomplete_sent: article.units_incomplete_sent,
                    units_incomplete_received: returnDetail ? infoReturnDetail.units_incomplete_received : 0,
                    units_consumed: returnDetail ? infoReturnDetail.units_consumed : 0,
                    returned: article.returned,
                    id: article.id,
                    article_id: article.article_id,
                    Key: product.id
                };
            });

            this.dialog = true
        },

        closeDialog() {
            this.editedItem = {};
            this.editedItem.third = {};
            this.editedItem.user_loan = {};
            this.editedItem.articles = [];
            this.articles = [];
            this.selected = [];
            this.dialog = false
        },

        updateSelected(selected) {
            const selectedItems = this.articles.filter(item => selected.includes(item));
            const nonSelectedItems = this.articles.filter(item => !selected.includes(item));
            this.articles = [...selectedItems, ...nonSelectedItems];
        },

        async saveForm() {
            try {
                this.editedItem.returns = [] //Devolución

                if (!this.editedItem.agree) {
                    throw "No ha verificado la información"
                }

                if (!(this.selected.length > 0)) {
                    throw "No ha seleccionado elementos para enviar";
                }

                for (const element of this.selected) {
                    element.units_full_received = element.units_full_received ? element.units_full_received : 0
                    element.units_incomplete_received = element.units_incomplete_received ? element.units_incomplete_received : 0
                    element.units_consumed = element.units_consumed ? element.units_consumed : 0

                    //1: Valor numérico
                    if (isNaN(element.units_full_received)) {
                        throw `El valor en unidades completas para ${element.name} no es numérico`
                    }

                    if (isNaN(element.units_incomplete_received)) {
                        throw `El valor en unidades incompletas para ${element.name} no es numérico`
                    }

                    if (isNaN(element.units_consumed)) {
                        throw `El valor en unidades consumidas para ${element.name} no es numérico`
                    }

                    //2: Valores positivos
                    if (parseInt(element.units_full_received) < 0) {
                        throw `El valor en unidades completas para ${element.name} debe ser mayor o igual a 0`
                    }

                    if (parseInt(element.units_incomplete_received) < 0) {
                        throw `El valor en unidades incompletas para ${element.name} debe ser mayor o igual a 0`
                    }

                    if (parseInt(element.units_consumed) < 0) {
                        throw `El valor en unidades consumidas para ${element.name} debe ser mayor o igual a 0`
                    }

                    //3: Devolución igual a préstamo
                    let unitsLoan = parseInt(element.units_full_sent) + parseInt(element.units_incomplete_sent)
                    let unitsReceived = parseInt(element.units_full_received) + parseInt(element.units_incomplete_received) + parseInt(element.units_consumed);
                    let difference = unitsLoan - unitsReceived

                    if (difference != 0) {
                        throw `Error: El número de elementos devueltos en ${element.name} no es igual a los entregados`
                    }

                    const { name, id, article_id, units_full_sent, units_incomplete_sent, units_full_received, units_incomplete_received, units_consumed } = element;

                    this.editedItem.articles.push({
                        id: id,
                        article_id: article_id,
                        name: name,
                        units_full_sent: units_full_sent,
                        units_incomplete_sent: units_incomplete_sent,
                        units_full_received: units_full_received,
                        units_incomplete_received: units_incomplete_received,
                        units_consumed: units_consumed,
                    });

                    this.$swal({
                        title: "Confirmación",
                        text: "¿Está seguro de guardar la información registrada?",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, deseo guardar',
                        cancelButtonText: 'Cancelar'
                    }).then(async (res) => {
                        if (res.isConfirmed) {
                            this.chgLoad(true);
                            try {
                                var msg = "";
                                msg = await this.returnDelivery(this.editedItem);
                                this.$root.$alert({ text: msg.message, color: "success", timeout: 2000 });
                                await this.$emit('initialize3');
                                this.closeDialog();
                            } catch (error) {
                                this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 5000 });
                                this.editedItem.articles = []
                                this.chgLoad(false);
                            }
                            this.chgLoad(false);
                        }
                    });

                }

            }
            catch (error) {
                this.chgLoad(false);
                this.$root.$alert({ text: error, color: "error", timeout: 5000 })
            }
        }
    },

    computed: {
        ...mapGetters(['loading'])
    },

    watch: {
        // Vigilar los cambios en el array 'selected' y actualizar el orden en 'Articles'
        selected: {
            handler: 'updateSelected',
            deep: true,
        },
    },
}
</script>

<style lang="scss" scoped></style>