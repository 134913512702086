<template>
    <div>
        <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="800px" transition="dialog-transition">
            <v-toolbar dense color="primary" dark>
                <v-toolbar-title>FORMULARIO DE TRASLADO DE ARTICULOS</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="closeItem">
                            <v-icon>fa-times</v-icon>
                        </v-btn>
                    </template>
                    <span>Cerrar</span>
                </v-tooltip>
            </v-toolbar>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "FormTransferArticles",
    data() {
        return {
            dialog: false,
        }
    },
    methods: {
        openItem() {
            this.dialog = true
        },

        closeItem() {
            this.dialog = false
        }
    },
}
</script>

<style lang="scss" scoped></style>