<template>
    <div>
        <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
            <v-card>
                <v-toolbar color="primary" dark>
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                    <v-spacer />
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>

                <v-card-text>
                    <v-form v-model="valid" ref="formSupplier_" @submit.prevent="save">
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field name="name" label="* Nombre del proveedor" id="name"
                                        v-model="editedItem.name" :counter="100" :rules="rules.name"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field name="nit" label="* NIT del proveedor" id="nit" v-model="editedItem.nit"
                                        :counter="50" :rules="rules.nit"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field name="responsible" label="* Nombre del responsable" id="responsible"
                                        v-model="editedItem.responsible" :counter="255"
                                        :rules="rules.responsible"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field name="phone" label="Teléfono del responsable" id="phone"
                                        v-model="editedItem.phone" :counter="50" :rules="rules.phone"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="8">
                                    <v-text-field name="email" label="Correo electrónico del responsable" id="email"
                                        v-model="editedItem.email" :counter="200" :rules="rules.email"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="secondary" @click.stop="close">Cancelar</v-btn>
                    <v-btn color="primary" @click.stop="save">{{ viewMode }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'FormConsumableSuppliers',
    data: () => {
        return {
            dialog: false,
            valid: true,
            editedItem: {},
            rules: {
                name: [
                    (v) => !!v || 'El nombre es requerido',
                    (v) => (v && v.length <= 100) || 'El nombre no puede contener más de 100 carácteres'
                ],
                nit: [
                    (v) => !!v || 'El nit es requerido',
                    (v) => (v && v.length <= 50) || 'El nit no puede contener más de 50 carácteres'
                ],
                responsible: [
                    (v) => !!v || 'El nombre del responsable es requerido',
                    (v) => (v && v.length <= 255) || 'El nombre del responsable no puede contener más de 255 carácteres'
                ],
                phone: [
                (v) => (!v || v.length <= 50) || 'El número de teléfono solo puede contener máximo 50 carácteres'

                ],
                email: [
                    v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Corre electrónico no válido'
                ]
            }
        }
    },
    methods: {
        ...mapActions(['chgLoad', 'saveSupplier', 'updateSupplier']),
        newItem() {
            this.dialog = true
        },

        close() {
            this.dialog = false
            this.editedItem = {}
            this.$refs.formSupplier_.reset()
        },

        editItem(item) {
            this.editedItem = Object.assign({}, item);
            this.dialog = true
        },

        async save() {
            if (this.$refs.formSupplier_.validate()) {
                this.chgLoad(true);
                try {
                    var msg = "";
                    if (this.editedItem.id) {
                        msg = await this.updateSupplier(this.editedItem)
                    }
                    else {
                        msg = await this.saveSupplier(this.editedItem)
                    }

                    this.$root.$alert({ text: msg.message, color: "success", timeout: 2000 });
                    this.close();

                }
                catch (error) {
                    this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 });
                }
                this.chgLoad(false);


            }
            else {
                this.$root.$alert({ text: "Revise los errores", color: "error", timeout: 2000 });
            }
        }
    },

    computed: {
        ...mapGetters(['loading']),
        currentuser() {
            return this.$store.getters.currentuser
        },
        formTitle() {
            return !this.editedItem.id ? 'Nuevo Registro' : 'Editar Registro'
        },
        viewMode() {
            return !this.editedItem.id ? 'Guardar' : 'Editar'
        },
    }
}
</script>

<style lang="scss" scoped></style>