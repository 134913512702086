<template>
  <div>
    <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
      <v-card>
        <v-toolbar color="primary" dense dark>
          Trasladar Equipos
          <v-spacer/>
          <v-btn icon small>
            <v-icon small @click="close">fa-times</v-icon>
          </v-btn>
        </v-toolbar>
          <v-card-text>
            <v-form ref="formImport" v-model="valid">
              <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-file-input accept=".xls,.xlsx" label="Seleccionar Archivo"
                      :rules="rules.fileImport"
                        v-model="formImport.fileImport"></v-file-input>
                    </v-col>
                      <template v-for="(field, index) in equipmentFields">
                        <template v-if="field.column_id=='third_id'">
                          <v-col cols=12 md=6 :key="'col1'+field.id">
                              <v-text-field readonly
                                v-model="third.identification"
                                label="Identificación Responsable"
                                type="text" 
                                clearable
                                @click:clear="setThird(defaultThird)"
                              >
                                <template v-slot:append-outer>
                                  <template>
                                    <v-tooltip
                                      bottom
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="info" @click="$refs.formThird.editItem()">
                                          fa-plus
                                        </v-icon>
                                      </template>
                                      Agregar Responsable
                                    </v-tooltip>
                                  </template>
                                </template>
                                <template v-slot:prepend>
                                  <template>
                                    <v-tooltip
                                      bottom
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="success" @click="$refs.selectThird.show()">
                                          fa-clipboard-list
                                        </v-icon>
                                      </template>
                                      Seleccionar Responsable
                                    </v-tooltip>
                                  </template>
                                </template>
                              </v-text-field>
                          </v-col>
                          <v-col cols=12 md=6 :key="'col2'+field.id">
                            <v-text-field disabled
                              v-model="third.name"
                              label="Nombre" 
                              required 
                              type="text"
                            />
                          </v-col>
                        </template>
                        <v-col cols=12 md=6 v-if="field.column_id=='cost_center_id'" :key="'col3'+field.id">
                          <v-autocomplete
                            v-model="equipmentFields[index].pivot.value" 
                            item-value="id" 
                            item-text="name" 
                            :items="allCostCenters"
                            label="Centro de Costos"
                            placeholder="Seleccione el Centro de Costos"
                            clearable
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols=12 md=6 v-if="field.column_id=='location_id'" :key="'col4'+field.id">
                          <v-autocomplete
                            v-model="equipmentFields[index].pivot.value" 
                            item-value="id" 
                            item-text="name" 
                            :items="allLocations"
                            label="Ubicación"
                            placeholder="Seleccione la Ubicación"
                            clearable
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols=12 md=6 v-if="field.column_id=='campus_id'" :key="'col5'+field.id">
                          <v-autocomplete
                            v-model="equipmentFields[index].pivot.value" 
                            item-value="id" 
                            item-text="name" 
                            :rules="rules.campus_id"
                            :items="allCampuses"
                            label="Campus"
                            placeholder="Seleccione el Campus"
                            required 
                            clearable
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols=12 md=6 v-if="field.column_id=='building_id'" :key="'col6'+field.id">
                          <v-autocomplete clearable
                            v-model="equipmentFields[index].pivot.value" 
                            item-text="name" 
                            item-value="id" 
                            :items="allBuildings"
                            label="Edificio"
                            placeholder="Seleccione el Edificio de Localización"
                          />
                        </v-col>
                        <v-col cols=12 md=6 v-if="field.column_id=='tier_id'" :key="'col7'+field.id">
                          <v-autocomplete clearable
                            v-model="equipmentFields[index].pivot.value" 
                            item-text="name" 
                            item-value="id" 
                            :items="allTiers"
                            label="Piso"
                            placeholder="Seleccione el Piso de Localización"
                          />
                        </v-col>
                        <v-col cols=12 md=6 v-if="field.column_id=='area_id'" :key="'col8'+field.id">
                          <v-autocomplete clearable
                            v-model="equipmentFields[index].pivot.value" 
                            item-text="name" 
                            item-value="id" 
                            :items="allAreas"
                            label="Área"
                            placeholder="Seleccione el Área de Localización"
                          />
                        </v-col>
                      </template>
                    <v-col>
                        <v-textarea
                          required
                          label="Observación"
                          v-model="formImport.observation"
                          :rules="rules.observation"
                        /> 
                    </v-col>
                  </v-row>
                </v-container>                
              </v-form>
            </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn outlined color="secondary darken-1" @click="close">Cancelar</v-btn>
            <v-btn outlined color="primary darken-1" @click="save">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <SelectThird ref="selectThird" @setThird="setThird"/>
    <FormThird ref="formThird" />
    <FormErrors ref="formErrors_" :errors="errors"/>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import { URL_APP } from "../../../util/constants";
  import { objectToFormData } from "../../../util/functions"
  import SelectThird from '../../general/Third/SelectThird'
  import FormThird from '../../general/Third/FormThird'
  import FormErrors from './FormErrors'
  
  export default{
    name: 'ImportTransfer',
    components: {
      SelectThird, FormThird, FormErrors
    },
    props: {
      isgroup: {
        type: Boolean,
        default: true
      },
    },
    data() {
      return {
        dialog: false,
        rules: {
          fileImport: [
            v => {
              if (v) {
                return true
              } else {
                return 'Campo requerido'
              }
            },
            v => !v || v.size < 10000000 || 'No puede Subir Archivos mayor a 10 MB!'
          ],
          observation: [
            v => !!v || 'Campo Requerido',
          ],
        },
        indexThird: -1,
        errors:[],
        equipmentFields: [],
        third:{id:null, identification:'', name:''},
        defaultThird:{id:0, identification:'', name:''},
        editedItem: { details: [], fields: [], old_group: {}, new_group:{}, id: 0, new_group_tic_id: null,
          observation: "", old_group_tic_id: null, user: {}, user_id: 1 },
        defaultItem: { details: [], fields: [], old_group: {}, new_group:{}, id: 0, new_group_tic_id: null,
          observation: "", old_group_tic_id: null, user: {}, user_id: 1 },
        formImport: {fileImport:null, observation:''},
        formDefault: {fileImport:null, observation:''},
        valid: true
      }
    },

    computed: {
      ...mapGetters(['allManufacturers','lastManufacturer','loading', 'allEquipmentFields',
          'allEquipmentTypes', 'allCampuses', 'allBuildings', 'allTiers',
          'allAreas', 'allCostCenters', 'allLocations']),

      currentUser(){
        return this.$store.getters.currentUser
      }
    },
    mounted () {
      this.initialize()
      var this_2 = this
      window.addEventListener('keydown', function(e) {
        if (e.altKey == true && e.code.includes('KeyS')){
          this_2.save()
        }
      });
    },
    watch:{
      allEquipmentFields(){
        this.initialize()
      }
    },
    methods: {
      ...mapActions(['chgLoad']),

      initialize () {
        this.equipmentFields = []
        this.indexThird = -1
        var i = 0
        this.allEquipmentFields.forEach(field =>{
          if(field.column_id=='third_id')
            this.indexThird = i
          field.pivot = {value: ""}
          this.equipmentFields.push(field)
          i++
        })
      },

      async save(){
        this.$refs.formImport.validate()
        if(!this.loading){
          if(this.valid){
            this.formImport.fields = this.equipmentFields.filter(e=>e.pivot.value)
            var formData = objectToFormData(this.formImport)
            if(this.formImport.fields.length>0){
              this.chgLoad(true)
              try{
                var response = await this.$http.post(URL_APP+"/api/loan/import_transfer", formData)
                this.$root.$alert({text:response.data.message,color:"success",timeout:3000})
                this.chgLoad(false)
                this.close()
                await this.$emit('initialize')
              }catch(error){
                this.chgLoad(false)
                console.log(error.response)
                this.errors = error.response.data.errors
                this.$refs.formErrors_.show()
                this.$root.$alert({text:"Ocurrió un error al trasladar los equipos",color:"error",timeout:3000})
              }
            }else{
                this.$root.$alert({text:"Debe realizar al menos un cambio",color:"error",timeout:2000})
            }
          }else{
            this.$root.$alert({text:"Por favor Revise los campos obligatorios",color:"error",timeout:2000})
          }
        }
      },

      setThird(third){
        this.third = third
        this.equipmentFields[this.indexThird].pivot.value = third.id
      },

      open(){
        this.dialog = true
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.formImport = this.formDefault
        }, 300)
      },


    }
  }
</script>
