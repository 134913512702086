<template>
  <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>
          Devolución de Préstamo
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
              X
            </v-btn>
          </template>
          <span>Cerrar</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>
        <v-container fluid dense>
          <v-row dense>
            <v-col cols="12" md="6">
              <span class="font-weight-bold">Identificación: </span>
              <span class="info--text">{{editedLoan.third.identification}}</span>
            </v-col>
            <v-col cols="12" md="6">
              <span class="font-weight-bold">Nombre: </span>
              <span class="info--text">{{editedLoan.third.name}}</span>
            </v-col>
            <v-col cols="12" md="6">
              <span class="font-weight-bold">Tipo de Solicitante: </span>
              <span class="info--text">{{editedLoan.type_borrower}}</span>
            </v-col>
            <v-col cols="12" md="6">
              <span class="font-weight-bold">Datos del Solicitante: </span>
              <span class="info--text">{{editedLoan.data_borrower}}</span>
            </v-col>
            <v-col cols="12" md="6">
              <span class="font-weight-bold">Fecha Préstamo: </span>
              <span class="info--text">{{editedLoan.date}}</span>
            </v-col>
            <v-col cols="12" md="6">
              <span class="font-weight-bold">Colaborador Préstamo: </span>
              <span class="info--text">{{editedLoan.user_loan.name}}</span>
            </v-col>
            <v-col cols="12" md="6">
              <span class="font-weight-bold">Lugar: </span>
              <span class="info--text">{{editedLoan.place.name}} - {{editedLoan.place.location}} - {{editedLoan.place.campus.name}}</span>
            </v-col>
            <v-col cols="12" md="6" dense>
              <v-switch v-model="loadReturn.agree" label="Información Verificada"
                :rules="[v => !!v || 'Debe Aceptar antes de Continuar!']"
                required dense hide-details
              ></v-switch>
            </v-col>
            <v-col cols="12" v-if="editedLoan.type=='Equipo'">
              <v-data-table item-key="pivot.id"
                :headers="headers"
                :items="editedLoan.equipment"
                sort-by="description"
                hide-default-footer
                show-select
                v-model="loadReturn.equipment"
                :items-per-page="50"
              >
              <template v-slot:item.data-table-select="{ item }">
                <v-checkbox v-if="(item.pivot.quantity-item.pivot.quantity_return)>0" v-model="loadReturn.equipment" :value="item" style="margin:0px;padding:0px"
                  hide-details></v-checkbox>
                <v-icon v-else small color="green">fa-check</v-icon>
              </template>

              <template v-slot:item.quantity_r="props">
                <v-edit-dialog v-if="!props.item.serial"
                  :return-value.sync="props.item.quantity_r"
                > {{ props.item.quantity_r }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.quantity_r"
                      label="Cantidad"
                      single-line
                      counter
                      type="number"
                    ></v-text-field>
                  </template>
      
                </v-edit-dialog>
                <template v-else>{{ props.item.quantity_r }}</template>
              </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" v-if="editedLoan.observation">
              <span class="font-weight-bold">Observación Préstamo: </span>
              <span class="info--text">{{editedLoan.observation}}</span>
            </v-col>
            <v-col cols="12">
              <v-textarea outlined
                name="observation_return"
                id="observation_return"
                label="Observación Devolución"
                v-model="loadReturn.observation" 
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
        <v-btn color="primary darken-1" outlined @click="save" :disabled="!loadReturn.agree">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default{
    name: 'LoanReturn',
    props: {
      editedLoan: {
        type: Object,
        default: null
      },
      dialog: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        headers: [
            { text: 'Descripción', value: 'description' },
            { text: 'Grupo', value: 'name' },
            { text: 'Serial', value: 'serial' },
            { text: 'Cantidad', value: 'quantity_r' },
            { text: 'Tipo', value: 'type.name' },
        ],
        loadReturn: {loan: 0, agree: false, observation:'', equipment:[]},
        defaultReturn: {loan: 0, agree: false, observation:'', equipment:[]},
      }
    },

    methods: {

      close () {
        this.$emit('closeReturn')
        this.agree = false
      },

      save() {
        if(this.loadReturn.equipment.length > 0 || this.editedLoan.type=="Lugar"){
          this.loadReturn.loan = this.editedLoan.id
          this.$emit('saveReturn',this.loadReturn)
          this.loadReturn = Object.assign({}, this.defaultReturn)
          this.loadReturn.equipment = Array()
        }else{
          this.$root.$alert({text:"Debe Seleccionar los equipos a devolver",color:"error",timeout:2000})
        }
      },
    }
  }
</script>
