import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance;
const URL_ = URL_APP + "/api/loan_consumables/delivery";

const state = {
  consumables_deliverys: [],
  consumables_deliverys_T: 0,
};
const getters = {
  allConsumablesDeliverys: (state) => state.consumables_deliverys,
  totalConsumablesDeliverys: (state) => state.consumables_deliverys_T,
};
const actions = {
  //! Obtener entregas
  async getConsumablesDeliverys({ commit }, params) {
    return new Promise(function(resolve, reject) {
      commit("setConsumableDelivery", []);
      axios
        .get(URL_ + "?page=" + params.options.page, { params })
        .then((res) => {
          commit("setConsumableDelivery", res.data.data);
          commit("setConsumableDeliveryT", res.data.total);
          resolve("Ok");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  //! Guardar entrega
  async saveDelivery({ commit }, delivery) {
    return new Promise((resolve, reject) => {
      axios
        .post(URL_, delivery)
        .then((res) => {
          commit("newDelivery", res.data.delivery);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  //! Guardar retorno
  async returnDelivery({ commit }, delivery) {
    return new Promise((resolve, reject) => {
      axios
        .put(URL_, delivery)
        .then((res) => {
          commit("returnDelivery", res.data.delivery);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  setConsumableDelivery: (state, consumables_deliverys) =>
    (state.consumables_deliverys = consumables_deliverys),
  setConsumableDeliveryT: (state, total) =>
    (state.consumables_deliverys_T = total),
  newDelivery: (state, delivery) => {
    state.consumables_deliverys.unshift(delivery);
    state.consumables_deliverys_T = state.consumables_deliverys_T + 1;
  },
  returnDelivery: (state, delivery) => {
    const index = state.consumables_deliverys.findIndex(
      (u) => u.id === delivery.id
    );
    if (index !== -1) {
      state.consumables_deliverys.splice(index, 1, delivery);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
