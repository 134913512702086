// sweetAlertUtil.js
import Swal from "sweetalert2";

export const mostrarSweetAlert = ({
  titulo,
  html,
  texto,
  icono,
  textoBotonConfirmar,
  textoBotonCancelar,
  colorBotonConfirmar,
  colorBotonCancelar,
} = {}) => {
  return Swal.fire({
    title: titulo || "¡Hola!",
    html: html || "",
    text: texto || "",
    icon: icono || "success",
    showCancelButton: true,
    confirmButtonText: textoBotonConfirmar || "Confirmar",
    cancelButtonText: textoBotonCancelar || "Cancelar",
    confirmButtonColor: colorBotonConfirmar || "#3F51B5",
    cancelButtonColor: colorBotonCancelar || "#f6ba2a",
  });
};
