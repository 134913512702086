<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Archivos</span>
      </v-card-title>
      <v-card-text>
        <FilterQuery :filters="filters" :fieldFilters="fieldFilters" @freshFilters="initialize2(1)" id="filterQuery"></FilterQuery>
        <v-data-table
          :headers="headers"
          :items="allInventoryFiles"
          :search="search"
          :loading="loading"
          :options.sync="options"
          :sort-by.sync="options.sortBy"
          :sort-desc.sync="options.sortDesc"
          :items-per-page.sync="options.per_page"
          :server-items-length="totalInventoryFiles"
          multi-sort
          loading-text="Cargando Registros ..."
          class="elevation-1"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'fa-angle-double-left',
            lastIcon: 'fa-angle-double-right',
            prevIcon: 'fa-angle-left',
            nextIcon: 'fa-angle-right'
          }"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-text-field
                v-model="search"
                append-icon="fa-search"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-tooltip bottom v-if="can('file.create')">
                <template v-slot:activator="{ on }">
                  <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="$refs.formInventoryFile_.editItem()">
                    <v-icon dark>fa-plus</v-icon>
                  </v-btn>
                </template>
                <span>Nuevo</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <template v-slot:item.permissions="{ item }">
            <v-chip class="mt-1 ml-1" color="secondary" v-for="(permission, indexPer) in item.permissions" :key="indexPer" dark>{{ permission.name }}</v-chip>
          </template>
          <template v-slot:item.signatures="{ item }">
            <v-chip class="mt-1 ml-1" color="secondary" v-for="(signature, indexSig) in item.signatures" :key="indexSig" dark>{{ signature.name }}</v-chip>
          </template>
          <template v-slot:item.action="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon @click="$refs.formInventoryFile_.editItem(item)" v-on="on" class="mr-1 my-1" color="info">fa-pen</v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon @click="downloadFile(item)" v-on="on" class="mr-1 my-1" color="secondary">fa-download</v-icon>
              </template>
              <span>Decargar</span>
            </v-tooltip>
            <v-tooltip bottom v-if="can('file.delete')">
              <template v-slot:activator="{ on }">
                <v-icon @click="deleteItem(item)" v-on="on" class="mr-1 my-1" color="error">fa-trash</v-icon>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn text icon color="green" dark @click="initialize()" v-on="on"><v-icon dark v-on="on">fa-sync-alt</v-icon></v-btn>
              </template>
              <span>Actualizar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <FormInventoryFile ref="formInventoryFile_"/>
  </div>
</template>
<script>
  import { can, createLinkFile } from '../../../util/functions';
  import { mapActions, mapGetters } from 'vuex'
  import FormInventoryFile from './FormInventoryFile'
  import FilterQuery from '../../general/FilterQuery';
  import { URL_APP } from "../../../util/constants";

  export default{
    name: 'InventoryFile',
    components: { FormInventoryFile, FilterQuery },
    data() {
      return {
        search: '',
        headers: [
          { text: 'Fecha', value: 'created_at' },
          { text: 'Tipo', value: 'type.name' },
          { text: 'Público', value: 'is_public' },
          { text: 'Firmas', value: 'signatures' },
          { text: 'Permisos', value: 'permissions' },
          { text: 'Acciones', value: 'action', sortable: false },
        ],
        options: {page:1, per_page:10, sortBy:['created_at'], sortDesc:[true]},
        filters:[],
        fieldFilters:[
          {field:'loan_file.created_at', name:'Fecha Subida', type:'date'},
          {field:'loan_file.is_public', name:'Público', type:'select', options:[true, false]},
          {field:'type_file.name', name:'Nombre Tipo de Archivo', type:'text'},
          {field:'third.identification', name:'Identificación Firma', type:'text'},
          {field:'third.name', name:'Nombre de Firma', type:'text'},
          {field:'permission.identity', name:'Identificación Permisos', type:'text'},
          {field:'permission.name', name:'Nombre Permisos', type:'text'},
          {field:'user.identity', name:'Identificación Usuario Grabación', type:'text'},
          {field:'user.name', name:'Nombre Usuario Grabación', type:'text'},
        ],
      }
    },

    computed: {
      ...mapGetters(['loading','allInventoryFiles', 'totalInventoryFiles']),
      formTitle () {
        return !this.editedItem.id ? 'Nuevo Registro' : 'Editar Registro'
      },
      viewMode () {
        return !this.editedItem.id ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      ...mapActions(['getInventoryFiles', 'getFileTypes', 'deleteInventoryFile', 'chgLoad']),
      can:can,

      async initialize () {
        this.chgLoad(true)
        this.getFileTypes()
        var params = {filters : this.filters, options : this.options, search : this.search}
        try{
          await this.getInventoryFiles(params)
        }catch(error){ console.error(error)
          this.$root.$alert({text:"Error al Obtener los Archivos",color:"error",timeout:2000})
        }
        this.chgLoad(false)
      },
      async initialize2 () {
        this.chgLoad(true)
        var params = {filters : this.filters, options : this.options, search : this.search}
        try{
          await this.getInventoryFiles(params)
        }catch(error){ console.error(error)
          this.$root.$alert({text:"Error al Obtener los Archivos",color:"error",timeout:2000})
        }
        this.chgLoad(false)
      },

      stateItem (stateIt) {
        return stateIt ? 'Activo' : 'Inactivo'
      },

      async downloadFile(item){
        this.chgLoad(true)
        try{
          var response = await this.$http.get(URL_APP+"/api/inventory_file/"+item.id, {responseType: 'blob'})
          createLinkFile(response, "file.pdf")
          this.$root.$alert({text:"Archivo Generado Exitosamente",color:"success",timeout:2000})
        }catch(error){
          this.$root.$alert({text:"Error al Descargar el Archivo",color:"error",timeout:2000})
        }
        this.chgLoad(false)
      },

      deleteItem (item) {
        this.$root.$confirm('Eliminar Archivo', 'Esta Seguro?', { color: 'red' })
        .then(async (res) => {
          if(res){
            this.chgLoad(true)
            try{
              var msg = await this.deleteInventoryFile(item)
              this.$root.$alert({text:msg.message,color:"success",timeout:3000})
            }catch(error){
              console.error(error)
              this.$root.$alert({text:"Imposible Eliminar Registro",color:"error",timeout:3000})
            }
            this.chgLoad(false)
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

    }
  }
</script>
