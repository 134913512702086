import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP + "/api/loan_consumables/shelfs"

const state = {
    shelfs: []
}

const getters = {
    allShelfs: state => state.shelfs
}

const actions= {
    async getShelfs({commit}){
        return new Promise(function(resolve, reject){
            commit('setShelfs', [])
            axios.get(URL_). then(res => {
                commit('setShelfs', res.data)
                resolve("OK")
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async getNameShelfs({commit}){
        return new Promise(function(resolve, reject){
            commit('setShelfs', [])
            axios.get(URL_APP + '/api/loan_consumables/getNameShelfs'). then(res => {
                commit('setShelfs', res.data)
                resolve("OK")
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    async saveShelf({commit}, shelf){
        return new Promise ((resolve, reject) => {
            axios.post(URL_, shelf)
            .then((res) => {
                commit('newShelf', res.data.shelf)
                resolve(res.data)   
            })
            .catch((err) => {
                reject(err)
            })
        })
    },

    async updateShelf({commit}, shelf){
        return new Promise ((resolve, reject) => {
            axios.put(URL_, shelf)
            .then((res) => {
                commit('updateShelf', res.data.shelf)
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
        })
    },

    async deleteShelf({commit}, shelf){
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_}/${shelf}`)
            .then((res) => {
                commit('deleteShelf', shelf)
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
        })
    }
}

const mutations = {
    setShelfs : (state, shelfs) => state.shelfs = shelfs,
    newShelf : (state, shelf) => state.shelfs.unshift(shelf),
    updateShelf : (state, shelf) => {
        const index = state.shelfs.findIndex(u => u.id === shelf.id)
        if(index !== -1){
            state.shelfs.splice(index, 1, shelf)
        }
    },
    deleteShelf : (state, shelf) => state.shelfs = state.shelfs.filter(c => c.id !== shelf.id)


}

export default{
    state, getters, actions, mutations
}