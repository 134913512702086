<template>
    <div>
        <v-card>
            <v-card-title primary-title>
                Entrega de consumibles
            </v-card-title>
            <v-card-text>
                <FilterQuery :filters="filters" :fieldFilters="fieldFilters" @freshFilters="initialize2(1)"
                    id="filterQuery"></FilterQuery>
                <v-data-table :headers="headers" item-key="id" :items="allConsumablesDeliverys" :search="search"
                    :options.sync="options" :sort-by="options.sortBy" :sort-desc.sync="options.sortDesc"
                    :items-per-page.sync="options.per_page" :server-items-length="totalConsumablesDeliverys" multi-sort
                    :loading="loading" loading-text="Cargando..." :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'fa-angle-double-left',
                        lastIcon: 'fa-angle-double-right',
                        prevIcon: 'fa-angle-left',
                        nextIcon: 'fa-angle-right'
                    }">
                    <template v-slot:top>
                        <v-toolbar color="white">
                            <v-text-field v-model="search" prepend-icon="fa-search" label="Buscar..." clearable single-line
                                hide-details @keypress.enter="initialize2(1)" @click:clear="initialize2(1)"> </v-text-field>
                            <v-spacer></v-spacer>
                            <v-tooltip bottom v-if="can('consumables.delivery-admin')">
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn v-on="{ ...tooltip }" small color="primary" class="mx-2 my-2" fab dark
                                        @click.stop="$event => $refs.formDelivery_.openDialog()">

                                        <v-icon>fa-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>Nuevo</span>
                            </v-tooltip>
                        </v-toolbar>
                    </template>

                    <!-- <template v-slot:item.actions="{ item }"> -->
                    <template v-slot:item.actions="{ item }">

                        <v-tooltip left v-if="can('consumables.delivery-list')">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2" color="blue"
                                    @click="$refs.viewDelivery_.openDialog(item)">
                                    fa-eye
                                </v-icon>
                            </template>
                            <span>Ver</span>
                        </v-tooltip>

                        <v-tooltip right v-if="can('consumables.delivery-admin') && item.state == 'Entregado'">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2" color="orange"
                                    @click="$refs.formReturn_.openDialog(item)">
                                    fa-reply
                                </v-icon>
                            </template>
                            <span>Devolución</span>
                        </v-tooltip>
                    </template>

                </v-data-table>
            </v-card-text>
        </v-card>
        <FormDelivery ref="formDelivery_" :allMyWarehouses="allMyWarehouses" :allInfoArticles="allInfoArticles"
            :allArticlesLocation="allArticlesLocation" @initialize3="initialize3" />
        <ViewDelivery ref="viewDelivery_" :allInfoArticles="allInfoArticles" />
        <formReturn ref="formReturn_" :allInfoArticles="allInfoArticles" @initialize3="initialize3" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FilterQuery from '../../general/FilterQuery.vue';
import FormDelivery from './formDelivery.vue';
import ViewDelivery from './viewDelivery.vue';
import formReturn from './formReturn.vue';
import { can } from '../../../util/functions';

export default {
    name: 'DeliveryConsumables',
    components: {
        FilterQuery,
        FormDelivery,
        ViewDelivery,
        formReturn
    },
    data: () => {
        return {
            headers: [
                {
                    text: 'ID',
                    align: 'start',
                    value: 'id'
                },
                { text: 'Entregado por', value: 'user_send.name' },
                { text: 'Identificación solicitante', value: 'user_receive.identification', sortable: false },
                { text: 'Nombre del solicitante', value: 'user_receive.name', sortable: false },

                { text: 'Fecha de entrega', value: 'dateRegister', sortable: false },
                { text: 'Estado', value: 'state', sortable: false },
                { text: 'Acciones', value: 'actions', sortable: false }

            ],
            items: [],
            search: '',
            loading: false,
            editedIndex: -1,
            options: { page: 1, per_page: 10, sortBy: ['id'], sortDesc: [true] },
            filters: [
                { field: { field: 'state', name: 'Estado', type: 'select', options: ['Entregado', 'Devuelto'] }, operator: '=', value_start: 'Entregado', value_end: '' }
            ],
            fieldFilters: [
                { field: 'state', name: 'Estado', type: 'select', options: ['Entregado', 'Devuelto'] },
                { field: 'created_at', name: 'Fecha de entrega', type: 'date' },
            ],

        }
    },
    methods: {
        ...mapActions(['chgLoad', 'getMyWarehouses', 'getAllInfoArticles', 'getArticlesLocation', 'getConsumablesDeliverys']),
        can: can,

        async initialize() {
            this.chgLoad(true)
            this.getMyWarehouses()
            this.getAllInfoArticles()
            this.getArticlesLocation()
            let params = { filters: this.filters, options: this.options, search: this.search }
            try {
                await this.getConsumablesDeliverys(params)
            }
            catch (error) {
                this.$root.$alert({ text: "Error al obtener las entregas", color: "error", timeout: 2000 })
            }
            this.chgLoad(false)
        },

        async initialize2(page = null) {
            if (!this.loading) {
                if (page != null) this.options.page = page
                this.chgLoad(true);
                this.getMyWarehouses();
                this.getAllInfoArticles();
                this.getArticlesLocation();
                var params = { filters: this.filters, options: this.options, search: this.search }
                try {
                    await this.getConsumablesDeliverys(params)
                }
                catch (error) {
                    this.$root.$alert({ text: "Error al Obtener las entregas", color: "error", timeout: 2000 })
                }
                this.chgLoad(false);
                this.loading = false;
            }
            else {
                this.chgLoad(false);
            }

            this.chgLoad(false);
        },



        async initialize3() {
            this.getArticlesLocation();
        }
    },
    computed: {
        ...mapGetters(['allMyWarehouses', 'allInfoArticles', 'allArticlesLocation', 'allConsumablesDeliverys', 'totalConsumablesDeliverys'])

    },
    mounted() {
        this.initialize()
        var this_2 = this
        window.addEventListener('keydown', function (e) {
            if (e.altKey == true && e.code == 'KeyN') {
                this_2.$refs.formDelivery_.newItem()
            }
        });
    },
    watch: {
        'options.page'() {
            this.initialize2()
        },
        'options.per_page'() {
            this.initialize2()
        },
        'options.sortBy'() {
            this.initialize2()
        },
        'options.sortDesc'() {
            this.initialize2()
        },
    },

}
</script>

<style lang="scss" scoped></style>