<template>
  <div>
    <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>
            Equipos del Grupo {{ editedItem.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                X
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-card class="mx-2 my-2">
            <v-data-table 
              :headers="headers"
              :items="editedItem.equipment"
              :search="search2"
              sort-by="description"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'fa-angle-double-left',
                lastIcon: 'fa-angle-double-right',
                prevIcon: 'fa-angle-left',
                nextIcon: 'fa-angle-right'
              }"
            >
              <template v-slot:top>
                <v-toolbar color="white">
                  <v-toolbar-title>Equipos</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>
                  <v-text-field autofocus
                    v-model="search2"
                    append-icon="fa-search"
                    label="Buscar"
                    id="equipment_id2"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <template v-if="can('group_tic-create')">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="newEquipment" id="newEquipment">
                          <v-icon small dark>fa-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Nuevo</span>
                    </v-tooltip>
                  </template>
                  <v-dialog v-model="dialog2" max-width="1000px" scrollable persistent>
                    <v-card>
                      <v-toolbar dark color="primary" dense>
                        <v-toolbar-title>
                          {{formTitle}}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close2">
                              X
                            </v-btn>
                          </template>
                          <span>Cerrar</span>
                        </v-tooltip>
                      </v-toolbar>

                      <v-card-text>
                        <v-text-field autofocus
                          v-model="search"
                          append-icon="fa-search"
                          label="Buscar"
                          id="equipment_id"
                          single-line
                          hide-details
                          @click:row="addItem"
                        ></v-text-field>
                        <v-data-table 
                          :headers="header2"
                          :items="equipment"
                          :search="search"
                          sort-by="description"
                          :footer-props="{
                            showFirstLastPage: true,
                            firstIcon: 'fa-angle-double-left',
                            lastIcon: 'fa-angle-double-right',
                            prevIcon: 'fa-angle-left',
                            nextIcon: 'fa-angle-right'
                          }"
                        >
                        <template v-slot:item="{ item }">
                            <tr @click="addItem(item)">
                              <td>{{ item.id }}</td>
                              <td>{{ item.description }}</td>
                              <td>{{ item.serial }}</td>
                              <td>{{ item.inventory }}</td>
                              <td>{{ item.type.name }}</td>
                            </tr>
                        </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              
              <template v-slot:item.action="{ item }">
                <!--<v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon small @click="editItem(item)" v-on="on" class="mr-1" color="info">fa-pen</v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>-->
                <v-tooltip bottom v-if="item.state=='Activo' || !can('group_tic-create')">
                  <template v-slot:activator="{ on }">
                    <v-icon small @click="deleteItem(item)" v-on="on" class="mr-1" color="error">fa-trash</v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </template>
              <template v-slot:item.pivot.stock="props"> 
                <template v-if="props.item.serial || props.item.inventory || !can('group_tic-create')">
                  {{ props.item.pivot.stock }}
                </template>
                <v-edit-dialog v-else
                  :return-value.sync="props.item.pivot.stock"
                > {{ props.item.pivot.stock }}
                  <template v-slot:input>
                    <v-text-field
                      v-model.number="props.item.pivot.stock"
                      label="Existencias"
                      single-line
                      counter
                      type="number"
                      @focus="addFocus"
                      @change="verifyChange(props.item)"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
          <v-btn color="primary darken-1" outlined @click="save" :disabled="!can('group_tic-create')">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { can } from '../../util/functions';
  import { URL_APP } from "../../util/constants";
  export default{
    name: 'SelectEquipment',
    props: {
      editedItem: {
        type: Object,
        default: null
      },
      dialog: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        search:'',
        editedIndex: -1,
        search2:'',
        dialog2: false,
        dialogStock: false,
        headers: [
            { text: 'Tipo', value: 'type.name' },
            { text: 'Descripción', value: 'description' },
            { text: 'Serial', value: 'serial' },
            { text: 'Inventario', value: 'inventory' },
            { text: 'Cantidad', value: 'pivot.stock'},
            { text: 'Acciones', value: 'action'}
        ],
        header2: [
            { text: 'Id', value: 'id' },
            { text: 'Descripción', value: 'description' },
            { text: 'Serial', value: 'serial' },
            { text: 'Inventario', value: 'inventory' },
            { text: 'Tipo', value: 'type.name' },
        ],
        loading: false,
        bchanges: false,
        equipment: [],
        selected: [],
        equip: {id:0, description:'', serial:'', inventory:'', type:{name:''}, pivot:{group_tic_id: 0, equipment_id: 0, stock: 1, borrowed: 0}},
        defaultEquip: {id:0, description:'', serial:'', inventory:'', type:{name:''}, pivot:{group_tic_id: 0, equipment_id: 0, stock: 1, borrowed: 0}},
        stockIndex: -1,
        stockItem: {pivot:{stock:0,borrowed:0}},
        defaultPivot: {group_tic_id: 0, equipment_id: 0, stock: 1, borrowed: 0},
        newPivot: {group_tic_id: 0, equipment_id: 0, stock: 1, borrowed: 0},
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Equipo' : 'Editar Equipo'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      editedItem (val) {
        return val
      }
    },
    mounted () {
      //this.initialize()
    },
    methods: {
      can:can,
      initialize () {
        this.loading = true
        this.equipment = []
        this.$http.get(URL_APP+"/api/loan/selectequipment?campus="+this.editedItem.campus_id+"&group_id="+this.editedItem.id)
        .then(response => {
          this.equipment = response.data
          this.loading = false
        }).catch(error => {
          this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
          this.loading = false
        });
      },

      addFocus (evt) {
        this.valueChanging = parseInt(evt.target.value)
      },

      verifyChange (item) {
        if(item.created_at && this.valueChanging > parseInt(item.pivot.stock)){
          this.bchanges = true
        }
      },

      newEquipment(){
        this.dialog2 = true
        setTimeout(function(){ document.getElementById('equipment_id').focus(); }, 500);
      },

      close () {
        this.editedIndex = -1
        this.bchanges = false
        this.$emit('close')
      },

      close2 () {
        this.editedIndex = -1
        this.dialog2 = false
      },

      addItem(item){
        var valid = true
        var _this2 = this
        if(item.id==''){
          valid = false
          this.$root.$alert({text:"Equipo Obligatorio",color:"error",timeout:2000})
        }else{
          //Revisar si ya existe el equipo
          if (Array.isArray(this.editedItem.equipment)) {
            this.editedItem.equipment.forEach(function(equipment){
              if(item.id == equipment.id){
                _this2.$root.$alert({text:"Equipo ya Existe",color:"error",timeout:2000})
                valid = false
              }
            })
          }
        }
        if(valid){
          this.equip.id = item.id
          this.equip.description = item.description
          this.equip.serial = item.serial
          this.equip.inventory = item.inventory
          this.equip.type.name = item.type.name
          this.equip.pivot.group_tic_id = this.editedItem.id
          this.equip.pivot.equipment_id = item.id
          this.equip.pivot.stock = 1
          this.equip.pivot.borrowed = 0
          this.editedItem.equipment.push(this.equip)
          this.equip = Object.assign({}, this.defaultEquip)
          this.equip.pivot = Object.assign({}, this.defaultPivot)
          //this.$emit('addEquipment', this.equip)
          this.$root.$alert({text:"Añadido Correctamente",color:"success",timeout:1000})
          this.close2()
        }
      },

      saveItem(){
        this.newPivot = Object.assign({}, this.defaultPivot)
      },

      deleteItem (item) {
        this.editedIndex = this.editedItem.equipment.indexOf(item)
        this.$root.$confirm('Eliminar Equipo', 'Esta Seguro?', { color: 'red' })
        .then((res) => {
          if(res){
            this.editedItem.equipment.splice(this.editedIndex, 1)
            if(item.updated_at && !item.inventory){
              this.bchanges = true
            }
            console.log(item)
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

      save() {
        if(this.bchanges){
          if(this.editedItem.observation_drop){
            this.$emit('save')
          }else{
            this.$root.$alert({text:"Antes de Continuar, Por favor Rellena la observación",color:"warning",timeout:2000})
            this.$emit('showDrop')
          }
        }else{
          this.$emit('save')
        }
      },
    }
  }
</script>
