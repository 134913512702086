<template>
    <div>
        <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="800px" transition="dialog-transition">
            <v-card>
                <v-toolbar dense color="primary" dark>
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>

                <v-card-text>
                    <v-form v-model="valid" ref="formUnitsMeasurement_" @submit.prevent="save">
                        <v-container>
                            <v-row>

                                <v-col cols="12" md="8">
                                    <v-text-field v-model="editedItem.name" label="* Nombre" :counter="100"
                                        :rules="rules.name" required></v-text-field>
                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-text-field v-model="editedItem.symbol" label="* Simbolo" :counter="30"
                                        :rules="rules.symbol" required></v-text-field>
                                </v-col>

                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="secondary" @click.stop="close">Cancelar</v-btn>
                    <v-btn color="primary" @click.stop="save">{{ viewMode }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { can } from '../../../util/functions';

export default {
    name: 'FormConsumableUnitsMeasurement',
    data: () => {
        return {
            dialog: false,
            valid: true,
            editedItem: {},
            rules: {
                name: [
                    (v) => !!v || 'El nombre es requerido',
                    (v) => (v && v.length <= 100) || 'El nombre no puede contener más de 100 carácteres'
                ],
                symbol: [
                    (v) => !!v || 'El simbolo es requerido',
                    (v) => (v && v.length <= 30) || 'El simbolo no puede contener más de 30 carácteres'
                ]
            }
        }
    },

    methods: {

        ...mapActions(['chgLoad', 'saveUnitsMeasurement', 'updateUnitsMeasurement']),
        can: can,

        newItem() {
            this.dialog = true
        },

        close() {
            this.dialog = false
            this.editedItem = {}
            this.$refs.formUnitsMeasurement_.reset()
        },

        editItem(item) {
            this.editedItem = Object.assign({}, item);
            this.dialog = true
        },

        async save() {
            if (this.$refs.formUnitsMeasurement_.validate()) {
                this.chgLoad(true);
                try {
                    var msg = "";
                    if (this.editedItem.id) {
                        msg = await this.updateUnitsMeasurement(this.editedItem)
                    }
                    else {
                        msg = await this.saveUnitsMeasurement(this.editedItem)
                    }
                    await this.$root.$alert({ text: msg.message, color: "success", timeout: 2000 });
                    await this.close();
                }
                catch (error) {
                    this.$root.$alert({ text:  error.response.data.message, color: "error", timeout: 2000 });
                }
                this.chgLoad(false);

            }
            else {
                this.$root.$alert({ text: "Revise los errores", color: "error", timeout: 2000 });
            }
        },

    },

    computed: {
        ...mapGetters(['loading']),
        currentuser() {
            return this.$store.getters.currentuser
        },
        formTitle() {
            return !this.editedItem.id ? 'Nuevo Registro' : 'Editar Registro'
        },
        viewMode() {
            return !this.editedItem.id ? 'Guardar' : 'Editar'
        },
    }
}
</script>

<style lang="scss" scoped></style>