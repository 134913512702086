import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/permissions/permissions"

const state = {
  permissions: []
}

const getters = {
  allPermissions: state => state.permissions
}

const actions = {

  async getPermissions({commit}){
    return new Promise(function(resolve, reject){
      commit('setPermissions', [])
      axios.get(URL_).then(res=>{
        commit('setPermissions', res.data)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async savePermission({commit},permission){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, permission).then(res=>{
        commit('newPermission', res.data.permission)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async updatePermission({commit},permission){
    return new Promise((resolve, reject)=>{
      axios.put(URL_, permission).then(res=>{
        //console.log(res.data)
        commit('updPermission', res.data.permission)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deletePermission({commit}, permission){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${permission.id}`).then(res=>{
        commit('delPermission', permission)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setPermissions : (state, permissions) => state.permissions = permissions,
  newPermission  : (state, permission) => state.permissions.unshift(permission),
  updPermission  : (state, permission) => {
    const index = state.permissions.findIndex(r => r.id === permission.id)
    if (index !== -1) state.permissions.splice(index, 1, permission)
  },
  delPermission  : (state, permission) => state.permissions = state.permissions.filter(r => r.id !== permission.id)
}

export default{
  state, getters, actions, mutations
}