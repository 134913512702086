  <template>
    <div>
    <v-card>
      <v-card-title>
        <span class="headline">Bajas de Equipos</span>
      </v-card-title>
      <v-card-text>
        <FilterQuery :filters="filters" :fieldFilters="fieldFilters" @freshFilters="initialize" id="filterQuery"></FilterQuery>
        <v-data-table
          :headers="headers"
          :items="allEquipmentDrops"
          :search="search"
          :loading="loading"
          :options.sync="options"
          :sort-by.sync="options.sortBy"
          :sort-desc.sync="options.sortDesc"
          :items-per-page.sync="options.per_page"
          :server-items-length="totalEquipmentDrop"
          multi-sort
          loading-text="Cargando Registros ..."
          class="elevation-1 mt-1"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <v-tooltip right>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn class="mx-2" fab dark small color="primary" v-on="{ ...tooltip }" id="newDrop" @click="newDrop()">
                    <v-icon dark>fa-plus</v-icon>
                  </v-btn>
                </template>
                <span>Nueva Baja</span>
              </v-tooltip>
              <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
                <v-card>
                  <v-toolbar dark color="primary" dense>
                    <v-toolbar-title>
                        Baja de Equipos
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                          X
                        </v-btn>
                      </template>
                      <span>Cerrar</span>
                    </v-tooltip>
                  </v-toolbar>
                  <v-card-text class="pt-2">
                    <v-form ref="formDrop">
                      <span class="subtitle-1 font-weight-bold" v-if="editedItem.created_at">Datos de Bajas </span>
                      <v-container fluid>
                        <v-row>
                            <v-col cols="12" md="6" class="my-0 py-0" v-if="editedItem.user.name">
                              <span class="font-weight-bold">Usuario Grabación: </span>
                              <span class="info--text">{{editedItem.user.name}}</span>
                            </v-col>
                            <v-col cols="12" md="6" class="my-0 py-0" v-if="editedItem.created_at">
                              <span class="font-weight-bold">Fecha Baja: </span>
                              <span class="info--text">{{editedItem.created_at}}</span>
                            </v-col>
                            <v-col cols="12" v-if="editedItem.id == 0">
                              <v-autocomplete :disabled="!can('loan.equipment_drop.create')"
                                v-model="editedItem.group_tic_id" 
                                :items="allGroupTics"
                                item-value="id"
                                item-text="name"
                                id="searchFormType"
                                label="* Grupo donde se va a dar de Baja"
                                placeholder="Seleccione el Grupo"
                                required
                                :rules="rules.group_tic_id"
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" class="my-0 py-0" v-if="editedItem.group">
                              <span class="font-weight-bold">Grupo: </span>
                              <span class="info--text">{{editedItem.group.name}}</span>
                            </v-col>
                            <v-col cols="12" class="my-0 py-0 px-3">
                              <DropEquipment :editedDrop="editedItem" :equipment="equipment"></DropEquipment>
                            </v-col>
                            <v-col cols="12" v-if="editedItem.id == 0">
                              <v-textarea required  :disabled="!can('loan.equipment_drop.create')"
                                name="observation"
                                id="observation"
                                label="Observación"
                                v-model="editedItem.observation" 
                                :rules="rules.observation"
                              ></v-textarea>
                            </v-col>
                            <v-col cols="12" v-else>
                              Observación: {{editedItem.observation}}
                            </v-col>           
                        </v-row>
                      </v-container>                      
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
                    <v-btn color="primary darken-1" outlined @click="save" :disabled="!can('loan.equipment_drop.create')">{{ viewMode }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:item.equipment="{ item }">
            <template v-for="(equipment, indexEqu) in item.equipment" >
              <v-chip color="secondary" :key="indexEqu" dark class="mt-1 mr-1">
                {{ equipment.description+'('+equipment.pivot.quantity+')' }}
              </v-chip>
            </template>
          </template>
          <template v-slot:item.action="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon @click="editItem(item)" v-on="on" class="mr-1 my-1" color="info">fa-eye</v-icon>
              </template>
              <span>Detalle</span>
            </v-tooltip>
            <v-tooltip bottom v-if="can('loan.equipment_drop.delete')">
              <template v-slot:activator="{ on }">
                <v-icon @click="deleteItem(item)" v-on="on" class="mr-1 my-1" color="error">fa-trash</v-icon>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn text icon color="green" dark @click="initialize()" v-on="on"><v-icon dark v-on="on">fa-sync-alt</v-icon></v-btn>
              </template>
              <span>Actualizar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
  import { can } from '../../util/functions';
  import { mapActions, mapGetters } from 'vuex'
  import { URL_APP } from "../../util/constants";
  import { fechaHoy } from '../../util/functions';
  import DropEquipment from './DropEquipment';
  import FilterQuery from '../general/FilterQuery';

  export default{
    name: 'Loan',
    components: { DropEquipment, 
        FilterQuery },
    data() {
      return {
        dialog: false,
        dialogFilter: false,
        notifyEmail: '',
        search: '',
        options: {page:1, per_page:10, sortBy:['created_at'], sortDesc:[true]},
        filters:[
        ],
        fieldFilters:[
          {field:'user.identity', name:'Identificación Usuario', type:'number'},
          {field:'user.name', name:'Nombre Usuario', type:'text'},
          {field:'created_at', name:'Fecha de Baja', type:'date'},
          {field:'equipment.inventory', name:'Numero Inventario de Equipo', type:'text'},
          {field:'equipment.description', name:'Descripción de Equipo', type:'text'},
        ],
        rules: {
          observation: [
            v => !!v || 'Campo Requerido'
          ],
          group_tic_id: [
            v => !!v || 'Campo Requerido'
          ],
        },
        headers: [
          { text: 'Id', value: 'id'},
          { text: 'Fecha', value: 'created_at'},
          { text: 'Usuario', value: 'user.name'},
          { text: 'Equipos', value: 'equipment'},
          { text: 'Acciones', value: 'action', sortable: false },
        ],
        equipment: [],
        editedIndex: -1,
        editedItem: {id: 0, group_tic_id:0, observation: '', user_id:'', user:{name:''}, user_loan:'', group:{}, equipment:[]},
        defaultItem: {id: 0, group_tic_id:0, observation: '', user_id:'', user:{name:''}, user_loan:'', group:{}, equipment:[]},
        valid: true
      }
    },

    computed: {
      ...mapGetters(['allEquipmentDrops','loading','allGroupTics', 'totalEquipmentDrop']),
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Préstamo' : 'Editar Préstamo'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      async 'editedItem.group_tic_id' (val) {
        if (this.editedItem.id==0) this.editedItem.equipment = []
        var group = this.allGroupTics.find(g=>g.id==val)
        if(group){
          var equipmentTemp = await group.equipment.filter(e=>(parseInt(e.pivot.stock)-parseInt(e.pivot.borrowed)>0))
          this.equipment = await equipmentTemp.map((e) => {
              e.pivot.quantity = e.pivot.quantity?e.pivot.quantity:1 
              return e
            })
        }else{
          this.equipment = []
        }
      },
      'options.page' () {
        this.initialize()
      },
      'options.per_page' () {
        this.initialize()
      },
      'options.sortBy' () {
        this.initialize()
      },
      'options.sortDesc' () {
        this.initialize()
      },
    },
    mounted () {
      this.initialize()
    },
    methods: {
      ...mapActions(['getEquipmentDrops','deleteEquipmentDrop','getGroupTics', 'chgLoad']),
      can:can,
      async initialize () {
        if(!this.loading){
          this.getGroupTics()
          this.chgLoad(true)
          var params = {filters : this.filters, options : this.options, search : this.search}
          await this.getEquipmentDrops(params)
          this.chgLoad(false)
        }
      },

      async initialize2 (page=null) {
        if(!this.loading){
          if (page!=null) this.options.page = page
          this.chgLoad(true)
          var params = {filters : this.filters, options : this.options, search : this.search}
          await this.getEquipmentDrops(params)
          this.chgLoad(false)
        }
      },

      newDrop(){
        this.editedIndex = -1
        this.editedItem = Object.assign({}, this.defaultItem)
        this.dialog = true
      },

      editItem (item) {
        this.editedIndex = this.allEquipmentDrops.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      fechaHoy(){
        fechaHoy()
      },

      deleteItem (item) {
        this.$root.$confirm('Eliminar Equipo', 'Esta Seguro?', { color: 'red' })
        .then(async (res) => {
          if(res){
            this.chgLoad(true)
            try{
              var msg = await this.deleteEquipmentDrop(item)
              this.$root.$alert({text:msg,color:"success",timeout:3000})
            }catch(error){
              console.error(error)
              this.$root.$alert({text:"Imposible Eliminar Registro",color:"error",timeout:3000})
            }
            this.chgLoad(false)
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedItem.equipment = Array()
          this.editedIndex = -1
        }, 300)
      },

      save () {
          this.$refs.formDrop.validate()
          var valid = true
          if(this.editedItem.equipment.length < 1){
            valid = false
            this.$root.$alert({text:"Debe Seleccionar al Menos un Equipo",color:"error",timeout:3000})
            document.getElementById('newEquipment').focus()
          }else if(!this.editedItem.observation){
            valid = false
            this.$root.$alert({text:"Debe Escribir una observación",color:"error",timeout:3000})
            document.getElementById('observation').focus()
          }
          if(valid){
            this.$root.$loadApp(true)
            var method1 = "post"
            if (this.editedIndex > -1) {
              //Editar Item
              method1 = "put"
            }
            this.$http({
              method: method1,
              url: URL_APP+'/api/loan/equipment_drops',
              data: this.editedItem
            })
            .then(response => {
              this.close()
              this.$root.$loadApp(false)
              this.initialize();
              this.$root.$alert({text:response.data.message,color:"success",timeout:2000})
              this.dialogInformation = true
            }).catch(error => {
              this.$root.$loadApp(false)
              this.$root.$alert({text:error.response.data.message,color:"error",timeout:2000})
            })
          }
      },

    }
  }
</script>
