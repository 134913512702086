import { logout } from '../helpers/auth.js';

export default [
  {
    name: 'APP_LOGIN_SUCCESS',
    callback: function (e) {
      console.log(e)
      this.$router.push({ path: '/' });
    }
  },
  {
    name: 'APP_LOGOUT',
    callback: function (e) {
      console.log(e)
      logout()
      .then((res)=>{
        this.$store.commit('logout');
        this.$root.$alert({text:res.message,color:"success",timeout:2000})
        this.$router.push({ path: '/' });
        //console.log(res)
      })
      .catch((err)=>{
        this.$root.$alert({text:err,color:"error",timeout:3000})
      });
    }
  },
  {
    name: 'APP_CHPASSWD',
    callback: function (e) {
      console.log(e)
      this.changePasswd(true)
    }
  },
  {
    name: 'APP_AUTH_FAILED',
    callback: function (e) {
      console.log(e)
      this.$router.push('/');
      this.$root.$alert({text:'El token ha expirado',color:"error",timeout:2000})
    }
  },
  {
    name: 'APP_BAD_REQUEST',
    // @error api response data
    callback: function (msg) {
      this.$message.error(msg);
    }
  },
  {
    name: 'APP_ACCESS_DENIED',
    // @error api response data
    callback: function (msg) {
      this.$message.error(msg);
      this.$router.push('/forbidden');
    }
  },

];
