<template>
  <div>
    <v-card color="grey lighten-3">
      <v-card-text >
        <v-container>
          <v-row>
            <v-col cols=12 class="px-2">
              <VueSignaturePad
                id="signature"
                width="500px"
                height="250px"
                ref="signaturePad"
              />
            </v-col>
            <v-col cols=12 class="px-2">
              <croppa v-model="signature" id="croppa"
              :height="250" :width="500"
              initial-size="contain"
              :show-remove-button="false"
              :zoom-speed="5" class="resizable-croppa"
              :reverse-scroll-to-zoom="false"
              canvas-color="white"
              :prevent-white-space="true">
                <img crossOrigin="anonymous" :src="img"
                  slot="initial">
              </croppa>
            </v-col>
            <v-col>
              <iframe
                src="myPDF.pdf"
                style="
                  position: fixed;
                  top: 0px;
                  bottom: 0px;
                  right: 0px;
                  width: 100%;
                  border: none;
                  margin: 0;
                  padding: 0;
                  overflow: hidden;
                  z-index: 999999;
                  height: 100%;
                ">
              </iframe>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary darken-1" outlined @click="clear">Limpiar</v-btn>
        <v-btn color="primary darken-1" outlined @click="save" :disabled="loading">Visualizar</v-btn>
        <v-btn color="primary darken-1" outlined @click="download" :disabled="loading">Descargar</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'
//  import { URL_APP } from "../util/constants";
//  import { objectToFormData, createLinkImage } from "../util/functions"
//  import { createLinkImage } from "../util/functions"
export default {
  name: 'SignaturePad',
  data() {
    return {
      img: '#',
      signature: {}
    }
  },
  mounted(){
    this.$refs.signaturePad.openSignaturePad()
  },
  computed:{
    ...mapGetters(['loading']),
  },
  methods: {
    ...mapActions(['chgLoad']),
    clear() {
      this.$refs.signaturePad.clearSignature()
    },
    async save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      if(isEmpty){
        this.$root.$alert({text:"Error Firma no Encontrada",color:"error",timeout:2000})
      }else{
        //createLinkImage(data,"signature.png")
        //this.signature.img = data
        this.img = data
        console.log(this.img)
        //this.signature.img = new File([data], "signature.png",{ type: "image/png" })
        /*this.img = data
        this.signature.img = new File([data], "signature.png",{ type: "image/png" })
        console.log(this.signature)
        var formData = objectToFormData(this.signature)
        this.chgLoad(true)
        try{
          var response = await this.$http.post(URL_APP+"/api/loan/signature_pdf", formData)
          this.$root.$alert({text:response.data.message,color:"success",timeout:3000})
          this.chgLoad(false)
          this.close()
          await this.$emit('initialize')
        }catch(error){
          this.chgLoad(false)
          console.log(error.response)
          this.errors = error.response.data.errors
          this.dialogError = true
          this.$root.$alert({text:"Ocurrió un error al dar de baja los equipos",color:"error",timeout:3000})
        }*/
      }
    },
    download(type, compressionRate) {
      this.signature.generateBlob((blob) => {
        var url = URL.createObjectURL(blob)
        console.log(url)
        var a = document.createElement('a');
        a.download = 'filename';
        a.href = url;
        a.click();
        URL.revokeObjectURL(url);
      }, type, compressionRate)
    }
  }
};
</script>
<style>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
#croppa canvas {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>