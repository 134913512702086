import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/loan/grouptics"

const state = {
  group_tics: [],
  selgroup_tics: [],
}

const getters = {
  allGroupTics: state => state.group_tics,
  selGroupTics: state => state.selgroup_tics,
}

const actions = {

  async getGroupTics({commit}){
    return new Promise(function(resolve, reject){
      commit('setGroupTics', [])
      axios.get(URL_).then(res=>{
        commit('setGroupTics', res.data)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async getSelGroupTics({commit}){
    return new Promise(function(resolve, reject){
      commit('setSelGroupTics', [])
      axios.get(URL_APP+"/api/loan/selgrouptics").then(res=>{
        commit('setSelGroupTics', res.data)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async saveGroupTic({commit}, group_tic){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, group_tic).then(res=>{
        commit('newGroupTic', res.data.group_tic)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async updateGroupTic({commit}, group_tic){
    return new Promise((resolve, reject)=>{
      axios.put(URL_, group_tic).then(res=>{
        //console.log(res.data)
        commit('updGroupTic', res.data.group_tic)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deleteGroupTic({commit}, group_tic){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${group_tic.id}`).then(res=>{
        commit('delGroupTic', group_tic)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setGroupTics : (state, group_tics) => state.group_tics = group_tics,
  setSelGroupTics : (state, selgroup_tics) => state.selgroup_tics = selgroup_tics,
  newGroupTic  : (state, group_tic) => state.group_tics.unshift(group_tic),
  updGroupTic  : (state, group_tic) => {
    const index = state.group_tics.findIndex(u => u.id === group_tic.id)
    if (index !== -1) state.group_tics.splice(index, 1, group_tic)
  },
  delGroupTic  : (state, group_tic) => state.group_tics = state.group_tics.filter(c => c.id !== group_tic.id)
}

export default {
  state, getters, actions, mutations
}