  <template>
    <div>
      <v-data-table
        :headers="headers"
        :items="allFileTypes"
        :search="search"
        :loading="loading"
        sort-by="title"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fa-angle-double-left',
          lastIcon: 'fa-angle-double-right',
          prevIcon: 'fa-angle-left',
          nextIcon: 'fa-angle-right'
        }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Tipos de Archivos</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" small fab dark color="green" v-on="on" @click.stop="exportItems()">
                  <v-icon dark>fa-file-excel</v-icon>
                </v-btn>
              </template>
              <span>Exportar a Excel</span>
            </v-tooltip>
            <v-tooltip bottom v-if="can('file_type.create')">
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="$refs.formFileType_.editItem()">
                  <v-icon dark>fa-plus</v-icon>
                </v-btn>
              </template>
              <span>Nuevo</span>
            </v-tooltip>
          </v-toolbar>
        </template>
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="$refs.formFileType_.editItem(item)" v-on="on" class="mr-1 my-1" color="info">fa-pen</v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom v-if="can('file_type.delete')">
            <template v-slot:activator="{ on }">
              <v-icon @click="deleteItem(item)" v-on="on" class="mr-1 my-1" color="error">fa-trash</v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn text icon color="green" dark @click="initialize()" v-on="on"><v-icon dark v-on="on">fa-sync-alt</v-icon></v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <FormFileType ref="formFileType_"/>
    </div>
</template>
<script>
  import XLSX from 'xlsx';
  import { can, createLinkBinaryFile, changeHeaderJson } from '../../../util/functions';
  import { mapActions, mapGetters } from 'vuex'
  import FormFileType from './FormFileType'
  export default{
    name: 'FileType',
    components: { FormFileType },
    data() {
      return {
        search: '',
        headers: [
          { text: 'Id', value: 'id' },
          { text: 'Nombre', value: 'name' },
          { text: 'Acciones', value: 'action', sortable: false },
        ],
      }
    },

    computed: {
      ...mapGetters(['loading','allFileTypes']),
      formTitle () {
        return !this.editedItem.id ? 'Nuevo Registro' : 'Editar Registro'
      },
      viewMode () {
        return !this.editedItem.id ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      ...mapActions(['getFileTypes', 'deleteFileType', 'chgLoad']),
      can:can,

      async initialize () {
        this.chgLoad(true)
        await this.getFileTypes()
        this.chgLoad(false)
      },

      stateItem (stateIt) {
        return stateIt ? 'Activo' : 'Inactivo'
      },
      async exportItems() {
        let header = ['id','name','active','created_at','updated_at'];
        let hreplace = ['Id','Nombre','Activo','Creado','Actualizado'];
        var fileName = "Tipos de Archivo.xlsx"
        var wb = XLSX.utils.book_new();
        wb.Props = {
          Title: "Areas",
          Author: this.currentUser.name,
          CreatedDate: new Date()
        };
        var workSheet = XLSX.utils.json_to_sheet(await changeHeaderJson(this.allFileTypes, header, hreplace));
        XLSX.utils.book_append_sheet(wb, workSheet, fileName.split('.')[1]);
        var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
        console.log(wbout);
        createLinkBinaryFile(wbout,"Tipos de Archivo.xlsx");
      },

      deleteItem (item) {
        this.$root.$confirm('Eliminar Tipo de Archivo', 'Esta Seguro?', { color: 'red' })
        .then(async (res) => {
          if(res){
            this.chgLoad(true)
            try{
              var msg = await this.deleteFileType(item)
              this.$root.$alert({text:msg.message,color:"success",timeout:3000})
            }catch(error){
              console.error(error)
              this.$root.$alert({text:"Imposible Eliminar Registro",color:"error",timeout:3000})
            }
            this.chgLoad(false)
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

    }
  }
</script>
