import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/cities"

const state = {
  cities: []
}

const getters = {
  allCities: state => state.cities
}

const actions = {

  async getCities({commit}){
    return new Promise(function(resolve, reject){
      commit('setCities', [])
      axios.get(URL_).then(res=>{
        commit('setCities', res.data)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async saveCity({commit}, city){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, city).then(res=>{
        commit('newCity', res.data.city)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async updateCity({commit}, city){
    return new Promise((resolve, reject)=>{
      axios.put(URL_, city).then(res=>{
        //console.log(res.data)
        commit('updCity', res.data.city)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deleteCity({commit}, city){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${city.id}`).then(res=>{
        commit('delCity', city)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setCities : (state, cities) => state.cities = cities,
  newCity  : (state, city) => state.cities.unshift(city),
  updCity  : (state, city) => {
    const index = state.cities.findIndex(u => u.id === city.id)
    if (index !== -1) state.cities.splice(index, 1, city)
  },
  delCity  : (state, city) => state.cities = state.cities.filter(c => c.id !== city.id)
}

export default{
  state, getters, actions, mutations
}