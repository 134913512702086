import axios from 'axios';
import { getLocalUser } from "./auth"

const axiosInstance = axios.create({});

/*axiosInstance.defaults.headers.common['Accept'] = 'application/json;charset=UTF-8'
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8'
axiosInstance.defaults.headers.common['Access-Control-Allow-Headers'] = '*'*/
axiosInstance.defaults.headers.common['Accept'] = 'application/json;charset=UTF-8'
//axiosInstance.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8'
axiosInstance.defaults.headers.common['Access-Control-Allow-Headers'] = '*'
axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

//axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
axiosInstance.interceptors.request.use(
  config => {
    const user = getLocalUser();
    if(user)
      config.headers.authorization = `Bearer ${user.token.access_token}`;
    return config;
  },
  error => Promise.reject(error)
);

export default axiosInstance;