<template>
    <div>
        <v-form v-model="valid" ref="formChangeInformation_" @submit.prevent="saveItem">

            <div>
                <v-row mt-2 my-2>
                    <!--? Descripción  -->
                    <v-col cols=12 class="my-0 py-0">
                        <v-text-field autofocus v-model="tempEdited.description" :counter="255"
                            label="* Descripción del activo" :rules="rules.description"></v-text-field>
                    </v-col>
                    <!--? Inventario  -->
                    <v-col cols=12 md="6" class="my-0 py-0">
                        <v-text-field v-model="tempEdited.inventory" label="* Inventario actual" :counter="50"
                            :rules="rules.inventory"></v-text-field>
                    </v-col>

                    <!--? Inventario Anterior  -->
                    <v-col cols=12 md="6" class="my-0 py-0">
                        <v-text-field v-model="tempEdited.old_inventory" label="Inventario anterior" :counter="50"
                            :rules="rules.old_inventory"></v-text-field>
                    </v-col>

                    <!--? Serial  -->
                    <v-col cols=12 md="12" class="my-0 py-0">
                        <v-text-field v-model="tempEdited.serial" label="Serial" :counter="100"
                            :rules="rules.serial"></v-text-field>
                    </v-col>

                    <v-col cols=12 md="6" class="my-0 py-0">
                        <v-text-field :value="tempEdited.status ? tempEdited.status.description : ''" label="Estado" :counter="100" disabled></v-text-field>
                    </v-col>

                    <v-switch v-model="tempEdited.found" label="Elemento encontrado" required dense hide-details>
                        <template v-slot:label>
                            {{ editedItem.found ? 'Elemento encontrado' : 'Elemento no encontrado' }}
                        </template>
                    </v-switch>

                    <!--? Fecha de compra  -->
                    <v-col cols=12 md="6" class="my-0 py-0">
                        <v-menu v-model="menFechIni" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field v-model="tempEdited.buy_date" label="* Fecha de compra"
                                    append-icon="fa-calendar-alt" readonly v-on="on" id="buy_date" clearable
                                    placeholder="Día, Mes, Año" :rules="rules.generalRequired"></v-text-field>
                            </template>
                            <v-date-picker v-model="tempEdited.buy_date" @input="menFechIni = false"
                                :max="fechaHoy()"></v-date-picker>
                        </v-menu>
                    </v-col>

                    <!--? Costo  -->
                    <v-col cols="12" md="6" class="my-0 py-0">
                        <div>
                            <vuetify-money v-model="tempEdited.cost" label="Valor en pesos colombianos"
                                placeholder="Escriba el valor en pesos colombianos" :readonly="false" :disabled="false"
                                :outlined="false" clearable valueWhenIsEmpty="0" :options="configCurrency"
                                :rules="rules.cost" />
                        </div>
                    </v-col>

                    <!--? NIT del proveedor  -->
                    <v-col cols=12 md="6" class="my-0 py-0">
                        <v-text-field v-model="tempEdited.supplier.nit" label="* NIT del proveedor" readonly
                            :rules="rules.generalRequired">
                            <template v-slot:append v-if="!disabled">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="green darken-3" @click="$refs.selectSupplier_.show()">
                                            fa-people-carry
                                        </v-icon>
                                    </template>
                                    Seleccionar proveedor
                                </v-tooltip>

                                <v-tooltip bottom v-if="editedItem.supplier.id && !disabled">
                                    <template v-slot:activator="{ on }" pr-2>
                                        <v-icon v-on="on" color="orange darken-3" @click="clearSupplier()">
                                            fa-times-circle
                                        </v-icon>
                                    </template>
                                    Limpiar datos
                                </v-tooltip>
                            </template>
                        </v-text-field>
                    </v-col>

                    <!--? Nombre del proveedor  -->
                    <v-col cols=12 md="6" class="my-0 py-0">
                        <v-text-field v-model="tempEdited.supplier.name" label="Nombre del proveedor" readonly
                            :rules="rules.generalRequired"></v-text-field>
                    </v-col>

                    <v-col cols=12 md="12" class="my-0 py-0">
                        <!-- <v-text-a v-model="tempEdited.supplier.name" label="Nombre del proveedor" readonly
                            :rules="rules.generalRequired"></v-text-a> -->
                        <v-textarea v-model="tempEdited.observations" label="Observaciones" rows="2"></v-textarea>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="close">Cancelar</v-btn>
                    <v-btn color="primary" @click="saveItem">Guardar</v-btn>

                </v-card-actions>
            </div>
        </v-form>
        <SelectSupplier ref="selectSupplier_" @setSupplier="setSupplier" />
    </div>
</template>

<script>
import SelectSupplier from '../../../loan_consumables/suppliers/SelectSupplier.vue'
export default {
    name: "FormChangeInformation",
    components: { SelectSupplier },
    props: {
        editedItem: {
            type: Object,
            required: true
        },
        options: {
            type: Number, // Se espera recibir 1
            required: true
        },
        allAssetStatus: {
            type: Array,
            required: true
        }
    },
    data: () => {
        return {
            menFechIni: false,
            disabled: false,
            valid: true,
            tempEdited: {
                supplier: {}
            }, //Objeto temporal para almacenar los valores del prop y no tener problemas con la reactividad
            configCurrency: {
                locale: "es-CO",
                prefix: "$",
                suffix: "",
                length: 30,
                precision: 2,
                decimal: '.',
            },
            rules: {

                description: [
                    v => !!v || 'Campo requerido',
                ],
                inventory: [
                    (v) => !!v || 'El inventario es requerido',
                    (v) => (v && v.length <= 50) || 'El inventario solo puede contener 50 carácteres'
                ],
                old_inventory: [
                    v => !v || (v.length <= 50) || 'El inventario anterior debe tener como máximo 50 caracteres'
                ],
                cost: [
                    (v) => !!v || 'El costo es requerido',
                ],
                generalRequired: [
                    (v) => !!v || 'El campo es requerido',
                ]
            }

        }
    },

    watch: {
        'tempEdited.supplier': {
            handler: function (newVal) {
                if (newVal && newVal.id) {
                    this.tempEdited.supplier_id = newVal.id;
                } else {
                    this.tempEdited.supplier_id = null;
                }
            },
            deep: true
        }
    },
    methods: {
        fechaHoy() {
            return new Date().toISOString().substr(0, 10)
        },

        setSupplier(supplier) {
            this.tempEdited.supplier = supplier
        },

        clearSupplier() {
            this.tempEdited.supplier = {}
        },

        saveItem() {
            if (this.$refs.formChangeInformation_.validate()) {
                this.tempEdited.options = this.options;
                this.$emit('updateItem', this.tempEdited);
            }
            else {
                this.$root.$alert({ text: "Revise los errores", color: "error", timeout: 2000 });
            }

        },

        itemDisabled(item) {
            return item.id === 4 || item.id === 2;
        },

        close() {
            this.$emit('closeItem');
            this.tempEdited = {
                supplier: {}
            }
        }
    },
    mounted() {
        //asigna el valor del prop editedItem al objeto tempEdited
        Object.assign(this.tempEdited, this.editedItem);

    }

}
</script>

<style lang="scss" scoped></style>