  <template>
    <div>
      <v-data-table
        :headers="headers"
        :items="allPlaces"
        :search="search"
        :loading="loading"
        sort-by="title"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fa-angle-double-left',
          lastIcon: 'fa-angle-double-right',
          prevIcon: 'fa-angle-left',
          nextIcon: 'fa-angle-right'
        }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Lugares - Salas</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" small fab dark color="green" v-on="on" @click.stop="exportItems()">
                  <v-icon dark>fa-file-excel</v-icon>
                </v-btn>
              </template>
              <span>Exportar a Excel</span>
            </v-tooltip>
            <v-tooltip bottom v-if="can('place-create')">
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="dialog = true">
                  <v-icon dark>fa-plus</v-icon>
                </v-btn>
              </template>
              <span>Nuevo</span>
            </v-tooltip>
            <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-form v-model="valid" ref="formPlace" @submit.prevent="save">
                    <v-container>
                      <v-row>
                        <v-col cols=12>
                          <v-text-field v-model="editedItem.name" label="Nombre"
                          :disabled="!can('place-create')"
                          :rules="rules.name" autofocus></v-text-field>
                        </v-col>
                        <v-col cols=12>
                          <v-text-field v-model="editedItem.location" label="Ubicación"
                          :disabled="!can('place-create')"
                          :rules="rules.location"></v-text-field>
                        </v-col>
                        <v-col cols=12>
                          <v-textarea v-model="editedItem.description" label="Descripcion"
                          :disabled="!can('place-create')"
                          :rules="rules.description"></v-textarea>
                        </v-col>
                        <v-col cols=12 md=6>
                          <v-autocomplete :disabled="!can('place-create')"
                            v-model="editedItem.place_type_id" 
                            item-value="id"
                            item-text="name"
                            :rules="rules.place_type_id"
                            :items="place_types"
                            label="* Tipo de Lugar"
                            placeholder="Seleccione el Tipo"
                            required
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols=12 md=6>
                          <v-autocomplete :disabled="!can('place-create')"
                            v-model="editedItem.campus_id" 
                            item-value="id"
                            item-text="name"
                            :rules="rules.campus_id"
                            :items="allCampuses"
                            label="* Campus"
                            placeholder="Seleccione el Campus"
                            required
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols=12 md=6>
                          <v-switch v-model="editedItem.loan" label="Préstamo" color="primary"
                          :disabled="!can('place-create')"></v-switch>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
                  <v-btn color="primary darken-1" outlined @click="save" :loading="loading" :disabled="loading || !can('place-create')">{{ viewMode }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.loan="props">
          {{stateItem(props.item.loan)}}
        </template>
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="editItem(item)" v-on="on" class="mr-1 my-1" color="info">fa-pen</v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom v-if="can('place-delete')">
            <template v-slot:activator="{ on }">
              <v-icon @click="deleteItem(item)" v-on="on" class="mr-1 my-1" color="error">fa-trash</v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn text icon color="green" dark @click="initialize()" v-on="on"><v-icon dark v-on="on">fa-sync-alt</v-icon></v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
</template>
<script>
  import XLSX from 'xlsx';
  import { can, createLinkBinaryFile, changeHeaderJson } from '../../util/functions';
  import { mapActions, mapGetters } from 'vuex'
  import { URL_APP } from "../../util/constants";
  export default{
    name: 'Place',
    data() {
      return {
        dialog: false,
        search: '',
        rules: {
          name: [
            v => !!v || 'Campo requerido',
            v => v.length >= 3 || 'Mínimo 3 caracteres',
            v => v.length < 100 || 'Máximo 100 letras',
          ],
          description: [
            v => v.length < 200 || 'Máximo 200 letras',
          ],
          location: [
            v => !!v || 'Campo Requerido',
            v => v.length < 200 || 'Máximo 200 letras',
          ],
          campus_id: [
            v => !!v || 'Campo Requerido',
          ],
          place_type_id: [
            v => !!v || 'Campo Requerido',
          ],
        },
        headers: [
          { text: 'Id', value: 'id' },
          { text: 'Nombre', value: 'name' },
          { text: 'Ubicación', value: 'location' },
          { text: 'Descripción', value: 'description' },
          { text: 'Campus', value: 'campus.name' },
          { text: 'Préstamo', value: 'loan' },
          { text: 'Activo', value: 'state' },
          { text: 'Acciones', value: 'action', sortable: false },
        ],
        place_types: [],
        editedIndex: -1,
        editedItem: {id: 0, name: '', location: '', description:'', campus_id: '', place_type_id:'', loan:false, state: 'Activo'},
        defaultItem: {id: 0, name: '', location: '', description:'', campus_id: '', place_type_id:'', loan:false, state: 'Activo'},
        valid: true
      }
    },

    computed: {
      ...mapGetters(['loading','allCampuses','allPlaces']),
      formTitle () {
        return !this.editedItem.id ? 'Nuevo Registro' : 'Editar Registro'
      },
      viewMode () {
        return !this.editedItem.id ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      ...mapActions(['getPlaces','deletePlace','savePlace', 'updatePlace',
            'chgLoad', 'getCampuses']),
      can: can,
      async initialize () {
        this.getCampuses()
        this.getTypes()
        this.chgLoad(true)
        await this.getPlaces()
        this.chgLoad(false)
      },

      stateItem (stateIt) {
        return stateIt ? 'Activo' : 'Inactivo'
      },

      getTypes () {
        this.$http.get(URL_APP+'/api/loan/selplacetype')
        .then(response => {
          this.place_types = response.data;
        })
        .catch(error => { console.error(error)
          this.$root.$alert({text:"Error al Obtener los Tipos de Lugares",color:"error",timeout:2000})
        });
      },

      editItem (item) {
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      async exportItems() {
        let header = ['id','name','active','created_at','updated_at'];
        let hreplace = ['Id','Nombre','Activo','Creado','Actualizado'];
        var fileName = "areas.xlsx"
        var wb = XLSX.utils.book_new();
        wb.Props = {
          Title: "Areas",
          Author: this.currentUser.name,
          CreatedDate: new Date()
        };
        var workSheet = XLSX.utils.json_to_sheet(await changeHeaderJson(this.allAreas, header, hreplace));
        XLSX.utils.book_append_sheet(wb, workSheet, fileName.split('.')[1]);
        var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
        console.log(wbout);
        createLinkBinaryFile(wbout,"areas.xlsx");
      },

      deleteItem (item) {
        var url = URL_APP+"/api/loan/places/" + item.id;
        this.$root.$confirm('Eliminar Lugar', 'Esta Seguro?', { color: 'red' })
        .then((res) => {
          if(res){
            this.$http.delete(url)
            .then(response => { console.error(response)
              this.initialize();
              this.$root.$alert({text:"¡ Registro Eliminado !",color:"success",timeout:2000})
            })
            .catch(error => { console.error(error)
              this.$root.$alert({text:"Imposible Eliminar Registro",color:"error",timeout:2000})
            });
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        }, 300)
      },

      async save () {
        if(!this.loading){
          this.$refs.formPlace.validate()
          if (this.valid){
            this.chgLoad(true)
            try{
              var msg = ""
              if (this.editedItem.id){
                msg = await this.updatePlace(this.editedItem)
              }else{
                msg = await this.savePlace(this.editedItem)
              }
              this.$root.$alert({text:msg.message,color:"success",timeout:2000})
              this.close()
            }catch(error){
              this.$root.$alert({text:"Ha Ocurrido un Error al guardar el Campus",color:"error",timeout:2000})
            }
            this.chgLoad(false)
          }else{
            this.$root.$alert({text:"Por favor revise los errores",color:"error",timeout:2000})
          }
        }
      },
    }
  }
</script>
