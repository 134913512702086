import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/cost_centers"

const state = {
  costCenters: []
}

const getters = {
  allCostCenters: state => state.costCenters
}

const actions = {

  async getCostCenters({commit}){
    return new Promise(function(resolve, reject){
      commit('setCostCenters', [])
      axios.get(URL_).then(res=>{
        commit('setCostCenters', res.data)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async saveCostCenter({commit}, costCenter){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, costCenter).then(res=>{
        commit('newCostCenter', res.data.costCenter)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async updateCostCenter({commit}, costCenter){
    return new Promise((resolve, reject)=>{
      axios.put(URL_, costCenter).then(res=>{
        //console.log(res.data)
        commit('updCostCenter', res.data.costCenter)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deleteCostCenter({commit}, costCenter){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${costCenter.id}`).then(res=>{
        commit('delCostCenter', costCenter)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setCostCenters : (state, costCenters) => state.costCenters = costCenters,
  newCostCenter  : (state, costCenter) => state.costCenters.unshift(costCenter),
  updCostCenter  : (state, costCenter) => {
    const index = state.costCenters.findIndex(u => u.id === costCenter.id)
    if (index !== -1) state.costCenters.splice(index, 1, costCenter)
  },
  delCostCenter  : (state, costCenter) => state.costCenters = state.costCenters.filter(c => c.id !== costCenter.id)
}

export default{
  state, getters, actions, mutations
}