import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/loan/equipment_fields"

const state = {
  equipmentFields: []
}

const getters = {
  allEquipmentFields: state => state.equipmentFields
}

const actions = {

  async getEquipmentFields({commit}){
    return new Promise(function(resolve, reject){
      commit('setEquipmentFields', [])
      axios.get(URL_).then(res=>{
        commit('setEquipmentFields', res.data)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async saveEquipmentField({commit},equipmentField){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, equipmentField).then(res=>{
        commit('newEquipmentField', res.data.equipmentField)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async updateEquipmentField({commit}, equipmentField){
    return new Promise((resolve, reject)=>{
      axios.put(URL_, equipmentField).then(res=>{
        commit('updEquipmentField', res.data.equipmentField)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deleteEquipmentField({commit}, equipmentField){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${equipmentField.id}`).then(res=>{
        commit('delEquipmentField', equipmentField)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setEquipmentFields : (state, equipmentFields) => state.equipmentFields = equipmentFields,
  newEquipmentField  : (state, equipmentField) => state.equipmentFields.unshift(equipmentField),
  updEquipmentField  : (state, equipmentField) => {
    const index = state.equipmentFields.findIndex(u => u.id === equipmentField.id)
    if (index !== -1) state.equipmentFields.splice(index, 1, equipmentField)
  },
  delEquipmentField  : (state, equipmentField) => state.equipmentFields = state.equipmentFields.filter(u => u.id !== equipmentField.id)
}

export default{
  state, getters, actions, mutations
}