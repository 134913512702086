<template>
  <div>
    <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
      <v-card>
        <v-toolbar dense color="primary" dark>
          <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
          <v-spacer/>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                <v-icon>fa-times</v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-tabs v-model="tab"
            fixed-tabs
            background-color="primary"
            dark class="mx-0 mt-2"
          >
            <v-tab :href="`#tabData`">
              Datos
            </v-tab>
            <v-tab :href="`#tabTransfer`">
              Traslados
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item
              :value="'tabData'"
            >
              <v-card flat min-height="500px">
                <v-card-text >
                  <v-form v-model="valid" ref="formEquipment_" @submit.prevent="save">
                    <v-container >
                      <v-row>
                        <v-col cols=12 md=6>
                          <v-autocomplete :disabled="editedItem.state!='Activo'"
                            v-model="editedItem.equipment_type_id" 
                            item-value="id"
                            item-text="name"
                            :rules="rules.equipment_type_id"
                            :items="allEquipmentTypes"
                            @change="changeType"
                            label="* Tipo de Equipo"
                            placeholder="Seleccione el Tipo de Equipo"
                            required autofocus
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols=12>
                          <v-text-field v-model="editedItem.description" label="* Descripcion" 
                          :disabled="editedItem.state!='Activo'"
                          :rules="rules.description" required></v-text-field>
                        </v-col>
                        <template v-if="editedItem.type.inventory">
                          <v-col cols=12 md=6 >
                            <v-menu 
                              v-model="menBuyDate"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field :disabled="editedItem.state!='Activo'"
                                  v-model="editedItem.buy_date"
                                  label="* Fecha de Compra"
                                  prepend-icon="fa-calendar-alt"
                                  readonly
                                  v-on="on"
                                  id="buy_date"
                                  clearable
                                  :rules="rules.buy_date"
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="editedItem.buy_date" @input="menBuyDate = false" :max="fechaHoy()"></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols=12 md=6>
                            <v-text-field :disabled="editedItem.state!='Activo'"
                              v-model.number="editedItem.cost"
                              label="* Valor Costo"
                              required
                              :rules="rules.cost"
                              type="number"

                            />
                          </v-col>
                          <v-col cols=12 md=6>
                            <v-text-field :disabled="editedItem.state!='Activo'"
                            v-model="editedItem.serial" label="Serial" 
                            ></v-text-field>
                          </v-col>
                          <v-col cols=12 md=6>
                            <v-text-field :disabled="editedItem.state!='Activo'"
                            v-model="editedItem.inventory" label="Inventario" 
                            />
                          </v-col>
                          <v-col cols=12 md=6>
                            <v-text-field :disabled="editedItem.state!='Activo'"
                            v-model="editedItem.model" label="Modelo" 
                            />
                          </v-col>
                          <v-col cols=12 md=6>
                            <v-autocomplete :disabled="editedItem.state!='Activo'"
                              v-model="editedItem.manufacturer" 
                              item-text="manufacturer"
                              :items="allManufacturers"
                              label="* Proveedor"
                              placeholder="Seleccione el Proveedor"
                              :rules="rules.manufacturer"
                            >
                              <template v-slot:append-outer>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on: tooltip }">
                                    <v-icon color="info" v-on="{ ...tooltip }" @click="$refs.formManufacturer.newItem()">fa-plus</v-icon>
                                  </template>
                                  <span>Nuevo Proveedor</span>
                                </v-tooltip>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols=12 md=6>
                            <v-text-field readonly
                              v-model="editedItem.third.identification"
                              label="* Identificación Responsable"
                              type="text"
                              :disabled="editedItem.state!='Activo'"
                              clearable
                              append-outer-icon="fa-clipboard-list"
                              :rules="rules.third.identification"
                              
                              @click:clear="setThird(defaultItem.third)"
                            >
                              <template v-slot:append-outer>
                                <v-tooltip
                                  bottom
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" color="info" @click="$refs.formThird.editItem()">
                                      fa-plus
                                    </v-icon>
                                  </template>
                                  Agregar Responsable
                                </v-tooltip>
                              </template>
                              <template v-slot:prepend>
                                <v-tooltip
                                  bottom
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" color="success" @click="$refs.selectThird.show()">
                                      fa-clipboard-list
                                    </v-icon>
                                  </template>
                                  Seleccionar Responsable
                                </v-tooltip>
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col cols=12 md=6>
                            <v-text-field
                              v-model="editedItem.third.name"
                              label="Nombre" 
                              required disabled
                              type="text"
                            />
                          </v-col>
                          <v-col cols=12 md=6>
                            <v-autocomplete
                              v-model="editedItem.cost_center_id" 
                              item-value="id" :readonly="editedItem.state!='Activo'"
                              item-text="name" :disabled="editedItem.state!='Activo'"
                              :items="allCostCenters"
                              :rules="rules.cost_center_id"
                              label="* Centro de Costos"
                              placeholder="Seleccione el Centro de Costos"
                              clearable
                            ></v-autocomplete>
                          </v-col>

                          <v-col cols=12 md=6>
                            <v-autocomplete
                              v-model="editedItem.location_id" 
                              item-value="id" :readonly="editedItem.state!='Activo'"
                              item-text="name" :disabled="editedItem.state!='Activo'"
                              :items="allLocations"
                              label="Ubicación"
                              placeholder="Seleccione la Ubicación"
                              clearable
                            ></v-autocomplete>
                          </v-col>
                          
                          <!-- <v-col cols=12 md=6>
                            <v-autocomplete
                              v-model="editedItem.location_id" 
                              item-value="id" :readonly="editMode"
                              item-text="name" :disabled="editMode"
                              :items="allLocations"
                              label="Ubicación"
                              placeholder="Seleccione la Ubicación"
                              clearable
                            ></v-autocomplete>
                          </v-col> -->
                          <v-col cols=12 md=6>
                            <v-autocomplete
                              v-model="editedItem.campus_id" 
                              item-value="id" :readonly="editedItem.state!='Activo'"
                              item-text="name" :disabled="editedItem.state!='Activo'"
                              :rules="rules.campus_id"
                              :items="allCampuses"
                              label="* Campus"
                              placeholder="Seleccione el Campus"
                              required
                              clearable
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols=12 md=6>
                            <v-autocomplete clearable
                              v-model="editedItem.building_id" 
                              item-text="name" :readonly="editedItem.state!='Activo'"
                              item-value="id" :disabled="editedItem.state!='Activo'"
                              :items="allBuildings"
                              :rules="rules.building_id"

                              label="* Edificio"
                              placeholder="Seleccione el Edificio de Localización"
                            />
                          </v-col>
                          <v-col cols=12 md=6>
                            <v-autocomplete clearable
                              v-model="editedItem.tier_id" 
                              item-text="name" :readonly="editedItem.state!='Activo'"
                              item-value="id" :disabled="editedItem.state!='Activo'"
                              :rules="rules.tier_id"
                              
                              :items="allTiers"
                              label="* Piso"
                              placeholder="Seleccione el Piso de Localización"
                            />
                          </v-col>
                          <v-col cols=12 md=6>
                            <v-autocomplete clearable
                              v-model="editedItem.area_id" 
                              item-text="name" :readonly="editedItem.state!='Activo'"
                              item-value="id" :disabled="editedItem.state!='Activo'"
                              :rules="rules.area_id"
                              :items="allAreas"
                              label="* Área"
                              placeholder="Seleccione el Área de Localización"
                            />
                          </v-col>
                          <v-col cols=12 md=6>
                            <v-switch label="Generar automáticamente número de inventario" 
                            v-model="editedItem.generate_inventory" 
                            :disabled="disableGenerate"></v-switch>
                          </v-col>
                        </template>
                        <v-col cols=12 md=6>
                          <!--<v-autocomplete
                            v-model="editedItem.state" 
                            :rules="rules.state"
                            :items="states"
                            label="* Estado"
                            placeholder="Seleccione el Estado"
                            required
                          ></v-autocomplete>-->
                          {{editedItem.state}}
                        </v-col>
                        <v-col cols=12 md=6 v-if="editedItem.groups.length>0">
                          Grupo / Bodega :
                          <template v-for="group in editedItem.groups">
                              <v-chip :key="'chip'+group.id"
                                  class=""
                                  color=""
                                >
                                  {{group.name+'('+group.pivot.stock+')'}}
                                </v-chip>
                          </template>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :value="'tabTransfer'"
            >
              <v-card flat min-height="500px">
                <v-card-text>
                  <v-container >
                    <v-row>
                        <v-col cols=12 >
                          <template v-for="transfer in editedItem.transfers">
                            <v-card :key="'cardTransfer'+transfer.id"
                                class="mt-1 elevation-1"
                                color="blue-grey lighten-5"
                              >
                              <v-card-text>
                                <v-row>
                                  <v-col cols=12 md=6 dense>
                                    <b>Fecha:</b> {{transfer.transfer.created_at}}
                                  </v-col>
                                  <v-col cols=12 md=6 dense>
                                    <b>Usuario:</b> {{transfer.transfer.user.name}}
                                  </v-col>
                                  <v-col cols=12 dense>
                                    <b>Valor Anterior:</b> {{transfer.field.name}} {{transfer.value}}
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </template>
                        </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
          <v-btn color="primary darken-1" outlined @click="save" :disabled="editedItem.state!='Activo'" v-if="can('equipment-create')">{{ viewMode }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <FormManufacturer ref="formManufacturer" />
    <SelectThird ref="selectThird" @setThird="setThird"/>
    <FormThird ref="formThird" />
  </div>
</template>
<script>
  import { can } from '../../../util/functions';
  import { mapActions, mapGetters } from 'vuex'
  import FormManufacturer from './FormManufacturer'
  import SelectThird from '../../general/Third/SelectThird'
  import FormThird from '../../general/Third/FormThird'
  export default{
    name: 'FormEquipment',
    components: {
      FormManufacturer, SelectThird, FormThird
    },
    data() {
      return {
        tab: null,
        dialog: false,
        menBuyDate: false,
        rules: {
          description: [
            v => !!v || 'Campo Requerido',
            v => v.length < 200 || 'Máximo 200 letras',
          ],
          observation: [
            v => !!v || 'Campo Requerido',
          ],
          buy_date: [
            v => !!v || 'Campo requerido'
          ],
          cost: [
            v => !!v || 'Campo requerido'
          ],
          manufacturer: [
            v => !!v || 'Campo requerido'
          ],
          /*serial: [
            v => !!v || 'Campo Requerido',
            v => v.length < 50 || 'Máximo 50 letras',
          ],*/
          campus_id: [
            v => !!v || 'Campo Requerido',
          ],

          building_id: [
            v => !!v || 'Campo Requerido',
          ],

          tier_id: [
            v => !!v || 'Campo Requerido',
          ],

          area_id: [
            v => !!v || 'Campo Requerido',
          ],

          equipment_type_id: [
            v => !!v || 'Campo Requerido',
          ],

          cost_center_id: [
            v => !!v || 'Campo Requerido',
          ],

          third: {
            identification: [
            v => !!v || 'Campo Requerido',

            ]
          }
        },
        states: ['Activo','Baja','Nulo','Prestado'],
        editedItem: {
          id: 0, description: '', serial: '', 
          inventory: '', manufacturer: '', model: '', 
          domain: '', type_use: '', equipment_type_id: '', 
          campus_id: '', state: 'Activo', count:1, 
          borrowed: 1, type: { id: 0, name: '' }, 
          generate_inventory: false, groups:[],
          campus: { id: 0, name: '' }, third: {id:0, identification:'', name: ''}
        },
        defaultItem: {
          id: 0, description: '', serial: '', 
          inventory: '', manufacturer: '', model: '', 
          domain: '', type_use: '', equipment_type_id: '', 
          campus_id: '', state: 'Activo', count:1, 
          borrowed: 1, type: { id: 0, name: '' }, 
          generate_inventory: false, groups: [],
          campus: { id: 0, name: '' }, third: {id:0, identification:'', name: ''}
        },
        valid: true,
      }
    },

    computed: {
      ...mapGetters(['allManufacturers','lastManufacturer','loading',
          'allEquipmentTypes', 'allCampuses', 'allBuildings', 'allTiers',
          'allAreas', 'allCostCenters', 'allLocations','selGroupTics']),
      formTitle () {
        return this.editedItem.id == 0 ? 'Nuevo Equipo' : 'Editar Equipo'
      },
      viewMode () {
        return this.editedItem.id == 0 ? 'Guardar' : 'Editar'
      },
      editMode () {
        return this.editedItem.id == 0 ? false : true
      },
      currentUser(){
        return this.$store.getters.currentUser
      },
      disableGenerate(){
        return this.editedItem.inventory?true:false
      }
    },

    watch: {
      lastManufacturer (val) {
        if (val)
          this.editedItem.manufacturer = val
      },
      'editedItem.inventory'(val){
        if(!val && this.editedItem.id == 0){
          this.editedItem.generate_inventory = true
        }else if(val){
          this.editedItem.generate_inventory = false
        }
      },
    },
    methods: {
      ...mapActions(['saveEquipment','updateEquipment', 'getThird','chgLoad']),
      can:can,

      /*async getThird () {
        if(!this.loading){
          this.chgLoad(true)
          await this.$http.get(URL_APP+"/api/third/"+this.editedItem.third.identification).then(response => {
            if (response.data.id) Object.assign(this.editedItem.third, response.data)
            else this.editedItem.third.id = ''
          }).catch((err)=> { this.editedItem
            console.error(err)
          })
          this.chgLoad(false)
        }
      },*/
      setThird(third){
        this.editedItem.third = third
      },

      changeType(){
        var _this2 = this
        if (Array.isArray(this.allEquipmentTypes)) {
          this.allEquipmentTypes.forEach(function(type){
            if(type.id == _this2.editedItem.equipment_type_id){
              _this2.editedItem.type = Object.assign({}, type)
            }
          })
        }
      },

      fechaHoy(){
        return new Date().toISOString().substr(0, 10)
      },

      newItem() {
        this.dialog = true
      },

      editItem (item, tab="tabData") {
        this.editedItem = Object.assign({}, item)
        this.editedItem.serial = this.editedItem.serial?this.editedItem.serial:''
        console.log(this.editedItem)
        if(!this.editedItem.third)
          this.editedItem.third = this.defaultItem.third
        this.tab = tab
        this.dialog = true
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedItem.third = Object.assign({}, this.defaultItem.third)
          console.log(this.editedItem)
        }, 300)
      },

      async save () {
        if(this.editedItem.state!='Activo'){
          this.$root.$alert({text:"Error no tiene el estado Necesario",color:"error",timeout:2000})
        }else{
          this.$refs.formEquipment_.validate()
          if(!this.loading){
            var valid = this.valid
            if(this.editedItem.type.inventory==true){
              //Si es de Inventario Algunos otros Campos son Obligatorios
              /*if(this.editedItem.inventory==''){
                valid = false
                this.$root.$alert({text:"El tipo de Equipo es Necesario el Inventario",color:"error",timeout:2000})
                document.getElementById("inventory")
              }
              if(this.editedItem.serial==''){
                valid = false
                this.$root.$alert({text:"El Serial del Equipo es Obligatorio",color:"error",timeout:2000})
                document.getElementById("serial")
              }*/
              if(this.editedItem.campus_id=='' || this.editedItem.campus_id==null){
                valid = false
                this.$root.$alert({text:"El tipo de Equipo es Necesario el Campus",color:"error",timeout:2000})
                document.getElementById("campus")
              }
            }
            if (valid){
              this.chgLoad(true)
              try{
                var msg = ""
                if (this.editedItem.id){
                  msg = await this.updateEquipment(this.editedItem)
                }else{
                  msg = await this.saveEquipment(this.editedItem)
                }
                this.$root.$alert({text:msg.message,color:"success",timeout:2000})
                this.close()
              }catch(error){
                this.$root.$alert({text:"Ha Ocurrido un Error al guardar el Registro",color:"error",timeout:2000})
              }
              this.chgLoad(false)
            }else{
              this.$root.$alert({text:"Revise Por favor que no falte algún campo Obligatorio",color:"error",timeout:2000})
            }
          }
        }
      },
    }
  }
</script>
