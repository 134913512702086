<template>
    <div>
        <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="1000px"
            transition="dialog-transition">
            <v-card>
                <v-toolbar dense color="primary" dark>
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="closeItem">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-card-text>
                    <v-form v-model="valid" ref="formAssetArticle_" @submit.prevent="saveItem">
                        <v-container>

                            <div class="d-flex">
                                <v-expansion-panels v-model="panel" multiple outlined>

                                    <v-expansion-panel>
                                        <v-expansion-panel-header>Información general del
                                            activo</v-expansion-panel-header>
                                        <v-expansion-panel-content>

                                            <v-row>
                                                <!--? Descripción  -->
                                                <v-col cols=12 class="my-0 py-0">
                                                    <v-text-field autofocus v-model="editedItem.description"
                                                        :counter="255" label="* Descripción del activo"
                                                        :rules="rules.description"></v-text-field>
                                                </v-col>
                                                <!--? Inventario  -->
                                                <v-col cols=12 md="6" class="my-0 py-0">
                                                    <v-text-field v-model="editedItem.inventory"
                                                        label="* Inventario actual" :counter="50"
                                                        :rules="rules.inventory"></v-text-field>
                                                </v-col>

                                                <!--? Inventario Anterior  -->
                                                <v-col cols=12 md="6" class="my-0 py-0">
                                                    <v-text-field v-model="editedItem.old_inventory"
                                                        label="Inventario anterior" :counter="50"
                                                        :rules="rules.old_inventory"></v-text-field>
                                                </v-col>

                                                <!--? Serial  -->
                                                <v-col cols=12 md="12" class="my-0 py-0">
                                                    <v-text-field v-model="editedItem.serial" label="Serial"
                                                        :counter="100" :rules="rules.serial"></v-text-field>
                                                </v-col>

                                                <!--? Estado  -->
                                                <v-col cols=12 md="6" class="my-0 py-0">
                                                    <v-autocomplete v-model="editedItem.status_id" item-value="id"
                                                        item-text="description" :items="allAssetStatus"
                                                        id="searchFormType" label="* Estado"
                                                        placeholder="Seleccione el estado"
                                                        :rules="rules.generalRequired" required
                                                        clearable></v-autocomplete>
                                                </v-col>


                                                <v-switch v-model="editedItem.found" label="Elemento encontrado"
                                                    required dense hide-details>
                                                    <template v-slot:label>
                                                        {{ editedItem.found ? 'Elemento encontrado' : 'Elemento no encontrado' }}
                                                    </template>
                                                </v-switch>


                                                <!--? Fecha de compra  -->
                                                <v-col cols=12 md="6" class="my-0 py-0">
                                                    <!-- <v-menu v-model="menFechIni" :close-on-content-click="false"
                                                        :nudge-right="40" transition="scale-transition" offset-y
                                                        min-width="290px">
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field v-model="editedItem.buy_date"
                                                                label="* Fecha de compra" append-icon="fa-calendar-alt"
                                                                readonly v-on="on" id="buy_date" clearable
                                                                placeholder="Día, Mes, Año"
                                                                :rules="rules.generalRequired"></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="editedItem.buy_date"
                                                            @input="menFechIni = false" :max="fechaHoy()"></v-date-picker>
                                                    </v-menu> -->

                                                    <v-menu v-model="menFechIni" :close-on-content-click="false"
                                                        :nudge-right="40" transition="scale-transition" offset-y
                                                        min-width="290px">
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field v-model="editedItem.buy_date"
                                                                label="* Fecha de compra" append-icon="fa-calendar-alt"
                                                                :readonly="!editingDate" v-on="on" id="buy_date"
                                                                clearable placeholder="Año-Mes-Día"
                                                                :rules="rules.generalRequired"
                                                                @focus="editingDate = true"
                                                                @blur="editingDate = false"></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="editedItem.buy_date"
                                                            @input="menFechIni = false"
                                                            :max="fechaHoy()"></v-date-picker>
                                                    </v-menu>
                                                </v-col>

                                                <!--? Costo  -->
                                                <v-col cols="12" md="6" class="my-0 py-0">
                                                    <div>
                                                        <vuetify-money v-model="editedItem.cost"
                                                            label="Valor en pesos colombianos"
                                                            placeholder="Escriba el valor en pesos colombianos"
                                                            :readonly="false" :disabled="false" :outlined="false"
                                                            clearable valueWhenIsEmpty="0" :options="configCurrency"
                                                            :rules="rules.cost" />
                                                    </div>
                                                </v-col>

                                                <!--? Identificación del responsable  -->
                                                <v-col cols=12 md="6" class="my-0 py-0">
                                                    <v-text-field v-model="editedItem.third.identification"
                                                        label="* Número de documento del responsable" readonly
                                                        :rules="rules.generalRequired">
                                                        <template v-slot:append v-if="!disabled">
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon v-on="on" color="green darken-3"
                                                                        @click="$refs.selectThird_.show()">
                                                                        fa-user-circle
                                                                    </v-icon>
                                                                </template>
                                                                Seleccionar Responsable
                                                            </v-tooltip>

                                                            <v-tooltip bottom v-if="editedItem.third.id && !disabled">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon v-on="on" color="orange darken-3"
                                                                        @click="clearThird()" ml-2>
                                                                        fa-times-circle
                                                                    </v-icon>
                                                                </template>
                                                                Limpiar datos
                                                            </v-tooltip>

                                                        </template>
                                                    </v-text-field>
                                                </v-col>

                                                <!--? Nombre del responsable  -->
                                                <v-col cols=12 md="6" class="my-0 py-0">
                                                    <v-text-field v-model="editedItem.third.name"
                                                        label="Nombre del responsable" readonly
                                                        :rules="rules.third"></v-text-field>
                                                </v-col>

                                                <!--? NIT del proveedor  -->
                                                <v-col cols=12 md="6" class="my-0 py-0">
                                                    <v-text-field v-model="editedItem.supplier.nit"
                                                        label="* NIT del proveedor" readonly
                                                        :rules="rules.generalRequired">
                                                        <template v-slot:append v-if="!disabled">
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon v-on="on" color="green darken-3"
                                                                        @click="$refs.selectSupplier_.show()">
                                                                        fa-people-carry
                                                                    </v-icon>
                                                                </template>
                                                                Seleccionar proveedor
                                                            </v-tooltip>

                                                            <v-tooltip bottom
                                                                v-if="editedItem.supplier.id && !disabled">
                                                                <template v-slot:activator="{ on }" pr-2>
                                                                    <v-icon v-on="on" color="orange darken-3"
                                                                        @click="clearSupplier()">
                                                                        fa-times-circle
                                                                    </v-icon>
                                                                </template>
                                                                Limpiar datos
                                                            </v-tooltip>
                                                        </template>
                                                    </v-text-field>
                                                </v-col>

                                                <!--? Nombre del proveedor  -->
                                                <v-col cols=12 md="6" class="my-0 py-0">
                                                    <v-text-field v-model="editedItem.supplier.name"
                                                        label="Nombre del proveedor" readonly
                                                        :rules="rules.generalRequired"></v-text-field>
                                                </v-col>

                                                <!--? Observaciones  -->
                                                <v-col cols=12 class="my-0 py-0">
                                                    <v-textarea v-model="editedItem.observations" label="Observaciones"
                                                        rows="2"></v-textarea>
                                                </v-col>

                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>

                                    <v-expansion-panel>
                                        <v-expansion-panel-header>Ubicación del activo</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <!--? Campus  -->
                                                <v-col cols=12 md="6" class="my-0 py-0">
                                                    <v-autocomplete v-model="editedItem.campus_id" item-value="id"
                                                        item-text="name" :items="allCampuses" id="searchFormType"
                                                        label="* Campus" placeholder="Seleccione el Campus" required
                                                        clearable :rules="rules.generalRequired"></v-autocomplete>
                                                </v-col>

                                                <!--? Edificio  -->
                                                <v-col cols=12 md="6" class="my-0 py-0">
                                                    <v-autocomplete v-model="editedItem.building_id" item-value="id"
                                                        item-text="name" :items="allBuildings" id="searchFormType"
                                                        label="* Edificio" placeholder="Seleccione el Edificio" required
                                                        clearable :rules="rules.generalRequired"></v-autocomplete>
                                                </v-col>

                                                <!--? Piso  -->
                                                <v-col cols=12 md="6" class="my-0 py-0">
                                                    <v-autocomplete v-model="editedItem.tier_id" item-value="id"
                                                        item-text="name" :items="allTiers" id="searchFormType"
                                                        label="* Piso" placeholder="Seleccione el piso" required
                                                        clearable :rules="rules.generalRequired"></v-autocomplete>
                                                </v-col>

                                                <!--? Area  -->
                                                <v-col cols=12 md="6" class="my-0 py-0">
                                                    <v-autocomplete v-model="editedItem.area_id" item-value="id"
                                                        item-text="name" :items="allAreas" id="searchFormType"
                                                        label="* Area" placeholder="Seleccione el área" required
                                                        clearable :rules="rules.generalRequired"></v-autocomplete>
                                                </v-col>

                                                <!--? Código del centro de costos  -->
                                                <v-col cols=12 md="6" class="my-0 py-0">
                                                    <v-text-field v-model="editedItem.cost_center.cost_center_code"
                                                        label="* Código centro de costos" readonly
                                                        :rules="rules.generalRequired">
                                                        <template v-slot:append v-if="!disabled">
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon v-on="on" color="green darken-3"
                                                                        @click="$refs.selectCostCenter_.show()">
                                                                        fa-city
                                                                    </v-icon>
                                                                </template>
                                                                Seleccionar Centro de Costros
                                                            </v-tooltip>

                                                            <v-tooltip bottom
                                                                v-if="editedItem.cost_center.id && !disabled">
                                                                <template v-slot:activator="{ on }" pr-2>
                                                                    <v-icon v-on="on" color="orange darken-3"
                                                                        @click="clearCostCenter()">
                                                                        fa-times-circle
                                                                    </v-icon>
                                                                </template>
                                                                Limpiar datos
                                                            </v-tooltip>
                                                        </template>
                                                    </v-text-field>
                                                </v-col>

                                                <!--? Nombre del centro de costos  -->
                                                <v-col cols=12 md="6" class="my-0 py-0">
                                                    <v-text-field v-model="editedItem.cost_center.name"
                                                        label="Nombre del centro de costos" readonly
                                                        :rules="rules.generalRequired"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>

                                    </v-expansion-panel>

                                </v-expansion-panels>
                            </div>

                        </v-container>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="secondary" @click.stop="closeItem">Cancelar</v-btn>
                    <v-btn color="primary" @click.stop="saveItem">{{ viewMode }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <SelectAssetThird ref="selectThird_" @setThird="setThird" />
        <SelectSupplier ref="selectSupplier_" @setSupplier="setSupplier" />
        <SelectCostCenter ref="selectCostCenter_" @setCostCenter="setCostCenter" />

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import SelectThird from '@/views/general/Third/SelectThird'
import SelectAssetThird from '../thirds/SelectAssetThird';
import SelectSupplier from '../../loan_consumables/suppliers/SelectSupplier.vue';
import SelectCostCenter from '../../general/CostCenter/SelectCostCenter.vue';
import { mostrarSweetAlert } from '../../../util/sweetAlertUtil';


export default {
    name: 'FormAssetArticle',
    props: {},
    components: { SelectAssetThird, SelectSupplier, SelectCostCenter },
    data: () => {
        return {
            dialog: false,
            loading: false,
            disabled: false,
            panel: [0, 0],
            valid: true,
            menFechIni: false,
            status: {},
            configCurrency: {
                locale: "es-CO",
                prefix: "$",
                suffix: "",
                length: 30,
                precision: 2,
                decimal: '.',
            },
            editingDate: false, // Agregar esta variable
            rules: {
                description: [
                    (v) => !!v || 'La descripción es requerida',
                    (v) => (v && v.length <= 255) || 'La descripción solo puede contener 255 carácteres'
                ],
                inventory: [
                    (v) => !!v || 'El inventario es requerido',
                    (v) => (v && v.length <= 50) || 'El inventario solo puede contener 50 carácteres'
                ],
                old_inventory: [
                    v => !v || (v.length <= 50) || 'El inventario anterior debe tener como máximo 50 caracteres'
                ],
                serial: [
                    v => !v || (v.length <= 50) || 'El serial debe tener como máximo 100 carácteres'
                ],
                cost: [
                    (v) => !!v || 'El costo es requerido',
                ],
                generalRequired: [
                    (v) => !!v || 'El campo es requerido',
                ]
            },
            editedItem: {
                found: true,
                third: {},
                supplier: {},
                cost_center: {}
            },
        }
    },
    methods: {
        ...mapActions(['chgLoad', 'getCampuses', 'getBuildings', 'getTiers', 'getAreas', 'getAssetStatus', 'saveAssetArticle']),

        fechaHoy() {
            return new Date().toISOString().substr(0, 10)
        },

        async openItem() {
            this.chgLoad(true);
            await Promise.all([
                this.getCampuses(),
                this.getBuildings(),
                this.getTiers(),
                this.getAreas(),
                this.getAssetStatus()
            ]);
            this.chgLoad(false);
            this.dialog = true
        },

        async editItem(item) {
            this.disabled = true;
            this.chgLoad(true);
            await Promise.all([
                this.getCampuses(),
                this.getBuildings(),
                this.getTiers(),
                this.getAreas(),
                this.getAssetStatus()
            ]);
            this.editedItem = Object.assign({}, item);
            this.chgLoad(false);
            this.dialog = true

            // console.log(item)
        },

        closeItem() {
            this.dialog = false
            this.editedItem = {
                third: {},
                supplier: {},
                cost_center: {}
            }
            this.disabled = false
            this.$refs.formAssetArticle_.reset();
        },

        async saveItem() {
            if (this.$refs.formAssetArticle_.validate()) {
                mostrarSweetAlert({
                    titulo: 'Confirmación',
                    texto: '¿Estás seguro de que deseas guardar?',
                    icono: 'warning',
                    textoBotonConfirmar: 'Confirmar',
                    textoBotonCancelar: 'Cancelar',
                })
                    .then(async (res) => {
                        if (res.isConfirmed) {
                            this.chgLoad(true);
                            try {
                                var msg = "";
                                msg = await this.saveAssetArticle(this.editedItem)
                                this.$root.$alert({ text: msg.message, color: "success", timeout: 2000 })
                                this.closeItem();
                            }
                            catch (error) {
                                this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 });
                                this.chgLoad(false);

                            }
                            this.chgLoad(false);
                        }
                    })
            }
            else {
                this.$root.$alert({ text: "Revise los errores", color: "error", timeout: 2000 });
            }

        },

        setThird(third) {
            this.editedItem.third = third
        },

        clearThird() {
            this.editedItem.third = {}
        },

        setSupplier(supplier) {
            this.editedItem.supplier = supplier
        },

        clearSupplier() {
            this.editedItem.supplier = {}
        },

        setCostCenter(cost_center) {
            this.editedItem.cost_center = cost_center
        },

        clearCostCenter() {
            this.editedItem.cost_center = {}
        },

    },
    computed: {
        ...mapGetters(['allCampuses', 'allBuildings', 'allTiers', 'allAreas', 'allAssetStatus']),
        formTitle() {
            return !this.editedItem.id ? 'Nuevo Registro' : 'Editar Registro'
        },

        viewMode() {
            return !this.editedItem.id ? 'Guardar' : 'Editar'
        },
    },

}
</script>

<style lang="scss" scoped></style>