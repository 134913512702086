<template>
    <div>
        <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="1000px"
            transition="dialog-transition">
            <v-card>
                <v-toolbar dense color="primary" dark>
                    <v-toolbar-title>Historial del artículo</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="closeItem">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-card-text>
                    <v-container fluid mt-3>
                        <v-row v-if="allAssetHistory.length > 0">
                            <v-col cols="12" v-for="item in allAssetHistory" :key="item.id">
                                <v-card>
                                    <v-card-title class="my-0 py-0">
                                        {{ item.action }}
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col cols="12" md="12" class="my-0 py-0"
                                                    v-if="item.previous_description">
                                                    <span class="font-weight-bold">Descripción: </span>
                                                    <span class="info--text">{{ item.previous_description }}</span>
                                                </v-col>
                                                <v-col cols="12" md="6" class="my-0 py-0"
                                                    v-if="item.inventory_change_new != 0">
                                                    <span class="font-weight-bold">Inventario Actual: </span>
                                                    <span class="info--text">{{ item.inventory_change_new }}</span>
                                                </v-col>
                                                <v-col cols="12" md="6" class="my-0 py-0"
                                                    v-if="item.inventory_change_old != 0">
                                                    <span class="font-weight-bold">Inventario Anterior: </span>
                                                    <span class="info--text">{{ item.inventory_change_old }}</span>
                                                </v-col>
                                                <v-col cols="12" md="6" class="my-0 py-0"
                                                    v-if="item.previous_status_id">
                                                    <span class="font-weight-bold">Estado: </span>
                                                    <span class="info--text">{{ item.status.description }}</span>
                                                </v-col>
                                                <v-col cols="12" md="6" class="my-0 py-0"
                                                    v-if="item.previous_found !== null">
                                                    <span class="font-weight-bold">Elemento encontrado: </span>
                                                    <span class="info--text">{{ item.previous_found ? 'Si' : 'No'
                                                        }}</span>
                                                </v-col>
                                                <v-col cols="12" md="6" class="my-0 py-0"
                                                    v-if="item.serial_number_change != 0">
                                                    <span class="font-weight-bold">Serial: </span>
                                                    <span class="info--text">{{ item.serial_number_change }}</span>
                                                </v-col>
                                                <v-col cols="12" md="6" class="my-0 py-0" v-if="item.buy_date_change">
                                                    <span class="font-weight-bold">Fecha de compra: </span>
                                                    <span class="info--text">{{ item.buy_date_change }}</span>
                                                </v-col>
                                                <v-col cols="12" md="6" class="my-0 py-0" v-if="item.cost_change != 0">
                                                    <span class="font-weight-bold">Costo: </span>
                                                    <span class="info--text">{{ formatCurrency(item.cost_change)
                                                        }}</span>
                                                </v-col>
                                                <v-col cols="12" md="6" class="my-0 py-0"
                                                    v-if="item.previous_supplier_id">
                                                    <span class="font-weight-bold">NIT del proveedor: </span>
                                                    <span class="info--text">{{ item.supplier.nit }}</span>
                                                </v-col>

                                                <v-col cols="12" md="6" class="my-0 py-0"
                                                    v-if="item.previous_supplier_id">
                                                    <span class="font-weight-bold">Nombre del Proveedor: </span>
                                                    <span class="info--text">{{ item.supplier.name }}</span>
                                                </v-col>
                                                <v-col cols="12" md="6" class="my-0 py-0"
                                                    v-if="item.previous_campus_id">
                                                    <span class="font-weight-bold">Campus: </span>
                                                    <span class="info--text">{{ item.campus.name }}</span>
                                                </v-col>
                                                <v-col cols="12" md="6" class="my-0 py-0"
                                                    v-if="item.previous_building_id">
                                                    <span class="font-weight-bold">Edificio: </span>
                                                    <span class="info--text">{{ item.building.name }}</span>
                                                </v-col>
                                                <v-col cols="12" md="6" class="my-0 py-0" v-if="item.previous_tier_id">
                                                    <span class="font-weight-bold">Piso: </span>
                                                    <span class="info--text">{{ item.tier.name }}</span>
                                                </v-col>
                                                <v-col cols="12" md="6" class="my-0 py-0" v-if="item.previous_area_id">
                                                    <span class="font-weight-bold">Área: </span>
                                                    <span class="info--text">{{ item.area.name }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="6" class="my-0 py-0"
                                                    v-if="item.previous_cost_center_id">
                                                    <span class="font-weight-bold">Código del centro de costos: </span>
                                                    <span class="info--text">{{ item.cost_center.cost_center_code
                                                        }}</span>
                                                </v-col>

                                                <v-col cols="12" md="6" class="my-0 py-0"
                                                    v-if="item.previous_cost_center_id">
                                                    <span class="font-weight-bold">Nombre del centro de costos: </span>
                                                    <span class="info--text">{{ item.cost_center.name }}</span>
                                                </v-col>
                                                <v-col cols="12" md="6" class="my-0 py-0"
                                                    v-if="item.previous_responsible_id">
                                                    <span class="font-weight-bold">Documento del responsable anterior:
                                                    </span>
                                                    <span class="info--text">{{ item.third_old.identification }}</span>
                                                </v-col>

                                                <v-col cols="12" md="6" class="my-0 py-0"
                                                    v-if="item.previous_responsible_id">
                                                    <span class="font-weight-bold">Nombre del responsable anterior:
                                                    </span>
                                                    <span class="info--text">{{ item.third_old.name }}</span>
                                                </v-col>

                                                <v-col cols="12" md="6" class="my-0 py-0"
                                                    v-if="item.new_responsible_id">
                                                    <span class="font-weight-bold">Documento del nuevo responsable:
                                                    </span>
                                                    <span class="info--text">{{ item.third_new.identification }}</span>
                                                </v-col>

                                                <v-col cols="12" md="6" class="my-0 py-0"
                                                    v-if="item.new_responsible_id">
                                                    <span class="font-weight-bold">Nombre del nuevo responsable: </span>
                                                    <span class="info--text">{{ item.third_new.name }}</span>
                                                </v-col>
                                                <v-col cols="12" md="12" class="my-0 py-0" v-if="item.observation">
                                                    <span class="font-weight-bold">Observaciones: </span>
                                                    <span class="info--text">{{ item.observation }}</span>
                                                </v-col>
                                                <v-col cols="12" md="6" class="my-0 py-0">
                                                    <span class="font-weight-bold">Fecha de realización: </span>
                                                    <span class="info--text">{{ item.dateRegister }}</span>
                                                </v-col>
                                                <v-col cols="12" md="6" class="my-0 py-0">
                                                    <span class="font-weight-bold">Realizado por: </span>
                                                    <span class="info--text">{{ item.user.name }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                                <!-- <v-divider></v-divider> -->
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12">
                                <v-card>
                                    <v-card-title primary-title>
                                        No existen movimientos en el activo fijo
                                    </v-card-title>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click.stop="closeItem">Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: "HistoryArticles",
    data: () => {
        return {
            dialog: false,
            editedItem: {},
            moneyOptions: {
                decimal: ',',
                thousands: '.',
                prefix: '$ ',
                precision: 2,
                masked: false,
            },
            transfers: [] // Aquí almacenaremos los traslados
        }
    },
    methods: {
        ...mapActions(['chgLoad', 'getHistoryAsset']),

        async openItem(item) {
            this.chgLoad(true)
            try {
                await this.getHistoryAsset(item);
                this.chgLoad(false);
            }
            catch (error) {
                this.$root.$alert({ text: 'Se ha presentado un error', color: "error", timeout: 2000 });
                this.chgLoad(false);
            }
            this.dialog = true
        },

        closeItem() {
            this.editedItem = {
                status: {},
                third: {},
                cost_center: {},
                supplier: {},
                campus: {},
                building: {},
                tier: {},
                area: {},
            }
            this.dialog = false
        },
        formatCurrency(value) {
            // Formatear el valor como costo con 2 decimales y separadores de miles
            const formattedValue = new Intl.NumberFormat('es-CO', {
                style: 'currency',
                currency: 'COP',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(value);

            return formattedValue;
        },


    },
    mounted() {
    },
    computed: {
        ...mapGetters(['allAssetHistory'])
    }
}
</script>

<style lang="scss" scoped></style>
