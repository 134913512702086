<template>
  <div>
    <v-data-table v-if="editedDrop.id==0"
      :headers="headers"
      :items="editedDrop.equipment"
      item-key="id"
      sort-by="description"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar color="white">
          Equipos
          <v-spacer></v-spacer>
          <template v-if="editedDrop.id == 0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2 my-2" small fab dark color="primary" v-on="on" @click.stop="newEquipment" id="newEquipment">
                  <v-icon small dark>fa-plus</v-icon>
                </v-btn>
              </template>
              <span>Nuevo</span>
            </v-tooltip>
          </template>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <template v-if="editedDrop.id == 0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="deleteItem(item)" v-on="on" class="mr-1 my-1" color="error">fa-trash</v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </template>
      <template v-slot:item.pivot.quantity="props">
        <v-text-field v-if="!props.item.inventory" class="text-short"
          v-model="props.item.pivot.quantity"
          label="Cantidad" single-line counter
          type="number"
          hide-details
          required
        />
        <template v-else>{{ props.item.pivot.quantity }}</template>
      </template>
    </v-data-table>
    <v-simple-table dense v-else class="elevation-1">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center body-2 font-weight-bold" colspan="2">Equipos</th>
          </tr>
          <tr>
            <th class="text-left">Descripción</th>
            <th class="text-left">Serial</th>
            <th class="text-left">Inventario</th>
            <th class="text-left">Cantidad</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in editedDrop.equipment" :key="item.id">
            <td>{{ item.description }}</td>
            <td>{{ item.serial }}</td>
            <td>{{ item.inventory }}</td>
            <td>{{ item.pivot.quantity }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!--Dialogo-->
    <v-dialog v-model="dialog" max-width="1000px" scrollable :persistent="editedDrop.id!=0">
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>
            {{formTitle}}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                <v-icon class="ml-2"></v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-toolbar>

        <v-card-text>
          <v-text-field
            v-model="search"
            append-icon="fa-search"
            label="Buscar"
            id="equipment_id"
            single-line
            hide-details
            @click:row="save"
          ></v-text-field>
          <v-data-table v-if="editedDrop.id==0"
            v-model="selEquipment"
            :headers="header2"
            :items="equipment"
            item-key="id"
            :search="search"
            sort-by="description"
            show-select
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'fa-angle-double-left',
              lastIcon: 'fa-angle-double-right',
              prevIcon: 'fa-angle-left',
              nextIcon: 'fa-angle-right'
            }"
          >
            <template v-slot:item.available="{ item }">
              {{available(item)}}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
          <v-btn color="primary darken-1" outlined @click="save">{{ viewMode }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  export default{
    name: 'DropEquipment',
    props: {
      editedDrop: {
        type: Object,
        default: null
      },
      equipment: {
        type: Array,
        default: null
      }
    },
    data() {
      return {
        search:'',
        dialog: false,
        rules: {
          id: [
            v => !!v || 'Campo Requerido',
            //v => v < this.fechaHoy() || 'La fecha debe ser mayor a hoy',
          ],
        },
        headers: [
            { text: 'Descripción', value: 'description' },
            { text: 'Serial', value: 'serial' },
            { text: 'Inventario', value: 'inventory' },
            { text: 'Cant', value: 'pivot.quantity' },
            { text: 'Acciones', value: 'action', sortable: false },
        ],
        header2: [
            { text: 'Id', value: 'id' },
            { text: 'Descripción', value: 'description' },
            { text: 'Serial', value: 'serial' },
            { text: 'Inventario', value: 'inventory' },
            { text: 'Disponible', value: 'available' },
        ],
        selEquipment: [],
        editedIndex: -1,
        editedItem: {id: 0, equipment_id: 0, description:'', serial: '', equipment_type_id: 0, type: {}},
        defaultItem: {id: 0, equipment_id: 0, description:'', serial: '', equipment_type_id: 0, type: {}},
        valid: true
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Equipo' : 'Editar Equipo'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      async initialize () {
      },

      newEquipment(){
        this.dialog = true
        this.selEquipment = this.editedDrop.equipment
        setTimeout(function(){ document.getElementById('equipment_id').focus(); }, 500);
      },

      available(item){
        return parseInt(item.pivot.stock)-parseInt(item.pivot.borrowed)
      },

      deleteItem (item) {
        this.editedIndex = this.editedDrop.equipment.indexOf(item)
        this.$root.$confirm('Eliminar Equipo', 'Esta Seguro?', { color: 'red' })
        .then((res) => {
          if(res){
            this.editedDrop.equipment.splice(this.editedIndex, 1)
            this.$root.$alert({text:"¡ Equipo Eliminado !",color:"success",timeout:1000})
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

      close () {
        this.dialog = false
        this.selEquipment = []
        setTimeout(() => {
          document.getElementById('newEquipment').focus()
        }, 300)
      },

      save () {
        console.log(this.selEquipment)
        this.editedDrop.equipment = this.selEquipment
        console.log(this.editedDrop.equipment)
        this.close()
      },
    }
  }
</script>
<style scoped>
.text-short {
  max-width: 3.5em;
}
</style>
