import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance;
const URL_ = URL_APP + "/api/loan_assets/articles";

const state = {
  assetArticles: [],
  infoAssetArticles: [],
  assetArticlesT: 0,
  assetStatus: [],
  assetHistory: [],
};

const getters = {
  allAssetArticles: (state) => state.assetArticles,
  totalAssetArticles: (state) => state.assetArticlesT,
  allInfoAssetArticles: (state) => state.infoAssetArticles,
  allAssetStatus: (state) => state.assetStatus,
  allAssetHistory: (state) => state.assetHistory,
};

const actions = {
  async getAssetArticles({ commit }, params) {
    return new Promise(function (resolve, reject) {
      commit("setAssetArticles", []);
      axios
        .get(URL_ + "?page=" + params.options.page, { params })
        .then((res) => {
          commit("setAssetArticles", res.data.data);
          commit("setAssetArticlesT", res.data.total);
          resolve("OK");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async getAssetStatus({ commit }) {
    return new Promise(function (resolve, reject) {
      commit("setAssetStatus", []);
      axios
        .get(URL_APP + "/api/loan_assets/status")
        .then((res) => {
          commit("setAssetStatus", res.data);
          resolve("OK");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async saveAssetArticle({ commit }, article) {
    return new Promise((resolve, reject) => {
      axios
        .post(URL_, article)
        .then((res) => {
          commit("newAssetArticle", res.data.article);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async updateAssetArticle({ commit }, article) {
    return new Promise((resolve, reject) => {
      axios
        .put(URL_, article)
        .then((res) => {
          commit("updateAssetArticle", res.data.article);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async saveAssetDeprecation({ commit }, article) {
    return new Promise((resolve, reject) => {
      axios
        .put(URL_APP + "/api/loan_assets/assetDeprecation", article)
        .then((res) => {
          commit("saveAssetDeprecation", res.data.article);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  //TODO: Realizar el traslado de activos entre responsables
  async transferBetweenUsers({ commit }, article) {
    return new Promise((resolve, reject) => {
      axios
        .put(URL_APP + "/api/loan_assets/transfer_between_user", article)
        .then((res) => {
          commit("transferBetweenUsers", res.data.article);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  //TODO: Baja masiva
  async saveMassiveDeprecation({ commit }, article) {
    return new Promise((resolve, reject) => {
      axios
        .put(URL_APP + "/api/loan_assets/save_deprecation_massive_assets", article)
        .then((res) => {
          commit("saveMassiveDeprecation", res.data.article);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async getHistoryAsset({ commit }, article) {
    return new Promise((resolve, reject) => {
      axios
        .get(URL_APP + "/api/loan_assets/history", { params: article })
        .then((res) => {
          commit("setHistoryAsset", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async saveActivationAsset({ commit }, article) {
    return new Promise((resolve, reject) => {
      axios
        .put(URL_APP + "/api/loan_assets/activateAsset", article)
        .then((res) => {
          commit("setActivationAsset", res.data.article);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  setAssetArticles: (state, assetArticles) =>
    (state.assetArticles = assetArticles),
  setAssetArticlesT: (state, total) => (state.assetArticlesT = total),
  setAllInfoAssetArticles: (state, assetArticles) =>
    (state.infoAssetArticles = assetArticles),
  setAssetStatus: (state, assetStatus) => (state.assetStatus = assetStatus),

  setHistoryAsset: (state, assetHistory) => (state.assetHistory = assetHistory),

  newAssetArticle: (state, article) => {
    state.assetArticles.unshift(article);
    state.assetArticlesT = state.assetArticlesT + 1;
  },
  updateAssetArticle: (state, article) => {
    const index = state.assetArticles.findIndex((u) => u.id === article.id);
    if (index !== -1) {
      state.assetArticles.splice(index, 1, article);
    }
  },
  saveAssetDeprecation: (state, article) => {
    const index = state.assetArticles.findIndex((u) => u.id === article.id);
    if (index !== -1) {
      state.assetArticles.splice(index, 1, article);
    }
  },
  setActivationAsset: (state, article) => {
    const index = state.assetArticles.findIndex((u) => u.id === article.id);
    if (index !== -1) {
      state.assetArticles.splice(index, 1, article);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
