import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/inventory_files"

const state = {
  inventory_files: [],
  inventoryFilesT: 0
}

const getters = {
  allInventoryFiles: state => state.inventory_files,
  totalInventoryFiles: state => state.inventoryFilesT
}

const actions = {

  async getInventoryFiles({commit}, params){
    return new Promise(function(resolve, reject){
      commit('setInventoryFiles', [])
      axios.get(URL_+'?page='+params.options.page, {
        params
      }).then(res=>{
        commit('setInventoryFiles', res.data.data)
        commit('setInventoryFilesT', res.data.total)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async saveInventoryFile({commit}, inventory_file){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, inventory_file).then(res=>{
        commit('newInventoryFile', res.data.file)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async updateInventoryFile({commit}, inventory_file){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, inventory_file).then(res=>{
        //console.log(res.data)
        commit('updInventoryFile', res.data.file)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deleteInventoryFile({commit}, inventory_file){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${inventory_file.id}`).then(res=>{
        commit('delInventoryFile', inventory_file)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setInventoryFiles : (state, inventory_files) => state.inventory_files = inventory_files,
  setInventoryFilesT : (state, total) => state.inventoryFilesT = total,
  newInventoryFile  : (state, inventory_file) => {
    state.inventory_files.unshift(inventory_file)
    state.inventoryFilesT = state.inventoryFilesT+1 
  },
  updInventoryFile  : (state, inventory_file) => {
    const index = state.inventory_files.findIndex(if_ => if_.id === inventory_file.id)
    if (index !== -1) state.inventory_files.splice(index, 1, inventory_file)
  },
  delInventoryFile  : (state, inventory_file) => {
    state.inventory_files = state.inventory_files.filter(c => c.id !== inventory_file.id)
    state.inventoryFilesT = state.inventoryFilesT-1 
  }
}

export default{
  state, getters, actions, mutations
}