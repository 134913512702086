<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px" scrollable persistent>
      <v-card>
        <v-toolbar color="error" dense dark>
          <v-icon class="mx-2 my-2">fa-exclamation-triangle</v-icon>
          <v-toolbar-title>
              Errores Encontrados
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon @click.stop="dialog=false">fa-times</v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <p class="body-1 mt-3">
            <b>Errores Encontrados:</b><br>
          </p>
          <template v-for="error in errors">
              <v-row :key="error.row">
                <v-col cols="12" class="my-0 py-0">
                  <template v-for="(error2, index2) in error.errors">
                    <v-sheet
                      class="text-caption px-2 mt-3 error--text"
                      color="red lighten-5"
                      elevation="1"
                      :key="'error2'+index2"
                    ><b>Fila: </b>{{ error.row }} - <b>Error: </b>{{ error2 }}</v-sheet>
                  </template>
                </v-col>
              </v-row>            
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="dialog=false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  export default{
    name: 'formErrors',
    props: {
      errors: {
        type: Array,
        default: null
      },
    },
    data() {
      return {
        dialog: false,
      }
    },

    methods: {

      show(){
        this.dialog = true
      },

    }
  }
</script>
