var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"max-width":"1000px","scrollable":"","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary","dense":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.formTitle)+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mx-2 my-2",attrs:{"text":"","fab":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},on),[_vm._v(" X ")])]}}])},[_c('span',[_vm._v("Cerrar")])])],1),_c('v-card-text',[_c('v-text-field',{attrs:{"autofocus":"","append-icon":"fa-search","label":"Buscar","id":"equipment_id","single-line":"","hide-details":""},on:{"click:row":_vm.addItem},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"headers":_vm.header,"items":_vm.list_equipment,"search":_vm.search,"sort-by":"description","footer-props":{
            showFirstLastPage: true,
            firstIcon: 'fa-angle-double-left',
            lastIcon: 'fa-angle-double-right',
            prevIcon: 'fa-angle-left',
            nextIcon: 'fa-angle-right'
          }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',{staticClass:"cursor:pointer",on:{"click":function($event){return _vm.addItem(item)}}},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.description))]),_c('td',[_vm._v(_vm._s(item.serial))]),_c('td',[_vm._v(_vm._s(item.inventory))]),_c('td',[_vm._v(_vm._s(item.type.name))])])]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"info darken-1","outlined":""},on:{"click":_vm.close}},[_vm._v("Terminar")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }