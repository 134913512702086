  <template>
    <div>
    <v-card>
      <v-card-title>
        <span class="headline">Traslados de Equipos</span>
      </v-card-title>
      <v-card-text>
        <FilterQuery :filters="filters" :fieldFilters="fieldFilters" @freshFilters="initialize" id="filterQuery"></FilterQuery>
        <v-data-table
          :headers="headers"
          :items="allEquipmentTransfers"
          :search="search"
          :loading="loading"
          :options.sync="options"
          :sort-by.sync="options.sortBy"
          :sort-desc.sync="options.sortDesc"
          :items-per-page.sync="options.per_page"
          :server-items-length="totalEquipmentTransfer"
          multi-sort
          loading-text="Cargando Registros ..."
          class="elevation-1 mt-1"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <!--<v-text-field clearable
                v-model="search"
                append-icon="fa-search"
                label="Buscar"
                single-line
                hide-details
                @keypress.enter="initialize2(1)"
                @click:clear="initialize2(1)"
              ></v-text-field> -->
              <v-spacer></v-spacer>
              <v-tooltip right v-if="can('loan.equipment_transfer.create')">
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn class="mx-2" fab dark small color="primary" v-on="{ ...tooltip }" id="newTransfer" @click="$refs.formEquipmentTransfer.editItem()">
                    <v-icon dark>fa-plus</v-icon>
                  </v-btn>
                </template>
                <span>Nuevo Traslado</span>
              </v-tooltip>
            </v-toolbar>
          </template>

          <template v-slot:item.fields="{ item }">
            <template v-for="(field, indexDet) in item.fields" >
              <v-chip color="secondary" :key="indexDet" dark class="mt-1 mr-1">
                {{ field.name }}
              </v-chip>
            </template>
          </template>
          <template v-slot:item.action="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon @click="$refs.formEquipmentTransfer.editItem(item)" v-on="on" class="mr-1 my-1" color="info">fa-eye</v-icon>
              </template>
              <span>Detalle</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn text icon color="green" dark @click="initialize()" v-on="on"><v-icon dark v-on="on">fa-sync-alt</v-icon></v-btn>
              </template>
              <span>Actualizar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <FormEquipmentTransfer ref="formEquipmentTransfer" :isgroup="true"/>
  </div>
</template>
<script>
  import { can } from '../../../util/functions';
  import { mapActions, mapGetters } from 'vuex'
  import FormEquipmentTransfer from './FormEquipmentTransfer';
  import FilterQuery from '../../general/FilterQuery';

  export default{
    name: 'Loan',
    components: { FormEquipmentTransfer, 
        FilterQuery },
    data() {
      return {
        dialog: false,
        dialogFilter: false,
        notifyEmail: '',
        search: '',
        options: {page:1, per_page:10, sortBy:['created_at'], sortDesc:[true]},
        filters:[
        ],
        fieldFilters:[
          {field:'user.identity', name:'Identificación Usuario', type:'number'},
          {field:'user.name', name:'Nombre Usuario', type:'text'},
          {field:'created_at', name:'Fecha de Baja', type:'date'},
          {field:'equipment.inventory', name:'Numero Inventario de Equipo', type:'text'},
          {field:'equipment.description', name:'Descripción de Equipo', type:'text'},
        ],
        rules: {
          observation: [
            v => !!v || 'Campo Requerido'
          ],
          group_tic_id: [
            v => !!v || 'Campo Requerido'
          ],
        },
        headers: [
          { text: 'Id', value: 'id'},
          { text: 'Fecha', value: 'created_at'},
          { text: 'Usuario', value: 'user.name'},
          { text: 'Campos', value: 'fields'},
          { text: 'Acciones', value: 'action', sortable: false },
        ],
        equipment: [],
        editedIndex: -1,
        editedItem: {id: 0, old_group_id:0, observation: '', user_id:'', user:{name:''}, fields:[], old_group:{}, equipment:[]},
        defaultItem: {id: 0, old_group_id:0, observation: '', user_id:'', user:{name:''}, fields:[], old_group:{}, equipment:[]},
        valid: true
      }
    },

    computed: {
      ...mapGetters(['allEquipmentTransfers','loading','selGroupTics', 'allGroupTics','totalEquipmentTransfer']),
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Préstamo' : 'Editar Préstamo'
      },
      viewMode () {
        return this.editedIndex === -1 ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      async 'editedItem.group_tic_id' (val) {
        if (this.editedItem.id==0) this.editedItem.equipment = []
        var group = this.selGroupTics.find(g=>g.id==val)
        if(group){
          var equipmentTemp = await group.equipment.filter(e=>(parseInt(e.pivot.stock)-parseInt(e.pivot.borrowed)>0))
          this.equipment = await equipmentTemp.map((e) => {
              e.pivot.quantity = e.pivot.quantity?e.pivot.quantity:1 
              return e
            })
        }else{
          this.equipment = []
        }
      },
      'options.page' () {
        this.initialize()
      },
      'options.per_page' () {
        this.initialize()
      },
      'options.sortBy' () {
        this.initialize()
      },
      'options.sortDesc' () {
        this.initialize()
      },
    },
    mounted () {
      this.initialize()
    },
    methods: {
      ...mapActions(['getLocations', 'getCampuses', 'getBuildings', 'getTiers', 'getAreas', 'getEquipmentFields',
            'getCostCenters', 'getSelGroupTics', 'getGroupTics','getEquipmentTransfers', 'chgLoad']),
      can:can,
      async initialize () {
        if(!this.loading){
          this.getSelGroupTics()
          this.getGroupTics()
          this.getCampuses()
          this.getBuildings()
          this.getTiers()
          this.getLocations()
          this.getEquipmentFields()
          this.getAreas()
          this.getCostCenters()
          this.chgLoad(true)
          var params = {filters : this.filters, options : this.options, search : this.search}
          await this.getEquipmentTransfers(params)
          this.chgLoad(false)
        }
      },

      async initialize2 (page=null) {
        if(!this.loading){
          if (page!=null) this.options.page = page
          this.chgLoad(true)
          var params = {filters : this.filters, options : this.options, search : this.search}
          await this.getEquipmentTransfers(params)
          this.chgLoad(false)
        }
      },

      deleteItem (item) {
        this.$root.$confirm('Eliminar Equipo', 'Esta Seguro?', { color: 'red' })
        .then(async (res) => {
          if(res){
            this.chgLoad(true)
            try{
              var msg = await this.deleteEquipmentTransfer(item)
              this.$root.$alert({text:msg,color:"success",timeout:3000})
            }catch(error){
              console.error(error)
              this.$root.$alert({text:"Imposible Eliminar Registro",color:"error",timeout:3000})
            }
            this.chgLoad(false)
          }else{
            this.$root.$alert({text:"Acción Cancelada",color:"warning",timeout:1000})
          }
        })
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedItem.equipment = Array()
          this.editedIndex = -1
        }, 300)
      },

    }
  }
</script>
