<template>
  <v-overlay :value="loading" :z-index="zindex" :absolute="false">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    overlay: false,
    zindex: 10000
  }),
  computed:{
    ...mapGetters(['loading']),
  },
  methods: {
    change(value) {
      this.overlay = value
    },
  }
}
</script>