<template>
    <div>
        <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="600px" transition="dialog-transition">
            <v-card>
                <v-toolbar dense color="primary" dark>
                    Importar Unidades de Medida.
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small icon dark v-bind="attrs" v-on="on" class="mx-1" @click="close()">
                                <v-icon dark>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>

                <v-card-text>
                    <v-form ref="formImportUnitsMeasurement_" @submit.prevent="save()">
                        <v-row>
                            <v-col cols="12">
                                <v-file-input accept=".xls,.xlsx" label="Seleccionar Archivo" :rules="rules.fileImport"
                                    v-model="formImport.fileImport"></v-file-input>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="secondary" @click.stop="close">Cancelar</v-btn>
                    <v-btn color="primary" @click.stop="save">Enviar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { URL_APP } from '../../../util/constants';
import { createLinkFile, objectToFormData } from '../../../util/functions';

export default {
    name: 'ImportLoanConsumablesUnitsMeasurement',
    data() {
        return {
            dialog: false,
            formImport: {
                fileImport: null,
            },
            rules: {
                fileImport: [
                    (v) => !!v || 'El archivo es requerido',
                    v => (v && v.size < 10000000) || 'No puede Subir Archivos mayor a 10 MB!'
                ]
            }
        }
    },
    computed: {
        ...mapGetters(['loading']),
        currentUser() {
            return this.$store.getters.currentUser
        }
    },
    methods: {
        ...mapActions(['chgLoad']),

        async save() {
            if (this.$refs.formImportUnitsMeasurement_.validate()) {
                var formData = objectToFormData(this.formImport);
                this.chgLoad(true);
                try {
                    var response = await this.$http.post(URL_APP + '/api/loan_consumables/import_units_measurements', formData, {
                        responseType: 'blob'
                    })
                    if (response.data.size == 0) {
                        this.$root.$alert({ text: "Importación realizada correctamente", color: "success", timeout: 3000 })
                        this.chgLoad(false)
                        this.close()
                        await this.$emit('initialize');
                    }
                    else {
                        createLinkFile(response, "ErrorsImportSupplier.txt");
                        this.$root.$alert({ text: "Se ha presentado errores, revise el archivo.", color: "orange", timeout: 3000 })
                        this.chgLoad(false)
                        this.close()
                        await this.$emit('initialize');
                    }

                }
                catch (error) {
                    this.chgLoad(false)
                    this.$root.$alert({ text: "No se realizaron importaciones.", color: "error", timeout: 3000 })
                }
            }

        },
        open() {
            this.dialog = true
        },
        close() {
            this.dialog = false
            this.$refs.formImportUnitsMeasurement_.reset()
            this.fileImport = null
        },

    },
}
</script>

<style lang="scss" scoped></style>