<template>
    <div>
        <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="1000px"
            transition="dialog-transition">
            <v-card>
                <v-toolbar dense color="primary" dark>
                    <v-toolbar-title>Registro de terceros</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="closeItem">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-card-text>
                    <v-form v-model="valid" ref="formAssetThird_">
                        <v-container v-if="!editedItem.identification">
                            <div class="d-flex">
                                <v-row>

                                    <v-col cols=12 md="12" class="my-2 py-2">
                                        <v-text-field v-model="identification"
                                            label="* Número de documento del responsable" @keyup.enter="findThird()"
                                            autofocus>
                                            <template v-slot:append>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" color="primary " @click="findThird()">
                                                            fa-search
                                                        </v-icon>
                                                    </template>
                                                    Buscar tercero
                                                </v-tooltip>

                                            </template>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-container>

                        <v-container fluid mt-3 v-if="editedItem.identification">
                            <v-row>
                                <v-col cols="12" md="6" class="my-0 py-0">
                                    <span class="font-weight-bold">Tipo de documento: </span>
                                    <span class="info--text">{{ editedItem.type }}</span>
                                </v-col>
                                <v-col cols="12" md="6" class="my-0 py-0">
                                    <span class="font-weight-bold">Identificación: </span>
                                    <span class="info--text">{{ editedItem.identification }}</span>
                                </v-col>
                                <v-col cols="12" md="6" class="my-0 py-0">
                                    <span class="font-weight-bold">Nombres Completos: </span>
                                    <span class="info--text">{{ editedItem.name }}</span>
                                </v-col>
                                <v-col cols="12" md="6" class="my-0 py-0">
                                    <span class="font-weight-bold">Cargo: </span>
                                    <span class="info--text">{{ editedItem.position }}</span>
                                </v-col>
                                <v-col cols="12" md="6" class="my-0 py-0">
                                    <span class="font-weight-bold">Teléfono: </span>
                                    <span class="info--text">{{ editedItem.mobile }}</span>
                                </v-col>
                                <v-col cols="12" md="6" class="my-0 py-0">
                                    <span class="font-weight-bold">Correo Electrónico: </span>
                                    <span class="info--text">{{ editedItem.email }}</span>
                                </v-col>

                                <v-col cols="12" md="12" class="my-0 py-0">
                                    <span class="font-weight-bold">Departamento / Facultad: </span>
                                    <span class="info--text">{{ editedItem.department }}</span>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="secondary" @click.stop="closeItem">Cancelar</v-btn>
                    <v-btn color="primary" @click.stop="saveItem" :disabled="!editedItem.identification">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { URL_APP } from '../../../util/constants';
import { mostrarSweetAlert } from '../../../util/sweetAlertUtil';

export default {
    name: 'FormAssetThird',
    data: () => {
        return {
            dialog: false,
            identification: '',
            editedItem: {},
            tmpItem: {},
            valid: true,
            update: false
        }
    },

    methods: {
        ...mapActions(['chgLoad', 'saveAssetThird', 'updateAssetThird',]),
        openItem() {
            this.dialog = true
        },

        updateItem(item) {
            this.tmpItem = item
            this.identification = item.identification
            this.dialog = true
            this.update = true
        },

        closeItem() {
            this.dialog = false;
            this.identification = '';
            this.editedItem = {};
            this.update = false
        },

        async saveItem() {
            if (this.$refs.formAssetThird_.validate()) {
                mostrarSweetAlert({
                    titulo: 'Confirmación',
                    texto: '¿Estás seguro de que deseas guardar?',
                    icono: 'warning',
                    textoBotonConfirmar: 'Confirmar',
                    textoBotonCancelar: 'Cancelar',
                })
                    .then(async (res) => {
                        if (res.isConfirmed) {
                            if (this.update) {
                                this.chgLoad(true);
                                try {
                                    let msg = "";
                                    msg = await this.updateAssetThird(this.editedItem)
                                    this.$root.$alert({ text: msg.message, color: "success", timeout: 2000 })
                                    this.chgLoad(false);
                                }
                                catch (error) {
                                    this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 });
                                    this.chgLoad(false);
                                }
                                this.chgLoad(false);
                            }
                            else {
                                this.chgLoad(true);
                                try {
                                    let msg = "";
                                    msg = await this.saveAssetThird(this.editedItem)
                                    this.$root.$alert({ text: msg.message, color: "success", timeout: 2000 })
                                    this.closeItem();
                                }
                                catch (error) {
                                    this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 });
                                    this.chgLoad(false);
                                }
                                this.chgLoad(false);
                            }

                            this.closeItem();

                        }
                    })
            }
            else {
                this.$root.$alert({ text: "Revise los errores", color: "error", timeout: 2000 });
            }
        },

        async findThird() {
            this.chgLoad(true);
            try {
                await this.$http.get(URL_APP + "/api/loan_assets/third", {
                    params: { 'identification': this.identification }
                })
                    .then((res) => {
                        console.log(res)
                        this.editedItem = {
                            id: this.tmpItem.id,
                            identification: res.data.identification,
                            type: res.data.type,
                            name: res.data.name,
                            email: res.data.email,
                            mobile: res.data.mobile,
                            position: res.data.position,
                            department: res.data.department,
                            status: true,
                        };
                    })
                this.chgLoad(false)

            }
            catch (error) {
                this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 })
                this.chgLoad(false)
            }

        }
    },

    computed: {
        ...mapGetters(['allAssetThird'])
    }

}
</script>

<style lang="scss" scoped></style>