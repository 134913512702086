import { setAuthorization } from "./general";
import { URL_APP } from "../util/constants";

export function login(credentials){
  return new Promise((res, rej)=>{
    window.getApp.$http.post(URL_APP+'/api/auth/login', credentials)
      .then((response)=> {
        setAuthorization(response.data.token.access_token); 
        res(response.data);
      })
      .catch((err)=>{
        rej(err.response.data.message);
      })
  })
}

export function logout(){
  return new Promise((res, rej)=>{
    window.getApp.$http.post(URL_APP+'/api/auth/logout')
      .then((response)=> {
        console.log("Exitoso Logout 1");
        res(response.data);
      })
      .catch((err)=>{
        console.log(err);
        rej("Error al cerrar sesión");
      })
  })
}

export function refresh(){
  return new Promise((res, rej)=>{
    window.getApp.$http.post(URL_APP+'/api/auth/refresh')
      .then((response)=> {
        setAuthorization(response.data.token.access_token); 
        res(response.data);
      })
      .catch((err)=>{
        console.log(err);
        rej("Error al Obtener Nuevo Token");
      })
  })
}

export function getLocalUser(){
  const userStr = localStorage.getItem('user');
  if(!userStr){
      return null;
  }
  return JSON.parse(userStr);
}

export function timeout (ms) {
  return new Promise(resolve => {
    window.setTimeout(resolve, ms)
  })
}