<template>
    <div>
        <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
            <v-card>
                <v-toolbar color="primary" dark>
                    <v-toolbar-title>Detalle de transferencia - Información general</v-toolbar-title>
                    <v-spacer />
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-card-text>

                    <v-container mt-3>
                        <v-row>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Usuario que envía: </span>
                                <span class="info--text">{{ editedItem.user_send }}</span>
                            </v-col>

                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Usuario que recibe: </span>
                                <span class="info--text">{{ editedItem.user_receive }}</span>
                            </v-col>
                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Bodega de origen: </span>
                                <span class="info--text">{{ editedItem.warehouse_origin }}</span>
                            </v-col>

                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Bodega de destino: </span>
                                <span class="info--text">{{ editedItem.warehouse_destination }}</span>
                            </v-col>

                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Estado: </span>
                                <span class="info--text">{{ editedItem.status }}</span>
                            </v-col>

                            <v-col cols="12" md="6" class="my-0 py-0">
                                <span class="font-weight-bold">Fecha de registro: </span>
                                <span class="info--text">{{ editedItem.dateRegister }}</span>
                            </v-col>

                            <v-col cols="12" md="12" v-if="editedItem.observations !== null" class="my-0 py-0">
                                <span class="font-weight-bold">Observaciones: </span>
                                <span class="info--text">{{ editedItem.observations }}</span>
                            </v-col>
                        </v-row>
                    </v-container>

                    <v-container fluid mt-2>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table dense :headers="headers" :items="editedItem.details" class="elevation-1">
                                    <template v-slot:item.rejected="{ item }">
                                        <div>
                                            <v-icon v-if="!item.rejected" color="success">mdi-check</v-icon>
                                            <v-icon v-else color="red">mdi-close</v-icon>
                                        </div>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="secondary" @click.stop="close">Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "InfoMoveConsumables",
    props: {
        allInfoArticles: {
            type: Array,
            default: null
        },
    },
    data: () => {
        return {
            dialog: false,
            editedItem: {
            },
            headers: [
                { text: 'Nombre del Artículo', value: 'name' },
                { text: 'Unidades completas', value: 'full_amount_sent' },
                { text: 'Unidades Incompletas', value: 'incomplete_amount_sent' },
                { text: 'Elemento recibido', value: 'rejected' }

            ]

        }
    },
    methods: {
        open(item) {
            this.editedItem = {
                'id': item.id,
                'location': item.warehouse_destination.name,
                'user_send': item.user_send.name,
                'user_receive': item.user_receive ? item.user_receive.name : null,
                'warehouse_origin': item.warehouse_origin.name,
                'warehouse_destination': item.warehouse_destination ? item.warehouse_destination.name : null,
                'status': item.status,
                'dateRegister': item.dateRegister,
                'observations': item.observations !== null && item.observations.length !== 0 ? item.observations : null,
                'details': []
            };

            if (item && item.transfer_details.length > 0) {
                item.transfer_details.forEach((element) => {
                    const transferDetail = {
                        'id': element.id,
                        'full_amount_sent': element.full_amount_sent,
                        'incomplete_amount_sent': element.incomplete_amount_sent,
                        'rejected': element.rejected,
                        'article_id': element.article_id
                    };
                    this.editedItem.details.push(transferDetail);
                })
            }

            this.editedItem.details.forEach(elm => {
                const selectedItem = this.allInfoArticles.find(item => item.id === elm.article_id)
                elm['name'] = selectedItem.name
            })

            console.log(this.editedItem)
            this.dialog = true;

        },
        close() {
            this.dialog = false;
        }
    }
}
</script>

<style lang="scss" scoped></style>