<template>
  <div>
    <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
      <v-card>
        <v-toolbar dense color="primary" dark>
          <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
          <v-spacer/>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="close">
                <v-icon>fa-times</v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-form v-model="valid" ref="formInventoryFile" @submit.prevent="save">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="editedItem.file_type_id"
                    :rules="rules.file_type"
                    item-value="id" 
                    item-text="name" 
                    :items="allFileTypes"
                    label="Tipo de Archivo"
                    placeholder="Seleccione el Tipo de Archivo"
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" v-if="editedItem.id == 0">
                  <v-file-input label="Seleccionar Archivo"
                  :rules="rules.fileImport"
                    v-model="editedItem.file"></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-switch label="Archivo público" 
                    v-model="editedItem.is_public" ></v-switch>
                </v-col>
                <v-col cols=12>
                  <Signature :editedItem="editedItem"/>
                </v-col>
                <v-col cols=12 v-if="!editedItem.is_public">
                  <Permission :editedItem="editedItem"/>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" outlined @click="close">Cancelar</v-btn>
          <v-btn color="primary darken-1" outlined @click="save" :loading="loading"
           :disabled="loading" v-if="can('file.create')">{{ viewMode }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { can, objectToFormData } from '../../../util/functions';
  import { mapActions, mapGetters } from 'vuex'
  import Signature from './Signature';
  import Permission from './Permission';
  export default{
    name: 'FormInventoryFile',
    components: {Signature, Permission},
    data() {
      return {
        dialog: false,
        rules: {
          fileImport: [
            v => {
              if (v) {
                return true
              } else {
                return 'Campo requerido'
              }
            },
            //v => !v || v.size < 10000000 || 'No puede Subir Archivos mayor a 10 MB!'
          ],
          name: [
            v => !!v || 'Campo requerido',
            v => v.length >= 3 || 'Mínimo 3 caracteres',
            v => v.length < 100 || 'Máximo 100 letras',
          ],
          file_type: [
            v => !!v || 'Campo Requerido',
          ],
        },
        editedItem: {id: 0, file: null, is_public:false, signatures:[], permissions:[]},
        defaultItem: {id: 0, file: null, is_public:false, signatures:[], permissions:[]},
        valid: true
      }
    },

    computed: {
      ...mapGetters(['loading','allFileTypes']),
      formTitle () {
        return !this.editedItem.id ? 'Nuevo Registro' : 'Editar Registro'
      },
      viewMode () {
        return !this.editedItem.id ? 'Guardar' : 'Editar'
      },
      currentUser(){
        return this.$store.getters.currentUser
      }
    },
    methods: {
      ...mapActions(['saveInventoryFile', 'updateInventoryFile', 'chgLoad']),

      can:can,

      editItem (item=this.defaultItem) {
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        }, 300)
      },

      async save () {
        if(!this.loading){
          this.$refs.formInventoryFile.validate()
          if (this.valid){
            this.chgLoad(true)
            try{
              var msg = ""
              let formdata = objectToFormData(this.editedItem)
              if (this.editedItem.id){
                msg = await this.updateInventoryFile(formdata)
              }else{
                msg = await this.saveInventoryFile(formdata)
              }
              this.$root.$alert({text:msg.message,color:"success",timeout:2000})
              this.close()
            }catch(error){ console.log(error) 
              this.$root.$alert({text:"Ha Ocurrido un Error al guardar el Registro",color:"error",timeout:2000})
            }
            this.chgLoad(false)
          }else{
            this.$root.$alert({text:"Por favor revise los errores",color:"error",timeout:2000})
          }
        }
      },
    }
  }
</script>
