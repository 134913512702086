<template>
		<v-carousel cycle height="100%" show-arrows-on-hover>
			<v-carousel-item
				v-for="(item,i) in items"
				:key="i"
				:src="item.src"
				reverse-transition="slide-transition"
				transition="slide-transition"
			>
				<v-sheet color="blue-grey darken-3" class="titlecard"
					height="100%"
				>
					<v-row
						class="fill-height"
						align="center"
						justify="center"
					>
						<v-col cols="12">
							<div class="display-2 text-center mx-3 px-3 text-white">{{ item.title}}</div>
							<p></p>
							<div class="subtitle-1 text-center mx-3 px-3 text-white">{{ item.subtitle}}</div>
						</v-col>
					</v-row>
				</v-sheet>
			</v-carousel-item>
		</v-carousel>
</template>

<script>
export default {
	name: 'Welcome',
	data() {
		return {
			items:[
				{src:'/img/proyectos.png', title:'Proyectos de Investigación', subtitle:'Registro de Proyectos a Convocatorias de Semilleros y Proyectos de Investigación'},
				//{src:'/img/prestamos.png', title:'Préstamos', subtitle:'Gestión de Préstamos de Salas y Equipos a Cargo del Departamento de TICs'},
				//{src:'/img/grade.png', title:'Trabajos de Grado', subtitle:'Control y Seguimiento al Desarrollo y Evaluación de Trabajos de Grado '},
				//{src:'/img/events.png', title:'Registro de Eventos', subtitle:'Registro de Asistentes a Eventos Programados'},
				//{src:'/img/th.jpg', title:'Difusiones Talento Humano', subtitle:'Gestión de Imágenes de Difusión (Cumpleaños y Funcionarios)'},
				]
		}
	},
	mounted () {
		if(typeof this.$root.$loadApp === 'function') this.$root.$loadApp(false)
		if(typeof this.$root.$extSession === 'function') this.$root.$extSession(0)
	},
}
</script>
<style scoped lang="css">
.titlecard {
    opacity: 0.6;
  }
.slide-fade-enter-active {
  transition: all 2s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(30px);
  opacity: 0;
}
</style>