<template>
  <v-snackbar
    v-model="snackbar.show"
    :color="snackbar.color"
    right
    :timeout="snackbar.timeout"
    top
    transition="scale-transition"
  >
    <span :class="textColor">{{ snackbar.text }}</span>
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="snackbar.show = false"
        :class="textColor"
      >
        X
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data: () => ({
    snackbar: {
      show: false,
      text: '',
      color: '',
      timeout: 1000,
    },
  }),
  computed: {
    textColor(){
      if(this.snackbar.color.includes('light')){
        return 'black--text'
      }else if(this.snackbar.color.includes('warning')){
        return 'primary--text'
      }else{
        return 'white--text'
      }
    }
  },
  methods: {
    open(options) {
      this.snackbar = Object.assign(this.snackbar, options)
      this.snackbar.show = true
    },
    close() {
      this.snackbar.show = false
    },
  }
}
</script>