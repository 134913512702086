var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"max-width":"1000px","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary","dense":""}},[_c('v-toolbar-title',[_vm._v(" Selección de Usuarios ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mx-2 my-2",attrs:{"text":"","fab":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog = false}}},on),[_vm._v(" X ")])]}}])},[_c('span',[_vm._v("Cerrar")])])],1),_c('v-card-text',{staticStyle:{"min-height":"600px"}},[_c('v-text-field',{attrs:{"autofocus":"","prepend-icon":"fa-search","label":"Buscar","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.allUsers,"search":_vm.search,"loading":_vm.loading,"multi-sort":"","loading-text":"Cargando Registros ...","footer-props":{
             showFirstLastPage: true,
             firstIcon: 'fa-angle-double-left',
             lastIcon: 'fa-angle-double-right',
             prevIcon: 'fa-angle-left',
             nextIcon: 'fa-angle-right'
           }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.setUser(item)}}},[_c('td',[_vm._v(_vm._s(item.identity))]),_c('td',[_vm._v(_vm._s(item.name))])])]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }