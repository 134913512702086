import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance
const URL_ = URL_APP+"/api/loan/places"

const state = {
  places: [],
  myPlaces: []
}

const getters = {
  allPlaces: state => state.places,
  allMyPlaces: state => state.myPlaces
}

const actions = {

  async getPlaces({commit}){
    return new Promise(function(resolve, reject){
      commit('setPlaces', [])
      axios.get(URL_).then(res=>{
        commit('setPlaces', res.data)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async getMyPlaces({commit}, type){
    return new Promise(function(resolve, reject){
      commit('setMyPlaces', [])
      axios.get(URL_APP+"/api/loan/myplaces?type="+type).then(res=>{
        commit('setMyPlaces', res.data)
        resolve("OK")  
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async savePlace({commit}, place){
    return new Promise((resolve, reject)=>{
      axios.post(URL_, place).then(res=>{
        commit('newPlace', res.data.place)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async updatePlace({commit}, place){
    return new Promise((resolve, reject)=>{
      axios.put(URL_, place).then(res=>{
        //console.log(res.data)
        commit('updPlace', res.data.place)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  },

  async deletePlace({commit}, place){
    return new Promise((resolve, reject)=>{
      axios.delete(`${URL_}/${place.id}`).then(res=>{
        commit('delPlace', place)
        resolve(res.data)
      }).catch(err=>{
        reject(err)
      })
    })
  }
}

const mutations = {
  setPlaces : (state, places) => state.places = places,
  setMyPlaces : (state, places) => state.myPlaces = places,
  newPlace  : (state, place) => state.places.unshift(place),
  updPlace  : (state, place) => {
    const index = state.places.findIndex(u => u.id === place.id)
    if (index !== -1) state.places.splice(index, 1, place)
  },
  delPlace  : (state, place) => state.places = state.places.filter(c => c.id !== place.id)
}

export default {
  state, getters, actions, mutations
}