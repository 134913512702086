<template>
    <div>
        <v-card>
            <v-card-title primary-title>
                Traslados
            </v-card-title>
            <v-card-text>
                <FilterQuery :filters="filters" :fieldFilters="fieldFilters" @freshFilters="initialize2(1)"
                    id="filterQuery"></FilterQuery>
                <v-data-table :headers="headers" :items="allConsumablesTransfers" :search="search" item-key="id"
                    :options.sync="options" :sort-by="options.sortBy" :sort-desc.sync="options.sortDesc"
                    :items-per-page.sync="options.per_page" :server-items-length="totalConsumablesTransfers" multi-sort
                    :loading="loading" loading-text="Cargando..." :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'fa-angle-double-left',
                        lastIcon: 'fa-angle-double-right',
                        prevIcon: 'fa-angle-left',
                        nextIcon: 'fa-angle-right'
                    }">

                    <template v-slot:top>
                        <v-toolbar color="white">
                            <v-text-field v-model="search" prepend-icon="fa-search" label="Buscar..." clearable single-line
                                hide-details @keypress.enter="initialize2(1)" @click:clear="initialize2(1)"></v-text-field>
                            <v-spacer></v-spacer>
                            <v-tooltip bottom v-if="can('consumables.transfer-save')">
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn v-on="{ ...tooltip }" small color="primary" class="mx-2 my-2" fab dark
                                        @click.stop="$event => $refs.formStartTransfer_.openDialog()">

                                        <v-icon>fa-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>Nuevo</span>
                            </v-tooltip>
                        </v-toolbar>
                    </template>

                    <template v-slot:item.actions="{ item }">

                        <v-tooltip left v-if="can('consumables.transfer-list')">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2" @click="$event => $refs.infoMoveView_.open(item)"
                                    color="blue">
                                    fa-eye
                                </v-icon>
                            </template>
                            <span>Ver</span>
                        </v-tooltip>

                        <v-tooltip top
                            v-if="can('consumables.transfer-save') && !item.confirmed && allMyWarehouses.some(wh => wh.id === item.warehouse_destination_id)">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2" color="green"
                                    @click="$event => $refs.formSaveTransfer_.openDialog(item)">
                                    fa-check-square
                                </v-icon>
                            </template>
                            <span>Validar recepción</span>
                        </v-tooltip>


                        <v-tooltip top
                            v-if="can('consumables.transfer-save') && item.action == 'Traslado' && !item.confirmed">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" class="mr-2"
                                    @click="$event => $refs.formPurchase_.editItem(item, params = { action: 'Editar' })"
                                    color="orange">
                                    fa-pen
                                </v-icon>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                    </template>
                </v-data-table>


            </v-card-text>
        </v-card>
        <FormStartTransfer ref="formStartTransfer_" :allMyWarehouses="allMyWarehouses" :allWarehouses="allWarehouses"
            :allArticlesLocation="allArticlesLocation" :allInfoArticles="allInfoArticles" @initialize3="initialize3" />
        <InfoMoveView ref="infoMoveView_" :allInfoArticles="allInfoArticles" />
        <FormSaveTransfer ref="formSaveTransfer_" :allInfoArticles="allInfoArticles" @initialize2="initialize2" />
    </div>
</template>

<script>
import FilterQuery from '../../../general/FilterQuery.vue';
import FormStartTransfer from './FormStartTransfer.vue';
import FormSaveTransfer from './FormSaveTransfer.vue'
import InfoMoveView from './InfoMoveView.vue';
import { mapGetters, mapActions } from 'vuex';
import { can } from '../../../../util/functions';
export default {
    name: "MoveConsumables",

    components: {
        FormStartTransfer, FilterQuery, InfoMoveView, FormSaveTransfer
    },
    data: () => {
        return {
            headers: [
                { text: 'ID', value: 'id', sortable: true },
                { text: 'Fecha de registro', value: 'dateRegister', sortable: false },
                { text: 'Usuario que envía', value: 'user_send.name', sortable: false },
                { text: 'Usuario que recibe', value: 'user_receive.name', sortable: false },
                { text: 'Bodega de origen', value: 'warehouse_origin.name', sortable: false },
                { text: 'Bodega de destino', value: 'warehouse_destination.name', sortable: false },
                { text: 'Estado de revisión', value: 'status', sortable: false },
                { text: 'Acciones', value: 'actions', sortable: false },
            ],
            items: [],
            search: '',
            loading: false,
            editedIndex: -1,
            options: { page: 1, per_page: 10, sortBy: ['confirmed'], sortDesc: [false] },
            filters: [
                { field: { field: 'confirmed', name: 'Confirmación', type: 'select', options: ['true', 'false'] }, operator: '=', value_start: 'false', value_end: '' }
            ],
            fieldFilters: [
                { field: 'confirmed', name: 'Confirmación', type: 'select', options: ['true', 'false'] },
                { field: 'action', name: 'Acción', type: 'text' },
                { field: 'created_at', name: 'Fecha de creación', type: 'date' },
            ],
        }
    },

    mounted() {
        this.initialize()
        var this_2 = this
        window.addEventListener('keydown', function (e) {
            if (e.altKey == true && e.code == 'KeyN') {
                this_2.$refs.formStartTransfer_.newItem()
            }
        });
    },
    methods: {
        ...mapActions(['chgLoad', 'getConsumablesTransfer', 'getWarehouses', 'getMyWarehouses', 'getAllInfoArticles', 'getArticlesLocation']),
        can: can,

        async initialize() {
            this.chgLoad(true);
            this.getWarehouses();
            this.getMyWarehouses();
            this.getAllInfoArticles();
            this.getArticlesLocation();
            var params = { filters: this.filters, options: this.options, search: this.search }
            try {
                await this.getConsumablesTransfer(params)

            }
            catch (error) {
                this.$root.$alert({ text: "Error al Obtener las transferencias", color: "error", timeout: 2000 })
            }
            this.chgLoad(false);
        },

        async initialize2(page = null) {
            if (!this.loading) {
                if (page != null) this.options.page = page
                this.chgLoad(true);
                this.getWarehouses();
                this.getMyWarehouses();
                this.getAllInfoArticles();
                this.getArticlesLocation();
                var params = { filters: this.filters, options: this.options, search: this.search }
                try {
                    await this.getConsumablesTransfer(params)
                }
                catch (error) {
                    this.$root.$alert({ text: "Error al Obtener las transferencias", color: "error", timeout: 2000 })
                }
                this.chgLoad(false);
                this.loading = false;
            }
            else {
                this.chgLoad(false);
            }

            this.chgLoad(false);

        },

        async initialize3() {
            this.getArticlesLocation();
        }
    },

    watch: {
        'options.page'() {
            this.initialize2()
        },
        'options.per_page'() {
            this.initialize2()
        },
        'options.sortBy'() {
            this.initialize2()
        },
        'options.sortDesc'() {
            this.initialize2()
        },
    },

    computed: {
        ...mapGetters(['allConsumablesTransfers', 'totalConsumablesTransfers', 'allWarehouses', 'allMyWarehouses', 'allInfoArticles', 'allArticlesLocation'])
    }

}
</script>

<style lang="scss" scoped></style>