<template>
    <div>
        <v-dialog v-model="dialog" scrollable persistent :overlay="false" :max-width="dialogWidth"
            transition="dialog-transition">
            <div v-if="Object.keys(editedItem).length === 0">
                <v-card>
                    <v-toolbar dense color="primary" dark>
                        <v-toolbar-title>Baja Masiva</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="closeItem">
                                    <v-icon>fa-times</v-icon>
                                </v-btn>
                            </template>
                            <span>Cerrar</span>
                        </v-tooltip>
                    </v-toolbar>

                    <v-card-text>
                        <v-form ref="formAttachment_">
                            <v-row>
                                <v-col cols="12">
                                    <v-file-input accept=".xls,.xlsx" label="Seleccionar Archivo"
                                        :rules="rules.fileImport" v-model="formImport.fileImport"></v-file-input>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="secondary" @click.stop="closeItem">Cancelar</v-btn>
                        <v-btn color="primary" @click.stop="findItems">Iniciar búsqueda</v-btn>
                    </v-card-actions>
                </v-card>
            </div>

            <div v-else>
                <v-card>
                    <v-toolbar dense color="primary" dark>
                        <v-toolbar-title>Elementos para baja</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="closeItem">
                                    <v-icon>fa-times</v-icon>
                                </v-btn>
                            </template>
                            <span>Cerrar</span>
                        </v-tooltip>

                        <v-tooltip top v-if="searchErrors.length > 0">
                            <template v-slot:activator="{ on }">
                                <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="showErrors"
                                    color="orange darken-2">
                                    <v-icon>mdi-alert-circle</v-icon>
                                </v-btn>
                            </template>
                            <span>Ver errores</span>
                        </v-tooltip>
                    </v-toolbar>
                    <v-card-text>
                        <v-data-table :headers="headers" :items="editedItem" class="mr-4 tl-4">

                            <template v-slot:item.found="{ item }">
                                <span v-if="item.found">SI</span>
                                <span v-else>NO</span>
                            </template>

                        </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="secondary" @click.stop="closeItem">Cerrar</v-btn>
                        <v-btn color="primary" @click.stop="saveItem">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </div>

        </v-dialog>

        <v-dialog v-model="dialogErrors" scrollable persistent :overlay="false" max-width="800px"
            transition="dialog-transition">
            <div>
                <v-card>
                    <v-toolbar dense color="orange darken-3" dark>
                        <v-toolbar-title>Errores!</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn class="mx-2 my-2" text fab small v-on="on" @click.stop="closeErrors">
                                    <v-icon>fa-times</v-icon>
                                </v-btn>
                            </template>
                            <span>Cerrar</span>
                        </v-tooltip>

                    </v-toolbar>

                    <v-card-text>
                        <v-data-table :headers="headersErrors" :items="formattedErrors"></v-data-table>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="secondary" @click.stop="closeErrors">Cerrar</v-btn>
                    </v-card-actions>
                </v-card>
            </div>
        </v-dialog>
    </div>
</template>

<script>
// import { objectToFormData } from '../../../../util/functions';
import { URL_APP } from '../../../../util/constants.js'
import { mapActions, mapGetters } from 'vuex';
import { mostrarSweetAlert } from '../../../../util/sweetAlertUtil';
export default {
    name: "ViewDeprecationMassive",
    data: () => {
        return {
            dialog: false,
            dialogErrors: false,
            editedItem: {},
            searchErrors: [],
            saveErrors: [],
            formImport: {
                fileImport: null,
            },
            rules: {
                fileImport: [
                    (v) => !!v || 'El archivo es requerido',
                    v => (v && v.size < 10000000) || 'No puede Subir Archivos mayor a 10 MB!'
                ]
            },
            headers: [
                { text: 'Descripción del artículo', value: 'description', width: '20%', sortable: false },
                { text: 'Inventario actual', value: 'inventory' },
                { text: 'Inventario anterior', value: 'old_inventory', sortable: false },
                { text: 'Serial', value: 'serial', sortable: false },
                { text: 'Estado', value: 'status.description', sortable: false },
                { text: 'Encontrado', value: 'found', sortable: false },
                { text: 'Responsable', value: 'third.name', sortable: false },
                { text: 'Observaciones registradas', value: 'local_observation', sortable: false },

            ],
            headersErrors: [
                { text: 'Descripción del error', value: 'error' }
            ],
        }
    },

    methods: {
        ...mapActions(['chgLoad', 'saveMassiveDeprecation']),
        openItem() {
            this.dialog = true
        },

        async findItems() {
            if (this.$refs.formAttachment_.validate()) {
                this.chgLoad(true);
                let formData = new FormData();
                formData.append('fileImport', this.formImport.fileImport);

                try {
                    let response = await this.$http.post(URL_APP + '/api/loan_assets/deprecation_massive_assets', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    // Asignar los datos importados a la variable importedData
                    this.editedItem = response.data.data;
                    this.searchErrors = response.data.errors;

                    if (this.editedItem.length === 0) {
                        this.$root.$alert({ text: "Todos los elementos están dados de baja.", color: "error", timeout: 5000 });
                    }
                    else if (this.searchErrors.length > 0) {
                        this.$root.$alert({ text: "Se han encontrado errores durante la consulta.", color: "warning", timeout: 5000 });
                    }

                    else {
                        this.$root.$alert({ text: "Consulta realizada correctamente", color: "success", timeout: 5000 });
                    }
                } catch (error) {
                    this.$root.$alert({ text: "No se realizó la consulta.", color: "error", timeout: 5000 });
                } finally {
                    this.chgLoad(false);
                }
            }
        },

        closeItem() {
            this.dialog = false
            this.editedItem = {}
            this.searchErrors = []
            this.formImport.fileImport = null
        },

        showErrors() {
            if (this.searchErrors.length > 0) {
                this.dialogErrors = true
            }
        },

        closeErrors() {
            this.dialogErrors = false
        },

        async saveItem() {
            const confirmacion = await mostrarSweetAlert({
                titulo: 'Confirmación',
                texto: '¿Estás seguro que desea realizar la baja masiva?',
                icono: 'warning',
                textoBotonConfirmar: 'Confirmar',
                textoBotonCancelar: 'Cancelar',
            });

            if (confirmacion.isConfirmed) {
                this.chgLoad(true);
                try {
                    const msg = await this.saveMassiveDeprecation(this.editedItem);
                    if (msg.errors && msg.errors.length > 0) {
                        this.saveErrors = msg.errors;
                        const tablaHTML = this.generarHTMLTabla(this.saveErrors);
                        this.chgLoad(false);

                        mostrarSweetAlert({
                            titulo: 'Errores Encontrados',
                            html: tablaHTML,
                            icono: 'error',
                            textoBotonConfirmar: 'Cerrar',
                            textoBotonCancelar: null,
                        });
                        this.$emit('item-saved');
                    } else {
                        this.$root.$alert({ text: msg.message, color: "success", timeout: 6000 });
                        this.chgLoad(false);
                        this.closeItem();
                        this.$emit('item-saved');
                    }
                } catch (error) {
                    this.$root.$alert({ text: error.response.data.message, color: "error", timeout: 2000 });
                    this.chgLoad(false);
                }
            }
        },


        generarHTMLTabla(errors) {
            let html = '<table border="1" style="width: 100%; border-collapse: collapse;">';
            html += '<thead><tr><th>Mensajes de Error</th></tr></thead>';
            html += '<tbody>';
            errors.forEach(error => {
                html += `<tr><td>${error}</td></tr>`;
            });
            html += '</tbody></table>';
            return html;
        }

    },

    computed: {
        ...mapGetters((['loading'])),
        dialogWidth() {
            return Object.keys(this.editedItem).length === 0 ? '800px' : '1400px';
        },
        formattedErrors() {
            return this.searchErrors.map(error => ({ error }));
        }

    }
}
</script>

<style lang="scss" scoped></style>