 import { URL_APP } from "../../../util/constants";
 import axiosInstance from "../../../helpers/axiosInstance";

 const axios = axiosInstance;
 const URL_ = URL_APP + "/api/loan_consumables/suppliers";

 const state = {
    suppliers: []
 }

 const getters = {
    allSuppliers: state => state.suppliers
 }

 const actions = {
    async getSuppliers({commit}){
        return new Promise(function(resolve, reject){
            commit('setSuppliers', []);
            axios.get(URL_)
            .then(res => {
                commit('setSuppliers', res.data);
                resolve("OK")
            })
            .catch((err) => {
                reject(err)
            })
        })
    },

    async saveSupplier({commit}, supplier){
        return new Promise ((resolve, reject) => {
            axios.post(URL_, supplier)
            .then((res) => {
                commit('newSupplier', res.data.supplier)
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
        })
    },

    async updateSupplier({commit}, supplier){
        return new Promise ((resolve, reject) => {
            axios.put(URL_, supplier)
            .then((res) => {
                commit('updateSupplier', res.data.supplier)
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
        })
    },

    async deleteSupplier({commit}, supplier){
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_}/${supplier}`)
            .then((res) => {
                commit('deleteSupplier', supplier)
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
        })
    }
 }

 const mutations = {
    setSuppliers : (state, suppliers) => state.suppliers = suppliers,
    newSupplier : (state, supplier) => state.suppliers.unshift(supplier),
    updateSupplier : (state, supplier) => {
        const index = state.suppliers.findIndex(u => u.id === supplier.id)
        if(index !== -1){
            state.suppliers.splice(index, 1, supplier)
        }
    },
    deleteSupplier : (state, supplier) => state.suppliers = state.suppliers.filter(c => c.id !== supplier.id)


 }

 export default {
    state, getters, actions, mutations
 }