import { URL_APP } from "../../../util/constants";
import axiosInstance from "../../../helpers/axiosInstance";

const axios = axiosInstance;
const URL_ = URL_APP + "/api/loan_consumables/units_measurements";

const state = {
    unitsMeasurements: []
 }

 const getters = {
    allUnitsMeasurements: state => state.unitsMeasurements
 }

 const actions = {
    async getUnitsMeasurements({commit}){
        return new Promise(function(resolve, reject){
            commit('setUnitsMeasurements', []);
            axios.get(URL_)
            .then(res => {
                commit('setUnitsMeasurements', res.data);
                resolve("OK")
            })
            .catch((err) => {
                reject(err)
            })
        })
    },

    async getActiveUnitsMeasurements({commit}){
        return new Promise(function(resolve, reject){
            commit('setActiveUnitsMeasurements', []);
            axios.get(URL_APP + '/api/loan_consumables/getUnits_measurements')
            .then( (res) => {
                commit('setActiveUnitsMeasurements', res.data);
                resolve("OK")
            })
            .catch( (err) => {
                reject(err)

            })
        })
    },

    async saveUnitsMeasurement({commit}, unitsMeasurement){
        return new Promise ((resolve, reject) => {
            axios.post(URL_, unitsMeasurement)
            .then((res) => {
                commit('newUnitsMeasurement', res.data.unitsMeasurement)
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
        })
    },

    async updateUnitsMeasurement({commit}, unitsMeasurement){
        return new Promise ((resolve, reject) => {
            axios.put(URL_, unitsMeasurement)
            .then((res) => {
                commit('updateUnitsMeasurement', res.data.unitsMeasurement)
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
        })
    },

    async deleteUnitsMeasurement({commit}, unitsMeasurement){
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_}/${unitsMeasurement}`)
            .then((res) => {
                commit('deleteUnitsMeasurement', unitsMeasurement)
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
        })
    }
}

 const mutations = {
    setUnitsMeasurements : (state, unitsMeasurements) => state.unitsMeasurements = unitsMeasurements,
    setActiveUnitsMeasurements :(state, unitsMeasurements) => state.unitsMeasurements = unitsMeasurements,
    newUnitsMeasurement : (state, unitsMeasurement) => state.unitsMeasurements.unshift(unitsMeasurement),
    updateUnitsMeasurement : (state, unitsMeasurement) => {
        const index = state.unitsMeasurements.findIndex(u => u.id === unitsMeasurement.id)
        if(index !== -1){
            state.unitsMeasurements.splice(index, 1, unitsMeasurement)
        }
    },
    deleteUnitsMeasurement : (state, unitsMeasurement) => state.unitsMeasurements = state.unitsMeasurements.filter(c => c.id  !== unitsMeasurement.id),

 }

 export default {
    state, getters, actions, mutations
 }